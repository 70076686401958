import {CHANGE_PROTECTED_PAGE,LOAD_PROTECTED_PAYMENTS,LOAD_PROTECTED_PAYMENTS_SUCCESS,LOAD_PROTECTED_PAYMENTS_ERROR} from '../constants/constants'

const initialState = {
  pageProtectedNum: 0,
  isLoading: true,
  inProgressPayments:[],
  completedPayments:[],
  rejectedPayments:[],
}

const reducer = function protectedpaymentsReducer (
  state = initialState,
  action,
) {
  switch (action.type) {
    case CHANGE_PROTECTED_PAGE: {
      return {
        ...state,
        pageProtectedNum: action.pageNum,
      }
    }
    case LOAD_PROTECTED_PAYMENTS: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case LOAD_PROTECTED_PAYMENTS_SUCCESS: {
      return {
        ...state,
        inProgressPayments:action.response.inProgressList,
        completedPayments:action.response.completedList,
        rejectedPayments:action.response.rejectedList,
        isLoading: false,
      }
    }
    case LOAD_PROTECTED_PAYMENTS_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
