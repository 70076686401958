import {takeLatest, call, put, select, delay} from 'redux-saga/effects';
import {
  LINK_ACCOUNT_DETAILS,
  LINK_ACCOUNT_DETAILS_ERROR,
  LINK_ACCOUNT_DETAILS_SUCCESS,
  LOAD_ACCOUNT,
  LOAD_ACCOUNT_SUCCESS,
  LOAD_ACCOUNT_ERROR,
} from '../constants/constants';
import AccountService from '../../services/account.service';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import logsService from '../../services/logs.service';


const execLinkAccount = async actionData => {
  try {
    var currentUser = firebase.auth().currentUser.uid;
    //console.log("dkfg");
    var params = {
      uid: currentUser,
      type: actionData.mode,
      address: actionData.address,
    };
    if (actionData.mode == 'upi') {
      params.upi = actionData.upi;
    } else if (actionData.mode == 'bank') {
      params.acNumber = actionData.acNumber;
      params.ifsc = actionData.ifsc;
      params.acName = actionData.name;
    }
    var temp = await AccountService.linkAccount(params);
    logsService.logCustomEvent('add_beneficiary',{
      userid:currentUser,
      type:actionData.mode
    })
    return true;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* linkAccountDetails(action) {
  try {
    const res = yield call(execLinkAccount, action);
    yield put({
      type: LINK_ACCOUNT_DETAILS_SUCCESS,
      response: {successfullyLinked: res},
    });
  } catch (error) {
    // console.log(error);
    logsService.logCustomEvent('add_beneficiary_error',{
      userId: firebase.auth().currentUser.uid,
      description: error.response.data.message
    })
    yield put({
      type: LINK_ACCOUNT_DETAILS_ERROR,
      response: {message: error.response.data.message},
    });
  }
}

const execLoadAccount = async actionData => {
  try {
    var result = await AccountService.pendingSettlements();
    return result;
  } catch (error) {
    //console.log(error);
    throw {message: 'Something went wrong'};
  }
};

function* loadAccountDetails(action) {
  try {
    const res = yield call(execLoadAccount, action);
    yield put({type: LOAD_ACCOUNT_SUCCESS, response: res});
  } catch (error) {
    //console.log(error);
    yield put({type: LOAD_ACCOUNT_ERROR, response: {message: error}});
  }
}

function* accountsWatcher() {
  yield takeLatest(LINK_ACCOUNT_DETAILS, linkAccountDetails);
  yield takeLatest(LOAD_ACCOUNT, loadAccountDetails);
}

export default accountsWatcher;
