//import liraries
import React, {Component, useEffect} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native'
import config from '../../config'
import PrimaryButton from '../../components/PrimaryButton'
import FIcon from 'react-native-vector-icons/SimpleLineIcons'
import {connect} from 'react-redux'
import {CommonActions} from '@react-navigation/native'
import Lock from '../../assets/images/Lock.svg'
import {
  initState,
  resetState,
  makeRequest,
} from '../../state-management/actions/requestpayment.action'
import AIcon from 'react-native-vector-icons/AntDesign';
import { setFloatingData , resetFloatingData } from '../../state-management/actions/floating.action'
import { switchAuth } from '../../state-management/actions/auth.action'

const RequestPayment = props => {
  useEffect(() => {
    props.resetFloatingData()
    const {transaction_details} = props.route.params
    props.initState(transaction_details)
    return () => {}
  }, [])

  useEffect(() => {
    if (props.success) {
      props.resetState()
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {name: 'Main'},
            {
              name: 'PaymentResult',
              params: {
                mode: props.transaction_details.mode,
                uid: props.transaction_details.to_user,
              },
            },
          ],
        }),
      )
    }
  })

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{flexDirection: 'row', alignItems: 'center', padding: 20}}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => {
              if(history && history.back){
                history.back();
              }else{
                props.navigation.goBack()
              }
            }}>
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: 30,
              fontFamily: 'Roboto-Medium',
              color: config.primary_color,
            }}>
            Request Payment
          </Text>
        </View>
        <View style={styles.inputContainer}>


          <Lock width={70} height={70} style={{alignSelf: 'center'}}/>

          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 20,
              color: '#707070',
              lineHeight: 30,
              marginVertical: '5%',
            }}>
            A payment request will be sent to the Buyer
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto-Bold',
              fontSize: 18,
              color: config.primary_color,
              marginTop: 20,
              paddingLeft: 10,
            }}>
            Full Amount : Rs {props?.transaction_details?.total_amount}
          </Text>
          <PrimaryButton
            loading={props.isLoading}
            content={'Request full amount'}
            clicked={() => {
              if(props.auth.isLoginLater){
                props.setFloatingData({
                  transaction_details:props.transaction_details,
                  mode:'Request_Payment'
                })
                props.switchAuth()
              }else{
              props.makeRequest(props.transaction_details, true)
              }
            }}
          />
          {props?.transaction_details?.isVirtual == false ? (
            <View>
              <View style={{marginVertical: '10%'}}>
                  {/* <View style={{alignSelf:'center',position:'absolute',borderBottomColor:'#000',borderBottomWidth:1.5,height:'55%',width:'97%'}}/> */}
                  <Text style={{backgroundColor:'#FFF',fontFamily: 'Roboto-Bold',fontSize: 18,alignSelf:'center',paddingHorizontal:15,color:config.primary_color,}}>
                    OR
                  </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'Roboto-Bold',
                  fontSize: 18,
                  color: config.primary_color,
                  paddingLeft: 10,
                }}>
                1st Milestone : Rs {props?.milestones[0]?.milestoneAmount}
              </Text>
              <PrimaryButton
                loading={props.isLoading}
                content={'Request 1st Milestone'}
                clicked={() => {
                  if(props.auth.isLoginLater){
                    props.setFloatingData({
                      transaction_details:props.transaction_details,
                      mode:'Request_Payment'
                    })
                    props.switchAuth()
                  }else{
                  props.makeRequest(props.transaction_details, false)
                  }
                }}
              />
            </View>
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginRight: 30,
    marginTop:5,
    fontSize:25
  },
  inputContainer: {
    padding: '8%',
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
})

export default connect(state => ({auth:{...state.auth},...state.requestpayment}), {
  switchAuth,
  setFloatingData,
  initState,
  makeRequest,
  resetState,
  resetFloatingData
})(RequestPayment)
