import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  BackHandler,
  ActivityIndicator,
} from 'react-native';
import {Icon, Textarea, Picker} from 'native-base';
import config from '../../config';
import InputText from '../../components/InputText';
import {Formik} from 'formik';
import * as yup from 'yup';
import {connect} from 'react-redux';
import PaymentDetailsButton from '../../components/PaymentDetailsButton';
import AIcon from 'react-native-vector-icons/AntDesign';
import SVG2 from '../../assets/images/paydetails2.svg';
import { resetContactInfo } from '../../state-management/actions/category.action'
import { requestRecommendation,initRecommendation,fetchRecommendation,submitRecommendation} from '../../state-management/actions/recommendation.action'
import LottieView from 'lottie-react-web'
import {CommonActions} from '@react-navigation/native'
import {Thumbnail} from 'native-base'
import TickAnim from '../../assets/lottie-animations/tick-anim.json'
import AwesomeAlert from 'react-native-awesome-alerts';
import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
import { resetFloatingData } from '../../state-management/actions/floating.action'

class SaveRecommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false,
      alertVisible: false,
      response: '',
      feedbackVisible: false,
      alertText: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSuccess != prevProps.isSuccess) {
      if (this.props.isSuccess == true) {
        this.setState({alertVisible:true})
      }
    }
    if (this.props.error != prevProps.error) {
      if (this.props.error != '') {
        // console.log(this.props.error);
        alert(this.props.error)
      }
    }
    if(this.props?.recommendationDetails?.to_user){
      const currentUser = firebase.auth().currentUser.uid
      // console.log(this.props?.recommendationDetails?.to_user);
      // console.log(currentUser);
      if(this.props?.recommendationDetails?.to_user != currentUser){
        this.props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {name: 'Home'},
            {name: 'Errorpage'}
          ],
        }))
      }
    }
  }

  componentDidMount() {
    this.props.initRecommendation();
    this.props.resetFloatingData();
    this.props.fetchRecommendation(this.props.route.params?.id)
  }

  componentWillUnmount() {
    this.props.resetContactInfo()
  }

  handleresponse = response => {
    this.setState({response});
  };

  handleSubmit = values => {
    if (values.response.trim() != '' ) {
      this.props.submitRecommendation(this.props.route.params?.id,values.response.trim())
    } else {
      alert('Please fill all the fields');
    }
  };

  onValueChange(value) {
    this.setState({
      selected: value,
    });
  }

  render() {
    const {response} = this.state;
    const validationSchema = yup.object().shape({
      response: yup
        .string()
        .label('response')
        .required(),
    });

    return (
      <Formik
        initialValues={{
            response: '',
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => this.props.isFetchLoading?(<View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color={config.primary_color} />
        </View>)
        :(<SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
              <View
                style={{
                  backgroundColor: '#fff',
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    // alignItems: 'center',
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    paddingBottom: 10,
                    backgroundColor: '#fff',
                  }}>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => {
                      if(this.props.recommendationDetails?.recommendation_text) this.props.navigation.goBack()
                      this.setState({feedbackVisible: true})}}>
                    <AIcon name="left" style={styles.backButton} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: 'OpenSans-Regular',
                      color: config.primary_color,
                    }}>
                        {`${this.props.recommendationDetails?.firstname} ${this.props.recommendationDetails?.lastname} requested a \nrecommendation`}
                  </Text>
                </View>

                {/* ***********************************  Parterner name   ************************************ */}

                <View style={styles.partnerElement}>
                  {
                    this.props?.recommendationDetails.profile_image_path ? (
                      <Thumbnail
                        source={{uri: this.props.recommendationDetails?.profile_image_path}}
                        style={{
                          width: 50,
                          height: 50,
                        }}
                      />
                    ) : (
                  <TouchableOpacity
                  onPress={()=>{this.props.navigation.navigate('UserProfile',{key:'mobile',value:mobile})}}
                    style={{
                      backgroundColor: '#B80D57',
                      width: 55,
                      height: 55,
                      justifyContent: 'center',
                      borderRadius: 50,
                      alignSelf: 'center',
                      borderWidth: 1.5,
                      borderColor: config.primary_color,
                    }}>
                    <Text
                      style={{
                        fontSize: 20,
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Roboto-Bold',
                      }}>
                      {this.props.recommendationDetails?.firstname?
                    this.props.recommendationDetails.firstname[0]:''  
                    }
                    </Text>
                  </TouchableOpacity>
                    )}
                  <TouchableOpacity
                  onPress={()=>{this.props.navigation.navigate('UserProfile',{key:'mobile',value:mobile})}}
                  style={{flex: 1, justifyContent: 'space-evenly'}}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Bold',
                        color: config.primary_color,
                        paddingHorizontal: 10,
                      }}>
                      {`${this.props.recommendationDetails?.firstname} ${this.props.recommendationDetails?.lastname}`}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Regular',
                        color: '#707070',
                        paddingHorizontal: 10,
                      }}>
                      {this.props.recommendationDetails?.mobile}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View style={styles.inputContainer}>
                {/* *****************************  Select  **************************** */}
                <Text
                  style={styles.text1}>
                  Category
                </Text>
                <Text
                style={styles.text2}>
                      {this.props.recommendationDetails?.category}
                </Text>
                <View style={{height: 20, width: '95%', paddingTop: 3}} />

                <Text
                  style={styles.text1}>
                  Amount
                </Text>
                <Text
                style={styles.text2}>
                    {`\u20B9 ${this.props.recommendationDetails?.total_amount}`}                    
                </Text>
                <View style={{height: 20, width: '95%', paddingTop: 3}} />
                <Text
                  style={styles.text1}>
                  Payment for
                </Text>
                <Text
                style={styles.text2}>
                      {this.props.recommendationDetails?.gig_name}
                </Text>
                <View style={{height: 20, width: '95%', paddingTop: 3}} />
                <Text
                  style={styles.text1}>
                  Details of the transaciton
                </Text>
                <Text
                style={styles.text2}>
                      {this.props.recommendationDetails?.transaction_details}
                </Text>
                <View style={{height: 20, width: '95%', paddingTop: 3}} />

                <Text
                  style={styles.text1}>
                    Personal message
                </Text>
                <Text
                style={styles.text2}>
                      {this.props.recommendationDetails?.personal_message}
                </Text>
                <View style={{height: 20, width: '95%', paddingTop: 3}} />
                <Text style={styles.text1}>
                    Your recommendation
                </Text>
                {this.props.recommendationDetails?.recommendation_text?
                <Text style={styles.text2}>
                      {this.props.recommendationDetails?.recommendation_text}
                </Text>
                :<Textarea
                  rowSpan={5}
                  bordered
                  name="response"
                  placeholder={`Write your recommendation. Give your recommendation and make ${this.props.recommendationDetails?.firstname} ${this.props.recommendationDetails?.lastname} a Rockstar :)`}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('response')}
                  onBlur={handleBlur('response')}
                  error={touched.response && errors.response}
                  value={values.response}
                  style={{fontSize: 14, padding: 15}}
                />}
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.response && errors.response && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify response
                    </Text>
                  )}
                </View>
              </View>
            </ScrollView>
            {this.props.recommendationDetails?.recommendation_text?
            null
            :<View>
              <PaymentDetailsButton
                content={'Submit'}
                clicked={handleSubmit}
                loading={this.props.isLoading}
              />
            </View>}


            <AwesomeAlert
                show={this.state.alertVisible}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                  style={{
                    alignItems: 'center',
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}>
                    <LottieView
                      options={{
                        animationData: TickAnim,
                        autoplay: true,
                        loop: false,
                      }}
                    style={{
                      width: 100,
                      height: 120,
                    }}
                    />
                  <Text style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#707070',
                      lineHeight: 21,
                    }}>
                    Thanks for submitting your response
                  </Text>
                </View>
                )}
                showCancelButton={false}
                showConfirmButton={true}
                confirmText="OK"
                confirmButtonColor={config.primary_color}
                onConfirmPressed={() =>{
                  this.setState({alertVisible: false}, () => {
                    this.props.navigation.dispatch(
                      CommonActions.reset({
                        index: 1,
                        routes: [
                          {name: 'Home'},
                        ]
                      }))
                  });
                }}
              />
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    // width: 20,
    // height: 30,
    fontSize: 22,
    marginRight: 10,
    marginTop: 3,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  partnerElement: {
    flexDirection: 'row',
    borderColor: '#AAA',
    marginTop: 15,
    paddingHorizontal: '9%',
  },
  inputContainer: {
    padding: '9%',
    paddingBottom: 0,
  },
  default: {
    paddingVertical: '2%',
    height:17,
    borderRadius:100
  },
  text1:{
    fontFamily: 'OpenSans-Bold',
    fontSize: 14,
    color: '#473BB3',
    marginTop:15
  },
  text2:{
    fontFamily: 'OpenSans-Regular',
    fontSize: 12,
    color: '#828282',
    marginTop:10,
    paddingLeft:10
}
});

export default connect(
  state => ({
    category: {...state.category},
    contacts: {...state.contacts},
    ...state.recommendations,
}),{resetContactInfo,requestRecommendation,initRecommendation,fetchRecommendation,submitRecommendation,resetFloatingData})(SaveRecommendation)