import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  Modal,
  Image,
  ImageBackground
} from 'react-native'
import config from '../config'
import {Icon, Textarea} from 'native-base'
import InputText from './InputText'
import PrimaryButton from './PrimaryButton'
import {Formik} from 'formik'
import * as yup from 'yup'
import AIcon from 'react-native-vector-icons/AntDesign'
import AddPhoto from './Profile/AddPhoto'
import EIcon from 'react-native-vector-icons/Entypo'
import imageService from '../services/image.service'

export default class DeliveryDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      details: '',
      alertVisible:false,
      showImagePicker:false,
      imagesData:null,
      milestoneAmount:null
    }
  }

  componentDidMount () {
    var currentMilestone=this.props.gigMilestoneDetails.find((obj) => {
      if(obj.uid == this.props.currentMilestoneId) return obj
    })
    // console.log(typeof(currentMilestone.milestoneAmount));
    this.setState({milestoneAmount:currentMilestone.milestoneAmount});
  }

  handleDetails = details => {
    this.setState({details})
  }
  handleSubmit = values => {
    if (values.details.trim() != '') {
      if(this.state.milestoneAmount>=2000 && !this.state.imagesData){
        return alert('Attachment is mandatory')
      }
    this.setState({alertVisible:true})
    }
    else alert('Please fill all the fields')
  }

  render () {
    var {details} = this.state
    const validationSchema = yup.object().shape({
      details: yup.string().label('details')
      .trim('Delivery details is mandatory')
      .required('Delivery details is mandatory')
      .max(500, 'Must have at most 500 characters'),
    })
    return (
      <Formik
        initialValues={{
          details: details,
        }}
        onSubmit={values => {
          this.handleSubmit(values)
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
            <View style={{flex:1}}>
              
            <View style={[{alignItems: 'center',position:'absolute',zIndex:100,justifyContent:'center',width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.7)'},{zIndex:this.state.alertVisible?100:0}]}>
                <View style={{backgroundColor: '#FFF',borderRadius:10,alignItems:'center',paddingTop:30,paddingVertical:20,lineHeight:21,paddingHorizontal:30,marginHorizontal:"5%"}}>
                    <Text style={{fontFamily:'Roboto-Regular',fontSize:16,color:'#707070'}}>
                    Are you sure you want to mark the order as completed? {this.props?.clickedUser?.company_name?this.props?.clickedUser?.company_name:this.props?.clickedUser?.firstname} will be notified.
                    </Text>
            <View
              style={{
                marginTop: 20,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <TouchableOpacity
                onPress={() => {
                  this.props.onMilestoneClose(values.details,this.state.imagesData)
                }}
                style={{
                  borderRadius: 20,
                  paddingVertical: 5,
                  margin: 10,
                  paddingHorizontal: 25,
                  backgroundColor: config.primary_color,
                }}>
                <Text
                  style={{
                    color: '#FFF',
                    fontSize: 15,
                    fontFamily: 'Roboto-Regular',
                  }}>
                  Yes
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={()=>{
                    this.setState({alertVisible:false})}}
                style={{
                  borderRadius: 20,
                  paddingVertical: 5,
                  margin: 10,
                  paddingHorizontal: 25,
                  backgroundColor: config.primary_color,
                }}>
                <Text
                  style={{
                    color: '#FFF',
                    fontSize: 15,
                    fontFamily: 'Roboto-Regular',
                  }}>
                  No
                </Text>
              </TouchableOpacity>
            </View>


                </View>
            </View>
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: '#707070',
              justifyContent: 'flex-end',
            }}>
              {/* <Modal
                animated
                animationType="slide"
                visible={this.state.showImagePicker}
                onRequestClose={() => {
                  this.setState({showImagePicker:false});
                }}>
                <AddPhoto
                  cameBack={() => {
                    this.setState({showImagePicker:false});
                  }}
                  onClose={data => {
                    this.setState({ imagesData:data})
                    this.setState({showImagePicker:false});
                  }}
                />
              </Modal> */}
            <View
              style={{
                backgroundColor: '#FFF',
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 20,
                }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => this.props.onGoBack('close')}>
                  <AIcon name='left' style={styles.backButton} />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: 'Roboto-Bold',
                    color: config.primary_color,
                  }}>
                  Delivery Details
                </Text>
              </View>
              <View style={styles.inputContainer}>
                {/* **********************************  details Input *********************************** */}

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Details
                </Text>

                <Textarea
                  name='details'
                  rowSpan={4}
                  bordered
                  placeholder='Please mention details of the delivery.(Include invoice, shipping receipt, photos etc). Vouch will use this to verify the order delivery and process the payment.'
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  style={{fontSize: 14, padding: 15}}
                  onChangeText={handleChange('details')}
                  onBlur={handleBlur('details')}
                  error={touched.details && errors.details}
                  value={values.details}
                />

                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.details && errors.details && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      {errors.details}
                    </Text>
                  )}
                </View>
{
  this.state.imagesData?
    <View style={{flexDirection:'row'}}>
      <ImageBackground
        source={{uri: this.state.imagesData.uri}}
        resizeMode="cover"
        style={{
          width: 100,
          height: 100,
          alignItems:'flex-end'
        }}
        imageStyle={{
          borderRadius: 5,
          borderColor: 'black',
          borderWidth: 1,
          marginHorizontal: 3,
        }}
      >
      <TouchableOpacity 
      onPress={()=>{
        this.setState({imagesData:null})
      }}
      style={{marginTop:5,marginRight:2}}>
        <EIcon name='circle-with-cross' style={{fontSize:20,color:'#fff'}}/>
      </TouchableOpacity>
      </ImageBackground>
    </View>:
    <PrimaryButton content={'Attach Image'} clicked={()=>{
      var el = document.getElementById("profile-pic");
      el.click();
      el.addEventListener("change",($event)=>{
        var file = $event.target.files?.length?$event.target.files[0]:null;
        if(file){
          if(file && !file.type.includes("image")
            // && !file.type.includes("pdf")
            ){
            el.value = null;
            alert("Unsupported File Format!");
            return;
          }
          if(file.size<10000000){
          imageService.toBase64(file).then(data=>{
            var imageData = {
              uri:data,
              type:file.type,
              fileName:file.name,
              fileSize:file.size,
            }
            this.setState({imagesData:imageData})
        })
      }
      else{
        window.alert('Max image size is 2 MB')
      }
        }
      })
    }} />
    
}
                <PrimaryButton content={'Submit'} clicked={handleSubmit} />
                <input type="file" id="profile-pic" hidden accept='image/jpeg, image/png'  />
              </View>
            </View>
          </SafeAreaView>
          </View>
        )}
      </Formik>
    )
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    height: 30,
    marginRight: 15,
    fontSize:25
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    paddingHorizontal: '8%',
    paddingBottom: '10%',
  },
})
