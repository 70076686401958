import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  FlatList
} from 'react-native'
// import {Icon} from 'native-base'
import config from '../config'
import Products from '../assets/images/products.svg'
import Services from '../assets/images/services.svg'
import AIcon from 'react-native-vector-icons/AntDesign';

export default class Picker extends Component {
  constructor (props) {
    super(props)
    this.state = {
      serviceClicked: false,
      serviceSelected: null,
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.deSelect!=this.props.deSelect){
      if(this.props.deSelect){
        this.deSelect()
      }
    }
  }

  list = () => {
    return this.props.data.map((element,index) => {
      return (
        <TouchableOpacity
          key={index}
          onPress={() => this.handleServiceSelected(element.title)}
          style={styles.dropDownViewTouchable}>
          <Text style={styles.txt4}>{element.title}</Text>
        </TouchableOpacity>
      )
    })
  }

  handleServiceSelected = serv => {
    this.setState({serviceSelected: serv})
    this.setState({serviceClicked: !this.state.serviceClicked})
    this.props.onServiceSelected(serv)
  }
  handleServicePress = () => {
    this.setState({serviceClicked: !this.state.serviceClicked})
  }

  deSelect = () => {
    this.setState({serviceClicked: false, serviceSelected: null})
  }
  renderPayment = (item, index) => {
    return (
      <TouchableOpacity
          key={index}
          onPress={() => this.handleServiceSelected(item.title)}
          style={styles.dropDownViewTouchable}>
          <Text style={styles.txt4}>{item.title}</Text>
        </TouchableOpacity>
      // <Text>{item.title}</Text>
    )
  }
  render () {
    return (
      <View style={styles.touched}>
        <TouchableOpacity
          style={{flexDirection: 'row'}}
          onPress={this.handleServicePress}>
          <View
            style={{
              flex: 0.25,
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 5,
            }}>
              {/* <Image source={this.props.imgName} resizeMode='contain' /> */}
              {this.props.mode=='1'?
            <Products width={45}  />
            :
            <Services width={45}  />}
            
          </View>
          <View style={{flex: 0.55, justifyContent: 'center'}}>
            <Text style={styles.txt3}>
              {this.state.serviceSelected
                ? this.state.serviceSelected
                : this.props.name}
            </Text>
          </View>
          <View
            style={{flex: 0.2, justifyContent: 'center', alignItems: 'center'}}>
            {this.state.serviceClicked ?<AIcon name='up' style={{fontSize:20,color:'#707070'}}/>:<AIcon name='down' style={{fontSize:20,color:'#707070'}}/>}
          </View>
        </TouchableOpacity>
        {this.state.serviceClicked ? (
          <View style={styles.dropDownView}>
            {this.props.data ? 
            // this.list()
            <FlatList
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled 
            data={this.props.data}
            renderItem={({item, index}) => this.renderPayment(item, index)}
            keyExtractor={(item, index) => index.toString()}
            contentContainerStyle={{
              flexGrow: 1,
              }}/> 
            :null}
          </View>
        ) : null}
        
      </View>
    )
  }
}

const styles = StyleSheet.create({
  touched: {
    elevation: 4,
    shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#fff',
    backgroundColor: '#fff',
  },
  dropDownView: {
    borderTopColor: '#DCDCDC',
    borderTopWidth: 1,
    marginTop: 10,
    paddingHorizontal: '8%',
    paddingVertical: '5%',
    height:200
  },
  dropDownViewTouchable: {
    paddingVertical: 10,
    borderBottomWidth: 0.7,
    borderBottomColor: '#DCDCDC',
  },
  txt3: {
    fontSize: 16,
    color: config.primary_color,
    fontFamily: 'Roboto-Regular',
  },
  txt4: {
    fontSize: 14,
    color: '#AAAAAA',
    fontFamily: 'Roboto-Regular',
  },
})
