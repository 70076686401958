import React from 'react';
import {View, Text, TouchableOpacity, Image,} from 'react-native'
import Kotak from '../../assets/images/kotak'
import Hdfc from '../../assets/images/hdfc'
import Icic from '../../assets/images/icic'
import Sbi from '../../assets/images/sbi'
import AIcon from 'react-native-vector-icons/AntDesign';

const NetBanking = props => {
    return (
        <>
            <Text style={{fontSize: 18,fontFamily: 'OpenSans-Regular',color: '#000',marginBottom:20,marginTop:30}}>Netbanking</Text>
            <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                <TouchableOpacity 
                onPress={()=>{props.netBankingSelected('3044')}}
                style={{alignItems:'center'}}>
                    <Sbi width={45} height={45}/>
                    <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#464646',marginTop:10}}>SBI</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                onPress={()=>{props.netBankingSelected('3022')}}
                style={{alignItems:'center'}}>
                    <Icic width={45} height={45}/>
                    <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#464646',marginTop:10}}>ICICI</Text>
                </TouchableOpacity>
                <TouchableOpacity 
                onPress={()=>{props.netBankingSelected('3032')}}
                style={{alignItems:'center'}}>
                    <Kotak width={45} height={45}/>
                    <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#464646',marginTop:10}}>Kotak</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity
                onPress={()=>{props.netBankingSelected('3021')}}
                style={{alignItems:'center'}}>
                    <Hdfc width={45} height={45}/>
                    <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#464646',marginTop:10}}>HDFC</Text>
                </TouchableOpacity> */}
                <TouchableOpacity
                onPress={()=>{props.netBankingSelected('3058')}}
                style={{alignItems:'center'}}>
                    <Image source={require("../../assets/images/yesb.png")} style={{width:40,height:40,borderRadius:100}} />
                    <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#464646',marginTop:15}}>Yes Bank</Text>
                </TouchableOpacity>
            </View>
            <TouchableOpacity
                style={{flexDirection: 'row', marginTop:25}}
                onPress={() => {
                    props.moreBanksSelected()
                }}>
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    color: '#464646',
                    fontSize: 16,
                    flex: 1,
                  }}>
                  More Banks
                </Text>
                <AIcon name='right' style={{fontSize: 25, color: props?.color? props.color: '#473BB3'}} />
              </TouchableOpacity>
        </>
    );
}

export default NetBanking;