import React, { Component } from 'react';
import LottieView from 'lottie-react-web'
import Anim404 from '../../assets/lottie-animations/404.json'
import { View } from 'react-native';

const Errorpage = () => {

  const [redirectTime, setRedirectTime] = useState(10);

  setTimeout(() => {
    if(redirectTime <= 1){
      window.location.replace("/");
    }
    setRedirectTime(redirectTime-1);
  }, 1000);

    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
      <LottieView
            options={{
              animationData: Anim404,
              autoplay: true,
              loop: true,
            }}
          style={{
            width: 300,
            height: 300,
          }}
          />

<Text style={{ 
                      color:"black",
                      fontFamily: 'OpenSans-Bold',
                      fontSize: 12,
                      marginTop : "3rem" }}>
                        Redirecting to Main Page in {redirectTime} seconds ...
          </Text>

          <Text style={{ 
                      color:"black",
                      fontFamily: 'OpenSans-Bold',
                      fontSize: 12,
                      margin : "1rem",
                      marginTop:"2rem" }}>
                        Or Click Below
          </Text>

          <TouchableOpacity
            onPress={() => {
              window.location.replace("/");
            }}
            style={{backgroundColor: 'rgb(72, 19, 128)', borderRadius: 8}}>
            <Text
              style={{
                color: "#fff",
                fontFamily: 'OpenSans-Bold',
                fontSize: 12,
                padding: "1rem"
              }}>
              Go To Main Page
            </Text>
          </TouchableOpacity>
      
    </View>
    );
}

export default Errorpage;