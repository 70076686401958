import { takeLatest, put, call } from "redux-saga/effects";
import {
  MAKE_PAYMENT,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
} from "../constants/constants";

const execMakePayment = async actionData => {
  return true;
};

function* makePayment(action) {
  try {
    const res = yield call(execMakePayment, action);
    yield put({ type: PAYMENT_SUCCESS, response: {} });
  } catch (error) {
    // console.log(error)

    yield put({ type: PAYMENT_ERROR, response: { message: error.message } });
  }
}

function* watchMakePayment() {
  yield takeLatest(MAKE_PAYMENT, makePayment);
}

export default watchMakePayment;
