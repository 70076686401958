import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_SUCCESS,
} from '../constants/constants'
import {takeLatest, put, call, delay} from 'redux-saga/effects'
import ProfileService from '../../services/profile.service'

const execLoadAllNotifications = async actionData => {
  try {
    var allNotifications = await ProfileService.getAllNotifications()
    // console.log(allNotifications);
    return allNotifications
  } catch (error) {
    throw {message: 'Something went wrong'}
  }
}

function * loadAllNotifications (action) {
  try {
    const res = yield call(execLoadAllNotifications, action)
    yield put({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      response: {
        allNotifications: res,
      },
    })
  } catch (error) {
    yield put({
      type: FETCH_NOTIFICATIONS_ERROR,
      response: {message: error.message},
    })
  }
}

function * watchAllNotifications () {
  yield takeLatest(FETCH_NOTIFICATIONS, loadAllNotifications)
}

export default watchAllNotifications
