import React, {useState} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image as Img,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import moment from 'moment';
import config from '../config';
// import ImageView from 'react-native-image-viewing';
// import FastImage from 'react-native-fast-image';

const MessageBubble = props => {
  const [imageVisible, setImageVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  // console.log("Attachments", props.attachments)

  const getFileExt = (i) => {
    const fileType = i.split(";")[0];
    const ext = fileType.split("/")[1];
    console.log("Ext", ext);
    return ext;
  }

  const showImage = (i) =>{
    if(!i.slice(0,50).includes("image")){
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = i;
      downloadLink.target = '_self';
      downloadLink.download = "file."+getFileExt(i);
      downloadLink.click(); 
      return;
    }
    var image = new Image();
    image.src = i;
    var w = window.open("");
    w.document.write(image.outerHTML);
  }
  return (
    <SafeAreaView style={{backgroundColor: '#f7f7f9', flex: 1}}>
      {props?.me ? (
        <View style={[{alignSelf: 'flex-end'}]}>
          <Text style={[styles.bubbleTime, {textAlign: 'right'}]}>
            {moment(new Date(props.date).toISOString()).format('D-MMM-YY') ==
            moment().format('D-MMM-YY')
              ? 'Today'
              : moment(new Date(props.date).toISOString()).format('LL')}
            {','}
            {props.time}
          </Text>
          <View style={[styles.bubbleContainer, styles.bubbleMe]}>
            {props?.isAttachment ? (
              <TouchableOpacity
                style={{justifyContent: 'flex-end', paddingBottom: 5}}
                onPress={() => {
                  showImage(props.attachments)
                }}>
                { props.attachments.slice(0,50).includes("image") ? <Img
                  source={{uri: props.attachments}}
                  style={{
                    width: '100%',
                    height: 200,
                    resizeMode: 'cover',
                    borderRadius: 0,
                    minWidth: 200,
                    backgroundColor:"white"
                  }}
                /> : 
                <View
                style={{
                  width: '100%',
                  height: 200,
                  resizeMode: 'cover',
                  borderRadius: 0,
                  minWidth: 200,
                  display:"flex",
                  justifyContent:"center",
                  alignItems:"center",
                  backgroundColor:"white",
                  flexDirection:"column"
                }}
                 >
                 <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="rgb(72, 19, 128)" class="bi bi-file-earmark-arrow-down-fill" viewBox="0 0 16 16">
                  <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m-1 4v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 11.293V7.5a.5.5 0 0 1 1 0"/>
                </svg>
                  <Text style={{ color:"rgb(72, 19, 128)", fontSize:"1rem", fontWeight:"700", marginTop:"1rem", fontFamily:"Verdana, sans-serif"}}>Download File</Text>
                  </View>}
              </TouchableOpacity>
            ) : null}
            <Text
              style={[
                styles.bubbleText,
                {color: props.me ? '#FFF' : config.primary_color,
                userSelect : "text" }
              ]}>
              {props.message}
            </Text>
          </View>
        </View>
      ) : (
        <View style={[{alignSelf: 'flex-start'}]}>
          <Text style={[styles.bubbleTime, {textAlign: 'left'}]}>
            {moment(new Date(props.date).toISOString()).format('D-MMM-YY') ==
            moment().format('D-MMM-YY')
              ? 'Today'
              : moment(new Date(props.date).toISOString()).format('LL')}
            {','}
            {props.time}
          </Text>
          <View style={[styles.bubbleContainer, styles.bubbleTo]}>
            {props?.isAttachment ? (
              <TouchableOpacity
                onPress={() => {
                  showImage(props.attachments)
                }}>
                <Img
                  source={{uri: props.attachments}}
                  style={{
                    width: 200,
                    height: 200,
                    resizeMode: 'cover',
                    borderRadius: 8,
                  }}
                />
              </TouchableOpacity>
            ) : null}
            <Text
              style={[
                styles.bubbleText,
                {color: props.me ? '#FFF' : config.primary_color,
                userSelect : "text"},
              ]}>
              {props.message}
            </Text>
          </View>
        </View>
      )}
      {/* <ImageView
        images={[
          {
            uri: imageUrl,
          },
        ]}
        imageIndex={0}
        visible={imageVisible}
        onRequestClose={() => {
          setImageVisible(false);
        }}
      /> */}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bubbleContainer: {
    padding: 10,
    paddingHorizontal: 15,
    borderRadius: 24,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  bubbleMe: {
    backgroundColor: config.primary_color,
    alignSelf: 'flex-end',
  },
  bubbleTo: {
    backgroundColor: '#FFF',
    alignSelf: 'flex-start',
  },
  bubbleText: {
    color: '#000',
    fontFamily: 'Roboto-Regular',
  },
  bubbleTime: {
    color: '#666',
    fontSize: 10,
    fontFamily: 'OpenSans-Regular',
    marginHorizontal: 20,
    marginVertical: 5,
  },
  dateContainer: {
    padding: 5,
    borderRadius: 30,
    marginVertical: 10,
    marginHorizontal: 10,
    alignSelf: 'center',
    backgroundColor: '#f0f0f0',
    elevation: 4,
  },
  dateText: {fontFamily: 'Roboto-Medium', fontSize: 10, color: '#000'},
});

export default MessageBubble;
