import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import 'react-native-gesture-handler';
import MainApp from './src/App'

export default function App() {
  return (
    <MainApp />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});



// Face Sdk Load
window.fbAsyncInit = function() {
  FB.init({
    appId      : '2571654103074573',
    cookie     : true,
    xfbml      : true,
    version    : 'v8.0'
  });
    
  FB.AppEvents.logPageView();   
};

(function(d, s, id){
   var js, fjs = d.getElementsByTagName(s)[0];
   if (d.getElementById(id)) {return;}
   js = d.createElement(s); js.id = id;
   js.src = "https://connect.facebook.net/en_US/sdk.js";
   fjs.parentNode.insertBefore(js, fjs);
 }(document, 'script', 'facebook-jssdk'));



 // Facebook pixel script loading
 !function(f,b,e,v,n,t,s)
 {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
 n.callMethod.apply(n,arguments):n.queue.push(arguments)};
 if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
 n.queue=[];t=b.createElement(e);t.async=!0;
 t.src=v;s=b.getElementsByTagName(e)[0];
 s.parentNode.insertBefore(t,s)}(window, document,'script',
 'https://connect.facebook.net/en_US/fbevents.js');
 fbq('init', '480441142652630');
 fbq('track', 'PageView');


//razorpay,maps script loading
[
  'https://sdk.cashfree.com/js/core/1.0.26/bundle.prod.js',
  'https://www.cashfree.com/assets/cashfree.sdk.v1.2.js',
  'https://checkout.razorpay.com/v1/razorpay.js',
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAhDNvmPpeiyfNaSCJv4_LvpsiDQpBU05Y&libraries=places"
].forEach(
  i=>{
    var script = document.createElement('script');
    script.src = i;
    document.head.appendChild(script)
  }
)



function loadScript() {
  return new Promise(function (resolve, reject) {
      var s;
      s = document.createElement('script');
      s.src = 'https://www.googletagmanager.com/gtag/js?id=AW-580246236';
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
  });
}

loadScript()
    .catch(loadScript.bind(null))
    .then((successCallback)=>{
      try{
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-580246236');
    }catch (error) {
      console.log('Failure:',error);
    }}, (failureCallback)=>{
      console.log('failure:',failureCallback);
    });
