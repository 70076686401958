import {
  AUTH_CHECK,
  AUTH_FAILURE,
  AUTH_SUCCESS,
  SET_INTRO_SHOWN,
  SET_INTRO_SHOWN_DONE,
  GOOGLE_SIGNIN_ERROR,
  GOOGLE_SIGNIN_SUCCESS,
  LOGOUT_DONE,
  GOOGLE_SIGNIN, LOGIN_LATER, SWITCH_TO_AUTH
} from '../constants/constants'

const initialState = {
  isLoading: true,
  isAuthenticated: false,
  isIntroShown: false,
  isLoginLater:false,
  success: false,
  error: '',
}

const reducer = function authReducer (state = initialState, action) {

  switch (action.type) {
    case AUTH_CHECK: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case AUTH_SUCCESS: {
      // console.log(AUTH_SUCCESS);
      return {
        ...state,
        isLoading: false,
        isIntroShown: action.response.intro,
        isAuthenticated: action.response.auth,
        isLoginLater:false,
        success:true
      }
    }
    case AUTH_FAILURE: {
      // console.log(AUTH_FAILURE);
      return {
        ...state,
        isLoading: false,
        isIntroShown: action.response.intro,
        isAuthenticated: false,
        success:false
      }
    }
    case SWITCH_TO_AUTH: {
      return {
        ...state,
        isAuthenticated: false,
      }
    }
    case LOGIN_LATER:{
      return{
        ...state,
        isLoginLater:true,
        isAuthenticated:true,
      }
    }
    case LOGOUT_DONE: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
