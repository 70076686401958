import {
  LOAD_ESSENTIALS,
  LOAD_ESSENTIALS_ERROR,
  LOAD_ESSENTIALS_SUCCESS,
} from '../constants/constants'

const initialState = {
  isLoading: false,
}

const reducer = function helperReducer (state = initialState, action) {
  switch (action.type) {
    case LOAD_ESSENTIALS: {
      return {
        isLoading: true,
      }
    }
    case LOAD_ESSENTIALS_ERROR: {
      return {
        isLoading: false,
      }
    }
    case LOAD_ESSENTIALS_SUCCESS: {
      return {
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
