import {
  HANDLE_DEEP_LINK,
  HANDLE_DEEP_LINK_ERROR,
  HANDLE_DEEP_LINK_SUCCESS,
} from '../constants/constants'

const initialState = {
  isLoading: false,
  code:''
}

const reducer = function deepLinkingReducer (state = initialState, action) {
  switch (action.type) {
    case HANDLE_DEEP_LINK: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case HANDLE_DEEP_LINK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        code:action.response.code
      }
    }
    case HANDLE_DEEP_LINK_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
