import {
  INIT_MESSAGE_STATE,
  INIT_MESSAGE_STATE_SUCCESS,
  INIT_MESSAGE_STATE_ERROR,
  SEND_MESSAGE,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_ERROR,
  SUBSCRIBE_MESSAGES,
  ON_MESSAGE_RECEIVED,
  MESSAGE_PAGE_STATE,
  MESSAGE_PAGE_STATE_SUCCESS,
  MESSAGE_PAGE_STATE_ERROR,
  FETCH_CHAT_MESSAGES,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES_ERROR,
} from '../constants/constants';

const initialState = {
  current_user_id: null,
  to_user_id: null,
  thread_id: null,
  isLoading: true,
  error: null,
  messages: [],
  isLoadingMore: false,
  chatId: null,
  isFetchingMessages: false,
  isTyping: false,
  isMessageSending: false,
  token: null,
};

const reducer = function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_MESSAGE_STATE: {
      return {
        ...initialState,
      };
    }
    case INIT_MESSAGE_STATE_SUCCESS: {
      return {
        current_user_id: action.response.current_user_id,
        to_user_id: action.response.to_user_id,
        thread_id: action.response.thread_id,
        chatId: action.response.chatId,
        token: action.response.token,
        isLoading: false,
        isLoadingMore: false,
        isMessageSending: false,
        error: '',
        messages: [],
      };
    }
    case INIT_MESSAGE_STATE_ERROR: {
      return {
        ...initialState,
      };
    }
    case SUBSCRIBE_MESSAGES: {
      return {
        ...state,
      };
    }
    case ON_MESSAGE_RECEIVED: {
      return {
        ...state,
        messages: [...state.messages, action.data],
      };
    }
    case SEND_MESSAGE: {
      return {
        ...state,
        isMessageSending: true,
        error: '',
      };
    }
    case SEND_MESSAGE_ERROR: {
      return {
        ...state,
        isMessageSending: false,
        error: action.response.message,
      };
    }
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        isMessageSending: false,
        messages: [...state.messages, action.response],
        error: '',
      };
    }
    case MESSAGE_PAGE_STATE: {
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    }
    case MESSAGE_PAGE_STATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
      };
    }
    case MESSAGE_PAGE_STATE_SUCCESS: {
      return {
        ...state,
        error: '',
      };
    }
    case FETCH_CHAT_MESSAGES: {
      return {
        ...state,
        isFetchingMessages: true,
      };
    }
    case FETCH_CHAT_MESSAGES_SUCCESS: {
      return {
        ...state,
        isFetchingMessages: false,
        messages: [...action.response, ...state.messages],
      };
    }
    case FETCH_CHAT_MESSAGES_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
        isFetchingMessages: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
