import {
  FETCH_ACTIVITY,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_ERROR,
} from '../constants/constants'

const initialState = {
  activities: [],
  isLoading: true,
  error: '',
}

const reducer = function activityReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACTIVITY: {
      return {
        ...state,
        isLoading: true,
        error: '',
        activities: [],
      }
    }
    case FETCH_ACTIVITY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activities: action.response.activities,
      }
    }
    case FETCH_ACTIVITY_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    default:
      return state
  }
}

export default reducer
