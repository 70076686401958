import React, {Component} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import config from '../../config';
import {Icon, Textarea} from 'native-base';
import InputText from '../InputText';
import PrimaryButton from '../PrimaryButton';
import {Formik} from 'formik';
import * as yup from 'yup';
import AIcon from 'react-native-vector-icons/AntDesign';

export default class CardDetailsOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
    };
  }
  handleSubmit = values => {
    if( /^3[47][0-9]{13}$/.test(values.cardNumber))
      return alert('Sorry! American Express cards are Not Allowed')
    else if(/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(values.cardNumber))
      return alert('Sorry! Diners Club cards are Not Allowed')
    this.props.onSuccess(values)
    // props.initPaymentCheckout(props.route.params,{method:'CARD',values:values})
};



  render() {
    var {upiNumber} = this.state;
    const validationSchema = yup.object().shape({
        nameOnCard: yup
        .string()
        .label('nameOnCard')
        .trim()
        .required('Name is mandatory'),
        cardNumber: yup
        .string()
        .label('cardNumber')
        .required('Card Number is mandatory')
        .max(16,'Card Number cant not have more than 16 characters')
        .trim()
        .min(13,'Card Number must at least have 13 characters'),
        expiry: yup
        .string()
        .label('expiry')
        .min(7,'Invalid')
        .max(7,'Invalid')
        .trim()
        .required('This is mandatory'),
        cvv: yup
        .string()
        .label('cvv')
        .required()
        .trim()
        .min(3,'CVV must at least have 3 characters')
        .max(4,'CVV cant not have more than 4 characters'),
  
    });
    return (
      <Formik
        initialValues={{
            nameOnCard:'',
            cardNumber: '',
            expiry: '',
            expiryYear:'',
            cvv:''
      }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          setFieldValue
        }) => (
          <View style={{flex: 1}}>
            <SafeAreaView
              style={{
                flex: 1,
                backgroundColor: 'rgba(0,0,0,0.7)',
                justifyContent: 'flex-end',
              }}>
              <View
                style={{
                  backgroundColor: '#FFF',
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                  paddingTop:10
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 20,
                  }}>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => {
                      this.props.comeBack();
                    }}>
                    <AIcon name="left" style={{
                      color: this.props?.color ? this.props.color: config.primary_color,
                      width: 16,
                      height: 30,
                      marginTop: 5,
                      marginRight: 20,
                      fontSize: 25,
                    }} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Roboto-Medium",
                      color: this.props?.color ? this.props.color: config.primary_color,
                    }}>
                    Enter card details
                  </Text>
                </View>

                <ScrollView style={styles.inputContainer}>
              <InputText
                name="cardNumber"
                iconName='creditcard'
                label='Card number'
                placeholder="XXXX-XXXX-XXXX-XXXX"
                onChangeText={handleChange('cardNumber')}
                onBlur={handleBlur('cardNumber')}
                error={touched.cardNumber && errors.cardNumber}
                value={values.cardNumber}
              />
              <View style={{height: 20, width: '100%', paddingTop: 3}}>
                      {touched.cardNumber && errors.cardNumber && (
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: 'right',
                            fontFamily: 'Roboto-Regular',
                            color: '#F90000',
                          }}>
                          {errors.cardNumber}
                        </Text>
                      )}
              </View>
              <InputText
                name="nameOnCard"
                iconName='idcard'
                label='Name on card'
                placeholder="Bruce Wayne"
                onChangeText={handleChange('nameOnCard')}
                onBlur={handleBlur('nameOnCard')}
                error={touched.nameOnCard && errors.nameOnCard}
                value={values.nameOnCard}
              />
              <View style={{height: 20, width: '100%', paddingTop: 3}}>
                      {touched.nameOnCard && errors.nameOnCard && (
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: 'right',
                            fontFamily: 'Roboto-Regular',
                            color: '#F90000',
                          }}>
                          {errors.nameOnCard}
                        </Text>
                      )}
              </View>
              <InputText
                name="expiry"
                iconName='calendar'
                label='Valid till (MM/YYYY)'
                placeholder="MM/YYYY"
                maxLength={7}
                onChangeText={
                  e => {
                  if(e.length==2 && values.expiry.length<e.length){
                    setFieldValue('expiry',e+'/')
                  }
                  else{
                    setFieldValue('expiry',e) 
                  }
                }
                }
                onBlur={handleBlur('expiry')}
                error={touched.expiry && errors.expiry}
                value={values.expiry}
              />
              <View style={{height: 20, width: '100%', paddingTop: 3}}>
                      {touched.expiry && errors.expiry && (
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: 'right',
                            fontFamily: 'Roboto-Regular',
                            color: '#F90000',
                          }}>
                            {errors.expiry}
                        </Text>
                      )}
              </View>
              <InputText
                name="cvv"
                iconName='lock'
                label='CVV'
                placeholder="XXX"
                onChangeText={handleChange('cvv')}
                onBlur={handleBlur('cvv')}
                error={touched.cvv && errors.cvv}
                value={values.cvv}
              />
              <View style={{height: 20, width: '100%', paddingTop: 3}}>
                      {touched.cvv && errors.cvv && (
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: 'right',
                            fontFamily: 'Roboto-Regular',
                            color: '#F90000',
                          }}>
                          {errors.cvv}
                        </Text>
                      )}
              </View>
            <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#707070'}}>This is a secure payment screen and your card details are not stored</Text>
                  <PrimaryButton 
                  content={'Pay'} 
                  clicked={handleSubmit} 
                  color={this.props?.color}
                  />
                </ScrollView>
              </View>
            </SafeAreaView>
          </View>
        )}
      </Formik>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginTop: 5,
    marginRight: 20,
    fontSize: 25,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    paddingHorizontal: '8%',
    paddingBottom: '10%',
  },
});
