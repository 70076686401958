import React, {Component} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import config from '../config';
import {Icon, Textarea} from 'native-base';
import InputText from './InputText';
import PrimaryButton from './PrimaryButton';
import {Formik} from 'formik';
import * as yup from 'yup';
import AIcon from 'react-native-vector-icons/AntDesign';

export default class MilestoneOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      milestoneAmount: '',
      details: '',
      milestonesLocal: [],
      alertVisible: false,
    };
  }

  componentDidMount() {
    if (this.props.mode == 'edit') {
      this.state.milestonesLocal = this.props.milestones.filter(
        item => item.index !== this.props.data.index,
      );
    } else if (this.props.mode == 'add') {
      
      this.state.milestonesLocal = this.props.milestones;
    }
  }
  handleAmountChange = milestoneAmount => {
    this.setState({milestoneAmount});
  };
  handleDetails = details => {
    this.setState({details});
  };
  handleSubmit = values => {
    if (values.milestoneAmount.trim() != '' && values.details.trim() != '') {
      // console.log('fullAmount'+this.props.fullAmount);
      // console.log('totalMilestoneAmount'+this.props.totalMilestoneAmount);

      if (this.props.mode == 'add') {
        var availableAmount =
          this.props.fullAmount - this.props.totalMilestoneAmount;

        // console.log('availableAmount'+availableAmount);

        if (availableAmount < parseFloat(values.milestoneAmount)) {
          this.setState({alertVisible: true});
          // alert(
          //   'Oops! The Milestone value entered is more than the Order Value. Please change the Order Value or reduce the Milestone Value to proceed',
          // );
          return;
        }
      }
      if (this.props.mode == 'edit') {
        var checkAmount =
          this.props.fullAmount -
          values.milestoneAmount -
          this.state.milestonesLocal.reduce((prev, cur) => {
            return parseFloat(prev) + parseFloat(cur.milestoneAmount);
          }, 0);
        if (checkAmount < 0) {
          this.setState({alertVisible: true});
          // alert('Oops! The Milestone value entered is more than the Order Value. Please change the Order Value or reduce the Milestone Value to proceed',);
          return;
        }
      }
      this.props.onMilestoneClose(
        this.props.mode,
        values,
        this.props.mode == 'edit' ? this.props.data.index : null,
      );
    } else {
      alert('Please fill all the fields');
    }
  };

  render() {
    var {milestoneAmount, details} = this.state;
    const {mode, data} = this.props;
    if (mode == 'edit') {
      milestoneAmount = data.milestoneAmount;
      details = data.details;
    }
    const validationSchema = yup.object().shape({
      milestoneAmount: yup
        .string()
        .trim()
        .min(2, 'Minimum milestone amount is 10 Rupees')
        .matches(/^[0-9-+()]+$/, 'This field is not Valid :(')
        .label('milestoneAmount')
        .required('This is a required Field :('),
      details: yup
        .string()
        .trim()
        .label('details')
        .required(),
    });
    return (
      <Formik
        initialValues={{
          milestoneAmount: milestoneAmount,
          details: details,
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <View style={{flex: 1}}>
            <View
              style={[
                {
                  alignItems: 'center',
                  position: 'absolute',
                  zIndex: 100,
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                },
                {zIndex: this.state.alertVisible ? 100 : 0},
              ]}>
              <View
                style={{
                  backgroundColor: '#FFF',
                  borderRadius: 10,
                  alignItems: 'center',
                  paddingTop: 30,
                  paddingVertical: 20,
                  lineHeight: 21,
                  paddingHorizontal: 30,
                  marginHorizontal: '5%',
                }}>
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#707070',
                  }}>
                  Oops! The Milestone value entered is more than the Order
                  Value.
                </Text>
                {/* <View style={{paddingTop:40,flexDirection:'row'}}>
                  <Text onPress={()=>{this.setState({alertVisible:false})}}
                  style={{marginLeft:180,color:'#FFF',borderRadius:20,paddingVertical:8,paddingHorizontal:30,backgroundColor:config.primary_color,fontSize:15,fontFamily:'Roboto-Bold'}}>OK</Text>
                  </View> */}
                <View style={{paddingTop: 20, alignSelf: 'flex-end'}}>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({alertVisible: false});
                    }}
                    style={{
                      borderRadius: 20,
                      paddingVertical: 5,
                      margin: 10,
                      paddingHorizontal: 35,
                      backgroundColor: config.primary_color,
                    }}>
                    <Text
                      style={{
                        color: '#FFF',
                        fontSize: 15,
                        fontFamily: 'Roboto-Regular',
                      }}>
                      OK
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
            <SafeAreaView
              style={{
                flex: 1,
                backgroundColor: '#707070',
                justifyContent: 'flex-end',
              }}>
              <View
                style={{
                  backgroundColor: '#FFF',
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 20,
                  }}>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => this.props.onMilestoneClose('close')}>
                    <AIcon name="left" style={styles.backButton} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 30,
                      fontFamily: 'Roboto-Medium',
                      color: config.primary_color,
                    }}>
                    {this.props.mode == 'add'
                      ? 'Add Milestone'
                      : 'Edit Milestone'}
                  </Text>
                </View>

                <View style={styles.inputContainer}>
                  {/* **********************************  Amount Input *********************************** */}

                  <InputText
                    name="milestoneAmount"
                    label="Amount"
                    placeholder="Milestone Amount"
                    onChangeText={handleChange('milestoneAmount')}
                    onBlur={handleBlur('milestoneAmount')}
                    error={touched.milestoneAmount && errors.milestoneAmount}
                    value={values.milestoneAmount}
                    keyboard="numeric"
                  />
                  <View style={{height: 20, width: '95%', paddingTop: 3}}>
                    {touched.milestoneAmount && errors.milestoneAmount && (
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: 'right',
                          fontFamily: 'Roboto-Regular',
                          color: '#F90000',
                        }}>
                        {errors.milestoneAmount}
                      </Text>
                    )}
                  </View>

                  {/* ********************************** Milestone  Amount Input  End*********************************** */}

                  {/* **********************************  Requirements Input *********************************** */}

                  <Text
                    style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#464646',
                      paddingLeft: 5,
                    }}>
                    Details
                  </Text>

                  <Textarea
                    name="details"
                    rowSpan={4}
                    bordered
                    placeholder="Enter Milestone Details"
                    borderRadius={10}
                    placeholderTextColor={'#AAAAAA'}
                    style={{fontSize: 14, padding: 15}}
                    onChangeText={handleChange('details')}
                    onBlur={handleBlur('details')}
                    error={touched.details && errors.details}
                    value={values.details}
                  />

                  <View style={{height: 20, width: '95%', paddingTop: 3}}>
                    {touched.details && errors.details && (
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: 'right',
                          fontFamily: 'Roboto-Regular',
                          color: '#F90000',
                        }}>
                        Details are required
                      </Text>
                    )}
                  </View>
                  {this.props.fullAmount -
                    values.milestoneAmount -
                    this.state.milestonesLocal.reduce((prev, cur) => {
                      return parseFloat(prev) + parseFloat(cur.milestoneAmount);
                    }, 0) >
                  0 ? (
                    <Text
                      style={{
                        fontFamily: 'Roboto-Bold',
                        fontSize: 18,
                        color: config.primary_color,
                        textAlign: 'center',
                      }}>
                      Balance Amount: {' \u20B9'}
                      {/* {console.log('fullAmount'+this.props.fullAmount)}
                    {console.log('totalMilestoneAmount'+this.props.totalMilestoneAmount)}
                    {console.log(this.props.fullAmount-this.props.totalMilestoneAmount)} */}
                      {this.props.fullAmount -
                        values.milestoneAmount -
                        this.state.milestonesLocal.reduce((prev, cur) => {
                          return (
                            parseFloat(prev) + parseFloat(cur.milestoneAmount)
                          );
                        }, 0)}
                    </Text>
                  ) : null}
                  {this.props.fullAmount -
                    values.milestoneAmount -
                    this.state.milestonesLocal.reduce((prev, cur) => {
                      return parseFloat(prev) + parseFloat(cur.milestoneAmount);
                    }, 0) <
                  0 ? (
                    <Text
                      style={{
                        fontFamily: 'Roboto-Bold',
                        fontSize: 18,
                        color: 'red',
                        textAlign: 'center',
                      }}>
                      Balance Amount: {' \u20B9'}
                      {/* {console.log('fullAmount'+this.props.fullAmount)}
                      {console.log('totalMilestoneAmount'+this.props.totalMilestoneAmount)}
                      {console.log(this.props.fullAmount-this.props.totalMilestoneAmount)} */}
                      {this.props.fullAmount -
                        values.milestoneAmount -
                        this.state.milestonesLocal.reduce((prev, cur) => {
                          return (
                            parseFloat(prev) + parseFloat(cur.milestoneAmount)
                          );
                        }, 0)}
                    </Text>
                  ) : null}
                  <PrimaryButton
                    content={this.props.mode == 'add' ? 'Add' : 'Update'}
                    clicked={handleSubmit}
                  />
                </View>
              </View>
            </SafeAreaView>
          </View>
        )}
      </Formik>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    fontSize: 25,
    height: 30,
    marginRight: 20,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    paddingHorizontal: '8%',
    paddingBottom: '10%',
  },
});
