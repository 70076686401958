import React, { Component } from "react";
import {
  Image,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Share,
  View,
  Text,
  TouchableOpacity,
} from "react-native";
import PrimaryButton from "../../components/PrimaryButton";
import moment from "moment";
import {
  initState,
  makeTransaction,
} from "../../state-management/actions/paymentdetail.action";
import { connect } from "react-redux";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../../config";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import Clipboard from "expo-clipboard";

class ForSaleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      imageVisible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.proceedToPayment != prevProps.proceedToPayment) {
      if (this.props.proceedToPayment == true) {
        this.props.navigation.navigate("ForSalePreview", {
          transaction_details: this.props.transaction_details,
        });
      }
    }
  }

  componentDidMount() {
    this.props.initState();
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <TouchableOpacity
            onPress={() => {
              this.props.navigation.goBack();
            }}
            style={{
              position: "absolute",
              width: 30,
              height: 30,
              margin: "3%",
              zIndex: 100,
              backgroundColor: "rgba(0,0,0,0.5)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
            }}
          >
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          {this.props.route.params.data?.images.map((item, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  window.open(item);
                }}
              >
                <Image
                  source={{ uri: item }}
                  style={{ height: 300, left: 0, right: 0 }}
                  resizeMode='cover'
                />
              </TouchableOpacity>
            );
          })}
          <View style={{ margin: "7%" }}>
            <Text
              style={{
                fontFamily: "OpenSans-SemiBold",
                fontSize: 16,
                color: "#000",
                marginBottom: 15,
              }}
            >
              {this.props.route.params.data?.gig_name}
            </Text>
            <Text
              style={{
                fontFamily: "OpenSans-Regular",
                fontSize: 10,
                color: "#000",
                marginBottom: 12,
              }}
            >
              {moment(this.props.route.params.data?.createdAt).format(
                "DD-MM-YYYY"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "OpenSans-Regular",
                fontSize: 16,
                color: "#000",
                marginBottom: 30,
              }}
            >
              {this.props.route.params.data?.description}
            </Text>
            <View>
              <TouchableOpacity
                onPress={() => {
                  Clipboard.setString(
                    `https://app.iamvouched.com/${this.props.profile.requestedProfile.username}/store/${this.props.route.params.data?.id}`
                  );
                  Share.share({
                    message: `https://app.iamvouched.com/${this.props.profile.requestedProfile.username}/store/${this.props.route.params.data?.id}`,
                  });
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 10,
                  marginTop: 25,
                }}
              >
                <AIcon
                  name='sharealt'
                  style={{ color: "#0C76EF", fontSize: 25 }}
                />
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    color: "#0C76EF",
                    fontSize: 16,
                    textAlign: "center",
                    marginLeft: 12,
                  }}
                >
                  Share
                </Text>
              </TouchableOpacity>
              <PrimaryButton
                content={`Buy for \u20B9 ${this.props.route.params.data?.total_amount}`}
                loading={this.props.isLoading}
                clicked={() => {
                  var { data, mobile } = this.props.route.params;
                  this.props.makeTransaction(
                    "buying",
                    {
                      amount: data.total_amount,
                      gigName: data.gig_name,
                      requirements: data.requirements,
                    },
                    data.milestones,
                    data.milestones.length != 0 ? true : false,
                    data.category,
                    mobile,
                    data.classification
                  );
                }}
              />
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: "#FFF",
    fontSize: 25,
  },
});

export default connect(
  state => ({
    profile: { ...state.userProfile },
    ...state.paymentdetail,
  }),
  { initState, makeTransaction }
)(ForSaleDetail);
