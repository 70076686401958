import { STORE_FLOAT_DATA,SET_FLOAT_USER_ID, RESET_FLOAT_DATA } from '../constants/constants';
  
  const initialState = {
    status: '',
    isFloatingPresent:false,
    data:null,
  };
  
  const reducer = function floatingReducer(state = initialState, action) {
    switch (action.type) {
      case STORE_FLOAT_DATA: {
        return {
          ...state,
          isFloatingPresent:true,
          data:action.data
        };
      }
      case SET_FLOAT_USER_ID:{
          var temp = state.data
          temp.transaction_details.request_from=action.uid
          return{
              ...state,
              data:temp
          }
      }
      case RESET_FLOAT_DATA:{
          return{
              ...initialState
          }
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  