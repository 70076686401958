import React, { useEffect } from 'react'
import {View,Text,StyleSheet, TouchableOpacity} from 'react-native'
import config from '../config'
import { Thumbnail } from 'native-base'

const PersonHeader = props => {
  return (
    <TouchableOpacity 
    onPress={()=>{props.onClick()}}
    style={styles.partnerElement}>
      {props?.profile_image_path ?
          (<Thumbnail
            source={{uri: props?.profile_image_path}}
            style={{
              width: 55,
              height: 55,
            }}
          />)
        :
      <View
        style={{
          backgroundColor: '#B80D57',
          width: 55,
          height: 55,
          justifyContent: 'center',
          borderRadius: 50,
          alignSelf: 'center',
          borderWidth: 1.5,
          borderColor: config.primary_color,
        }}>
          <Text
          style={{
            fontSize: 20,
            color: '#FFF',
            textAlign: 'center',
            fontFamily:'Roboto-Regular'
          }}>
          {props?.company_name?props?.company_name[0]:props?.firstname[0]}
        </Text>
      </View>
}
      <View style={{justifyContent: 'space-evenly'}}>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Roboto-Bold',
            color: config.primary_color,
            paddingHorizontal: 10,
          }}>
            {props?.company_name?props?.company_name:`${props?.firstname} ${props?.lastname}`}
        </Text>
        <Text
          style={{
            fontSize: 14,
            fontFamily: 'Roboto-Regular',
            color: '#707070',
            paddingHorizontal: 10,
          }}>
          {props?.mobile}
        </Text>
      </View>
      </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  partnerElement: {
    flexDirection: 'row',
    borderColor: '#AAA',
    marginBottom: 10,
    paddingBottom: 12,
    paddingHorizontal: '2%',
  },
})


export default PersonHeader