import {takeLatest, put, call, delay, select} from 'redux-saga/effects';
import {
  LOAD_DATA,
  FETCH_ERROR,
  DATA_FETCHED,
  UPDATE_WINDOW_COUNT,
} from '../constants/constants';
import {Platform} from 'react-native';
import MiscService from '../../services/misc.service';

const executeGetContacts = async currentState => {
  try {
    var vouchUsers;
    // vouchUsers = await MiscService.vouchUsers();
    vouchUsers = [];
    vouchUsers = vouchUsers.filter(x => (x.mobile ? true : false));
    vouchUsers = vouchUsers.filter(
      x => x.mobile != currentState.userProfile.mobile,
    );
    var vouchUsers1 = vouchUsers.filter(x =>
      Object.keys(x).includes('dynamic_link') ? false : true,
    );
      return {
        phoneContacts: [],
        vouchUsers: vouchUsers1,
        phoneVouchUsers: [],
      };
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'};
  }
};

function* getContacts() {
  try {
    const getItems = state => state.userProfile;
    const currentState = yield select(getItems);
    const res = yield call(executeGetContacts, currentState);
    yield put({
      type: DATA_FETCHED,
      response: {
        phoneContacts: res.phoneContacts,
        vouchUsers: res.vouchUsers,
        phoneVouchUsers: res.phoneVouchUsers,
      },
    });
  } catch (error) {
    yield put({
      type: FETCH_ERROR,
      response: {message: error.message, error: error},
    });
  }
}

function* loadMore() {
  yield delay(2000);
  yield {type: UPDATE_WINDOW_COUNT};
}

function* watchContacts() {
  yield takeLatest(LOAD_DATA, getContacts);
  yield takeLatest(UPDATE_WINDOW_COUNT, loadMore);
}

export default watchContacts;
