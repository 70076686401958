import React, { useEffect,useState } from 'react';
import {View, Text, Modal, TouchableOpacity,} from 'react-native'
import FIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import UpiImage from '../../assets/images/upi'
import AddUpi from './AddUpi';
import NetBanking from './NetBanking';
const UpiOption = (props) => {

    return (
        <>
            <Text style={{fontSize: 18,fontFamily: 'OpenSans-Regular',color: '#000',marginBottom:8}}>UPI</Text>
            <Text style={{fontSize: 10,fontFamily: 'Roboto-Regular',color: '#707070',paddingBottom:25}}>(no payment gateway charges)</Text>
            
            {props.amount>=100000?null
              :props.saved.map((item,index)=>{
                return(
                  <TouchableOpacity 
                  onPress={()=>{props.proceed(item)}}
                  style={{flexDirection:'row',alignItems:'center',marginLeft:15,marginBottom:20}}>
                  <UpiImage width={40} height={40}/>
                  <Text style={{fontSize: 14,marginLeft:15,fontFamily: 'Roboto-Regular',color: '#707070',}}>{item}</Text>
                  </TouchableOpacity>
                )
              })
            }
            {
            props.amount>=100000?
            <View 
            onPress={()=>{setOverlay(true)}}
            style={{flexDirection:'row',alignItems:'center',marginLeft:15,marginBottom:15}}>
                <View style={{backgroundColor:'#707070',padding:8,borderRadius:100}}>
                    <FIcon
                        name="plus"
                        style={{color: '#FFF', fontSize: 20}}
                    />
                </View>
                <Text style={{fontSize: 14,marginLeft:15,fontFamily: 'Roboto-Regular',color: '#707070',}}>Add new UPI</Text>
            </View>
            :
            <TouchableOpacity 
            onPress={()=>{props.addUpiPressed()}}
            style={{flexDirection:'row',alignItems:'center',marginLeft:15,marginBottom:15}}>
                <View style={{backgroundColor:props?.color? props.color:config.primary_color,padding:8,borderRadius:100}}>
                    <FIcon
                        name="plus"
                        style={{color: '#FFF', fontSize: 20}}
                    />
                </View>
                <Text style={{fontSize: 14,marginLeft:15,fontFamily: 'Roboto-Regular',color: '#707070',}}>Add new UPI</Text>
            </TouchableOpacity>
            }
        </>
    );
}

export default UpiOption;