import React, {Component, useState,useEffect} from 'react'
import {TextInput,View, SafeAreaView, TouchableOpacity,StyleSheet,Text,Image,} from 'react-native'
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import {Item,Input} from 'native-base'
import FIcon from 'react-native-vector-icons/Feather'
import PrimaryButton from '../PrimaryButton';
import { ScrollView } from 'react-native-gesture-handler';
import LanguageLevelPicker from './LanguageLevelPicker';

const LanguagesKnown = props=> {
    const [languages,setLanguages] = useState([])
    const [itm,setItem] = useState(null)
    const [disable,setDisable] = useState(true)
    const option= [
        {
          title: 'Basic',
        },
        {
          title: 'Conversational',
        },
        {
          title: 'Fluent'
        },
        {
          title: 'Native/ Bilingual'
        }
      ]
      useEffect(()=>{
        if(props.languages){
          setLanguages(props.languages)
        }
        return
      },[])
    const renderHeader= () => {
        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingTop: 20,
            }}>
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={()=>{props.cameBack()}}>
              <AIcon name="left" style={styles.backButton} />
            </TouchableOpacity>
            <Text
              numberOfLines={1}
              style={{
                flex: 1,
                fontSize: 26,
                fontFamily: 'OpenSans-Semibold',
                color: config.primary_color,
              }}>
              Languages known
            </Text>
          </View>
        );
      };
    
        return(
            <SafeAreaView  style={{flex: 1,backgroundColor: '#FFF',}}>
                {renderHeader()}
                <View style={{flex:1}}>
                    <ScrollView style={{paddingHorizontal:'10%'}}>
                    
                    <Item regular style={{borderRadius:5,flex:1,height:43,marginTop:25,marginBottom:10}}>
                        <Input placeholder='Add language ' 
                        onChangeText={
                          (txt)=>{setItem(txt)
                          setDisable(false)
                          }}
                        style={{fontSize:16,fontFamily:'Roboto-Regular'}} 
                        value={itm}
                        placeholderTextColor={'#DCDCDC'} />
                    </Item>
                    <LanguageLevelPicker communicationData={''}
                    data={option} onServiceSelected={serv => {
                        if(itm){
                            setLanguages(old=>[...old,
                                {
                                    name:itm,
                                    strength:serv
                                }
                            ])
                        }
                        setItem('')
                    }} name={'Language Level'}/>
                    <View style={{minHeight:200}}>
                    {languages.map((item, index) => {
                            return (
                                <View 
                                style={{flexDirection:'row',
                                alignItems:'center',
                                borderWidth:1,
                                borderColor:'#DCDCDC',
                                borderRadius:15,
                                padding:15,marginTop:'5%'}}>
                                    <Text style={{fontSize:14,flex:1,fontFamily:'Roboto-Regular',color:'#707070'}}>
                                    {item.name + '-' }
                                    { item.strength }
                                    </Text>
                                    <TouchableOpacity
                                    onPress={()=>{setLanguages(languages.filter((e)=>(e.name !== item.name)))}}
                                    >
                                        <FIcon name='trash-2' 
                                        style={{fontSize:25,color:config.primary_color}} />
                                    </TouchableOpacity>
                                </View> 
                            )
                        })}
                        </View>
                        <View style={{marginBottom: 40}}>
                            <PrimaryButton content={'Save'} clicked={()=>{props.onClose(languages)}}/>
                        </View>
                        </ScrollView>
                </View>
            </SafeAreaView>
        )
    }

const styles = StyleSheet.create({
    backButton: {
      color: '#473BB3',
      fontSize: 22,
      paddingRight: 15,
    }
})
export default LanguagesKnown