import { LOAD_PAYMENT_PREVIEW } from "../constants/constants";



export function loadPreview(paymentId){
    // console.log(paymentId);
    return {
        type:LOAD_PAYMENT_PREVIEW,
        paymentId:paymentId
    }
}