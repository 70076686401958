import {
  INIT_PAYMENT_STATE,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  MAKE_PAYMENT,
  RESET_PAYMENT_STATE,
} from '../constants/constants'

const initialState = {
  isLoading: true,
  error: '',
  transaction_details: {},
  milestones: [],
  success: false,
}

const reducer = function makePaymentReducer (state = initialState, action) {
  switch (action.type) {
    case INIT_PAYMENT_STATE: {
      return {
        ...state,
        transaction_details: action.transaction_details,
        milestones: action.transaction_details.milestones,
        isLoading: false,
        error: '',
        success: false,
      }
    }
    case MAKE_PAYMENT: {
      return {
        ...state,
        isLoading: true,
        error: '',
        success: false,
      }
    }
    case PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
      }
    }
    case PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.message,
      }
    }
    case RESET_PAYMENT_STATE: {
      return {
        ...state,
        success: false,
      }
    }
    default:
      return state
  }
}

export default reducer
