import {
  INVITE_USER,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
} from '../constants/constants';

const initialState = {
  isLoading: false,
  message: '',
  error: '',
};

const reducer = function inviteUserReducer(state = initialState, action) {
  switch (action.type) {
    case INVITE_USER: {
      return {
        ...state,
        isLoading: true,
        error: '',
        message: '',
      };
    }
    case INVITE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case INVITE_USER_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
