import config from '../config';
import Interceptor from './Interceptor';

class PaymentsService {
  getAllPayments = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/payments/all`,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  getUserPayments = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/user`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  getGigDetails = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      // console.log(params);
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payment/detail`,
        params,
      );
      // console.log(result.data.data);
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  setReview = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/review`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  createPayment = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/create`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  requestPayment = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/request`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  updatePayment = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/update`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  getGig = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/gig`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  getMilestone = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/milestone`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  updateOrderStatus = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/status/update`,
        params,
      );
      // console.log(result.data.data);
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  };
  processRefund = async params =>{
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payment/refund`,
        params,
      );
      // console.log(result.data.data);
      return result.data.data;
    } catch (error) {
      // console.log(error.response.data);
      return error.response.data;
    }
  }




  //**********************************    Guset Payment Checkout  HTTP Services    ********************************


  fetch_order_for_guest_payment = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/order/preview/fetch`,
        params,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  };


  update_guest_payment_order_payment_detials = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payments/order/update`,
        params,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }


  //**********************************    Guset Payment Checkout  HTTP Services    ********************************

}

export default new PaymentsService();
