// import React, {useEffect} from 'react';
// import {
//   View,
//   Text,
//   ScrollView,
//   SafeAreaView,
//   ActivityIndicator,
// } from 'react-native';
// import config from '../../config';
// import {connect} from 'react-redux';
// import GigMilestoneCard from '../../components/GigMilestoneCard';
// import PrimaryButton from '../../components/PrimaryButton';
// import RejectedButton from '../../components/RejectedButton';
// import { loadGigDetails } from '../../state-management/actions/gigdetails.action'
// import moment from 'moment'
// import { setFloatingData , resetFloatingData } from '../../state-management/actions/floating.action'
// import AwesomeAlert from 'react-native-awesome-alerts';
// import {loadProfile} from '../../state-management/actions/userprofile.action';

// const MerchantPayment = props => {

//   useEffect(() => {
//     console.log('Inside MerchantPayment');
//     props.loadGigDetails(props.route.params.uid);
//     props.loadProfile();
//     return () => {
      
//     }
//   }, [])

//   const renderMilestones = () => {
//     return props.gigMilestoneDetails.map((item, index) => {
//       return (
//         <GigMilestoneCard
//           key={index}
//           index={index + 1}
//           gigDetails={props.gigDetails}
//           {...item}
//           loading={props.isLoading}
//         />
//       );
//     });
//   };

//   const next = () =>{

//         if(props.gigMilestoneDetails[0].hasOwnProperty('merchant_details')){
//           props.navigation.navigate('PaymentCheckout', {
//             data: {
//               transaction_details: {
//                 payment_id: props.route.params.uid,
//                 milestone_id: props.gigMilestoneDetails[0].uid,
//               },
//               to_user:props.gigDetails.client_id,
//               amount:props.gigDetails.total_amount,
//               type: 'Fund_Full_Payment',
//               merchant_details:{
//               return_url:props.gigMilestoneDetails[0].merchant_details.return,
//               notify_url:props.gigMilestoneDetails[0].merchant_details.notify_url,
//               order_id:props.gigMilestoneDetails[0].merchant_details.order_id,
//               order_key:props.gigMilestoneDetails[0].merchant_details.order_key,
//               total_amount:props.gigDetails.total_amount,
//               merchant_platform:props.gigMilestoneDetails[0].merchant_details.merchant_platform
//               }
//             },
//           });
//         }
//         // console.log(props.gigMilestoneDetails[0].merchant_details.return);
//         // console.log(props.gigMilestoneDetails[0].merchant_details.notify_url);
//   }
//     return props.isLoading?(
//         <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
//             <ActivityIndicator size="large" color={config.primary_color} />
//         </View>):
        // <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        //   <ScrollView showsVerticalScrollIndicator={false}>
        //         <View
        //         style={{
        //           paddingVertical:'5%',
        //           paddingHorizontal:30
        //         }}>

        //           <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#828282',lineHeight:22}}>
        //               Payment for
        //           </Text>
        //           <Text style={{fontFamily: 'OpenSans-Bold',fontSize:20,marginTop:10,color:config.primary_color,lineHeight:24}}>
        //             {props.gigDetails.gig_name}
        //           </Text>
        //           <View style={{flexDirection: 'row', marginTop:'7%',marginBottom:'3%'}}>
        //             <Text
        //               style={{
        //                 fontFamily: 'OpenSans-SemiBold',
        //                 fontSize: 16,
        //                 color: config.primary_color,
        //                 textAlign: 'left',
        //                 flex: 0.9,
        //                 lineHeight:22
        //               }}>
        //               Amount
        //             </Text>
        //             <Text
        //               style={{
        //                 fontFamily: 'OpenSans-SemiBold',
        //                 fontSize: 16,
        //                 color: config.primary_color,
        //                 lineHeight:22
        //               }}>
        //               {`\u20B9${props.gigDetails.total_amount}`}
        //             </Text>
        //           </View>
        //           <Text style={{fontFamily: 'Roboto-Regular',fontSize:12,color:'#0BA888',lineHeight:16}}>
        //           Your funds are safe and secure with us. We will release the money 
        //           only after you approve the payment after {props?.clickedUser?.company_name?props?.clickedUser?.company_name:`${props?.clickedUser.firstname} ${props?.clickedUser.lastname}`} completes the gig/order.
        //           </Text>
        //           <Text
        //           style={{
        //             fontSize: 12,
        //             fontFamily: 'Roboto-Regular',
        //             color: '#828282',
        //             marginTop: 20,
        //           }}>
        //           Created on {moment(props.gigDetails.createdAt).format('MMM D YYYY')}
        //         </Text>
        //         {props.gigDetails.mode == 'buying' &&
        //           props.gigDetails.order_status == 'yet_to_pay' ? (
        //             <View>
        //               <PrimaryButton
        //                 content={'Fund full Amount'}
        //                 loading={props.isLoading}
        //                 clicked={() => next()}
        //               />
        //               {props.gigDetails.isVirtual == false ? (
        //                 <View>
        //                   <Text
        //                     style={{
        //                       fontFamily: 'OpenSans-Bold',
        //                       fontSize: 20,
        //                       color: config.primary_color,
        //                       textAlign: 'center',
        //                       marginTop: 30,
        //                     }}>
        //                     Or
        //                   </Text>
        //                   <PrimaryButton
        //                     content={'Fund 1st Milestone'}
        //                     loading={props.isLoading}
        //                     clicked={() => next()}
        //                   />
        //                 </View>
        //               ) : null}
        //             </View>
        //           ) : null}
        //           <Text style={{fontFamily: 'OpenSans-SemiBold',fontSize:16,marginTop:40,color:config.primary_color,lineHeight:19}}>
        //             Details for payment
        //           </Text>
        //           <Text style={{fontSize: 12,fontFamily: 'Roboto-Regular',color: '#828282',marginTop: 10,}}>
        //           {props.gigDetails.requirements}
        //           </Text>

        //           {props.gigDetails.isVirtual? null :(
        //         <View style={{marginTop:30}}>
        //           <Text
        //             style={{
        //               fontFamily: 'Roboto-Bold',
        //               fontSize: 18,
        //               color: config.primary_color,
        //             }}>
        //             Milestone
        //           </Text>
        //           {renderMilestones()}
        //         </View>
        //       )}


        //         </View>

        //   </ScrollView>
        
        // </SafeAreaView>
// }


// export default connect(
//     state => ({...state.gigDetails}),
//     {
//         loadGigDetails,
//         setFloatingData,
//         resetFloatingData,
//         loadProfile
//     },
//   )(MerchantPayment);


import React, {useEffect} from 'react';
import {
  View,
  Text,
  ScrollView,
  SafeAreaView,
  ActivityIndicator,
} from 'react-native';
import config from '../../config';
import {connect} from 'react-redux';
import GigMilestoneCard from '../../components/GigMilestoneCard';
import PrimaryButton from '../../components/PrimaryButton';
import { loadGigDetails } from '../../state-management/actions/gigdetails.action'
import moment from 'moment'
import { setFloatingData , resetFloatingData } from '../../state-management/actions/floating.action'
import {loadProfile} from '../../state-management/actions/userprofile.action';
import ProfileBadge from '../../assets/images/profileBadge';

const MerchantPayment = props => {

  useEffect(() => {
    console.log('Inside MerchantPayment');
    props.loadGigDetails(props.route.params.uid);
    props.loadProfile();
    return () => {
      
    }
  }, [])

  const renderMilestones = () => {
    return props.gigMilestoneDetails.map((item, index) => {
      return (
        <GigMilestoneCard
          key={index}
          index={index + 1}
          gigDetails={props.gigDetails}
          {...item}
          loading={props.isLoading}
        />
      );
    });
  };

  const next = () =>{

        // if(props.gigMilestoneDetails[0].hasOwnProperty('merchant_details')){
          props.navigation.navigate('PaymentCheckout', {
            data: {
              transaction_details: {
                payment_id: props.route.params.uid,
                milestone_id: props.gigMilestoneDetails[0].uid,
              },
              to_user:props.gigDetails.client_id,
              amount:props.gigDetails.total_amount,
              type: 'Fund_Full_Payment',
              merchant_details:{
              return_url:props.gigMilestoneDetails[0]?.merchant_details?.return,
              notify_url:props.gigMilestoneDetails[0]?.merchant_details?.notify_url,
              order_id:props.gigMilestoneDetails[0]?.merchant_details?.order_id,
              order_key:props.gigMilestoneDetails[0]?.merchant_details?.order_key,
              total_amount:props.gigDetails.total_amount,
              merchant_platform:props.gigMilestoneDetails[0]?.merchant_details?.merchant_platform
              }
            },
          });
        // }
        // console.log(props.gigMilestoneDetails[0].merchant_details.return);
        // console.log(props.gigMilestoneDetails[0].merchant_details.notify_url);
  }

  const renderNormalUI = () => {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <ScrollView showsVerticalScrollIndicator={false}>
            <View
            style={{
              paddingVertical:'5%',
              paddingHorizontal:30
            }}>

              <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#828282',lineHeight:22}}>
                  Payment for
              </Text>
              <Text style={{fontFamily: 'OpenSans-Bold',fontSize:20,marginTop:10,color:config.primary_color,lineHeight:24}}>
                {props.gigDetails.gig_name}
              </Text>
              <View style={{flexDirection: 'row', marginTop:'7%',marginBottom:'3%'}}>
                <Text
                  style={{
                    fontFamily: 'OpenSans-SemiBold',
                    fontSize: 16,
                    color: config.primary_color,
                    textAlign: 'left',
                    flex: 0.9,
                    lineHeight:22
                  }}>
                  Amount
                </Text>
                <Text
                  style={{
                    fontFamily: 'OpenSans-SemiBold',
                    fontSize: 16,
                    color: config.primary_color,
                    lineHeight:22
                  }}>
                  {`\u20B9${props.gigDetails.total_amount}`}
                </Text>
              </View>
              <Text style={{fontFamily: 'Roboto-Regular',fontSize:12,color:'#0BA888',lineHeight:16}}>
              Your funds are safe and secure with us. We will release the money 
              only after you approve the payment after {props?.clickedUser?.company_name?props?.clickedUser?.company_name:`${props?.clickedUser.firstname} ${props?.clickedUser.lastname}`} completes the gig/order.
              </Text>
              <Text
              style={{
                fontSize: 12,
                fontFamily: 'Roboto-Regular',
                color: '#828282',
                marginTop: 20,
              }}>
              Created on {moment(props.gigDetails.createdAt).format('MMM D YYYY')}
            </Text>
            {props.gigDetails.mode == 'buying' &&
              props.gigDetails.order_status == 'yet_to_pay' ? (
                <View>
                  <PrimaryButton
                    content={'Fund full Amount'}
                    loading={props.isLoading}
                    clicked={() => next()}
                  />
                  {props.gigDetails.isVirtual == false ? (
                    <View>
                      <Text
                        style={{
                          fontFamily: 'OpenSans-Bold',
                          fontSize: 20,
                          color: config.primary_color,
                          textAlign: 'center',
                          marginTop: 30,
                        }}>
                        Or
                      </Text>
                      <PrimaryButton
                        content={'Fund 1st Milestone'}
                        loading={props.isLoading}
                        clicked={() => next()}
                      />
                    </View>
                  ) : null}
                </View>
              ) : null}
              <Text style={{fontFamily: 'OpenSans-SemiBold',fontSize:16,marginTop:40,color:config.primary_color,lineHeight:19}}>
                Details for payment
              </Text>
              <Text style={{fontSize: 12,fontFamily: 'Roboto-Regular',color: '#828282',marginTop: 10,}}>
              {props.gigDetails.requirements}
              </Text>

              {props.gigDetails.isVirtual? null :(
            <View style={{marginTop:30}}>
              <Text
                style={{
                  fontFamily: 'Roboto-Bold',
                  fontSize: 18,
                  color: config.primary_color,
                }}>
                Milestone
              </Text>
              {renderMilestones()}
            </View>
          )}


            </View>

      </ScrollView>
    
    </SafeAreaView>
    )
  }
    return props.isLoading?(
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator size="large" color={config.primary_color} />
        </View>):props.route.params.hasOwnProperty('render')?
        renderNormalUI()
        :<SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <ScrollView showsVerticalScrollIndicator={false}>
              <View
              style={{
                paddingVertical:'5%',
                paddingHorizontal:30
              }}>

<Text style={{fontFamily: 'Roboto-Bold',fontSize:24,color:config.primary_color,lineHeight:27,textAlign:'center'}}>
                  Welcome to the world of buyer protection
                </Text>
                <Text style={{fontFamily: 'Roboto-Bold',fontSize:18,color:'#000',textAlign:'center',marginTop:30,lineHeight:24}}>
                  {props?.clickedUser?.company_name?props?.clickedUser?.company_name:`${props?.clickedUser.firstname} ${props?.clickedUser.lastname}`}
                </Text>
                <Text style={{fontFamily: 'Roboto-Regular',fontSize:18,color:'#000',lineHeight:24,textAlign:'center',marginTop:10}}>
                is a vouched business and offers
                </Text>
                <ProfileBadge style={{alignSelf: 'center',marginTop:20}}/>
                <Text style={{fontFamily: 'Roboto-Regular',fontSize:16,color:'#000',lineHeight:24,textAlign:'center',marginTop:25,textAlign:'center'}}>
                {`Payment protection backed by a money back guarantee.
i.e if you do not receive the ${props.gigDetails.classification} vouch will
refund your money`}
                </Text>
                <Text style={{fontFamily: 'Roboto-Regular',fontSize:16,color:'#000',lineHeight:24,textAlign:'center',marginTop:25,textAlign:'center'}}>
                {`Superior tracking, updates & customer support
through the Vouch app`}
                </Text>
                <Text style={{fontFamily: 'Roboto-Bold',fontSize:16,color:'#000',marginTop:25,lineHeight:24}}>
                Payment for
                </Text>
                <Text style={{fontFamily: 'OpenSans-Regular',fontSize:14,marginTop:10,color:config.primary_color,lineHeight:24}}>
                  {props.gigDetails.gig_name}
                </Text>
                <View style={{flexDirection: 'row', marginTop:10,marginBottom:'3%'}}>
                  <Text
                    style={{
                      fontFamily: 'Roboto-Bold',
                      fontSize: 16,
                      color: '#000',
                      textAlign: 'left',
                      flex: 0.9,
                      lineHeight:22
                    }}>
                    Amount
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto-Bold',
                      fontSize: 16,
                      color: '#000',
                      lineHeight:22
                    }}>
                    {`\u20B9${props.gigDetails.total_amount}`}
                  </Text>
                </View>
                <Text style={{fontFamily: 'OpenSans-Regular',fontStyle:'italic',fontSize:12,color:'#595959',lineHeight:16}}>
                Your funds are safe and secure with us. We will release the money 
                only after you approve the payment after {props?.clickedUser?.company_name?props?.clickedUser?.company_name:`${props?.clickedUser.firstname} ${props?.clickedUser.lastname}`} completes the gig/order.
                </Text>
                <Text
                style={{
                  fontSize: 12,
                  fontFamily: 'OpenSans-Regular',
                  color: '#828282',
                  marginTop: 20,
                }}>
                Created on {moment(props.gigDetails.createdAt).format('MMM D YYYY')}
              </Text>
              {props.gigDetails.mode == 'buying' &&
                props.gigDetails.order_status == 'yet_to_pay' ? (
                  <View>
                    <PrimaryButton
                      content={'Fund full Amount'}
                      loading={props.isLoading}
                      clicked={() => next()}
                    />
                  </View>
                ) : null}
                <Text style={{fontFamily: 'Roboto-Bold',fontSize:16,color:'#000',marginTop:25,lineHeight:24}}>
                Details for payment
                </Text>
                <Text style={{fontSize: 12,fontFamily: 'Roboto-Regular',color: '#828282',marginTop: 10,}}>
                {props.gigDetails.requirements}
                </Text>

                {props.gigDetails.isVirtual? null :(
              <View style={{marginTop:30}}>
                <Text
                  style={{
                    fontFamily: 'Roboto-Bold',
                    fontSize: 16,
                    color: '#000',
                  }}>
                  Milestone
                </Text>
                {renderMilestones()}
              </View>
            )}


              </View>

        </ScrollView>
      
      </SafeAreaView>
}


export default connect(
    state => ({...state.gigDetails}),
    {
        loadGigDetails,
        setFloatingData,
        resetFloatingData,
        loadProfile
    },
  )(MerchantPayment);