import {takeLatest, put, call, delay} from 'redux-saga/effects'
import {
  LOAD_PROTECTED_PAYMENTS,
  LOAD_PROTECTED_PAYMENTS_SUCCESS,
  LOAD_PROTECTED_PAYMENTS_ERROR,
} from '../constants/constants'
import PaymentsService from '../../services/payments.service'

const execLoadProtectedPayments = async actionData => {
  try {
    var result = await PaymentsService.getAllPayments()
    return result
  } catch (error) {
    throw {message: 'Something went wrong'}
  }
}

function * loadProtectedPayments (action) {
  try {
    const res = yield call(execLoadProtectedPayments, action)

    yield put({
      type: LOAD_PROTECTED_PAYMENTS_SUCCESS,
      response: res,
    })
  } catch (error) {
    yield put({
      type: LOAD_PROTECTED_PAYMENTS_ERROR,
      response: {message: error.message},
    })
  }
}

function * watchRecentProtectedPayments () {
  yield takeLatest(LOAD_PROTECTED_PAYMENTS, loadProtectedPayments)
}

export default watchRecentProtectedPayments
