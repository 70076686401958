import {VERIFY_INIT, VERIFY_OTP, RE_VERIFY_OTP} from '../constants/constants'

export function verifyOTPInit (data) {
  return {
    type: VERIFY_INIT,
    confirm: data.confirm,
    mobile: data.mobile,
    signupData: data.signupData,
    page: data.page,
  }
}

export function verifyOTP (data) {
  return {
    type: VERIFY_OTP,
    confirm: data.confirm,
    otp: data.otp,
    signupData: data.signupData,
    page: data.page,
  }
}

export function resendOTP (mobile) {
  return {
    type: RE_VERIFY_OTP,
    mobile: mobile,
  }
}
