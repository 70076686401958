class HelperService {

    setCookie = (name, value, expiryInDays, path = '/') => {
        if (typeof expiryInDays == "number") {
            var cookie = `${name}=${encodeURIComponent(value)}; max-age=${expiryInDays * 24 * 60 * 60}; path=${path}` // cookie string formation with max-age in days
            document.cookie = cookie; // adding cookie
        }
    }

    getCookie = (cookieName) => {
        var cookies = document.cookie.split(';');
        for (const cookie of cookies) {
            var pair = cookie.split('=');
            if (cookieName == pair[0].trim())
                return decodeURIComponent(pair[1])
        }
        return null; // cookie not found
    }

}

export default new HelperService();