import { FETCH_RECOMMENDATIONS, FETCH_RECOMMENDATIONS_ERROR, FETCH_RECOMMENDATIONS_SUCCESS, INIT_RECOMMENDATIONS, REQUEST_RECOMMENDATIONS, REQUEST_RECOMMENDATIONS_ERROR, REQUEST_RECOMMENDATIONS_SUCCESS, SUBMIT_RECOMMENDATIONS, SUBMIT_RECOMMENDATIONS_ERROR, SUBMIT_RECOMMENDATIONS_SUCCESS } from '../constants/constants'
  
  const initialState = {
    isLoading: false,
    error: '',
    isSuccess: false,
    recommendationDetails:{},
    isFetchLoading:true,
    link:''
  }
  
  const reducer = function recommendationsReducer (state = initialState, action) {
    switch (action.type) {
        case INIT_RECOMMENDATIONS:{
            return{
                ...initialState,
            }
        }
        case REQUEST_RECOMMENDATIONS: {
            return {
            ...state,
            isLoading: true,
            isSuccess: false,
            error: '',
            }
        }
    
        case REQUEST_RECOMMENDATIONS_SUCCESS: {
            return {
            ...state,
            isSuccess: true,
            error: '',
            isLoading: false,
            link:action.response.link
            }
        }
        case REQUEST_RECOMMENDATIONS_ERROR: {
            return {
            ...state,
            error: action.response.message,
            isLoading: false,
            isSuccess:false
            }
        }
        case FETCH_RECOMMENDATIONS:{
            return{
                ...state,
                isFetchLoading:true,
            }
        }
        case FETCH_RECOMMENDATIONS_SUCCESS:{
            return{
                ...state,
                isFetchLoading:false,
                recommendationDetails:action.response.recommendation_details
            }
        }
        case FETCH_RECOMMENDATIONS_ERROR: {
            return{
                ...state,
                isFetchLoading:false,
                error:action.response.message
            }
        }
        case SUBMIT_RECOMMENDATIONS: {
            return{
                ...state,
                isLoading:true
            }
        }
        case SUBMIT_RECOMMENDATIONS_SUCCESS: {
            return{
                ...state,
                isLoading:false,
                isSuccess:true
            }
        }
        case SUBMIT_RECOMMENDATIONS_ERROR: {
            return{
                ...state,
                isLoading:false
            }
        } 
        default:
            return state
        }
  }
  
  export default reducer
  