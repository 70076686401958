import {takeLatest, put, call} from 'redux-saga/effects';
// import PushNotification from 'react-native-push-notification';
import {INIT_NOTIFICATIONS} from '../constants/constants';
import config from '../../config';
import {dispatch} from '../../navigation/Navigator';
import {CommonActions} from '@react-navigation/native';
import userService from '../../services/user.service';

const execCheckNotifications = async () => {
  try {
    // const currentUser = firebase.auth().currentUser.uid;
    // if (currentUser) {
    //   PushNotification.configure({
    //     onRegister: token => {
    //         var q =userService.addFcmToken({
    //           token: token.token
    //         })
    //     },
    //     onNotification: notification => {
    //       if (notification.userInteraction) {
    //         handleRoutes(notification.data.type, notification.data);
    //       } else if (notification.foreground == false) {
    //         if (notification) {
    //           handleRoutes(notification.type, notification);
    //         }
    //       }
    //     },
    //     senderID: config.senderID,
    //     popInitialNotification: false,
    //     requestPermissions: true,
    //   });
    //   PushNotification.popInitialNotification(notification => {
    //     if (notification) {
    //       handleRoutes(notification.type, notification);
    //     }
    //   });
    // }
  } catch (error) {
    // console.log(error)
  }
};

const handleRoutes = (type, data) => {
  try {
    switch (type) {
      case 'Payment':
        dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {name: 'Home'},
              {
                name: 'PaymentUser',
                params: {
                  uid: data.uid,
                  subRoute: data.subRoute,
                },
              },
            ],
          }),
        );
        break;
      case 'PaymentDetail':
        dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {name: 'Home'},
              {
                name: 'GigDetails',
                params: {
                  uid: data.p_id,
                  action: data.action,
                  alertText: data.alertMessage,
                  showLinking: data.showLinking,
                },
              },
            ],
          }),
        );

        break;
      default:
        break;
    }
  } catch (error) {}
};

function* checkNotifications() {
  const res = yield call(execCheckNotifications);
}

function* watchNotifications() {
  yield takeLatest(INIT_NOTIFICATIONS, checkNotifications);
}

export default watchNotifications;
