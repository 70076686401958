import React, {Component} from 'react'
import {
  View, SafeAreaView, TouchableOpacity,StyleSheet,Text,ScrollView
} from 'react-native'
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import {Textarea} from 'native-base'
import PrimaryButton from '../PrimaryButton';
import CommunicationPicker from '../CommunicationPicker';


export default class CommunicationPreferences extends Component {
    constructor (props) {
      super(props)
      this.state = {
        serv1:'',
        serv2:'',
        serv3:'',
      }
    }
    componentDidMount(){

      if(this.props.communicationData?.slot1){
        this.setState({serv1:this.props.communicationData?.slot1})
      }
      if(this.props.communicationData?.slot2){
        this.setState({serv2:this.props.communicationData?.slot2})
      }
      if(this.props.communicationData?.slot3){
        this.setState({serv3:this.props.communicationData?.slot3})
      }
    }
    renderHeader= () => {
        return (
            <View
            style={{
              flexDirection: 'row',
              alignItems: 'flex-start',
              paddingHorizontal: 20,
              paddingTop: 20,
            }}>
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={()=>{this.props.cameBack()}}>
              <AIcon name="left" style={styles.backButton} />
            </TouchableOpacity>
            <Text
              style={{
                flex: 1,
                fontSize: 26,
                fontFamily: 'OpenSans-Semibold',
                color: config.primary_color,
              }}>
                  Communication {'\n'}preferences
            </Text>
          </View>
        );
      };
    render () {
        const option= [
            {
              title: '24/7',
            },
            {
              title: '9am to 9pm (extended day)',
            },
            {
              title: '9am to 6pm (work day)'
            }
          ]
        return(
            <SafeAreaView  style={{flex: 1,backgroundColor: '#FFF',}}>
                <ScrollView showsVerticalScrollIndicator={false}>
                {this.renderHeader()}
                <View style={{flex:1,marginHorizontal:'10%',paddingTop:50}}>
                    <Text
                    style={{
                        fontFamily: 'Roboto-Regular',
                        fontSize: 16,
                        color: '#464646',
                        paddingLeft: 5,
                        marginTop:20,
                        paddingBottom:5
                    }}>
                    Vouch Chat                
                    </Text>
                    <CommunicationPicker communicationData={this.props.communicationData?.slot1}
                    data={option} onServiceSelected={serv => this.setState({serv1:serv})} name={'Select Duration avaliable'}/>
                    <Text
                    style={{
                        fontFamily: 'Roboto-Regular',
                        fontSize: 16,
                        color: '#464646',
                        paddingLeft: 5,
                        marginTop:20,
                        paddingBottom:5
                    }}>
                        Phone
                    </Text>
                    <CommunicationPicker communicationData={this.props.communicationData?.slot2}
                    data={option} onServiceSelected={serv => this.setState({serv2:serv})} name={'Select Duration avaliable'}/>
                    <Text
                    style={{
                        fontFamily: 'Roboto-Regular',
                        fontSize: 16,
                        color: '#464646',
                        paddingLeft: 5,
                        marginTop:20,
                        paddingBottom:5
                    }}>
                    Email                
                    </Text>
                    <CommunicationPicker  communicationData={this.props.communicationData?.slot3}
                    data={option} onServiceSelected={serv => this.setState({serv3:serv})} name={'Select Duration avaliable'}/>
                    
                    <View style={{ marginVertical: 50}}>
                            <PrimaryButton content={'Save'} 
                            clicked={()=>{this.props.onClose({slot1:this.state.serv1,slot2:this.state.serv2,slot3:this.state.serv3})}}/>
                    </View>
                </View>
                </ScrollView>
            </SafeAreaView>
            )
        }
    }
    const styles = StyleSheet.create({
        backButton: {
          color: '#473BB3',
          fontSize: 22,
          paddingRight: 15,
          marginTop:10
        }
    })