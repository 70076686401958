import { takeLatest, delay, put, call } from "redux-saga/effects";
import {
  AUTH_CHECK,
  AUTH_FAILURE,
  AUTH_SUCCESS,
  LOGOUT,
  LOGOUT_DONE,
} from "../constants/constants";
import AsyncStorage from "@react-native-community/async-storage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import userService from '../../services/user.service'
import logsService from '../../services/logs.service';

const auth = firebase.auth;
const firestore = firebase.firestore;

const checkIntroAuth = async () => {
  try {
    var isAuth = false;
    var flags = await AsyncStorage.multiGet(["isIntroShown", "token"]);
    var authUser = await auth().currentUser;
    if (authUser) {
      // var currentUser = await firestore()
      //   .collection("users")
      //   .doc(authUser.uid)
      //   .get();
      // console.log(currentUser);
      var currentUser = await userService.getUser({
        id:authUser.uid,
        type:'doc'
      })
      isAuth = currentUser.exists;
      if (!currentUser.exists) {
        await auth().signOut();
      }
    }
    return {
      intro: flags[0][1] ? true : false,
      auth: isAuth,
    };
  } catch (error) {
    return { intro: false, auth: false };
  }
};

function* checkAuthToken() {
  try {
    const res = yield call(checkIntroAuth);
    yield put({
      type: AUTH_SUCCESS,
      response: { intro: res.intro, auth: res.auth },
    });
  } catch (error) {
    // console.log(error);
    yield put({ type: AUTH_FAILURE, response: { intro: false } });
  }
}

const firbaseLogout = async () => {
  try {
    const loger = await logsService.logCustomEvent('logout',{userId:firebase.auth().currentUser.uid})
    const res = await auth().signOut();
    window.location.href=`https://${window.location.hostname}`;
  } catch (error) {
    throw error;
  }
};

function* logout() {
  try {
    const res = yield call(firbaseLogout);
    yield put({ type: LOGOUT_DONE, response: false });
  } catch (err) {
    yield put({
      type: LOGOUT_DONE,
      response: { message: "Something went wrong", error: err },
    });
  }
}

function* authWatcher() {
  yield takeLatest(AUTH_CHECK, checkAuthToken);
  yield takeLatest(LOGOUT, logout);
}

export default authWatcher;
