import {
  INIT_CHECKOUT_DETAILS,
  INIT_CHECKOUT_SUCCESS,
  INIT_CHECKOUT_ERROR,
  RESET_PAYMENT_CHECKOUT_STATE,
  PAYMENT_COMPLETED,
  PAYMENT_COMPLETED_SUCCESS,
  PAYMENT_COMPLETED_ERROR,
  CHECKOUT_PAYMENT,
  CHECKOUT_PAYMENT_ERROR,
  CHECKOUT_PAYMENT_SUCCESS,
  CREATE_VIRTUALL_ACCOUNT_SUCCESS,
  CREATE_VIRTUALL_ACCOUNT
} from '../constants/constants';

const initialState = {
  gigDetails: {},
  type: '',
  user: {},
  isLoading: false,
  processPayment: false,
  paymentFailed: false,
  error: null,
  success: false,
  paymentDetails: {},
  app_id: null,
  link: '',
  paymentOptions:{},
  createAccountSuccess:false,
  virtualAccountData:{}
};

const reducer = function paymentCheckoutReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_CHECKOUT_DETAILS: {
      return {
        isLoading:true,
      };
    }
    case INIT_CHECKOUT_SUCCESS: {
      return {
        isLoading: true,
        paymentDetails: action.response.paymentDetails,
        type: action.response.type,
        gigDetails: action.response.gigDetails,
        user: action.response.user,
        processPayment: true,
        paymentFailed: false,
        app_id: action.response.app_id,
        paymentOptions:action.response.paymentOptions
      };
    }
    case INIT_CHECKOUT_ERROR: {
      return {
        ...initialState,
        isLoading: false,
        error: action.response.message,
        processPayment: false,
        paymentFailed: false,
      };
    }
    case PAYMENT_COMPLETED: {
      return {
        ...state,
        isLoading: true,
        error: null,
        processPayment: false,
        paymentFailed: false,
      };
    }
    case PAYMENT_COMPLETED_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        paymentFailed: false,
        link: action.response.link,
      };
    }
    case PAYMENT_COMPLETED_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
        paymentFailed: true,
      };
    }

    case CHECKOUT_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case CHECKOUT_PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
        paymentFailed: true,
      };
    }
    case RESET_PAYMENT_CHECKOUT_STATE: {
      return {
        ...initialState,
      };
    }
    case CREATE_VIRTUALL_ACCOUNT: {
      return {
        isLoading:true,
      };
    }
    case CREATE_VIRTUALL_ACCOUNT_SUCCESS: {
      return {
        createAccountSuccess:true,
        virtualAccountData:action.response
      };
    }

    default:
      return state;
  }
};

export default reducer;
