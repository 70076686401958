import React, {useEffect} from 'react';
import {Dimensions, Animated} from 'react-native';
import {connect} from 'react-redux';
import {logout} from '../../state-management/actions/auth.action';
import {loadRecentContacts} from '../../state-management/actions/recentcontacts.action';
import {loadProfile} from '../../state-management/actions/userprofile.action';
import {loadEssentials} from '../../state-management/actions/helper.action';
import NewHome from './NewHome';
import OldHome from './OldHome';
import { navigate } from '../../navigation/Navigator';
import { resetFloatingData } from '../../state-management/actions/floating.action'
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const HEADER_HEIGHT = 260;
const {height, width} = Dimensions.get('window');

const Home = props => {
  
  

  useEffect(() => {
    var unsubscribe = props.navigation.addListener('focus', () => {
      // console.log('Inside Main HOme');
      if (!props.isLoginLater) {
        props.loadRecentContacts();
        props.loadProfile();
        props.loadEssentials();
      }
    });
    return () => {
    unsubscribe();
    };
  }, []);

  useEffect(()=>{
    if (props.error) {
      if (props.error != '') {
        window.alert(props.error)
      }
    }
    if (props.success ) {
      if (props.floating.isFloatingPresent) {
          props.loadRecentContacts();
          props.loadProfile();
          props.loadEssentials();
          switch(props.floating.data.mode){
            case 'Make_Payment':
              setTimeout(()=>{
                navigate('MakePayment',{
                  transaction_details: props.floating.data.transaction_details,
                })
              },1000)
              break;
            case 'For_Sale_Make_Payment':
              if(firebase.auth().currentUser.uid!=props.floating.data.transaction_details?.to_user)
              setTimeout(()=>{
                navigate('ForSaleBuying',{
                  transaction_details: props.floating.data.transaction_details,
                })
              },1000)
              break;
            case 'All_Notifications':
              setTimeout(()=>{
                navigate('AllNotifications')
              },1000)
              break;
            case 'Recommendation':
              if(firebase.auth().currentUser.uid==props.floating.data.recommendation_details?.to_user)
              setTimeout(()=>{
                navigate('SaveRecommendation',{id:props.floating.data.recommendation_details?.id})
              },1000)
              else props.resetFloatingData()
              break;
            case 'Gig_Action':
              setTimeout(()=>{
                navigate('GigDetails',{uid:props.floating.data.uid})
              },1000)
              break;
            case 'Profile_Action':
              setTimeout(()=>{
                navigate('UserProfile',{value:props.floating.data.value})
              },1000)
              break;
            case 'Request_Payment':
              setTimeout(()=>{
                navigate('RequestPayment',{
                  transaction_details: props.floating.data.transaction_details,
                })
              },1000)
              break;
            case 'Merchant_Payment':
              setTimeout(()=>{
                navigate('MerchantPayment',{uid:props.floating.data.uid,render:true})
              },1000)
              break;
            default:
            break;
          }
      }
    }
  },[props.success])



  // componentDidMount() {
  //   unsubscribe = props.navigation.addListener('focus', () => {
  //     if (!props.isLoginLater) {
  //       console.log('Hello here');
  //         props.loadRecentContacts();
  //       props.loadProfile();
  //       props.loadEssentials();
  //     }
  //   });
  // }

  // componentDidUpdate(prevProps) {
  //   console.log(props);
  //   console.log(prevProps);
  //   if (props.error != prevProps.error) {
  //     if (props.error != '') {
  //       // alert(props.error)
  //       window.alert(props.error)
  //     }
  //   }
  //   if (props.success ) {
  //     if (props.floating.isFloatingPresent && !props.isLoginLater) {
  //         console.log('Hello inside');
  //         if(props.floating.data.mode != 'Gig_Action'){
  //         props.loadRecentContacts();
  //         props.loadProfile();
  //         props.loadEssentials();
  //         }
  //       if (props.floating.data.mode == 'Make_Payment') {
  //         props.navigation.navigate('MakePayment', {
  //           transaction_details: props.floating.data.transaction_details,
  //         });
  //       }
  //       else if(props.floating.data.mode == 'All_Notifications'){
  //         props.navigation.navigate('AllNotifications')
  //       }
  //       else if(props.floating.data.mode == 'Gig_Action'){
  //         console.log(props.floating.data.uid);
  //         props.navigation.navigate('GigDetails',{uid:props.floating.data.uid})
  //       }
  //       else {
  //         props.navigation.navigate('RequestPayment', {
  //           transaction_details: props.floating.data.transaction_details,
  //         });
  //       }
  //     }
  //   }
  // }
  // componentWillUnmount() {
  //   unsubscribe();
  // }
    return props.isLoginLater ? (
      <NewHome navigation={props.navigation} />
    ) : (
      props.recentContacts.total_payments_length?
      <OldHome navigation={props.navigation} />:
      <NewHome navigation={props.navigation} />
    );
  }

export default connect(
  state => ({
    recentContacts: {...state.recentContacts},
    profile: {...state.userProfile},
    floating: {...state.floating},
    ...state.auth,
  }),
  {
    logout,
    loadRecentContacts,
    loadProfile,
    loadEssentials,
    resetFloatingData
  },
)(Home);