import {
  LOAD_RECENT_CONTACTS,
  LOAD_RECENT_CONTACTS_ERROR,
  LOAD_RECENT_CONTACTS_SUCCESS,
} from '../constants/constants'

const initialState = {
  recentContacts: [],
  expressIntrestUsers: [],
  total_payments_length: 0,
  sum: 0,
  isLoading: false,
  transactions_completed_length:0,
  accountAdded:true,
  error: '',
}

const reducer = function recentContactsReducer (state = initialState, action) {
  switch (action.type) {
    case LOAD_RECENT_CONTACTS: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case LOAD_RECENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        recentContacts: action.response.recentsContacts,
        total_payments_length:action.response.total_payments_length,
        sum:action.response.sum,
        transactions_completed_length:action.response.transactions_completed_length,
        expressIntrestUsers:action.response.expressIntrestUsers,
        accountAdded:action.response.accountAdded,
        isLoading: false,
      }
    }
    case LOAD_RECENT_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
