import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  StatusBar,
  ActivityIndicator,
  Share,
  Linking,
} from "react-native";
import { Modal } from "react-native-paper";
import moment from "moment";
import config from "../../config";
import { connect } from "react-redux";
import {
  loadProfileByKey,
  resetProfile,
} from "../../state-management/actions/userprofile.action";
import ExpressIntrestImage from "../../assets/images/expressIntrestImage";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { setContactInfo } from "../../state-management/actions/category.action";
import AboutMeComponent from "../../components/Profile/AboutMeComponent";
import {
  setFloatingData,
  resetFloatingData,
} from "../../state-management/actions/floating.action";
import LottieView from "lottie-react-web";
import Anim404 from "../../assets/lottie-animations/404.json";
import ProfileCard from "../../components/Profile/ProfileCard";
import ReviewsTab from "../../components/Profile/ReviewsTab";
import { setPortfolio } from "../../state-management/actions/editprofile.action";
import AIcon from "react-native-vector-icons/AntDesign";
import FIcon from "react-native-vector-icons/Feather";
import Header from "../../reuseable/Header";
import ProfileBadge from "../../assets/images/ProfileBadge";
import CheckMark from "../../assets/images/CheckMark";
import * as Clipboard from "expo-clipboard";
import EIcon from "react-native-vector-icons/Entypo";
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const UserPreview = props => {
  const [ReviewsTabBool, setReviewsTabBool] = useState(false);
  const [AboutMeComponentBool, setAboutMeComponentBool] = useState(false);
  //usestate for sale state
  const [forsalemodel, setForSaleModel] = useState(false);
  // usestate for PortfolioModel
  const [portfoliomodel, setPortfolioModel] = useState(false);
  const [sociallinks, setSocialLinks] = useState({});
  const pagerRef = React.useRef(null);
  const [t2, setT2] = useState(false);
  const [redirectTime, setRedirectTime] = useState(10);


  useEffect(() => {
    props.loadProfileByKey({ key: "username", ...props.route.params });
    return () => {
      props.resetProfile();
    };
  }, []);

  useEffect(() => {
    setSocialLinks(props.requestedProfile?.social_links);
  }, [props.requestedProfile?.social_links]);

  if (props.success) {
    props.resetProfile();
    if (props.route.params.hasOwnProperty("changeTab")) {
      props.route.params.changeTab();
      props.navigation.goBack();
    }
    props.navigation.navigate("PaymentUser", {
      uid: `${props.requestedProfile?.id}`,
      subRoute: "Messages",
      showAlert: props.chatcreatedFlag,
    });
  }

  if (props.isLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size='large' color={config.primary_color} />
      </View>
    );
  }

  const next = () => {
    props.setFloatingData({
      value: props.route.params.value,
      mode: "Profile_Action",
    });
    props.navigation.navigate("GetStarted");
  };

  if (props.error) {

    setTimeout(() => {
      if(redirectTime <= 1){
        if(window) window.location.replace("/");
        else props.navigation.navigate("Main")
      }
      setRedirectTime(redirectTime-1);
    }, 1000);

    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <LottieView
          options={{
            animationData: Anim404,
            autoplay: true,
            loop: true,
          }}
          style={styles.animationSize}
        />

        
          <Text style={{ 
                      color:"black",
                      fontFamily: 'OpenSans-Bold',
                      fontSize: 12,
                      marginTop : "3rem" }}>
                        Redirecting to Main Page in {redirectTime} seconds ... Please, Login to Continue
          </Text>

          <Text style={{ 
                      color:"black",
                      fontFamily: 'OpenSans-Bold',
                      fontSize: 12,
                      margin : "1rem",
                      marginTop:"2rem" }}>
                        Or Click Below
          </Text>

          <TouchableOpacity
            onPress={() => {
              if(window) window.location.replace("/")
              else props.navigation.navigate("Main")
            }}
            style={{backgroundColor: 'rgb(72, 19, 128)', borderRadius: 8}}>
            <Text
              style={{
                color: "#fff",
                fontFamily: 'OpenSans-Bold',
                fontSize: 12,
                padding: "1rem"
              }}>
              Go To Main Page
            </Text>
          </TouchableOpacity>
          

      </View>
    );
  }

  const ForSaleModel = data => {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header props={data} title='For Sale' />
        <View style={{ flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {/* <TouchableOpacity
              onPress={() => {
                Share.share({
                  message: `https://app.iamvouched.com/${props.requestedProfile.username}/store`,
                });
              }}
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 40,
                marginTop: 5,
              }}
            >
              <AIcon
                name='sharealt'
                style={{ color: "#0C76EF", fontSize: 30 }}
              />
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  color: "#0C76EF",
                  fontSize: 16,
                  textAlign: "center",
                  marginLeft: 10,
                }}
              >
                Share
              </Text>
            </TouchableOpacity> */}
            <View
              style={{
                height: "292px",
                backgroundColor: "#F5EBFF",
                marginTop: "16px",
                marginBottom: "16px",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "16px",
                  textDecorationLine: "underline",
                  marginTop: "7.6px",
                }}
              >
                BUY WITH CONFIDENCE
              </Text>
              <View
                style={{
                  marginTop: "16.8px",
                  marginBottom: "16px",
                }}
              >
                <ProfileBadge style={{ alignSelf: "center" }} />
              </View>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "italic",
                  marginBottom: "8px",
                }}
              >
                {props.requestedProfile.company_name
                  ? props.requestedProfile.company_name
                  : `${props.requestedProfile?.firstname} ${props.requestedProfile?.lastname}`}
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  marginBottom: "8px",
                  fontWeight: "400",
                }}
              >
                Offers money back guarantee backed by vouch
              </Text>

              <View
                style={{
                  paddingLeft: isMobile ? 10 : 45,
                  flex: 1,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Text
                  style={{
                    // marginLeft: "24px",
                    marginRight: isMobile ? 1 : 10,
                  }}
                >
                  <CheckMark
                    style={{
                      alignSelf: "center",
                      innerWidth: 10,
                      innerHeight: 10,
                    }}
                  />
                </Text>
                <Text
                  style={{
                    fontSize: isMobile ? 12 : 14,
                    fontWeight: "bold",
                    paddingBottom: 20,
                    marginRight: isMobile ? 5 : 7.5,
                    color: "#481380",
                    verticalAlign: "middel",
                  }}
                >
                  Full refund
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#481380",
                    marginRight: 30,
                    marginTop: 2,
                  }}
                >
                  if you do not receive your order
                </Text>
              </View>
              <View
                style={{
                  paddingLeft: isMobile ? 10 : 45,
                  flex: 1,
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                <Text
                  style={{
                    // marginLeft: "24px",
                    marginRight: isMobile ? 1 : 10,
                  }}
                >
                  <CheckMark
                    style={{
                      alignSelf: "center",
                      innerWidth: 10,
                      innerHeight: 10,
                    }}
                  />
                </Text>
                <Text
                  style={{
                    fontSize: isMobile ? 12 : 14,
                    fontWeight: "bold",
                    paddingBottom: 20,
                    marginRight: isMobile ? 5 : 7.5,
                    color: "#481380",
                    verticalAlign: "middel",
                  }}
                >
                  Full or partial
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#481380",
                    marginRight: isMobile ? -26 : 30,
                    marginTop: 2,
                  }}
                >
                  refund if the item is not as described
                </Text>
              </View>
            </View>
            {props.forSaleData.map((item, index) => {
              return (
                <ProfileCard
                  {...item}
                  clicked={() => {
                    props.navigation.navigate("ForSale", {
                      data: item,
                      mobile: props.requestedProfile.mobile,
                    });
                  }}
                />
              );
            })}
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  };

  const PortfolioModel = data => {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        <Header props={data} title='Portfolio' />
        <View style={{ flex: 1 }}>
          <ScrollView showsVerticalScrollIndicator={false}>
            {props.portfolioData.map((item, index) => {
              return (
                <ProfileCard
                  {...item}
                  clicked={() => {
                    props.setPortfolio(item);
                    props.navigation.navigate("PortfolioDetail", {
                      mobile: props.requestedProfile.mobile,
                    });
                  }}
                />
              );
            })}
          </ScrollView>
        </View>
      </SafeAreaView>
    );
  };

  // const FirstRoute = () => (
  //   <View style={styles.scene}>
  //     <TrustScoreComponent {...props.requestedProfile} {...props.scores} />
  //   </View>
  // );

  // const SecondRoute = () => (
  //   <View style={styles.scene}>
  //     <View style={{ marginHorizontal: "7%", paddingTop: "7%" }}>
  //       <TouchableOpacity
  //         onPress={() => {
  //           Share.share({
  //             message: `https://app.iamvouched.com/${props.requestedProfile.username}/store`,
  //           });
  //         }}
  //         style={{
  //           flexDirection: "row",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           marginBottom: 40,
  //           marginTop: 5,
  //         }}
  //       >
  //         <AIcon name='sharealt' style={{ color: "#0C76EF", fontSize: 30 }} />
  //         <Text
  //           style={{
  //             fontFamily: "Roboto-Regular",
  //             color: "#0C76EF",
  //             fontSize: 16,
  //             textAlign: "center",
  //             marginLeft: 10,
  //           }}
  //         >
  //           Share
  //         </Text>
  //       </TouchableOpacity>
  //       {props.forSaleData.map((item, index) => {
  //         return (
  //           <ProfileCard
  //             {...item}
  //             clicked={() => {
  //               props.navigation.navigate("ForSale", {
  //                 data: item,
  //                 mobile: props.requestedProfile.mobile,
  //               });
  //             }}
  //           />
  //         );
  //       })}
  //     </View>
  //   </View>
  // );
  // const ThirdRoute = () => (
  //   <View style={styles.scene}>
  //     <View style={{ marginHorizontal: "7%", paddingTop: "7%" }}>
  //       {props.portfolioData.map((item, index) => {
  //         return (
  //           <ProfileCard
  //             {...item}
  //             clicked={() => {
  //               props.setPortfolio(item);
  //               props.navigation.navigate("PortfolioDetail", {
  //                 mobile: props.requestedProfile.mobile,
  //               });
  //             }}
  //           />
  //         );
  //       })}
  //     </View>
  //   </View>
  // );
  // const FourthRoute = () => (
  //   <View style={styles.scene}>
  //     <View style={{ paddingTop: "7%" }}>
  //       <ReviewsTab
  //         {...props.requestedProfile}
  //         reviewData={props.review_data}
  //         complementData={props.complement_data}
  //         recommendationData={props.recommendation_data}
  //         reqRecommFlow={() => {
  //           next();
  //         }}
  //         routeToChat={id => {
  //           next();
  //         }}
  //       />
  //     </View>
  //   </View>
  // );
  // const FifthRoute = () => (
  //   <View style={styles.scene}>
  //     <AboutMeComponent {...props.requestedProfile} />
  //   </View>
  // );
  // const renderScene = SceneMap({
  //   Vouched: FirstRoute,
  //   Forsale: SecondRoute,
  //   Portfolio: ThirdRoute,
  //   Reviews: FourthRoute,
  //   Aboutme: FifthRoute,
  // });
  // const renderTabBar = props => (
  //   <TabBar
  //     {...props}
  //     renderLabel={({ route, focused, color }) => (
  //       <Text
  //         style={{
  //           color: focused ? config.primary_color : "#9090A6",
  //           margin: 8,
  //           fontFamily: "Roboto-Bold",
  //           alignSelf: "flex-start",
  //         }}
  //       >
  //         {route.title}
  //       </Text>
  //     )}
  //     indicatorStyle={{ backgroundColor: "#FFF" }}
  //     style={{
  //       backgroundColor: "#FFF",
  //       shadowOffset: { height: 0, width: 0 },
  //       shadowColor: "transparent",
  //       shadowOpacity: 0,
  //       elevation: 0,
  //     }}
  //     tabStyle={{ width: 130 }}
  //     activeColor={config.primary_color}
  //     inactiveColor={"#9090A6"}
  //     scrollEnabled={true}
  //   />
  // );

  const renderSkills = () => {
    return (
      <View style={{ paddingVertical: 10, alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingBottom: 10,
          }}
        >
          <Text
            style={{
              fontFamily: "Roboto-Medium",
              fontSize: 14,
              color: "#464646",
            }}
          >
            Known for
          </Text>
        </View>
        {props.requestedProfile?.known_for ? (
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {props.requestedProfile?.known_for.map((item, index) => {
              return (
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 13,
                    color: "#464646",
                    padding: 5,
                  }}
                >
                  #{item}
                </Text>
              );
            })}
          </View>
        ) : (
          <Text
            style={{
              fontFamily: "Roboto-Regular",
              fontSize: 14,
              color: "#828282",
            }}
          >
            Not Provided
          </Text>
        )}
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <StatusBar backgroundColor={config.primary_color} />
      <ScrollView
        showsVerticalScrollIndicator={false}
        ref={pagerRef}
        onContentSizeChange={() => {
          if (props.route.params?.store) {
            setForSaleModel(true);
          }
        }}
      >
        <View style={{ height: 250, position: "relative" }}>
          <ImageBackground
            source={
              props.requestedProfile?.cover_image_path
                ? { uri: props.requestedProfile?.cover_image_path }
                : require("../../assets/images/profile-image-placeholder.png")
            }
            style={styles.image}
          >
            <View
              style={{
                flex: 1,
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.2)",
              }}
            />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingHorizontal: 10,
                paddingTop: 20,
                justifyContent: "space-between",
              }}
            >
              <TouchableOpacity
                onPress={() => props.navigation.goBack()}
                activeOpacity={0.6}
                style={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                  padding: 7,
                  borderRadius: 100,
                }}
              >
                <AIcon name='left' style={[styles.backButton]} />
              </TouchableOpacity>
            </View>
          </ImageBackground>
        </View>
        <View style={{ alignItems: "center" }}>
          <Image
            source={
              props.requestedProfile?.profile_image_path
                ? { uri: props.requestedProfile?.profile_image_path }
                : require("../../assets/images/profile-image-placeholder.png")
            }
            style={styles.profileImage}
          ></Image>
          <View style={{ alignItems: "center" }}>
            <Text
              style={{
                fontFamily: "OpenSans-SemiBold",
                fontSize: 18,
                color: "#000",
              }}
            >
              {props.requestedProfile.company_name
                ? props.requestedProfile.company_name
                : `${props.requestedProfile?.firstname} ${props.requestedProfile?.lastname}`}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                fontFamily: "OpenSans-SemiBold",
                marginTop: 10,
                fontSize: 14,
                color: "#000",
              }}
            >
              {props.requestedProfile?.livingIn
                ? props.requestedProfile?.livingIn
                : "Location not provided"}
            </Text>
            <Text
              numberOfLines={1}
              style={{
                fontFamily: "OpenSans-SemiBold",
                marginTop: 10,
                fontSize: 14,
                color: "#000",
              }}
            >
              Vouched since
              <Text
                style={{
                  color: "#747373",
                  fontFamily: "OpenSans-Regular",
                  marginTop: 11,
                  fontSize: 12,
                  marginLeft: 10,
                }}
              >
                {`   ${moment(props.requestedProfile?.createdAt).format(
                  "MMM YYYY"
                )}`}
              </Text>
            </Text>

            <Text
              numberOfLines={3}
              style={{
                fontFamily: "Roboto-Regular",
                fontStyle: "italic",
                marginTop: 15,
                fontSize: 14,
                color: "#545455",
                paddingHorizontal: 25,
                textAlign: "center",
              }}
              ellipsizeMode='tail'
            >
              {props.requestedProfile?.one_line_about_me
                ? props.requestedProfile?.one_line_about_me
                : null}
            </Text>
          </View>
          {renderSkills()}
        </View>
        <View
          style={{
            backgroundColor: "#FFF",
            marginHorizontal: "5%",
            borderRadius: 20,
            paddingVertical: "3%",
            paddingHorizontal: "4%",
            marginTop: 10,
            // shadowColor: "#000",
            // shadowOffset: {
            //   width: 0,
            //   height: 0,
            // },
            // shadowOpacity: 0.1,
            // shadowRadius: 10,
            // elevation: 8,
            // shadowColor: "#0e0d0d",
          }}
        >
          <ProfileBadge style={{ alignSelf: "center" }} />
        </View>
        <View
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginVertical: 10,
          }}
        >
          <TouchableOpacity
            style={styles.optionsButton}
            onPress={() => {
              setReviewsTabBool(true);
            }}
          >
            <Text style={styles.optionsButtonText}>Reviews</Text>
          </TouchableOpacity>
          {props?.forSaleData?.length != 0 ? (
            <TouchableOpacity
              style={styles.optionsButton}
              onPress={() => {
                setForSaleModel(true);
              }}
            >
              <Text style={styles.optionsButtonText}>For Sale</Text>
            </TouchableOpacity>
          ) : null}

          {props?.portfolioData?.length != 0 ? (
            <TouchableOpacity
              style={styles.optionsButton}
              onPress={() => {
                setPortfolioModel(true);
              }}
            >
              <Text style={styles.optionsButtonText}>Portfolio</Text>
            </TouchableOpacity>
          ) : null}

          <TouchableOpacity
            style={styles.optionsButton}
            onPress={() => {
              setAboutMeComponentBool(true);
            }}
          >
            <Text style={styles.optionsButtonText}>About Me</Text>
          </TouchableOpacity>
        </View>

        <View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                next();
              }}
              style={{
                flexDirection: "row",
                paddingHorizontal: 20,
                paddingVertical: 8,
                borderRadius: 100,
                backgroundColor: config.primary_color,
              }}
            >
              <Text
                style={{
                  fontFamily: "OpenSans-SemiBold",
                  color: "#FFF",
                  alignSelf: "center",
                  marginRight: 10,
                  fontSize: 16,
                }}
              >
                Say hello
              </Text>
              <ExpressIntrestImage />
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          {sociallinks?.call ? (
            <FIcon
              onPress={() => {
                Linking.openURL(`tel:${sociallinks?.call}`);
              }}
              name='phone-call'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='phone-call'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.facebook ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://www.facebook.com/${sociallinks?.facebook}`,
                  '_blank'
                );
              }}
              name='facebook'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='facebook'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.instagram ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://www.instagram.com/${sociallinks?.instagram}`,
                  '_blank'
                );
              }}
              name='instagram'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='instagram'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.twitter ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://www.twitter.com/${sociallinks?.twitter}`,
                  '_blank'
                )
              }}
              name='twitter'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='twitter'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.linkedin ? (
            <FIcon
              onPress={() => {
                window.open(
                  sociallinks?.linkedin,
                  '_blank'
                  );
              }}
              name='linkedin'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='linkedin'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 10,
          }}
        >
          {sociallinks?.web ? (
            <FIcon
              onPress={() => {
                window.open(
                  `https://${sociallinks?.web}`,
                  '_blank'
                  );
              }}
              name='globe'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='globe'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.mail ? (
            <FIcon
              onPress={() => {
                window.open(
                  `mailto:${sociallinks?.mail}`,
                  '_blank'
                  );
              }}
              name='mail'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='mail'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
          {sociallinks?.youtube ? (
            <FIcon
              onPress={() => {
                window.open(
                  sociallinks?.youtube,
                  '_blank'
                  );
              }}
              name='youtube'
              style={{ color: config.primary_color, fontSize: 22, padding: 12 }}
            />
          ) : (
            <FIcon
              name='youtube'
              style={{ color: "#D8D8D8", fontSize: 22, padding: 12 }}
            />
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginBottom: 20,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              Clipboard.setString(
                `app.getvouched.co/${props.requestedProfile.username}`
              );
              setT2(true);
            }}
            style={{ paddingLeft: 1 }}
          >
            {t2 ? (
              <View
                style={{
                  padding: 5,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  borderRadius: 10,
                }}
              >
                <Text style={{ fontSize: 10 }}>Copied!</Text>
              </View>
            ) : (
              <View>
                <Text
                  numberOfLines={1}
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 13,
                    flex: 0.9,
                    color: "#473BB3",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  app.getvouched.co/{props.requestedProfile.username}
                  <AIcon
                    name='copy1'
                    style={{ fontSize: 18, color: "#545455", marginLeft: 5 }}
                  />
                </Text>
              </View>
            )}
          </TouchableOpacity>
        </View>
        <Text
          numberOfLines={1}
          style={{
            fontFamily: "Roboto-Medium",
            fontSize: 13,
            textAlign: "center",
            justifyContent: "center",
            marginBottom: 20,
            cursor: "pointer",
          }}
          onPress={() => {
            next();
          }}
        >
          Get your vouch profile
          <EIcon
            name='chevron-thin-right'
            style={{ fontSize: 17, marginLeft: 3 }}
          />
        </Text>
        {/* <TabView
          renderTabBar={renderTabBar}
          navigationState={{ index, routes }}
          renderScene={renderScene}
          onIndexChange={setIndex}
          swipeEnabled={false}
          style={{
            shadowOffset: { height: 0, width: 0 },
            shadowColor: "transparent",
            shadowOpacity: 0,
            elevation: 0,
          }}
        /> */}
      </ScrollView>
      {/****** models start here *******/}

      {/* forsale model */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={forsalemodel}
        onDismiss={() => {
          setForSaleModel(false);
        }}
      >
        <ForSaleModel
          cameBack={() => {
            setForSaleModel(false);
          }}
          {...props}
        />
      </Modal>

      {/* PortfolioModel */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={portfoliomodel}
        onDismiss={() => {
          setPortfolioModel(false);
        }}
      >
        <PortfolioModel
          cameBack={() => {
            setPortfolioModel(false);
          }}
          {...props}
        />
      </Modal>

      {/* ReviewsTab Model */}

      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={ReviewsTabBool}
        onDismiss={() => {
          setReviewsTabBool(false);
        }}
      >
        <ReviewsTab
          {...props.requestedProfile}
          reviewData={props.review_data}
          complementData={props.complement_data}
          recommendationData={props.recommendation_data}
          reqRecommFlow={() => {
            next();
          }}
          routeToChat={id => {
            next();
          }}
          cameBack={() => {
            setReviewsTabBool(false);
          }}
        />
      </Modal>

      {/**** AboutMeComponent ****/}

      {/* AboutMe */}
      <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={AboutMeComponentBool}
        onDismiss={() => {
          setAboutMeComponentBool(false);
        }}
      >
        <AboutMeComponent
          {...props.requestedProfile}
          cameBack={() => {
            setAboutMeComponentBool(false);
          }}
        />
      </Modal>

      {/* editLanguagesKnown */}

      {/* <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={knownLanguagesBool}
        onDismiss={() => {
          setknownLanguagesBool(false);
        }}
      >
        <LanguagesKnown
          languages={props.requestedProfile?.languages_known}
          cameBack={() => {
            setknownLanguagesBool(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "languages_known", data);
            setknownLanguagesBool(false);
          }}
        />
      </Modal> */}

      {/* commPrefs */}

      {/* <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={communication}
        onDismiss={() => {
          setCommunication(false);
        }}
      >
        <CommunicationPreferences
          communicationData={props.requestedProfile?.communication_preference}
          cameBack={() => {
            setCommunication(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "communication_preference", data);
            setCommunication(false);
          }}
        />
      </Modal> */}

      {/* RandomInsights */}

      {/* <Modal
        contentContainerStyle={{
          backgroundColor: "white",
          padding: 20,
          flex: 1,
        }}
        visible={insights}
        onDismiss={() => {
          setInsights(false);
        }}
      >
        <RandomInsights
          insightsData={props.requestedProfile?.insights}
          cameBack={() => {
            setInsights(false);
          }}
          onClose={data => {
            props.changeActiveTab(3);
            props.editProfile(null, "insights", data);
            setInsights(false);
          }}
        />
      </Modal> */}
    </SafeAreaView>
  );
};

const styles = {
  profileImage: {
    width: 100,
    height: 100,
    borderRadius: 100,
    borderColor: "#FFF",
    borderWidth: 5,
    marginTop: -80,
  },
  image: {
    flex: 1,
    justifyContent: "space-between",
  },
  backButton: {
    color: "#FFF",
    fontSize: 20,
    paddingRight: 0,
  },
  scene: {
    flex: 1,
  },
  animationSize: {
    width: 300,
    height: 300,
  },
  optionsButton: {
    margin: 10,
    height: 55,
    width: "80%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 25,
    shadowColor: "#0e0d0d",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 7,
    elevation: 5,
  },
  optionsButtonText: {
    fontFamily: "OpenSans-Regular",
    fontWeight: "bold",
    fontSize: 16,
  },
};

export default connect(
  state => ({
    recentContacts: { ...state.recentContacts },
    ...state.userProfile,
  }),
  {
    loadProfileByKey,
    resetProfile,
    setContactInfo,
    setFloatingData,
    resetFloatingData,
    setPortfolio,
  }
)(UserPreview);
// export default UserProfile
