import React, {Component} from 'react'
import {TextInput,View, SafeAreaView, TouchableOpacity,StyleSheet,Text,ScrollView,} from 'react-native'
import EIcon from 'react-native-vector-icons/Feather';
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
// import Dash from 'react-native-dash'
import {Button,Item,} from 'native-base'
import FIcon from 'react-native-vector-icons/Fontisto'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import Axios from 'axios';
import { addMethod } from 'yup';

export default class LivingIn extends Component {
    constructor (props) {
      super(props)
      this.state = {
        inputValue:'',
        locationResults:[]
      }
    }


    componentDidMount(){
          
    }

    displaySuggestions = (predictions, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        // alert(status);
        return;
      }
      this.setState({locationResults:predictions})
    };
    renderHeader= () => {

        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingTop: 20,
            }}>
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={()=>{this.props.cameBack()}}>
              <AIcon name="left" style={styles.backButton} />
            </TouchableOpacity>
            <Text
              numberOfLines={1}
              style={{
                flex: 1,
                fontSize: 26,
                fontFamily: 'OpenSans-Semibold',
                color: config.primary_color,
              }}>
              Living in
            </Text>
          </View>
        );
      };
    render () {
      const service = new google.maps.places.AutocompleteService();

        return(
            <SafeAreaView  style={{flex: 1,backgroundColor: '#FFF',}}>
                {this.renderHeader()}
                <View style={{marginHorizontal:'5%',paddingTop: 20}}>
                  <TextInput
                    placeholder='Search City'
                    placeholderTextColor={config.textDullColor}
                    value={this.state.inputValue}
                    onChangeText={(txt)=>{
                      // console.log(txt);
                      this.setState({
                        inputValue:txt
                      })
                      service.getQueryPredictions({ input: txt }, this.displaySuggestions);
                    }}
                    autoFocus={true}
                    style={{
                      fontSize: 16,
                      paddingVertical:10,
                      paddingHorizontal:10,
                      color: '#AAAAAA',
                      flex: 1,
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.15,
                      shadowRadius: 2.84,
                    }}
                  />
                  <ScrollView>
                  <View style={{marginHorizontal:'3%',paddingBottom:10}}>


                {this.state.locationResults.map((item,index)=>{
                    return(
                      <TouchableOpacity onPress={()=>{
                        this.props.onClose(item.description);
                      }} 
                        style={{elevation:1,paddingHorizontal:25,backgroundColor:'#FFF',paddingVertical:15,marginTop:10}}>
                        <Text style={{fontSize:14,fontFamily:'Roboto-Regular',color:'#707070'}}>{item.description}</Text>
                        </TouchableOpacity>
                    )
                  })}
                </View>
                </ScrollView>
                </View>
                
            </SafeAreaView>
        )
    }
}
const styles = StyleSheet.create({
    backButton: {
      color: '#473BB3',
      fontSize: 22,
      paddingRight: 15,
    },icon: {fontSize: 20, marginBottom: 5,marginHorizontal:15, color: '#473BB3'},
    searchItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15,
        paddingVertical: 0,
        borderRightWidth: 1,
        borderRightColor: '#DCDCDC',
        borderLeftWidth: 1,
        borderTopWidth: 1,
        borderTopColor: '#DCDCDC',
        borderLeftColor: '#DCDCDC',
        backgroundColor: '#FFF',
        borderRadius: 30,
        marginVertical:25,
        elevation: 0,
        // alignSelf: 'center',
      },
})