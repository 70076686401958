import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import config from '../config'
import logsService from '../services/logs.service'

export default class PaymentDetailsButton extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <TouchableOpacity
        disabled={this.props.loading || this.props.disabled ? true : false}
        style={[
          this.props.styles ? this.props.styles : styles.button,
          {
            backgroundColor:
              this.props.loading || this.props.disabled
                ? `${config.primary_color}55`
                : config.primary_color,
          },
        ]}
        onPress={() => {
          logsService.logCustomEvent(`${this.props.content.replace(/ /g,"_").toLowerCase()}_button_clicked`,{})
          this.props.clicked()
          }}>
            {this.props.loading ? (
          <ActivityIndicator size='small' color='#fff' />
        ) : (
          <Text
            style={{
              color: '#F7F6F9',
              fontSize: 16,
              fontFamily: 'Roboto-Medium',
            }}>
            {' '}
            {this.props.content}
          </Text>
        )}
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: config.primary_color,
    padding: 15,
    // borderRadius: 25,
    // marginTop: 25,
    zIndex: 100,
  },
})
