import React, { useRef, useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Dimensions,
  SafeAreaView,
} from "react-native";
import ExcellentCommunicator from "../../assets/images/excellentCommunicator";
import FastDelivery from "../../assets/images/fastDelivery";
import GreatAttitude from "../../assets/images/greatAttitude";
import SixStarService from "../../assets/images/sixStarService";
import MiracleWorker from "../../assets/images/miracleWorker";
import FIcon from "react-native-vector-icons/FontAwesome";
import config from "../../config";
import { DeckSwiper } from "native-base";
import { Video, Audio } from "expo-av";
import BIcon from "react-native-vector-icons/FontAwesome5";
import OffersCarousal from "../OffersCarousal";
import ReviewsCarousal from "./ReviewsCarousal";
import Header from "../../reuseable/Header";
const sliderWidth = Dimensions.get("window").width;
const itemWidth = sliderWidth - 50;

const ReviewsTab = props => {
  const [carouselIndex, setIndex] = React.useState(1);
  const carousalRef = useRef(null);
  const [reviews, setReviews] = useState(props.reviewData);
  const [recommendations, setRecommendations] = useState(
    props.recommendationData
  );
  useEffect(() => {
    var temp1 = reviews;
    var temp2 = recommendations;
    temp1.forEach((object, i) => (object.index = i + 1));
    temp2.forEach((object, i) => (object.index = i + 1));
    setReviews(temp1);
    setRecommendations(temp2);
    return () => {};
  }, []);
  const _renderItem = item => {
    return (
      <View style={{ backgroundColor: "#fff" }}>
        <View style={styles.partnerElement}>
          <View
            onPress={() => {
              this.props.navigation.navigate("UserProfile", {
                key: "mobile",
                value: mobile,
              });
            }}
            style={{
              backgroundColor: "#EB330D",
              width: 55,
              height: 55,
              justifyContent: "center",
              borderRadius: 50,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontSize: 20,
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Roboto-Bold",
              }}
            >
              {item?.from_user_name[0]}
            </Text>
          </View>

          <View style={{ flex: 1, justifyContent: "space-evenly" }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: "Roboto-Bold",
                color: "#464646",
                paddingHorizontal: 10,
              }}
            >
              {item?.from_user_name}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontFamily: "Roboto-Regular",
                color: "#707070",
                paddingHorizontal: 10,
              }}
            >
              {item?.from_user_location ? item.from_user_location : "India"}
            </Text>
          </View>
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "flex-end",
                marginTop: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: config.primary_color,
                  paddingLeft: 5,
                }}
              >
                {item?.overall_review}
              </Text>
              <FIcon
                name='star'
                style={{ fontSize: 20, color: "#E89C04", marginLeft: 3 }}
              />
            </View>
            <Text
              style={{
                fontFamily: "OpenSans-SemiBold",
                color: "#464646",
                fontSize: 12,
              }}
            >
              For {item?.to_user_role}
            </Text>
          </View>
        </View>
        {item.attachment_type == "audio" ? (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              height: 175,
              width: "100%",
              borderColor: "#ccc",
              borderRadius: 7,
              borderWidth: 1,
              backgroundColor: "#ea4e42",
            }}
          >
            <View
              style={{
                width: 100,
                height: 100,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
              }}
            >
              <BIcon
                name='headphones'
                style={{ color: "#FFF", fontSize: 80 }}
              />
            </View>
          </View>
        ) : (
          <Video
            style={{ width: 100 }}
            source={{
              uri: item.attachment_path,
            }}
            useNativeControls
            resizeMode='contain'
            isLooping
          />
        )}
        <Text
          numberOfLines={4}
          style={{
            fontFamily: "Roboto-Regular",
            color: "#464646",
            fontSize: 14,
            marginHorizontal: 10,
            lineHeight: 22,
          }}
        >
          {item?.review_text}
        </Text>
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <TouchableOpacity
            onPress={() => {
              props.routeToChat(item.from_user);
            }}
            style={{
              borderRadius: 100,
              borderColor: "#481380",
              borderWidth: 1,
              padding: 8,
              marginTop: 30,
            }}
          >
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                color: "#481380",
                fontSize: 12,
              }}
            >
              Ask {item?.from_user_name} more about this review
            </Text>
          </TouchableOpacity>
        </View>
        <Text
          style={{
            fontFamily: "OpenSans-SemiBold",
            fontSize: 12,
            color: "#000",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          {`${item.index}/${props.reviewData.length}`}
        </Text>
      </View>
    );
  };

  const _renderRecomendationItem = item => {
    return (
      <View style={{ backgroundColor: "#fff", minHeight: 400 }}>
        <View style={styles.partnerElement}>
          <View
            onPress={() => {
              this.props.navigation.navigate("UserProfile", {
                key: "mobile",
                value: mobile,
              });
            }}
            style={{
              backgroundColor: "#EB330D",
              width: 55,
              height: 55,
              justifyContent: "center",
              borderRadius: 50,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontSize: 20,
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Roboto-Bold",
              }}
            >
              {item?.from_user_name[0]}
            </Text>
          </View>

          <View style={{ flex: 1, justifyContent: "space-evenly" }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: "Roboto-Bold",
                color: "#464646",
                paddingHorizontal: 10,
              }}
            >
              {item?.from_user_name}
            </Text>
            <Text
              style={{
                fontSize: 14,
                fontFamily: "Roboto-Regular",
                color: "#707070",
                paddingHorizontal: 10,
              }}
            >
              {item?.from_user_location ? item.from_user_location : "India"}
            </Text>
          </View>
        </View>
        <Text
          numberOfLines={4}
          style={{
            fontFamily: "OpenSans-Bold",
            fontStyle: "italic",
            color: "#000",
            marginBottom: 8,
            fontSize: 14,
            marginHorizontal: 10,
            lineHeight: 22,
          }}
        >
          {item?.gig_name}
        </Text>
        <Text
          numberOfLines={4}
          style={{
            fontFamily: "Roboto-Regular",
            color: "#464646",
            fontSize: 14,
            marginHorizontal: 10,
            lineHeight: 22,
          }}
        >
          {item?.recommendation_text}
        </Text>
        <Text
          style={{
            fontFamily: "OpenSans-SemiBold",
            fontSize: 12,
            color: "#000",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          {`${item.index}/${props.recommendationData.length}`}
        </Text>
      </View>
    );
  };

  const renderRatings = (mode, review, count, text) => {
    return (
      <View
        style={{
          paddingVertical: 20,
          borderStyle: "dashed",
          borderColor: "#ccc",
          borderBottomWidth: 1.9,
        }}
      >
        <Text
          style={{
            fontFamily: "Roboto-Medium",
            fontSize: 15,
            color: "#464646",
            paddingBottom: 20,
          }}
        >
          Ratings as {mode}
        </Text>
        <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
          <View style={{ justifyContent: "space-evenly" }}>
            <FIcon name='star' style={{ fontSize: 45, color: "#E89C04" }} />
            <Text
              style={{
                fontFamily: "Roboto-Bold",
                fontSize: 16,
                color: "#545455",
                textAlign: "center",
              }}
            >
              {Math.round(review * 10) / 10}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 40,
                color: "#473BB3",
                textAlign: "right",
              }}
            >
              {count}
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 16,
                color: "#464646",
                textAlign: "center",
              }}
            >
              {text}
            </Text>
          </View>
        </View>
      </View>
    );
  };
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <Header props={props} title='Reviews' />
      <View>
        {props.complementData.excellent_communicator > 0 ||
        props.complementData.fast_delivery > 0 ||
        props.complementData.six_star_service > 0 ||
        props.complementData.great_attitude > 0 ||
        props.complementData.miracle_worker > 0 ? (
          <View>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#464646",
                marginBottom: 15,
                marginHorizontal: "7%",
              }}
            >
              Compliments
            </Text>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              style={{ marginBottom: 30, marginLeft: 25 }}
            >
              {props.complementData.excellent_communicator > 0 ? (
                <View style={{ paddingTop: 10, width: 80, marginRight: 15 }}>
                  <Text style={styles.count}>2</Text>
                  <ExcellentCommunicator
                    style={{ alignSelf: "center", marginBottom: 5 }}
                  />
                  <Text
                    numberOfLines={2}
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 9,
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Excellent{"\n"}Communicator
                  </Text>
                </View>
              ) : null}
              {props.complementData.fast_delivery > 0 ? (
                <View style={{ paddingTop: 10, width: 80, marginRight: 15 }}>
                  <Text style={styles.count}>2</Text>
                  <FastDelivery
                    style={{ alignSelf: "center", marginBottom: 5 }}
                  />
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 10,
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Fast{"\n"}Delivery
                  </Text>
                </View>
              ) : null}
              {props.complementData.six_star_service > 0 ? (
                <View style={{ paddingTop: 10, width: 80, marginRight: 15 }}>
                  <Text style={styles.count}>2</Text>
                  <SixStarService
                    style={{ alignSelf: "center", marginBottom: 5 }}
                  />
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 10,
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    6 Star{"\n"}service
                  </Text>
                </View>
              ) : null}
              {props.complementData.great_attitude > 0 ? (
                <View style={{ paddingTop: 10, width: 80, marginRight: 15 }}>
                  <Text style={styles.count}>2</Text>
                  <GreatAttitude
                    style={{ alignSelf: "center", marginBottom: 5 }}
                  />
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 10,
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Great{"\n"}attitude
                  </Text>
                </View>
              ) : null}
              {props.complementData.miracle_worker > 0 ? (
                <View style={{ paddingTop: 10, width: 80, marginRight: 15 }}>
                  <Text style={styles.count}>2</Text>
                  <MiracleWorker
                    style={{ alignSelf: "center", marginBottom: 5 }}
                  />
                  <Text
                    style={{
                      fontFamily: "Roboto-Medium",
                      fontSize: 10,
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Miracle{"\n"}Worker
                  </Text>
                </View>
              ) : null}
            </ScrollView>
            <View
              style={{
                borderStyle: "dashed",
                borderColor: "#ccc",
                borderBottomWidth: 1.9,
                marginHorizontal: "7%",
              }}
            ></View>
          </View>
        ) : null}
        <View style={{ marginHorizontal: "7%" }}>
          {props.seller_count
            ? renderRatings(
                "seller",
                props.seller_review,
                props.seller_count,
                "Jobs completed"
              )
            : null}
          {props.buyer_count
            ? renderRatings(
                "buyer",
                props.buyer_review,
                props.buyer_count,
                "Purchase completed"
              )
            : null}
          {props.reviewData.length == 0 ? null : (
            <View
              style={{
                borderStyle: "dashed",
                borderColor: "#ccc",
                borderBottomWidth: 1.9,
              }}
            >
              <View
                style={{
                  paddingVertical: 20,
                  borderStyle: "dashed",
                  borderColor: "#ccc",
                  borderBottomWidth: 1.9,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto-Medium",
                    fontSize: 15,
                    color: "#464646",
                    paddingBottom: 30,
                  }}
                >
                  Reviews
                </Text>
                {/* <DeckSwiper
              ref={carousalRef}
            dataSource={props.reviewData}
            onSwipeRight = {()=>{setIndex(carouselIndex-1)}}
            onSwipeLeft = {() => {setIndex(carouselIndex+1)}}
            renderItem={item =>_renderItem(item)}
          /> */}
                <ReviewsCarousal items={props.reviewData} />
              </View>
            </View>
          )}
          <View style={{ paddingVertical: 20 }}>
            <Text
              style={{
                fontFamily: "Roboto-Medium",
                fontSize: 15,
                color: "#464646",
              }}
            >
              Recommendations
            </Text>
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 14,
                color: "#464646",
                paddingBottom: 20,
              }}
            >
              Gigs done outside Vouch
            </Text>
            {props.recommendationData?.length != 0 ? (
              <View style={{ paddingVertical: 20, minHeight: 200 }}>
                <DeckSwiper
                  dataSource={props.recommendationData}
                  onSwipeRight={() => {
                    setIndex(carouselIndex - 1);
                  }}
                  onSwipeLeft={() => {
                    setIndex(carouselIndex + 1);
                  }}
                  renderItem={item => _renderRecomendationItem(item)}
                />
              </View>
            ) : (
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 15,
                  color: "#828282",
                }}
              >
                Request recommendations from you previous customers for past
                transactions to increase your profile visibility and credibility
              </Text>
            )}
            <View style={{ flexDirection: "row", justifyContent: "center" }}>
              <TouchableOpacity
                onPress={() => {
                  props.reqRecommFlow();
                }}
                style={{
                  borderRadius: 13,
                  elevation: 3,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 1.84,
                  padding: 8,
                  paddingHorizontal: 30,
                  marginTop: 30,
                  backgroundColor: "#FFF",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    color: config.primary_color,
                    fontSize: 14,
                  }}
                >
                  Request Recommendation
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = {
  count: {
    borderWidth: 1,
    borderColor: "#707070",
    width: 20,
    height: 20,
    textAlign: "center",
    borderRadius: 20,
    fontFamily: "OpenSans-SemiBold",
    fontSize: 12,
    padding: 2,
    position: "absolute",
    zIndex: 10,
    backgroundColor: "#fff",
    right: 0,
    marginTop: 0,
  },
  partnerElement: {
    flexDirection: "row",
    borderColor: "#AAA",
    marginBottom: 10,
    paddingBottom: 12,
    backgroundColor: "#FFF",
  },
};

export default ReviewsTab;
