import {
  INIT_MESSAGE_STATE,
  SEND_MESSAGE,
  SUBSCRIBE_MESSAGES,
  ON_MESSAGE_RECEIVED,
  MESSAGE_PAGE_STATE,
  FETCH_CHAT_MESSAGES,
  UNSUBSCRIBE_SOCKET_CHANNEL_MESSAGE,
} from '../constants/constants';

export function initMessageState(data) {
  return {
    type: INIT_MESSAGE_STATE,
    data: data,
  };
}

export function subscribeToMessages() {
  return {
    type: SUBSCRIBE_MESSAGES,
  };
}

export function messageReceived(data) {
  return {
    type: ON_MESSAGE_RECEIVED,
    data: data,
  };
}

export function sendMessage(message) {
  return {
    type: SEND_MESSAGE,
    message: message,
  };
}
export function setMessagePageState(bool) {
  return {
    type: MESSAGE_PAGE_STATE,
    bool: bool,
  };
}
export function getChatMessages(page) {
  return {
    type: FETCH_CHAT_MESSAGES,
    page: page,
  };
}

export function unsubscribeSocketChannel() {
  return {
    type: UNSUBSCRIBE_SOCKET_CHANNEL_MESSAGE,
  };
}
