// Packages imports
import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  View,
  ScrollView,
  Text,
  ActivityIndicator,
  StyleSheet,
} from "react-native";
import { Modal } from "react-native-paper";
import CryptoJS from "crypto-js";
import LottieView from "lottie-react-web";

// Project module imports
import loadingAnim from "../../assets/lottie-animations/payment-processing.json";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import UpiOption from "../../components/custom_checkout/UpiOption";
import Header from "../../components/Header";
import OtherPaymentOptions from "../../components/custom_checkout/OtherPaymentOptions";
import AddUpi from "../../components/custom_checkout/AddUpi";
import MoreBanksOverlay from "../../components/custom_checkout/MoreBanksOverlay";
import CardDetailsOverlay from "../../components/custom_checkout/CardDetailsOverlay";
import paymentServices from "../../services/payments.service";
import { openCashfree } from "../../state-management/sagas/paymentcheckout.saga";
import config from "../../config";
import PrimaryButton from "../../components/PrimaryButton";
import { addMileStone } from "../../state-management/actions/paymentdetail.action";
import miscService from "../../services/misc.service";
import { SafeAreaFrameContext } from "react-native-safe-area-context";
import CollectSuccessRedirect from "../../components/Marketplace/CollectSuccessRedirect";
import NetBanking from "../../components/custom_checkout/NetBanking";
import CardPayment from "../../components/custom_checkout/CardPayment";
import CaptableLogo from "../../assets/images/captabl_logo.svg";
import Logo from "../../assets/images/darkpurple";
import { set } from "react-native-reanimated";
import VouchUPITimer from "../../components/custom_checkout/VouchUPITimer";

// Helper component for all the modals(overlays)
const ModalWrapper = (props) => (
  <Modal
    theme={{
      colors: {
        backdrop: "transparent",
      },
    }}
    visible={props.visible}
    contentContainerStyle={{ padding: 20, flex: 1 }}
    onDismiss={props.dismissHandler}
  >
    {props.children}
  </Modal>
);

const GuestPaymentCheckout = (props) => {
  const [order_data, set_order_data] = useState({});
  const [addUpi, setAddUpi] = useState(false);
  const [moreBanks, setMoreBanks] = useState(false);
  const [cardOverlay, setCardOverlay] = useState(false);
  const [payment_collect_detials, setPaymentCollectDetials] = useState({});

  const [isMakingUpiPayment, setIsMakingUpiPayment] = useState(false);
  const [vouchUPIOptions, setVouchUPIOptions] = useState(null);
  const [showUPITimer,setUPITimer] = useState(false);

  const [loaders, setLoaders] = useState({
    isFetching: true,
    isPaying: false,
    isPaymentCollectionSuccess: false,
    isError: false,
  });

  const { isFetching, isError, isPaying, isPaymentCollectionSuccess } = loaders;
  const { buyer_details, seller_details, order_details } = order_data;

  const amount_with_payment_charges = Math.ceil(
    parseInt(order_details?.amount) +
      (2 / 100) * parseInt(order_details?.amount)
  );

  // this block runs only once and when the page loads
  useEffect(() => {
    fetch_order_details();
  }, []);

  // this block fetches the order details from the server
  const fetch_order_details = () =>
    paymentServices
      .fetch_order_for_guest_payment({
        order_id: props.route.params.uid,
      })
      .then((result) => {
        set_order_data(result);
        setLoaders((loaders) => ({ ...loaders, isFetching: false }));
      })
      .catch((err) => props.navigation.navigate("Errorpage"));

  // overlay for upi overlay
  const upiModal = () => (
    <ModalWrapper
      visible={addUpi}
      dismissHandler={() => {
        setAddUpi(false);
      }}
    >
      <AddUpi
        onClose={(data) => {
          setAddUpi(false);
        }}
        onSuccess={(data) => {
          setAddUpi(false);
          // initPaymentCheckout({ method: "UPI", vpa: data });
          setIsMakingUpiPayment(true);
          initiateVouchUpiCollect({ vpa: data });
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  // helper for Net banking overlay
  const netBankingModal = () => (
    <ModalWrapper
      visible={moreBanks}
      dismissHandler={() => {
        setMoreBanks(false);
      }}
    >
      <MoreBanksOverlay
        comeBack={(data) => {
          setMoreBanks(false);
        }}
        onBankSelected={(data) => {
          setMoreBanks(false);
          initiate_net_banking_checkout({
            method: "NETBANKING",
            bank: data,
          });
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  // helper for card details Overlay
  const cardDetailsOverlay = () => (
    <ModalWrapper
      visible={cardOverlay}
      dismissHandler={() => {
        setCardOverlay(false);
      }}
    >
      <CardDetailsOverlay
        comeBack={(data) => {
          setCardOverlay(false);
        }}
        onSuccess={(data) => {
          setCardOverlay(false);
          initPaymentCheckout({
            method: "CARD",
            values: data,
          });
        }}
        color="#27AAE1"
      />
    </ModalWrapper>
  );

  // handler for upi, card payment modes
  const initPaymentCheckout = async (user_payment_detials) => {
    try {
      setLoaders((loaders) => ({ ...loaders, isPaying: true }));

      //prepare params of cashfree
      let options = {
        // appId: keyObj.appId,
        orderId: `VO${Date.now().toString()}`,
        orderAmount: parseInt(order_details.amount),
        customerName: buyer_details.name,
        customerPhone: buyer_details.mobile,
        customerEmail: buyer_details.email.trim(),
        notifyUrl:
          "https://prod.api.iamvouched.com/v1/cashfree/listen/order/paid",
        orderNote: props.route.params.uid,
        orderCurrency: "INR",
      };

      switch (user_payment_detials.method) {
        case "UPI":
          {
            options.paymentOption = "upi";
            options.upi = {};
            options.upi.vpa = user_payment_detials.vpa;
          }
          break;
        case "CARD":
          {
            options.orderAmount = parseInt(amount_with_payment_charges);
            options.paymentOption = "card";
            options.card = {};
            options.card["holder"] = user_payment_detials.values.nameOnCard;
            options.card["number"] = user_payment_detials.values.cardNumber;
            options.card["cvv"] = user_payment_detials.values.cvv;
            options.card["expiryMonth"] =
              user_payment_detials.values.expiry.slice(0, 2);
            options.card["expiryYear"] =
              user_payment_detials.values.expiry.slice(-4);
          }
          break;
        default:
          throw { message: "Something went wrong" };
      }

      //get the cashfree signature from the server
      let { cashfree_options } =
        await miscService.getCashfreeSignatureforGuestPayment({
          order_id: props.route.params.uid,
          options: {
            orderId: options.orderId,
            orderAmount: options.orderAmount,
            customerPhone: options.customerPhone,
            customerEmail: options.customerEmail,
            orderCurrency: options.orderCurrency,
          },
        });
      const pay = await openCashfree({ ...cashfree_options, ...options });

      if (
        pay.name == "PAYMENT_RESPONSE" &&
        pay.response.txStatus == "SUCCESS"
      ) {
        let redirect_details =
          await paymentServices.update_guest_payment_order_payment_detials({
            order_id: props.route.params.uid,
            payment_details: {
              ...pay.response,
              mode: options.paymentOption,
            },
          });
        setPaymentCollectDetials(redirect_details);
      }

      setLoaders((loaders) => ({
        ...loaders,
        isPaying: false,
        isPaymentCollectionSuccess: true,
      }));
    } catch (error) {
      setLoaders((loaders) => ({ ...loaders, isError: true, isPaying: false }));
    }
  };

  // Payment Handler for NETBANKING Mode
  const initiate_net_banking_checkout = async (user_payment_detials) => {
    try {
      setLoaders((loaders) => ({ ...loaders, isPaying: true }));
      const { token } = await miscService.getCashfreeOrderTokenforGuestPayment({
        order_id: props.route.params.uid,
        options: {
          orderId: `VO${Date.now().toString()}`,
          orderAmount: parseInt(order_details.amount),
          customerName: buyer_details.name,
          customerPhone: buyer_details.mobile,
        },
      });
      const options = {
        onPaymentSuccess: async function (data) {
          const { order, transaction } = data;

          if (order.status == "PAID") {
            let redirect_details =
              await paymentServices.update_guest_payment_order_payment_detials({
                order_id: props.route.params.uid,
                payment_details: {
                  ...order,
                  ...transaction,
                  orderAmount: transaction.transactionAmount,
                  referenceId: transaction.transactionId,
                  mode: "nb",
                },
              });
            setPaymentCollectDetials(redirect_details);
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isPaymentCollectionSuccess: true,
            }));
          }
        },
        onPaymentFailure: function (data) {
          setLoaders((loaders) => ({
            ...loaders,
            isError: true,
            isPaying: false,
          }));
        },
        onError: function (data) {
          setLoaders((loaders) => ({
            ...loaders,
            isError: true,
            isPaying: false,
          }));
        },
      };
      const cfCheckout = Cashfree.initializeApp(options);

      // Create cashfree element
      const el = cfCheckout.element({
        pay: document.getElementById("pay-nb"),
        type: "netbanking",
      });
      el.on("change", function (data) {
        document.getElementById("onChangeNB").innerHTML = JSON.stringify(data);
      });

      //add bank code to the value property of the hidden input
      document
        .getElementById("pay-nb-input")
        .setAttribute("value", user_payment_detials.bank);
      document
        .getElementById("pay-nb-btn")
        .addEventListener("click", async () => {
          await cfCheckout.pay(token, "netbanking");
        });
      document.getElementById("pay-nb-btn").click();
      document.getElementById("pay-nb-btn").removeEventListener();
    } catch (error) {
      setLoaders((loaders) => ({ ...loaders, isError: true, isPaying: false }));
    }
  };

  const initiateVouchUpiCollect = async ({ vpa }) => {
    try {
      let options = {
        collects: [
          {
            payer: {
              api_client_payer_ref_id: buyer_details.name,
              vouch_payer_ref_id: buyer_details.mobile,
              payer_vpa: vpa,
              firstname: buyer_details.name,
              lastname: "",
              mobile: buyer_details.mobile,
              email: buyer_details.email.trim(),
            },
            amount: order_details.amount,
            transaction_note: props.route.params.uid,
            collect_ref: `VO${Date.now().toString()}`,
          },
        ],
      };
      const payload = await miscService.instantiate_vouch_UPI_collect({
        order_id: props.route.params.uid,
        options: options,
      });
      if (payload.status == "success") {
        setVouchUPIOptions(options);
        setUPITimer(true);
      }
    } catch (error) {
      throw error;
    }
  };

  // Show this when he order is been fetched from the server
  if (isFetching)
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size="large" color={config.primary_color} />
      </View>
    );

  // Show this when there is an error in the payment
  if (isError)
    return (
      <View style={styles.container}>
        <View style={{ width: 400, height: 400 }}>
          <LottieView
            options={{
              animationData: failedAnim,
              autoplay: true,
              loop: false,
            }}
            style={styles.animationSize}
          />
        </View>
        <PrimaryButton
          content={"Retry "}
          styles={false}
          clicked={() => {
            setLoaders((loaders) => ({ ...loaders,isError: false }));
          }}
        />
      </View>
    );

  const hidden_div = () => (
    <>
      <div id="pay-nb" hidden>
        <input id="pay-nb-input" type="text" value="" data-netbanking-code />
      </div>
      <button id="pay-nb-btn" hidden>
        Pay
      </button>
    </>
  );

  // when we are collection money show this loade
  if (isPaying)
    return (
      <View style={styles.container}>
        {hidden_div()}
        <View style={{ width: 400, height: 400 }}>
          <LottieView
            options={{ animationData: loadingAnim, autoplay: true, loop: true }}
          />
        </View>
        <Text style={[styles.text, { fontFamily: "OpenSans-Bold" }]}>
          Protecting your payment
        </Text>
      </View>
    );

  // when we are collection money show this loade
  if (isPaymentCollectionSuccess)
    return (
      <CollectSuccessRedirect
        redirect_url={payment_collect_detials.redirect_url}
      />
    );

  // when we are collection money show this loade
  if (showUPITimer)
    return (
      <View style={styles.container}>
        {hidden_div()}
        <VouchUPITimer
          onCollectFailed={() => {
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isError: true,
            }));
            setUPITimer(false)
            setVouchUPIOptions(null);
            setIsMakingUpiPayment(false);
          }}
          onCollectSuccess={async (success_payload) => {
            let redirect_details =
              await paymentServices.update_guest_payment_order_payment_detials({
                order_id: props.route.params.uid,
                payment_details: {
                  ...success_payload,
                  mode: "upi",
                },
              });
            setPaymentCollectDetials(redirect_details);
            setLoaders((loaders) => ({
              ...loaders,
              isPaying: false,
              isPaymentCollectionSuccess: true,
            }));
            setUPITimer(false);
            setIsMakingUpiPayment(false);
            setVouchUPIOptions(null);
          }}
          collect_ref={vouchUPIOptions.collects[0].collect_ref}
          order_id={props.route.params.uid}
        />
      </View>
    );


  //   return <View style={{
  //     backgroundColor: "#fff",
  //     paddingHorizontal: 20,
  //     flex:1,
  //     alignItems: "center",
  //     justifyContent:"center"
  //   }}>
  //   {hidden_div()}
  //   <VouchUPITimer
  //     onCollectFailed={() => {
  //       setLoaders((loaders) => ({
  //         ...loaders,
  //         isPaying: false,
  //         isError: true,
  //       }));
  //       setUPITimer(false)
  //       setVouchUPIOptions(null);
  //       setIsMakingUpiPayment(false);
  //     }}
  //     onCollectSuccess={async (success_payload) => {
  //       let redirect_details =
  //         await paymentServices.update_guest_payment_order_payment_detials({
  //           order_id: props.route.params.uid,
  //           payment_details: {
  //             ...success_payload,
  //             mode: "upi",
  //           },
  //         });
  //       setPaymentCollectDetials(redirect_details);
  //       setLoaders((loaders) => ({
  //         ...loaders,
  //         isPaying: false,
  //         isPaymentCollectionSuccess: true,
  //       }));
  //       setUPITimer(false);
  //       setIsMakingUpiPayment(false);
  //       setVouchUPIOptions(null);
  //     }}
  //     collect_ref={"d"}
  //     order_id={props.route.params.uid}
  //   />
  // </View>

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 9999,
          display: isMakingUpiPayment ? "flex" : "none",
        }}
      >
        <ActivityIndicator size="large" color={config.primary_color} />
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Header title={"Payment Options"} color="#000" />
        <View style={{ paddingHorizontal: "8%" }}>
          <CaptableLogo
            style={{
              // marginVertical: 25,
              marginTop: 15,
              marginBottom: 15,
            }}
          />
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 18,
              color: "#000",
              marginBottom: 25,
            }}
          >
            Paying {"\u20B9"} {order_details?.amount}
          </Text>
          <View
            style={{
              backgroundColor: "#F3F3F3",
              borderRadius: 20,
              paddingHorizontal: 20,
              paddingVertical: 20,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontFamily: "Roboto-Regular",
                color: "#707070",
                marginBottom: 20,
              }}
            >
              Recommended method
            </Text>
            <UpiOption
              amount={order_details?.amount}
              addUpiPressed={() => {
                setAddUpi(true);
              }}
              saved={[]}
              proceed={(data) => {}}
              showNetBanking={false}
              color="#27AAE1"
            />
            <NetBanking
              moreBanksSelected={() => {
                setMoreBanks(true);
              }}
              netBankingSelected={(data) => {
                initiate_net_banking_checkout({
                  method: "NETBANKING",
                  bank: data,
                });
              }}
              color="#27AAE1"
            />
          </View>
          <View style={{ height: 40 }}></View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              paddingBottom: 30,
            }}
          >
            <Text
              style={{
                fontSize: 18,
                fontFamily: "OpenSans-Regular",
                color: "#000",
                marginRight: 10,
              }}
            >
              Powered by
            </Text>
            <Logo width="40" height="40" />
          </View>
        </View>
      </ScrollView>
      {upiModal()}
      {netBankingModal()}
      {cardDetailsOverlay()}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    color: config.primary_color,
    padding: 20,
  },
  animationSize: {
    width: 300,
    height: 300,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingHorizontal: 20,
  },
});

export default GuestPaymentCheckout;
