import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Image,
} from 'react-native'
import {Icon} from 'native-base'
import config from '../config'

export default class CommunicationPicker extends Component{
  constructor (props) {
    super(props)
    this.state = {
      serviceClicked: false,
      serviceSelected: null,
    }
  }

  componentDidMount(){
    if(this.props.communicationData!=''){
      this.setState({serviceSelected:this.props.communicationData})
    }
  }
  list = () => {
    return this.props.data.map((element,index) => {
      return (
        <TouchableOpacity
          key={index}
          onPress={() => this.handleServiceSelected(element.title)}
          style={[styles.dropDownViewTouchable,{borderBottomWidth:index==2?0:0.7}]}>
          <Text style={styles.txt4}>{element.title}</Text>
        </TouchableOpacity>
      )
    })
  }

  handleServiceSelected = serv => {
    this.setState({serviceSelected: serv})
    this.setState({serviceClicked: !this.state.serviceClicked})
    this.props.onServiceSelected(serv)
  }
  handleServicePress = () => {
    this.setState({serviceClicked: !this.state.serviceClicked})
  }


  render () {
    return (
      <View style={styles.touched}>
        <TouchableOpacity
          style={{flexDirection: 'row'}}
          onPress={this.handleServicePress}>
          <View style={{flex: 0.9, justifyContent: 'center'}}>
            <Text style={[styles.txt3,{color:this.state.serviceSelected?'#464646':'#DCDCDC'}]}>
              {this.state.serviceSelected
                ? this.state.serviceSelected
                : this.props.name}
            </Text>
          </View>
          <View
            style={{flex: 0.1, justifyContent: 'center', alignItems: 'center'}}>
            <Icon name='ios-arrow-down' />
          </View>
        </TouchableOpacity>
        {this.state.serviceClicked ? (
          <View style={styles.dropDownView}>
            {this.props.data ? this.list() : null}
          </View>
        ) : null}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  touched: {
    elevation: 2,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: '#fff',
    backgroundColor: '#fff',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  dropDownView: {
    borderTopColor: '#DCDCDC',
    borderTopWidth: 1,
  },
  dropDownViewTouchable: {
    paddingVertical: 10,
    // borderBottomWidth: 0.7,
    borderBottomColor: '#DCDCDC',
  },
  txt3: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    padding:10
  },
  txt4: {
    fontSize: 14,
    paddingHorizontal: '8%',
    color: '#464646',
    fontFamily: 'Roboto-Regular',
  },
})
