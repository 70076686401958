import {takeLatest, put, call, select} from 'redux-saga/effects'
import {
  HANDLE_DEEP_LINK,
  HANDLE_DEEP_LINK_SUCCESS,
} from '../constants/constants'
import {navigationRef} from '../../navigation/Navigator'
import url from 'url'
import userService from '../../services/user.service'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
const execHandleDeepLink = async urlString => {
  try {

    const currentUser = await firebase.auth().currentUser;
    let urlObject = url.parse(urlString)
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match
    while ((match = regex.exec(urlString))) {
      params[match[1]] = match[2]
    }

    switch (urlObject.pathname) {
      case '/invitation':
        {
          if (!currentUser) {
            var preloadData = {}
            if (params.hasOwnProperty('id')) {
              // const user = await firestore()
              //   .collection('users')
              //   .where('virtual_id', '==', params.id)
              //   .get()
                var user = await userService.getUser({
                  key:'virtual_id',
                  value:params.id
                })
              if (user){
                preloadData = {mobile: user.mobile}
                navigationRef.current.navigate('SignUp', {
                  data: {...preloadData},
                })
                navigationRef.current.add
              }
            }
          }
        }
        break
        case '/payment':
        {
          if (currentUser) {
            var preloadData = {}
            if (params.hasOwnProperty('id')) {
              navigationRef.current.navigate('GigDetails', {
                uid: params.id,
              })
              navigationRef.current.add
            }
          }
        }
        break

      default:
        break
    }
  } catch (error) {
    // console.log(error)
  }
}

function * handleDeepLink (action) {
  const res = yield call(execHandleDeepLink, action.url)
  yield put({type: HANDLE_DEEP_LINK_SUCCESS})
}

function * watchDeepLinks () {
  yield takeLatest(HANDLE_DEEP_LINK, handleDeepLink)
}

export default watchDeepLinks
