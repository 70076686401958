import { STORE_FLOAT_DATA,SET_FLOAT_USER_ID, RESET_FLOAT_DATA } from "../constants/constants";

export function setFloatingData(data){
    return {
        type:STORE_FLOAT_DATA,
        data:data
    }
}

export function setFloatUserId(uid){
    // console.log(uid);
    return {
        type:SET_FLOAT_USER_ID,
        uid:uid
    }
}

export function resetFloatingData(data){
    return {
        type:RESET_FLOAT_DATA
    }
}