import React, {Component, useEffect, useState} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  SafeAreaView,
} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import config from '../../config';
import LottieView from 'lottie-react-web';
import TickAnim from '../../assets/lottie-animations/tick-anim.json';
// import ExpressIntrestImage from '../../assets/images/expressIntrestImage';
// import MerchantImage from '../../assets/images/merchantImage.svg';
import {connect} from 'react-redux'
import MerchantImage from '../../assets/images/merchantImage';



const MerchantPaymentResult = props => {
  
const [timer,setTimer] = useState(10)

  const notifyMerchant = async (link,data,return_url) => {
    try {
      var form=document.getElementById("myForm");
      var input1 = document.createElement('input');
      input1.setAttribute('name', 'custom');
      input1.setAttribute('value', data['custom']);
      input1.setAttribute('type', 'hidden');
      form.appendChild(input1);
      var input2 = document.createElement('input');
      input2.setAttribute('name', 'payment_status');
      input2.setAttribute('value', data['payment_status']);
      input2.setAttribute('type', 'hidden');
      form.appendChild(input2);
      var input3 = document.createElement('input');
      input3.setAttribute('name', 'currency_type');
      input3.setAttribute('value', data['currency_type']);
      input3.setAttribute('type', 'hidden');
      form.appendChild(input3);
      var input4 = document.createElement('input');
      input4.setAttribute('name', 'gross');
      input4.setAttribute('value', data['gross']);
      input4.setAttribute('type', 'hidden');
      form.appendChild(input4);
      if(return_url){
      var input5 = document.createElement('input');
      input5.setAttribute('name', 'return_url');
      input5.setAttribute('value', return_url);
      input5.setAttribute('type', 'hidden');
      form.appendChild(input5);
      }
      form.action = link
      form.submit();
      // window.location=return_url;
    } catch (error) {
      // console.log('Not Done: ',error);
    }
  }

  const dispatchToMainStack = () => {
    props.navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {name: 'Main'},
        ],
      }),
    )
  }

  useEffect(() => {
    setTimeout(() => {
        if(props?.route?.params?.merchant_details){
          if(props?.route?.params?.merchant_details?.notify_url){
            var custom_data = getMerchantPostData();
            notifyMerchant(props?.route?.params?.merchant_details?.notify_url,{
                  custom:custom_data,
                  payment_status:'completed',
                  currency_type:'INR',
                  gross:props?.route?.params?.merchant_details?.total_amount,
                },props?.route?.params?.merchant_details?.return_url)
              }else dispatchToMainStack()
      }else dispatchToMainStack()
    }, 10000)
  }, [])

  useEffect(()=>{
      setInterval(()=>{
          setTimer(timer=>timer-1);
      },1000)
  },[])

  const getMerchantPostData = () => {
    switch (props?.route?.params?.merchant_details?.merchant_platform) {
      case 'woocommerce':
        return JSON.stringify({
          order_id:props?.route?.params?.merchant_details?.order_id,
          order_key:props?.route?.params?.merchant_details?.order_key
        });
        break;
      case 'opencart':
        return props?.route?.params?.merchant_details?.order_id;
        break;
      case 'web':
        return JSON.stringify({
          order_id:props?.route?.params?.merchant_details?.order_id
        });
        break;
      default:
        break;
    }
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        {/* <form id="myForm" action="https://fancyshop.unaux.com/wc-api/WC_Gateway_Vouch/" hidden method="GET"> */}
        <form id="myForm" hidden method="POST">

        </form>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{justifyContent: 'center',}}>
            <Text style={{fontFamily:'Roboto-Medium',fontSize:24,textAlign:'center',marginTop:80,marginBottom:20}}>
              Awesome! Your payment is successful
            </Text>
            <MerchantImage style={{alignSelf:'center'}}/>
            {/* <ExpressIntrestImage /> */}

            <View style={{flexDirection:'row'}}>
              <View style={{flex:1}}></View>
              <View>
              <Text style={{fontFamily:'Roboto-Regular',fontSize:16,textAlign:'left'}}>
                We have sent an SMS with an app download link
              </Text>
              <Text style={{fontFamily:'Roboto-Bold',fontSize:16,marginTop:30,marginBottom:5}}>
              The Vouch app offers 
              </Text>
              <Text style={{fontFamily:'Roboto-Regular',fontSize:16}}>
              1.Tracking, order updates and direct customer support{'\n'}
              2.Release the money when you get the delivery
              </Text>
              </View>
              <View style={{flex:1}}></View>
            </View>
            {timer>=0?
            <Text style={{textAlign:'center',marginTop:50}}>
            Redirecting in {timer} seconds
            </Text>:null}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  animationSize: {height: 400, aspectRatio: 16 / 9},
  text: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 24,
    textAlign: 'center',
    color: '#000000',
    lineHeight:26,
    letterSpacing:1
  },
})

export default connect(state => ({}),{},)(MerchantPaymentResult)