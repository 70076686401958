import {
  ADD_MILESTONE,
  DELETE_MILESTONE,
  EDIT_MILESTONE,
  INIT_STATE,
  MAKE_TRANSACTION,
  CHANGE_MILESTONE_MODE,
} from "../constants/constants";

export function addMileStone(data) {
  return {
    type: ADD_MILESTONE,
    milestone: data,
  };
}

export function deleteMileStone(id) {
  return {
    type: DELETE_MILESTONE,
    milestone_id: id,
  };
}

export function editMileStone(id, data) {
  return {
    type: EDIT_MILESTONE,
    milestone_id: id,
    milestone: data,
  };
}

export function initState() {
  return {
    type: INIT_STATE,
  };
}

export function changeMilestoneMode() {
  return {
    type: CHANGE_MILESTONE_MODE,
  };
}

export function makeTransaction(
  mode,
  data,
  milestones,
  milestone_mode,
  classification,
  request_to_mobile,
  category,
  Attachments
) {
  return {
    type: MAKE_TRANSACTION,
    mode: mode,
    milestones: milestones,
    data: data,
    milestone_mode: milestone_mode,
    classification: classification,
    request_to_mobile: request_to_mobile,
    category: category,
    Attachments: Attachments,
  };
}
