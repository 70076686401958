import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import RootReducer from "./reducers/index.reducer";
import RootSaga from "./sagas/index.saga";
import logger from "redux-logger";
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
// if (__DEV__) {
//   middlewares.push(logger);
// }

const store = createStore(RootReducer, applyMiddleware(...middlewares));
// console.log(store.getState());
sagaMiddleware.run(RootSaga);

export default store;
