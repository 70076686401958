//import liraries
import React, {PureComponent} from 'react'

import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native'
import {Thumbnail} from 'native-base'
import config from '../config'

class ContactItem extends PureComponent {
  render () {
    return (
      <TouchableOpacity
        onPress={this.props.clicked}
        style={{
          flexDirection: 'row',
          borderBottomColor: '#AAA',
          borderBottomWidth: 0.5,
          marginTop: 20,
          paddingBottom: 8,
          marginHorizontal: '8%'
        }}>
        {this.props.thumbnail == true ? (
          <Thumbnail
            source={this.props.thumbnailImage}
            style={{borderWidth: 1, borderColor: config.primary_color}}
          />
        ) : (
          <View
            style={{
              backgroundColor: this.props.color,
              width: 55,
              height: 55,
              justifyContent: 'center',
              borderRadius: 50,
              alignSelf: 'center',
              borderColor:config.primary_color,
              borderWidth:1
            }}>
            <Text
              style={{
                fontSize: 20,
                color: '#FFF',
                textAlign: 'center',
                fontFamily:'Roboto-Bold'
              }}>
              {this.props.name[0]}
            </Text>
          </View>
        )}
        <View style={{flex: 1, justifyContent: 'space-evenly'}}>
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Roboto-Bold',
              color: config.primary_color,
              paddingHorizontal: 10,
            }}>
            {this.props.name}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontFamily: 'Roboto-Regular',
              color: '#707070',
              paddingHorizontal: 10,
            }}>
            {this.props.mobile}
          </Text>
        </View>
        {this.props.vouched == true ? (
          <View
            style={{
              backgroundColor: config.primary_color,
              width: 30,
              height: 30,
              justifyContent: 'center',
              borderRadius: 50,
              alignSelf: 'center',
            }}>
            <Text
              style={{
                fontSize: 15,
                color: '#FFF',
                textAlign: 'center',
                fontFamily:'OpenSans-Bold'
              }}>
              V
            </Text>
          </View>
        ) : (
          <View
            style={{alignSelf: 'center'}}
            >
            <Text
              style={{
                fontSize: 17,
                fontFamily: 'Roboto-Regular',
                color: config.primary_color,
                alignSelf: 'center',
              }}>
              Invite
            </Text>
          </View>
        )}
      </TouchableOpacity>
    )
  }
}

export default ContactItem
