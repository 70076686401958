import {
  LOAD_GIG_DETAILS,
  GIG_SCROLL_DOWN,
  GIG_ORDER_STATUS,
  GIG_MILESTONE_STATUS,
  BUYER_FUND_FULL_AMOUNT,
  BUYER_FUND_MILESTONE,
  TOGGLE_OVERLAY_REDO,
  REDO_UPDATE,
  REFUND_GIG_ORDER,
  SHOW_ALERT,
  HIDE_ALERT,
  ADD_REVIEWS,
  ACTION_PENDING_SET,
  ACTION_PENDING_RESET
} from '../constants/constants'


export function loadGigDetails (uid) {
  return {
    type: LOAD_GIG_DETAILS,
    uid: uid,
  }
}
export function showAlert () {
  return {
    type: SHOW_ALERT
  }
}export function hideAlert () {
  return {
    type: HIDE_ALERT
  }
}
export function scrollToggle () {
  return {
    type: GIG_SCROLL_DOWN,
  }
}
export function overlayRedoToggle () {
  return {
    type: TOGGLE_OVERLAY_REDO,
  }
}

export function changeGigOrderStatus (uid, status) {
  return {
    type: GIG_ORDER_STATUS,
    uid: uid,
    status: status,
  }
}

export function changeGigMilestoneStatus (pid, uid, status,isLast,details='',imageData='') {
  return {
    type: GIG_MILESTONE_STATUS,
    pid: pid,
    uid: uid,
    status: status,
    isLast:isLast,
    details:details,
    imageData:imageData
  }
}

export function updateRedo(pid, uid, status,modifications) {
  return {
    type: REDO_UPDATE,
    pid: pid,
    uid: uid,
    status: status,
    modifications:modifications
  }
}

export function fundFullAmount (uid, status) {
  return {
    type: BUYER_FUND_FULL_AMOUNT,
    uid: uid,
    status: status,
  }
}

export function fundMilestone (pid, uid, status) {
  return {
    type: BUYER_FUND_MILESTONE,
    pid: pid,
    uid: uid,
    status: status,
  }
}

export function refundOrder(pid,action_by){
  
  return{
    type:REFUND_GIG_ORDER,
    pid: pid,
    action_by:action_by
  }
}

export function submitReview(pid,toUser,mode,rev1,rev2,complementSelected,complementCodeSelected,text,attachment,attachment_type){
  return{
    type:ADD_REVIEWS,
    pid:pid,
    toUser:toUser,
    mode:mode,
    rev1:rev1,
    rev2:rev2,
    complementSelected:complementSelected,
    complementCodeSelected:complementCodeSelected,
    text:text,
    attachment:attachment,
    attachment_type:attachment_type,
  }
}

export function setActionPending(){
  return{
    type:ACTION_PENDING_SET
  }
}

export function resetActionPending(){
  return{
    type:ACTION_PENDING_RESET
  }
}
