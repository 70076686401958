import React from "react";
import { View, TouchableOpacity, Text, StyleSheet } from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../config";

//NOTE: ...... This component is only for models ........

const Header = ({ props, title = "Give name" }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 20,
        paddingTop: 20,
      }}
    >
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={() => {
          // cameBack is a coustom function to close the model
          props.cameBack();
        }}
      >
        <AIcon name='left' style={styles.backButton} />
      </TouchableOpacity>
      <Text
        numberOfLines={1}
        style={{
          flex: 1,
          fontSize: 24,
          fontFamily: "OpenSans-Semibold",
          color: config.primary_color,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  backButton: {
    color: "#473BB3",
    fontSize: 22,
    paddingRight: 15,
  },
});

export default Header;
