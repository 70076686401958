import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {
  MY_MONEY_LOAD,
  MY_MONEY_LOAD_ERROR,
  MY_MONEY_LOAD_SUCCESS,
} from '../constants/constants';
import AccountService from '../../services/account.service';

const execMyMoney = async actionData => {
  try {
    var result = await AccountService.editProfile();

    return result;
  } catch (error) {
    // console.log(error);

    throw {message: 'Something went wrong'};
  }
};

function* LoadMyMoney(action) {
  try {
    const res = yield call(execMyMoney, action);
    yield put({
      type: MY_MONEY_LOAD_SUCCESS,
      response: {
        spentChart: res.spentChart,
        earnedChart: res.earnedChart,
        requiredMilestonesData: res.requiredMilestonesData,
        paidList: res.paidList,
        toBeRecievedList: res.toBeRecievedList,
        recievedList: res.recievedList,
        settledList: res.settledList,
      },
    });
  } catch (error) {
    yield put({type: MY_MONEY_LOAD_ERROR, response: {message: error.message}});
  }
}

function* watchMyMoney() {
  yield takeLatest(MY_MONEY_LOAD, LoadMyMoney);
}

export default watchMyMoney;
