import React, {Component} from 'react';
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Text,
  ScrollView,
} from 'react-native';
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import PrimaryButton from '../../components/PrimaryButton';
import InputText from '../../components/InputText';
import LinkUPIOverlay from '../../components/mybank/LinkUPIOverlay';
import {Formik} from 'formik';
import * as yup from 'yup';
import {linkAccount} from '../../state-management/actions/accounts.action';
import {connect} from 'react-redux';
import {Textarea} from 'native-base';
import { Modal } from 'react-native-paper';
;
import AwesomeAlert from 'react-native-awesome-alerts';
import {Svg, Image} from 'react-native-svg';
import SVG2 from '../../assets/images/paydetails2.svg';

class LinkUPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upiNumber: '',
      address: '',
      openOverlay: false,
      alertVisible: false,
    };
  }
  handleUpiNumberInChange = upiNumber => {
    this.setState({upiNumber});
  };
  handleAddressChange = address => {
    this.setState({address});
  };
  handleSubmit = values => {
    if (values.upiNumber.trim() != '') {
      this.setState({openOverlay: true});
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.error != prevProps.error) {
      if (this.props.error != '') {
        // alert(this.props.error)
        this.setState({alertVisible: true});
      }
    }
    if (this.props.linkedSuccess) {
      this.props.navigation.navigate('MyBank');
    }
  }
  renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            this.props.navigation.goBack();
          }}>
          <AIcon name="left" style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: 'OpenSans-Semibold',
            color: config.primary_color,
          }}>
          Update UPI ID
        </Text>
      </View>
    );
  };
  render() {
    const {upiNumber, address} = this.state;
    const validationSchema = yup.object().shape({
      upiNumber: yup
        .string()
        .required()
        .label('upiNumber'),
      address: yup
        .string()
        .required()
        .label('address'),
    });
    return (
      <Formik
        initialValues={{upiNumber: '', address: ''}}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{flex: 1, backgroundColor: '#FFF'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
              {this.renderHeader()}
              <View style={{flex: 1, marginHorizontal: '10%', paddingTop: 35}}>
              <Text
                style={{
                  fontSize: 18,
                  color: '#707070',
                  fontFamily: 'OpenSans-Regular',
                  marginBottom:25,
                  paddingHorizontal:8
                }}>
                Update your UPI ID to receive money in your account.
              </Text>
              <InputText
                  name="upiNumber"
                  label="UPI ID"
                  placeholder="Enter your UPI ID"
                  onChangeText={handleChange('upiNumber')}
                  onBlur={handleBlur('upiNumber')}
                  error={touched.upiNumber && errors.upiNumber}
                  value={values.upiNumber}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.upiNumber && errors.upiNumber && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please enter a proper UPI ID
                    </Text>
                  )}
                </View>

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Billing Address
                </Text>
                <Textarea
                  rowSpan={4}
                  bordered
                  name="address"
                  placeholder="Billing Address"
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('address')}
                  onBlur={handleBlur('address')}
                  error={touched.address && errors.address}
                  value={values.address}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.address && errors.address && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify valid billing address
                    </Text>
                  )}
                </View>
                <PrimaryButton
                  content={'Proceed'}
                  clicked={handleSubmit}
                  loading={this.props.isLoading}
                />
              </View>
            </ScrollView>
            <AwesomeAlert
        show={this.state.alertVisible}
        title=""
        message=""
        messageStyle={{ textAlign: 'center' }}
        customView={(
          <View
                    style={{
                      alignItems: 'center',
                      // paddingVertical: 10,
                      lineHeight: 21,
                      paddingHorizontal: 30,
                    }}>
                        <SVG2 />
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  marginTop: 20,
                  fontSize: 16,
                  color: '#707070',
                }}>
                {this.props?.error}
              </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          this.setState({alertVisible: false});
        }}
      />
      <Modal
                visible={this.state.openOverlay}
                contentContainerStyle={{backgroundColor: 'white', padding: 20,flex:1}}
                onDismiss={() => {
                  this.setState({openOverlay: false});
                }}>
                <LinkUPIOverlay
                  values={values}
                  onClose={data => {
                    this.setState({openOverlay: false});
                    if (data == 'success') {
                      this.props.linkAccount(
                        'upi',
                        null,
                        null,
                        values.address,
                        values.upiNumber,
                        null,
                      );

                    }
                  }}
                />
              </Modal>
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}
const styles = StyleSheet.create({
  backButton: {
    color: '#473BB3',
    fontSize: 22,
    paddingRight: 15,
  },
});

export default connect(
  state => ({
    ...state.accounts,
  }),
  {
    linkAccount,
  },
)(LinkUPI);
