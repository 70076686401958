import React, { useState, useEffect } from "react";
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Text,
} from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../../config";
import { Item, Input } from "native-base";
import FIcon from "react-native-vector-icons/Feather";
import PrimaryButton from "../PrimaryButton";
import { ScrollView } from "react-native-gesture-handler";
import AwesomeAlert from "react-native-awesome-alerts";

const KnownFor = props => {
  const [skills, setSkills] = useState([]);
  const [itm, setItem] = useState("");
  const [disable, setDisable] = useState(true);
  const [show, setShow] = useState(false);
  const [disableSaveBTN, setDisableSaveBTN] = useState(true);

  useEffect(() => {
    if (props.skills) {
      setSkills(props.skills);
    }
    return;
  }, []);

  const CostomHeader = () => {
    console.log(props);
    return (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            disableSaveBTN ? props.cameBack() : setShow(true);
          }}
        >
          <AIcon name='left' style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 24,
            fontFamily: "OpenSans-Semibold",
            color: config.primary_color,
          }}
        >
          Known For
        </Text>
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <CostomHeader />
      <View style={{ marginHorizontal: "7%", flex: 1 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginVertical: "6%",
            }}
          >
            <Item regular style={{ borderRadius: 5, flex: 1, height: 43 }}>
              <Input
                placeholder='Add skills or job you are known for'
                onChangeText={txt => {
                  setItem(txt);
                  setDisable(false);
                }}
                maxLength={19}
                style={{ fontSize: 14, fontFamily: "Roboto-Regular" }}
                value={itm}
                placeholderTextColor={"#DCDCDC"}
              />
            </Item>
            {skills.length <= 4 ? (
              <TouchableOpacity
                style={{ padding: 15 }}
                onPress={() => {
                  if (itm === "") {
                    alert("Enter somthing..");
                  } else {
                    if (itm.length > 19) {
                      alert("Max length is 19");
                    } else {
                      if (skills.includes(itm)) {
                        alert(`${itm} Alredy added`);
                      } else {
                        setSkills(old => [...old, itm]);
                        setDisable(true);
                        setItem("");
                        setDisableSaveBTN(false);
                      }
                    }
                  }
                }}
              >
                <AIcon
                  name='pluscircleo'
                  style={{ color: config.primary_color, fontSize: 30 }}
                />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={{ padding: 15 }}
                onPress={() => {
                  alert("Only 5 allowed");
                }}
              >
                <AIcon
                  name='pluscircleo'
                  style={{ color: config.primary_color, fontSize: 30 }}
                />
              </TouchableOpacity>
            )}
          </View>

          {skills.map((item, index) => {
            return (
              <View
                key={item}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: "#DCDCDC",
                  borderRadius: 15,
                  padding: 15,
                  marginTop: "5%",
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    flex: 1,
                    fontFamily: "Roboto-Regular",
                    color: "#707070",
                  }}
                >
                  {item}
                </Text>
                <TouchableOpacity
                  onPress={() => {
                    setSkills(skills.filter(e => e !== item));
                    setDisableSaveBTN(false);
                  }}
                >
                  <FIcon
                    name='trash-2'
                    style={{ fontSize: 30, color: config.primary_color }}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
          <View style={{ margin: 20, marginBottom: 40 }}>
            <PrimaryButton
              disabled={disableSaveBTN}
              content={"Save"}
              clicked={() => {
                props.onClose(skills);
              }}
            />
          </View>
        </ScrollView>
        <AwesomeAlert
          show={show}
          title=''
          message=''
          messageStyle={{ textAlign: "center" }}
          customView={
            <View
              style={{
                alignItems: "center",
                lineHeight: 21,
                paddingHorizontal: 5,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 16,
                  color: "#707070",
                  lineHeight: 21,
                }}
              >
                Please click the save button to save your changes
              </Text>
            </View>
          }
          showConfirmButton={true}
          confirmText='Ok'
          confirmButtonColor={config.primary_color}
          onConfirmPressed={() => {
            setShow(false);
          }}
        />
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  backButton: {
    color: "#473BB3",
    fontSize: 22,
    paddingRight: 15,
  },
});
export default KnownFor;
