import {CHANGE_PROTECTED_PAGE} from '../constants/constants'

const initialState = {
  
}

const reducer = function notificationReducer (state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}

export default reducer
