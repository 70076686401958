import { takeLatest, put, call, delay } from "redux-saga/effects";
import {
  LOAD_USER_PROFILE,
  LOAD_USER_PROFILE_SUCCESS,
  LOAD_USER_PROFILE_ERROR,
  LOAD_USER_PROFILE_BY_KEY,
  LOAD_USER_PROFILE_SUCCESS_BY_KEY,
  LOAD_USER_PROFILE_ERROR_BY_KEY,
  CREATE_USER_CHAT,
  CREATE_USER_CHAT_SUCCESS,
  CREATE_USER_CHAT_ERROR,
} from "../constants/constants";
import ProfileService from "../../services/profile.service";
import { setForSale } from "../actions/editprofile.action";
import { navigate } from "../../navigation/Navigator";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const execLoadProfile = async actionData => {
  try {
    const result = await ProfileService.getProfile();
    return result;
  } catch (error) {
    // console.log(error)
    throw { message: "Something went wrong" };
  }
};

function* loadProfile(action) {
  try {
    const res = yield call(execLoadProfile, action);
    // console.log(res);
    yield put({
      type: LOAD_USER_PROFILE_SUCCESS,
      response: {
        userProfile: res,
      },
    });
  } catch (error) {
    yield put({
      type: LOAD_USER_PROFILE_ERROR,
      response: { message: error.message },
    });
  }
}

const execLoadProfileByKey = async actionData => {
  try {
    const result = await ProfileService.getProfileByKey({
      key: actionData.data.key,
      value: actionData.data.value,
    });
    return result;
  } catch (error) {
    throw { message: "Something went wrong" };
  }
};

function* loadProfileByKey(action) {
  try {
    const res = yield call(execLoadProfileByKey, action);
    yield put({
      type: LOAD_USER_PROFILE_SUCCESS_BY_KEY,
      response: res,
    });
    if (action?.data?.storeId) {
      var for_sale_data = res.for_sale_data;
      var temp = for_sale_data.find(obj => {
        if (obj.id == action?.data?.storeId) return obj;
      });
      if (temp) {
        yield put(setForSale(temp));
        if (firebase.auth()?.currentUser?.uid)
          navigate("ForSaleDetail", { mobile: res.userProfile.mobile });
        else
          navigate("ForSale", { data: temp, mobile: res.userProfile.mobile });
      }
    }
  } catch (error) {
    // console.log(error);
    yield put({
      type: LOAD_USER_PROFILE_ERROR_BY_KEY,
      response: { message: error.message },
    });
  }
}

const execCreateUserChat = async actionData => {
  try {
    const result = await ProfileService.createUserChat({
      to_user: actionData.toUser,
    });
    return result;
  } catch (error) {
    throw { message: "Something went wrong" };
  }
};

function* createUserChat(action) {
  try {
    const res = yield call(execCreateUserChat, action);
    yield put({
      type: CREATE_USER_CHAT_SUCCESS,
      response: res,
    });
  } catch (error) {
    yield put({
      type: CREATE_USER_CHAT_ERROR,
      response: { message: error.message },
    });
  }
}

function* watchUserProfile() {
  yield takeLatest(LOAD_USER_PROFILE, loadProfile);
  yield takeLatest(LOAD_USER_PROFILE_BY_KEY, loadProfileByKey);
  yield takeLatest(CREATE_USER_CHAT, createUserChat);
}

export default watchUserProfile;
