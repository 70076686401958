import {
  takeLatest,
  put,
  call,
  select,
  take,
  cancelled,
  delay,
} from 'redux-saga/effects';
import {eventChannel, END} from 'redux-saga';
import {
  INIT_TOPIC_MESSAGE_STATE,
  INIT_TOPIC_MESSAGE_STATE_SUCCESS,
  INIT_TOPIC_MESSAGE_STATE_ERROR,
  SEND_TOPIC_MESSAGE,
  SEND_TOPIC_MESSAGE_ERROR,
  SEND_TOPIC_MESSAGE_SUCCESS,
  SUBSCRIBE_TOPIC_MESSAGES,
  TOPIC_PAGE_STATE_SUCCESS,
  TOPIC_PAGE_STATE,
  TOPIC_PAGE_STATE_ERROR,
  FETCH_TOPIC_CHAT_MESSAGES_SUCCESS,
  FETCH_TOPIC_CHAT_MESSAGES_ERROR,
  FETCH_TOPIC_CHAT_MESSAGES,
  UNSUBSCRIBE_SOCKET_CHANNEL_TOPIC_MESSAGE,
} from '../constants/constants';
import moment from 'moment';
import {
  getTopicChatMessages,
  subscribeToTopicMessages,
  topicMessageReceived,
} from '../actions/topicmessages.action';
import SocketIOClient from 'socket.io-client';
import config from '../../config';
import chatService from '../../services/chat.service';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

var socket = null;
var updateChannel = null;
const execInitTopicMessageState = async action => {
  try {
    const {data} = action;
    const {thread_id} = data;
    var currentUser = firebase.auth().currentUser.uid;
    var result = await chatService.initTopicChat({
      thread_id: thread_id,
    });
    const token = await firebase.auth().currentUser.getIdToken();
    return {
      current_user_id: currentUser,
      to_user_id: result.to_user_id,
      thread_id: thread_id,
      chatId: result.chatId,
      token: token,
    };
  } catch (error) {
    // console.log(error)
    throw {message: 'Something went wrong'};
  }
};

function* initTopicMessageStateSaga(action) {
  try {
    const res = yield call(execInitTopicMessageState, action);
    yield put({
      type: INIT_TOPIC_MESSAGE_STATE_SUCCESS,
      response: {...res},
    });
    yield put(getTopicChatMessages(1));
    yield put(subscribeToTopicMessages());
  } catch (error) {
    yield put({
      type: INIT_TOPIC_MESSAGE_STATE_ERROR,
      response: {message: error.message},
    });
  }
}

const execSendTopicMessage = async (action, state) => {
  try {
    const {current_user_id, to_user_id, thread_id, chatId} = state;
    const {message} = action;
    var messageObject = {
      message_id: new Date().getTime(),
      message: message.message,
      date: moment(new Date().toISOString()).format('DD-MMM-YY'),
      time: moment(new Date().toISOString()).format('hh:mm a'),
      sentBy: current_user_id,
      status: 'sent',
      status: 'sent',
      isAttachment: message.image ? true : false,
      attachments: message.image,
    };

    if (socket && socket.connected) {
      socket.emit('message', {
        message: messageObject,
        chatDetails: {threadId: thread_id, sentTo: to_user_id, chatId: chatId},
      });
    }
    return messageObject;
  } catch (error) {
    throw {message: 'Message Delivery failed'};
  }
};

function* sendTopicMessage(action) {
  try {
    const getItems = state => state.topicmessages;
    const currentState = yield select(getItems);
    const res = yield call(execSendTopicMessage, action, currentState);
    yield put({type: SEND_TOPIC_MESSAGE_SUCCESS, response: res});
  } catch (error) {
    // console.log(error)
   
    yield put({
      type: SEND_TOPIC_MESSAGE_ERROR,
      response: {message: error.message},
    });
  }
}

function TopicEventChannel(state) {
  try {
    const listener = eventChannel(emit => {
      socket = SocketIOClient(config.chat_server, {
        transports: ['websocket'],
        secure: true,
        path: '/chat/socket.io',
        query: {
          room: state.thread_id,
          chatId: state.chatId,
          uid: state.current_user_id,
          token: state.token,
        },
      });
      socket.on('connect_error', err => {
        // console.log('Chat connection error', err.message);
      });
      socket.on('message', function(message) {
        emit(message);
      });

      return () => {
        socket.disconnect();
      };
    });
    return listener;
  } catch (error) {
    // console.log(error)
  }
}

function* subscribeTopic() {
  try {
    const getItems = state => state.topicmessages;
    const currentState = yield select(getItems);
    updateChannel = TopicEventChannel(currentState);
    while (true) {
      const item = yield take(updateChannel);
      yield put(topicMessageReceived(item));
    }
  } catch (error) {
    // console.log(error)
  }
}

const updateTopicPageState = async (action, state) => {
  try {
    const thread_id = state.thread_id;

    var result = await chatService.updateChatPageState({
      thread_id: thread_id,
    });

    return;
  } catch (error) {
    throw {message: 'Something went wrong'};
  }
};

function* setTopicPageState(action) {
  try {
    const getItems = state => state.topicmessages;
    const currentState = yield select(getItems);
    const res = yield call(updateTopicPageState, action, currentState);
  } catch (error) {
    // console.log(error)
    yield put({
      type: MESSAGE_PAGE_STATE_ERROR,
      response: {message: error.message},
    });
  }
}

const execFetchChatMessages = async (action, state) => {
  try {
    const thread_id = state.thread_id;
    const chat_id = state.chatId;
    const page = action.page;
    const result = await chatService.fetchMessages({
      thread_id: thread_id,
      chatId: chat_id,
      page: page,
    });
    return result;
  } catch (error) {
    throw {message: 'Something went wrong'};
  }
};

function* fetchTopicChatMessages(action) {
  try {
    const getItems = state => state.topicmessages;
    const currentState = yield select(getItems);
    const res = yield call(execFetchChatMessages, action, currentState);
    yield put({type: FETCH_TOPIC_CHAT_MESSAGES_SUCCESS, response: res});
  } catch (error) {
    // console.log(error);
    yield put({
      type: FETCH_TOPIC_CHAT_MESSAGES_ERROR,
      response: {message: error.message},
    });
  }
}

function* watchTopicMessages() {
  yield takeLatest(INIT_TOPIC_MESSAGE_STATE, initTopicMessageStateSaga);
  yield takeLatest(SEND_TOPIC_MESSAGE, sendTopicMessage);
  yield takeLatest(SUBSCRIBE_TOPIC_MESSAGES, subscribeTopic);
  yield takeLatest(TOPIC_PAGE_STATE, setTopicPageState);
  yield takeLatest(FETCH_TOPIC_CHAT_MESSAGES, fetchTopicChatMessages);
  yield takeLatest(UNSUBSCRIBE_SOCKET_CHANNEL_TOPIC_MESSAGE, function*() {
    if (updateChannel) {
      updateChannel.close();
    }
  });
}

export default watchTopicMessages;
