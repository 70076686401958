import React, { useState, useEffect } from "react";
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Text,
  ScrollView,
} from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../../config";
import PrimaryButton from "../PrimaryButton";
import SocialInput from "../SocialInput";
import AwesomeAlert from "react-native-awesome-alerts";
const LinkSocials = props => {
  const [call, setCall] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [web, setWeb] = useState("");
  const [mail, setMail] = useState("");
  const [youtube, setYoutube] = useState("");
  const [checkCall, setCheckCall] = useState(true);
  const [checkmail, setCheckmail] = useState(true);
  const [checkfacebook, setCheckfacebook] = useState(true);
  const [checkinstagram, setCheckinstagram] = useState(true);
  const [checktwitter, setChecktwitter] = useState(true);
  const [checklinkedin, setChecklinkedin] = useState(true);
  const [checkweb, setCheckweb] = useState(true);
  const [checkyoutube, setCheckyoutube] = useState(true);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (props.profile.userProfile?.social_links !== undefined) {
      const {
        call,
        facebook,
        instagram,
        twitter,
        linkedin,
        web,
        mail,
        youtube,
      } = props.profile.userProfile?.social_links;
      setCall(call);
      setFacebook(facebook);
      setInstagram(instagram);
      setTwitter(twitter);
      setLinkedin(linkedin);
      setWeb(web);
      setMail(mail);
      setYoutube(youtube);
    }
  }, [props.profile.userProfile?.social_links]);

  // /[(https:\/\/www\.linkedin.com)]{20}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)+/g

  const validatePhone = async phone => {
    console.log("check phone before", checkCall);
    if (phone !== "") {
      const phoneRegex = /(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}/g;
      setCheckCall(phoneRegex.test(phone));
    } else {
      setCheckCall(true);
    }
  };

  const validateMail = email => {
    const emailRegex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    if (email !== "") {
      setCheckmail(emailRegex.test(email));
    } else {
      setCheckmail(true);
    }
  };

  const validateFacebook = facebook => {
    const facebookRegex =
      /((http|https):\/\/|)(www\.|)facebook\.com\/[a-zA-Z0-9.]{1,}/g;
    if (facebook !== "") {
      setCheckfacebook(!facebookRegex.test(facebook));
    } else {
      setCheckfacebook(true);
    }
  };

  const validateInstagram = instagram => {
    const instagramRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/gim;
    if (instagram !== "") {
      setCheckinstagram(instagramRegex.test(instagram));
    } else {
      setCheckinstagram(true);
    }
  };

  const validateTwitter = twitter => {
    const twitterRegex =
      /(https?:\/\/)?(www\.)?twitter\.com\/[A-Za-z0-9_]{5,15}(\?(\w+=\w+&?)*)?/g;
    if (twitter !== "") {
      setChecktwitter(!twitterRegex.test(twitter));
    } else {
      setChecktwitter(true);
    }
  };

  const validateLinkedin = linkedin => {
    const linkedinRegex =
      /[(https:\/\/www\.linkedin.com)]{20}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/=]*)+/g;
    if (linkedin !== "") {
      setChecklinkedin(linkedinRegex.test(linkedin));
    } else {
      setChecklinkedin(true);
    }
  };

  const validateWeb = web => {
    const webRegex =
      /^(https?:\/\/)?([\w\d-_]+)\.([\w\d-_\.]+)\/?\??([^#\n\r]*)?#?([^\n\r]*)/gm;
    if (web !== "") {
      setCheckweb(webRegex.test(web));
    } else {
      setCheckweb(true);
    }
  };

  const validateYoutube = youtube => {
    const youtubeRegex =
      /(?:(?:https?:\/\/)(?:www)?\.?(?:youtu\.?be)(?:\.com)?\/(?:.*[=/])*)([^= &?/\r\n]{8,11})/g;
    if (youtube !== "") {
      setCheckyoutube(youtubeRegex.test(youtube));
    } else {
      setCheckyoutube(true);
    }
  };

  const renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingTop: 20,
        }}
      >
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            if (
              checkCall &&
              checkmail &&
              checkfacebook &&
              checkinstagram &&
              checklinkedin &&
              checktwitter &&
              checkweb &&
              checkyoutube
            ) {
              props.onClose(props.linksData);
            } else {
              setShow(true);
            }
          }}
        >
          <AIcon name='left' style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: "OpenSans-Semibold",
            color: config.primary_color,
          }}
        >
          Connect with me
        </Text>
      </View>
    );
  };

  const showError = ({ state }) => {
    return (
      <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
        {state == false ? (
          <Text
            style={{
              fontSize: 12,
              textAlign: "right",
              fontFamily: "Roboto-Regular",
              color: "#F90000",
            }}
          >
            Invalid :(
          </Text>
        ) : null}
      </View>
    );
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        {renderHeader()}
        <View style={{ flex: 1, marginHorizontal: "10%" }}>
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Email ID'
            name='mail'
            placeholder='Email ID'
            onChangeText={txt => {
              validateMail(txt);
              setMail(txt);
            }}
            value={mail}
          />
          {showError({ state: checkmail })}
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Phone number'
            name='Phone'
            maxLength={10}
            placeholder='Phone'
            onChangeText={txt => {
              validatePhone(txt);
              setCall(txt);
            }}
            value={call}
          />
          {showError({ state: checkCall })}
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Your Website'
            name='web'
            placeholder='Your Website'
            onChangeText={txt => {
              validateWeb(txt);
              setWeb(txt);
            }}
            value={web}
          />
          {showError({ state: checkweb })}
          <View
            style={{
              width: "95%",
              paddingTop: 3,
              marginTop: 34,
            }}
          />
          <Text style={{ fontSize: 16, marginLeft: 2, marginBottom: 16 }}>
            Social links
          </Text>
          <SocialInput
            label='Facebook'
            name='facebook'
            placeholder='Facebook username'
            onChangeText={txt => {
              validateFacebook(txt);
              setFacebook(txt);
            }}
            value={facebook}
          />
          {showError({ state: checkfacebook })}
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Instagram'
            name='instagram'
            placeholder='Instagram username'
            onChangeText={txt => {
              validateInstagram(txt);
              setInstagram(txt);
            }}
            value={instagram}
          />
          {showError({ state: checkinstagram })}
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Twitter Username'
            name='twitter'
            placeholder='Twitter username'
            onChangeText={txt => {
              validateTwitter(txt);
              setTwitter(txt);
            }}
            value={twitter}
          />
          {showError({ state: checktwitter })}
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Linkedin'
            name='linkedin'
            placeholder='Linkedin URL'
            onChangeText={txt => {
              validateLinkedin(txt);
              setLinkedin(txt);
            }}
            value={linkedin}
          />
          {showError({ state: checklinkedin })}
          <View
            style={{
              marginTop: 16,
            }}
          />
          <SocialInput
            label='Youtube'
            name='youtube'
            placeholder='Youtube URL'
            onChangeText={txt => {
              validateYoutube(txt);
              setYoutube(txt);
            }}
            value={youtube}
          />
          {showError({ state: checkyoutube })}
          <View style={{ marginTop: 20, marginBottom: 20 }}>
            <PrimaryButton
              content={"Save"}
              clicked={async () => {
                if (
                  checkCall &&
                  checkmail &&
                  checkfacebook &&
                  checkinstagram &&
                  checklinkedin &&
                  checktwitter &&
                  checkweb &&
                  checkyoutube
                ) {
                  console.log("clicking 2");
                  if (props.profile.userProfile?.social_links !== undefined) {
                    props.onClose({
                      call: call,
                      facebook: facebook,
                      instagram: instagram,
                      twitter: twitter,
                      linkedin: linkedin,
                      web: web,
                      mail: mail,
                      youtube: youtube,
                    });
                  }
                  props.onClose({
                    call: call,
                    facebook: facebook,
                    instagram: instagram,
                    twitter: twitter,
                    linkedin: linkedin,
                    web: web,
                    mail: mail,
                    youtube: youtube,
                  });
                }
              }}
            />
          </View>
        </View>
      </ScrollView>
      <AwesomeAlert
        show={show}
        title=''
        message=''
        messageStyle={{ textAlign: "center" }}
        customView={
          <View
            style={{
              alignItems: "center",
              lineHeight: 21,
              paddingHorizontal: 5,
            }}
          >
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#707070",
                lineHeight: 21,
              }}
            >
              Please click the save button to save your changes
            </Text>
          </View>
        }
        showConfirmButton={true}
        confirmText='Ok'
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() => {
          setShow(false);
        }}
      />
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  backButton: {
    color: "#473BB3",
    fontSize: 22,
    paddingRight: 15,
  },
});

export default LinkSocials;
