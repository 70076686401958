import { LOAD_DATA, CHANGE_MODE ,UPDATE_WINDOW_COUNT,END_REACHED, RESET_MODE} from "../constants/constants";


export function loadData(){
    return {
        type:LOAD_DATA
    }
}

export function changeMode(mode){
    return {
        type:CHANGE_MODE,
        mode:mode
    }
}

export function updateWindowCount(){
    return {
        type:UPDATE_WINDOW_COUNT
    }
}
export function endReached(){
    return {
        type:END_REACHED
    }
}

export function resetMode(){
    return {
        type:RESET_MODE
    }
}

