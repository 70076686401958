import {
  LOAD_DATA,
  DATA_FETCHED,
  FETCH_ERROR,
  CHANGE_MODE,
  UPDATE_WINDOW_COUNT,
  END_REACHED,
  RESET_MODE,
} from '../constants/constants'

const initialState = {
  phoneContacts: [],
  vouchUsers: [],
  phoneVouchUsers: [],
  isLoading: true,
  error: '',
  mode: '',
  tempContacts: [],
  onEndReachedCalledDuringMomentum: true,
}

const reducer = function contactsReducer (state = initialState, action) {
  switch (action.type) {
    case CHANGE_MODE: {
      return {
        ...state,
        mode: action.mode,
      }
    }

    case RESET_MODE: {
      return {
        ...state,
        mode: '',
        isLoading:true
      }
    }

    case LOAD_DATA: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }

    case END_REACHED: {
      return {
        ...state,
        onEndReachedCalledDuringMomentum: !state.onEndReachedCalledDuringMomentum,
      }
    }
    case UPDATE_WINDOW_COUNT: {
      var current_length = state.tempContacts.length
      var new_length = current_length + 20
      var new_array = state.phoneContacts.slice(current_length, new_length)
      return {
        ...state,
        tempContacts: [...state.tempContacts, ...new_array],
      }
    }

    case DATA_FETCHED: {
      return {
        ...state,
        phoneContacts: action.response.phoneContacts,
        tempContacts: action.response.phoneContacts.slice(0, 20),
        vouchUsers: action.response.vouchUsers,
        phoneVouchUsers: action.response.phoneVouchUsers,
        isLoading: false,
      }
    }
    case FETCH_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
