/* -------------------------------------------------------------------------- */
/*                               INTRO CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const SET_INTRO_SHOWN = "SET_INTRO_SHOWN";
export const SET_INTRO_SHOWN_DONE = "SET_INTRO_SHOWN_DONE";

/* -------------------------------------------------------------------------- */
/*                               INTRO CONSTANTS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               AUTH CONSTANTS                               */
/* -------------------------------------------------------------------------- */
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const GOOGLE_SIGNIN = "GOOGLE_SIGNIN";
export const GOOGLE_SIGNIN_SUCCESS = "GOOGLE_SIGNIN_SUCCESS";
export const GOOGLE_SIGNIN_ERROR = "GOOGLE_SIGNIN_ERROR";
export const LOGOUT = "LOGOUT";
export const LOGOUT_DONE = "LOGOUT_DONE";
export const LOGIN_LATER = "LOGIN_LATER";
export const SWITCH_TO_AUTH = "SWITCH_TO_AUTH";

/* -------------------------------------------------------------------------- */
/*                               AUTH CONSTANTS                               */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               FLOAT CONSTANTS                               */
/* -------------------------------------------------------------------------- */

export const STORE_FLOAT_DATA = "STORE_FLOAT_DATA";
export const SET_FLOAT_USER_ID = "SET_FLOAT_USER_ID";
export const RESET_FLOAT_DATA = "RESET_FLOAT_DATA";

/* -------------------------------------------------------------------------- */
/*                               FLOAT CONSTANTS                               */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              SIGNIN CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const PHONE_SIGNIN = "PHONE_SIGNIN";
export const PHONE_SUCCESS = "PHONE_SUCCESS";
export const PHONE_ERROR = "PHONE_ERROR";
export const NAVIGATE_TO_SIGNUP = "NAVIGATE_TO_SIGNUP";

/* -------------------------------------------------------------------------- */
/*                              SIGNIN CONSTANTS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              SIGNUP CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

/* -------------------------------------------------------------------------- */
/*                              SIGNUP CONSTANTS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              OTP CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const VERIFY_INIT = "VERIFY_INIT";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const RE_VERIFY_OTP = "RE_VERIFY_OTP";
export const RE_VERIFY_OTP_SUCCESS = "RE_VERIFY_OTP_SUCCESS";
export const RE_VERIFY_OTP_ERROR = "RE_VERIFY_OTP_ERROR";

/* -------------------------------------------------------------------------- */
/*                              OTP CONSTANTS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             CATEGORY CONSTANTS                             */
/* -------------------------------------------------------------------------- */

export const SERVICE_SELECTED = "SERVICE_SELECTED";
export const PRODUCT_SELECTED = "PRODUCT_SELECTED";
export const RESET = "RESET";
export const SET_PAYMENT_AMOUNT = "SET_PAYMENT_AMOUNT";
export const LOAD_CATEGORIES = "LOAD_CATEGORIES";
export const LOAD_CATEGORIES_SUCCESS = "LOAD_CATEGORIES_SUCCESS";
export const LOAD_CATEGORIES_ERROR = "LOAD_CATEGORIES_ERROR";
export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const RESET_CONTACT_INFO = "RESET_CONTACT_INFO";

/* -------------------------------------------------------------------------- */
/*                             CATEGORY CONSTANTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              CONTACT CONSTANTS                             */
/* -------------------------------------------------------------------------- */

export const LOAD_DATA = "LOAD_DATA";
export const DATA_FETCHED = "DATA_FETCHED";
export const FETCH_ERROR = "FETCH_ERROR";
export const CHANGE_MODE = "CHANGE_MODE";
export const UPDATE_WINDOW_COUNT = "UPDATE_WINDOW_COUNT";
export const END_REACHED = "END_REACHED";
export const RESET_MODE = "RESET_MODE";

/* -------------------------------------------------------------------------- */
/*                              CONTACT CONSTANTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              SEARCH CONTACT CONSTANTS                      */
/* -------------------------------------------------------------------------- */
export const INIT_CONTACTS = "INIT_CONTACTS";
export const SEARCH_CONTACTS = "SEARCH_CONTACTS";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";

/* -------------------------------------------------------------------------- */
/*                              SEARCH CONTACT CONSTANTS                      */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          PAYMENT DETAILS CONSTANTS                         */
/* -------------------------------------------------------------------------- */

export const ADD_MILESTONE = "ADD_MILESTONE";
export const DELETE_MILESTONE = "DELETE_MILESTONE";
export const EDIT_MILESTONE = "EDIT_MILESTONE";
export const INIT_STATE = "INIT_STATE";
export const MAKE_TRANSACTION = "MAKE_TRANSACTION";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILURE = "TRANSACTION_FAILURE";
export const CHANGE_MILESTONE_MODE = "CHANGE_MILESTONE_MODE";

/* -------------------------------------------------------------------------- */
/*                          PAYMENT DETAILS CONSTANTS                         */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                           MAKE PAYMENT CONSTANTS                           */
/* -------------------------------------------------------------------------- */

export const INIT_PAYMENT_STATE = "INIT_PAYMENT_STATE";
export const MAKE_PAYMENT = "MAKE_PAYMENT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const RESET_PAYMENT_STATE = "RESET_PAYMENT_STATE";
/* -------------------------------------------------------------------------- */
/*                           MAKE PAYMENT CONSTANTS                           */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                           REQUEST PAYMENT CONSTANTS                        */
/* -------------------------------------------------------------------------- */

export const INIT_REQUEST_PAYMENT_STATE = "INIT_REQUEST_PAYMENT_STATE";
export const MAKE_REQUEST_PAYMENT = "MAKE_REQUEST_PAYMENT";
export const REQUEST_PAYMENT_SUCCESS = "REQUEST_PAYMENT_SUCCESS";
export const REQUEST_PAYMENT_ERROR = "REQUEST_PAYMENT_ERROR";
export const RESET_REQUEST_PAYMENT_STATE = "RESET_REQUEST_PAYMENT_STATE";
/* -------------------------------------------------------------------------- */
/*                           REQUEST PAYMENT CONSTANTS                        */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          PAYMENT/MESSAGES PAGE                              */
/* -------------------------------------------------------------------------- */

export const CHANGE_PAGE = "CHANGE_PAGE";
export const RESET_PAYMENT_USER_STATE = "RESET_PAYMENT_USER_STATE";
/* -------------------------------------------------------------------------- */
/*                          PAYMENT/MESSAGES PAGE                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          PAYMENTS TAB PAGE                              */
/* -------------------------------------------------------------------------- */

export const LOAD_PAYMENTS = "LOAD_PAYMENTS";
export const LOAD_PAYMENT_ERROR = "LOAD_PAYMENT_ERROR";
export const LOAD_PAYMENT_SUCCESS = "LOAD_PAYMENT_SUCCESS";

/* -------------------------------------------------------------------------- */
/*                          PAYMENTS TAB PAGE                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          RECENT CONTACTS PAGE                              */
/* -------------------------------------------------------------------------- */

export const LOAD_RECENT_CONTACTS = "LOAD_RECENT_CONTACTS";
export const LOAD_RECENT_CONTACTS_ERROR = "LOAD_RECENT_CONTACTS_ERROR";
export const LOAD_RECENT_CONTACTS_SUCCESS = "LOAD_RECENT_CONTACTS_SUCCESS";

/* -------------------------------------------------------------------------- */
/*                          RECENT CONTACTS PAGE                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          PROTECTED PAYMENTS PAGE                           */
/* -------------------------------------------------------------------------- */

export const CHANGE_PROTECTED_PAGE = "CHANGE_PROTECTED_PAGE";
export const LOAD_PROTECTED_PAYMENTS = "LOAD_PROTECTED_PAYMENTS";
export const LOAD_PROTECTED_PAYMENTS_SUCCESS =
  "LOAD_PROTECTED_PAYMENTS_SUCCESS";
export const LOAD_PROTECTED_PAYMENTS_ERROR = "LOAD_PROTECTED_PAYMENTS_ERROR";

/* -------------------------------------------------------------------------- */
/*                          PROTECTED PAYMENTS PAGE                           */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                           NOTIFICATIONS CONSTANTS                          */
/* -------------------------------------------------------------------------- */

export const INIT_NOTIFICATIONS = "INIT_NOTIFICATIONS";
export const TRIGGER_NOTIFICATION = "TRIGGER_NOTIFICATION";
export const NOTIFICATION_CLICKED = "NOTIFICATION_CLICKED";

/* -------------------------------------------------------------------------- */
/*                           NOTIFICATIONS CONSTANTS                          */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          LOAD GIG DETAILS                              */
/* -------------------------------------------------------------------------- */

export const LOAD_GIG_DETAILS = "LOAD_GIG_DETAILS";
export const LOAD_GIG_DETAILS_ERROR = "LOAD_GIG_DETAILS_ERROR";
export const LOAD_GIG_DETAILS_SUCCESS = "LOAD_GIG_DETAILS_SUCCESS";
export const GIG_SCROLL_DOWN = "GIG_SCROLL_DOWN";
export const GIG_ORDER_STATUS = "GIG_ORDER_STATUS";
export const GIG_ORDER_STATUS_SUCCESS = "GIG_ORDER_STATUS_SUCCESS";
export const GIG_ORDER_STATUS_ERROR = "GIG_ORDER_STATUS_ERROR";
export const GIG_MILESTONE_STATUS = "GIG_MILESTONE_STATUS";
export const GIG_MILESTONE_STATUS_SUCCESS = "GIG_MILESTONE_STATUS_SUCCESS";
export const GIG_MILESTONE_STATUS_ERROR = "GIG_MILESTONE_STATUS_ERROR";
export const BUYER_FUND_FULL_AMOUNT = "BUYER_FUND_FULL_AMOUNT";
export const BUYER_FUND_FULL_AMOUNT_SUCCESS = "BUYER_FUND_FULL_AMOUNT_SUCCESS";
export const BUYER_FUND_FULL_AMOUNT_ERROR = "BUYER_FUND_FULL_AMOUNT_ERROR";
export const BUYER_FUND_MILESTONE = "BUYER_FUND_MILESTONE";
export const BUYER_FUND_MILESTONE_SUCCESS = "BUYER_FUND_MILESTONE_SUCCESS";
export const BUYER_FUND_MILESTONE_ERROR = "BUYER_FUND_MILESTONE_ERROR";
export const TOGGLE_OVERLAY_REDO = "TOGGLE_OVERLAY_REDO";
export const REDO_UPDATE = "REDO_UPDATE";
export const REDO_UPDATE_SUCCESS = "REDO_UPDATE_SUCCESS";
export const REDO_UPDATE_ERROR = "REDO_UPDATE_ERROR ";
export const REFUND_GIG_ORDER = "REFUND_GIG_ORDER";
export const REFUND_GIG_ORDER_SUCCESS = "REFUND_GIG_ORDER_SUCCESS";
export const REFUND_GIG_ORDER_ERROR = "REFUND_GIG_ORDER_ERROR";
export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";
export const ADD_REVIEWS = "ADD_REVIEWS";
export const ADD_REVIEWS_SUCCESS = "ADD_REVIEWS_SUCCESS";
export const ADD_REVIEWS_ERROR = "ADD_REVIEWS_ERROR";
export const ACTION_PENDING_SET = "ACTION_PENDING_SET";
export const ACTION_PENDING_RESET = "ACTION_PENDING_RESET";

/* -------------------------------------------------------------------------- */
/*                          LOAD GIG DETAILS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             ACTIVITY CONSTANTS                             */
/* -------------------------------------------------------------------------- */

export const FETCH_ACTIVITY = "FETCH_ACTIVITY";
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const FETCH_ACTIVITY_ERROR = "FETCH_ACTIVITY_ERROR";

/* -------------------------------------------------------------------------- */
/*                             ACTIVITY CONSTANTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             MESSAGES CONSTANTS                             */
/* -------------------------------------------------------------------------- */

export const INIT_MESSAGE_STATE = "INIT_MESSAGE_STATE";
export const INIT_MESSAGE_STATE_SUCCESS = "INIT_MESSAGE_STATE_SUCCESS";
export const INIT_MESSAGE_STATE_ERROR = "INIT_MESSAGE_STATE_ERROR";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";
export const SUBSCRIBE_MESSAGES = "SUBSCRIBE_MESSAGES";
export const ON_MESSAGE_RECEIVED = "ON_MESSAGE_RECEIVED";
export const MESSAGE_PAGE_STATE = "MESSAGE_PAGE_STATE";
export const MESSAGE_PAGE_STATE_SUCCESS = "MESSAGE_PAGE_STATE_SUCCESS";
export const MESSAGE_PAGE_STATE_ERROR = "MESSAGE_PAGE_STATE_ERROR";
export const FETCH_CHAT_MESSAGES = "FETCH_CHAT_MESSAGES";
export const FETCH_CHAT_MESSAGES_SUCCESS = "FETCH_CHAT_MESSAGES_SUCCESS";
export const FETCH_CHAT_MESSAGES_ERROR = "FETCH_CHAT_MESSAGES_ERROR";
export const UNSUBSCRIBE_SOCKET_CHANNEL_MESSAGE =
  "UNSUBSCRIBE_SOCKET_CHANNEL_MESSAGE";

/* -------------------------------------------------------------------------- */
/*                             MESSAGES CONSTANTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                          TOPIC MESSAGES CONSTANTS                          */
/* -------------------------------------------------------------------------- */
export const INIT_TOPIC_MESSAGE_STATE = "INIT_TOPIC_MESSAGE_STATE";
export const INIT_TOPIC_MESSAGE_STATE_SUCCESS =
  "INIT_TOPIC_MESSAGE_STATE_SUCCESS";
export const INIT_TOPIC_MESSAGE_STATE_ERROR = "INIT_TOPIC_MESSAGE_STATE_ERROR";
export const SEND_TOPIC_MESSAGE = "SEND_TOPIC_MESSAGE";
export const SEND_TOPIC_MESSAGE_SUCCESS = "SEND_TOPIC_MESSAGE_SUCCESS";
export const SEND_TOPIC_MESSAGE_ERROR = "SEND_TOPIC_MESSAGE_ERROR";
export const SUBSCRIBE_TOPIC_MESSAGES = "SUBSCRIBE_TOPIC_MESSAGES";
export const ON_TOPIC_MESSAGE_RECEIVED = "ON_TOPIC_MESSAGE_RECEIVED";
export const TOPIC_PAGE_STATE = "TOPIC_PAGE_STATE";
export const TOPIC_PAGE_STATE_SUCCESS = "TOPIC_PAGE_STATE_SUCCESS";
export const TOPIC_PAGE_STATE_ERROR = "TOPIC_PAGE_STATE_ERROR";
export const FETCH_TOPIC_CHAT_MESSAGES = "FETCH_TOPIC_CHAT_MESSAGES";
export const FETCH_TOPIC_CHAT_MESSAGES_SUCCESS =
  "FETCH_TOPIC_CHAT_MESSAGES_SUCCESS";
export const FETCH_TOPIC_CHAT_MESSAGES_ERROR =
  "FETCH_TOPIC_CHAT_MESSAGES_ERROR";
export const UNSUBSCRIBE_SOCKET_CHANNEL_TOPIC_MESSAGE =
  "UNSUBSCRIBE_SOCKET_CHANNEL_TOPIC_MESSAGE";
/* -------------------------------------------------------------------------- */
/*                          TOPIC MESSAGES CONSTANTS                          */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             ALL NOTIFICATIONS CONSTANTS                    */
/* -------------------------------------------------------------------------- */

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";

/* -------------------------------------------------------------------------- */
/*                              ALL NOTIFICATIONS CONSTANTS                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             MY MONEY CONSTANTS                    */
/* -------------------------------------------------------------------------- */

export const MY_MONEY_CHANGE_PAGE = "MY_MONEY_CHANGE_PAGE";
export const MY_MONEY_LOAD = "MY_MONEY_LOAD";
export const MY_MONEY_LOAD_SUCCESS = "MY_MONEY_LOAD_SUCCESS";
export const MY_MONEY_LOAD_ERROR = "MY_MONEY_LOAD_ERROR";

/* -------------------------------------------------------------------------- */
/*                              MY MONEY CONSTANTS                   */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                         PAYMENT CHECKOUT CONSTANTS                         */
/* -------------------------------------------------------------------------- */

export const INIT_CHECKOUT_DETAILS = "INIT_CHECKOUT_DETAILS";
export const INIT_CHECKOUT_SUCCESS = "INIT_CHECKOUT_SUCCESS";
export const INIT_CHECKOUT_ERROR = "INIT_CHECKOUT_ERROR";
export const RESET_PAYMENT_CHECKOUT_STATE = "RESET_PAYMENT_CHECKOUT_STATE";
export const PAYMENT_COMPLETED = "PAYMENT_COMPLETED";
export const PAYMENT_COMPLETED_SUCCESS = "PAYMENT_COMPLETED_SUCCESS";
export const PAYMENT_COMPLETED_ERROR = "PAYMENT_COMPLETED_ERROR";
export const CHECKOUT_PAYMENT = "CHECKOUT_PAYMENT";
export const CHECKOUT_PAYMENT_SUCCESS = "CHECKOUT_PAYMENT_SUCCESS";
export const CHECKOUT_PAYMENT_ERROR = "CHECKOUT_PAYMENT_ERROR";
export const CREATE_VIRTUALL_ACCOUNT = "CREATE_VIRTUALL_ACCOUNT";
export const CREATE_VIRTUALL_ACCOUNT_SUCCESS =
  "CREATE_VIRTUALL_ACCOUNT_SUCCESS";
export const CREATE_VIRTUALL_ACCOUNT_ERROR = "CREATE_VIRTUALL_ACCOUNT_ERROR";

export const CANCEL_VIRTUALL_ACCOUNT = "CANCEL_VIRTUALL_ACCOUNT";
export const CANCEL_VIRTUALL_ACCOUNT_SUCCESS =
  "CANCEL_VIRTUALL_ACCOUNT_SUCCESS";
export const CANCEL_VIRTUALL_ACCOUNT_ERROR = "CANCEL_VIRTUALL_ACCOUNT_ERROR";
export const LOAD_VIRTUALL_ACCOUNT = "LOAD_VIRTUALL_ACCOUNT";
export const LOAD_VIRTUALL_ACCOUNT_SUCCESS = "LOAD_VIRTUALL_ACCOUNT_SUCCESS";
export const LOAD_VIRTUALL_ACCOUNT_ERROR = "LOAD_VIRTUALL_ACCOUNT_ERROR";

/* -------------------------------------------------------------------------- */
/*                         PAYMENT CHECKOUT CONSTANTS                         */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                         PROFILE CONSTANTS                         */
/* -------------------------------------------------------------------------- */

export const LOAD_USER_PROFILE = "LOAD_USER_PROFILE";
export const LOAD_USER_PROFILE_SUCCESS = "LOAD_USER_PROFILE_SUCCESS";
export const LOAD_USER_PROFILE_ERROR = "LOAD_USER_PROFILE_ERROR";
export const LOAD_USER_PROFILE_BY_KEY = "LOAD_USER_PROFILE_BY_KEY";
export const LOAD_USER_PROFILE_SUCCESS_BY_KEY =
  "LOAD_USER_PROFILE_SUCCESS_BY_KEY";
export const LOAD_USER_PROFILE_ERROR_BY_KEY = "LOAD_USER_PROFILE_ERROR_BY_KEY";
export const CREATE_USER_CHAT = "CREATE_USER_CHAT";
export const CREATE_USER_CHAT_SUCCESS = "CREATE_USER_CHAT_SUCCESS";
export const CREATE_USER_CHAT_ERROR = "CREATE_USER_CHAT_ERROR";
export const RESET_PROFILE = "RESET_PROFILE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_DETAILS = "UPDATE_USER_PROFILE_DETAILS";
export const UPDATE_USER_PROFILE_FOR_SALE = "UPDATE_USER_PROFILE_FOR_SALE";
export const UPDATE_USER_PROFILE_PORTFOLIO = "UPDATE_USER_PROFILE_PORTFOLIO";

/* -------------------------------------------------------------------------- */
/*                         PROFILE CONSTANTS                                  */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                         RECOMMENDATIONS CONSTANTS                          */
/* -------------------------------------------------------------------------- */

export const INIT_RECOMMENDATIONS = "INIT_RECOMMENDATIONS";
export const REQUEST_RECOMMENDATIONS = "REQUEST_RECOMMENDATIONS";
export const REQUEST_RECOMMENDATIONS_SUCCESS =
  "REQUEST_RECOMMENDATIONS_SUCCESS";
export const REQUEST_RECOMMENDATIONS_ERROR = "REQUEST_RECOMMENDATIONS_ERROR";
export const FETCH_RECOMMENDATIONS = "FETCH_RECOMMENDATIONS";
export const FETCH_RECOMMENDATIONS_SUCCESS = "FETCH_RECOMMENDATIONS_SUCCESS";
export const FETCH_RECOMMENDATIONS_ERROR = "FETCH_RECOMMENDATIONS_ERROR";
export const SUBMIT_RECOMMENDATIONS = "SUBMIT_RECOMMENDATIONS";
export const SUBMIT_RECOMMENDATIONS_SUCCESS = "SUBMIT_RECOMMENDATIONS_SUCCESS";
export const SUBMIT_RECOMMENDATIONS_ERROR = "SUBMIT_RECOMMENDATIONS_ERROR";

/* -------------------------------------------------------------------------- */
/*                         RECOMMENDATIONS CONSTANTS                          */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                         EDIT PROFILE CONSTANTS                             */
/* -------------------------------------------------------------------------- */

export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";
export const EDIT_USER_PROFILE_SUCCESS = "EDIT_USER_PROFILE_SUCCESS";
export const EDIT_USER_PROFILE_ERROR = "EDIT_USER_PROFILE_ERROR";
export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
export const ADD_GIG_FOR_SALE = "ADD_GIG_FOR_SALE";
export const ADD_GIG_FOR_SALE_SUCCESS = "ADD_GIG_FOR_SALE_SUCCESS";
export const ADD_GIG_FOR_SALE_ERROR = "ADD_GIG_FOR_SALE_ERROR";
export const LOAD_INITIALL_DATA = "LOAD_INITIALL_DATA";
export const LOAD_INITIALL_DATA_SUCCESS = "LOAD_INITIALL_DATA_SUCCESS";
export const LOAD_INITIALL_DATA_ERROR = "LOAD_INITIALL_DATA_ERROR";
export const ADD_NEW_PORTFOLIO = "ADD_NEW_PORTFOLIO";
export const ADD_NEW_PORTFOLIO_SUCCESS = "ADD_NEW_PORTFOLIO_SUCCESS";
export const ADD_NEW_PORTFOLIO_ERROR = "ADD_NEW_PORTFOLIO_ERROR";
export const EDIT_PORTFOLIO = "EDIT_PORTFOLIO";
export const EDIT_PORTFOLIO_SUCCESS = "EDIT_PORTFOLIO_SUCCESS";
export const EDIT_PORTFOLIO_ERROR = "EDIT_PORTFOLIO_ERROR";
export const EDIT_FOR_SALE = "EDIT_FOR_SALE";
export const EDIT_FOR_SALE_SUCCESS = "EDIT_FOR_SALE_SUCCESS";
export const EDIT_FOR_SALE_ERROR = "EDIT_FOR_SALE_ERROR";
export const SET_PORTFOLIO = "SET_PORTFOLIO";
export const SET_FOR_SALE = "SET_FOR_SALE";
export const DELETE_PORTFOLIO = "DELETE_PORTFOLIO";
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_ERROR = "DELETE_PORTFOLIO_ERROR";
export const DELETE_FOR_SALE = "DELETE_FOR_SALE";
export const DELETE_FOR_SALE_SUCCESS = "DELETE_FOR_SALE_SUCCESS";
export const DELETE_FOR_SALE_ERROR = "DELETE_FOR_SALE_ERROR";

/* -------------------------------------------------------------------------- */
/*                         EDIT PROFILE CONSTANTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                         INVITE USER CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";

/* -------------------------------------------------------------------------- */
/*                         INVITE USER  CONSTANTS                             */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                           DEEP LINKING CONSTANTS                           */
/* -------------------------------------------------------------------------- */
export const HANDLE_DEEP_LINK = "HANDLE_DEEP_LINK";
export const HANDLE_DEEP_LINK_ERROR = "HANDLE_DEEP_LINK_ERROR";
export const HANDLE_DEEP_LINK_SUCCESS = "HANDLE_DEEP_LINK_SUCCESS";

/* -------------------------------------------------------------------------- */
/*                           DEEP LINKING CONSTANTS                           */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                           LINK ACCOUNTS CONSTANTS                           */
/* -------------------------------------------------------------------------- */

export const LINK_ACCOUNT_DETAILS = "LINK_ACCOUNT_DETAILS";
export const LINK_ACCOUNT_DETAILS_SUCCESS = "LINK_ACCOUNT_DETAILS_SUCCESS";
export const LINK_ACCOUNT_DETAILS_ERROR = "LINK_ACCOUNT_DETAILS_ERROR";
export const LOAD_ACCOUNT = "LOAD_ACCOUNT";
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const LOAD_ACCOUNT_ERROR = "LOAD_ACCOUNT_ERROR";

/* -------------------------------------------------------------------------- */
/*                           LINK ACCOUNTS CONSTANTS                           */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               TOAST CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const SHOW_TOAST = "SHOW_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

/* -------------------------------------------------------------------------- */
/*                               TOAST CONSTANTS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              HELPER CONSTANTS                              */
/* -------------------------------------------------------------------------- */

export const LOAD_ESSENTIALS = "LOAD_ESSENTIALS";
export const LOAD_ESSENTIALS_SUCCESS = "LOAD_ESSENTIALS_SUCCESS";
export const LOAD_ESSENTIALS_ERROR = "LOAD_ESSENTIALS_ERROR";

/* -------------------------------------------------------------------------- */
/*                              HELPER CONSTANTS                              */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                              PAYMENT PREVIEW CONSTANTS                     */
/* -------------------------------------------------------------------------- */

export const LOAD_PAYMENT_PREVIEW = "LOAD_PAYMENT_PREVIEW";
export const LOAD_PAYMENT_PREVIEW_SUCCESS = "LOAD_PAYMENT_PREVIEW_SUCCESS";
export const LOAD_PAYMENT_PREVIEW_ERROR = "LOAD_PAYMENT_PREVIEW_ERROR";

/* -------------------------------------------------------------------------- */
/*                              PAYMENT PREVIEW CONSTANTS                     */
/* -------------------------------------------------------------------------- */
