//import liraries
import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,Platform
} from "react-native";
import config from "../../config";
import InputText from "../../components/InputText";
import PrimaryButton from "../../components/PrimaryButton";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { signUp } from "../../state-management/actions/signup.action";
import { verifyOTPInit } from "../../state-management/actions/otp.action";
import { FontAwesome, AntDesign } from "@expo/vector-icons";
import { FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
import AwesomeAlert from 'react-native-awesome-alerts';

// create a component
class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      mobile: "",
      referral: "",
      alertVisible: false,
      accountType:'individual',
      alertText:''
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.error != prevProps.error) {
      if (this.props.error != "") {
        this.setState({alertText:this.props.error})
        this.setState({alertVisible: true});
      }
    }
    if (this.props.proceedWithOtp != prevProps.proceedWithOtp) {
      if (this.props.proceedWithOtp == true) {
        this.props.verifyOTPInit({
          confirm: this.props.confirm,
          mobile: this.props.data.mobile,
          page: "signup",
          signupData: this.props.data,
        });
        this.props.navigation.navigate("OtpVerify");
      }
    }
  }

  componentDidMount() {
    console.log(this.props.route.params);
    if (Platform.OS != "web") {
      this.recaptchaVerifierRef
        .verify()
        .then((token) => {
          this.setState({ recaptcha: token });
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }

  handleFirstNameChange = (firstName) => {
    this.setState({ firstName });
  };
  handleLastNameChange = (lastName) => {
    this.setState({ lastName });
  };
  handleEmailChange = (email) => {
    this.setState({ email });
  };
  handleUserNameChange = (username) => {
    this.setState({ username });
  };
  handleMobileChange = (mobile) => {
    this.setState({ mobile });
  };
  handleReferralChange = referral => {
    this.setState({referral});
  };
  // handleSubmit = (values) => {
  //   if (
  //     values.username.trim() != "" &&
  //     values.lastName.trim() != "" &&
  //     values.firstName.trim() != "" &&
  //     values.mobile.trim()
  //   ) {
  //     this.props.signUp(values, this.state.recaptcha);
  //   } else {
  //     alert("Please fill all the fields");
  //   }
  // };

  handleSubmit = values => {
    if(this.state.accountType=='company' && values.companyName.trim()==''){
        this.setState({alertText:'Please fill the company name'})
        this.setState({alertVisible:true})
    }else{
      this.props.signUp({...values,accountType:this.state.accountType}, this.state.recaptcha);
    }        
  };

  render() {
    var { firstName, lastName, username, email, mobile, referral } = this.state;
    if (this.props.route.params) {
      const data  = this.props.route.params;
      if(data.hasOwnProperty('code')){
        referral = data.code
      }else{
        if(data?.data?.hasOwnProperty("mobile")) {
          mobile = data.data.mobile;
        }
        if(data?.data?.hasOwnProperty("firstname")) {
          firstName = data.data.firstname;
        }
        if(data?.data?.hasOwnProperty("lastname")) {
          lastName = data.data.lastname;
        }
        if(data?.data?.hasOwnProperty('email')){
          email = data.data.email;
        }
        if(data?.hasOwnProperty('code')){
          referral = data.code
        }
      }
    }

    const validationSchema = yup.object().shape({
      firstName: yup
        .string()
        .trim()
        .label("firstName")
        .required()
        .min(1, "Must have at least 2 characters"),
      lastName: yup
        .string()
        .trim()
        .label("lastName")
        .required()
        .min(1, "Must have at least 1 characters"),
      username: yup
        .string()
        .trim()
        .label("username")
        .required()
        .matches(/^\S+$/, 'Spaces are not allowed')
        .max(20, "Must have maximum of 20 charecters"),
      email: yup
        .string()
        .trim()
        .label("Email")
        .email("Enter a valid email :("),
      mobile: yup
        .string()
        .trim()
        .label("Mobile")
        .min(10, "Mobile must have 10 characters ")
        .max(10)
        .required("Mobile Number is required"),
      referral: yup
        .string()
        .trim()
        .label('referral'),
      companyName:yup
        .string()
        .trim()
        .label('companyName')
        .min(2, 'Must have at least 2 characters')
    });
    return (
      <Formik
        initialValues={{
          firstName: firstName,
          email: email,
          lastName: lastName,
          mobile: mobile,
          username: "",
          referral:referral,
          companyName:''
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
          setFieldValue
        }) => (
          <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                padding: 20,
              }}
            >
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => this.props.navigation.navigate('GetStarted')}
              >
                <AntDesign name="left" style={styles.backButton} />
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 30,
                  fontFamily: "Roboto-Medium",
                  color: config.primary_color,
                }}
              >
                Sign Up!
              </Text>
            </View>
            <ScrollView showsVerticalScrollIndicator={false}>
            <View style={{flexDirection:'row',justifyContent:'center'}}>
                <View style={styles.container}>
                <TouchableOpacity
                  style={styles.radioCircle}
                  onPress={() => {
                    this.setState({
                      accountType: 'company',
                    });
                  }}>
                  {this.state.accountType === 'company' && <View style={styles.selectedRb} />}
                </TouchableOpacity>
                <Text style={styles.radioText}>Company</Text>
              </View>
              <View style={styles.container}>
                <TouchableOpacity
                  style={styles.radioCircle}
                  onPress={() => {
                    this.setState({
                      accountType: 'individual',
                    });
                  }}>
                  {this.state.accountType === 'individual' && <View style={styles.selectedRb} />}
                </TouchableOpacity>
                <Text style={styles.radioText}>Individual</Text>
              </View>
              </View>
              <View style={styles.inputContainer}>
              {
                  this.state.accountType=='company'?
                  <View style={{marginBottom:20}}>
                  <InputText
                  name="companyName"
                  label="Company Name "
                  placeholder="Enter your company name"
                  onChangeText={handleChange('companyName')}
                  onBlur={handleBlur('companyName')}
                  error={touched.companyName && errors.companyName}
                  value={values.companyName}
                />
                </View>
                :null
                }
                <InputText
                  name="firstName"
                  label="First Name "
                  placeholder="Enter your First Name"
                  onChangeText={
                    e => {setFieldValue('firstName',e)
                    setFieldValue('username',e.trim()+values.lastName.trim())}
                  }
                  onBlur={handleBlur("firstName")}
                  error={touched.firstName && errors.firstName}
                  value={values.firstName}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.firstName && errors.firstName && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      Invalid name :(
                    </Text>
                  )}
                  {touched.firstName && !errors.firstName && (
                    <View>
                      <FontAwesome
                        name="check"
                        style={{
                          color: "#09C98F",
                          fontSize: 22,
                          alignSelf: "flex-end",
                          position: "absolute",
                          top: -40,
                          right: -10,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                          fontFamily: "Roboto-Regular",
                          color: "#09C98F",
                        }}
                      >
                        Nice name :)
                      </Text>
                    </View>
                  )}
                </View>
                <InputText
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your Last Name"
                  onChangeText={e => {setFieldValue('lastName',e)
                  setFieldValue('username',values.firstName.trim()+e.trim())}}
                  onBlur={handleBlur("lastName")}
                  error={touched.lastName && errors.lastName}
                  value={values.lastName}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.lastName && errors.lastName && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      Invalid name :(
                    </Text>
                  )}
                  {touched.lastName && !errors.lastName && (
                    <View>
                      <FontAwesome
                        name="check"
                        style={{
                          color: "#09C98F",
                          fontSize: 22,
                          alignSelf: "flex-end",
                          position: "absolute",
                          top: -40,
                          right: -10,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                          fontFamily: "Roboto-Regular",
                          color: "#09C98F",
                        }}
                      />
                    </View>
                  )}
                </View>
                <InputText
                  name="username"
                  label="Username"
                  placeholder="Enter a Unique Username(max 20 char)"
                  onChangeText={handleChange("username")}
                  onBlur={handleBlur("username")}
                  error={touched.username && errors.username}
                  value={values.username}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.username && errors.username && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.username}
                    </Text>
                  )}
                  {touched.username && !errors.username && (
                    <View>
                      <FontAwesome
                        name="check"
                        style={{
                          color: "#09C98F",
                          fontSize: 22,
                          alignSelf: "flex-end",
                          position: "absolute",
                          top: -40,
                          right: -10,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                          fontFamily: "Roboto-Regular",
                          color: "#09C98F",
                        }}
                      />
                    </View>
                  )}
                </View>
                <InputText
                  name="email"
                  label="Email"
                  placeholder="Enter your e-mail id"
                  onChangeText={handleChange("email")}
                  onBlur={handleBlur("email")}
                  error={touched.email && errors.email}
                  value={values.email}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.email && errors.email && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      Invalid email :(
                    </Text>
                  )}
                  {/* {touched.email && !errors.email && (
                    <View>
                      <FontAwesome
                        name="check"
                        style={{
                          color: "#09C98F",
                          fontSize: 22,
                          alignSelf: "flex-end",
                          position: "absolute",
                          top: -40,
                          right: -10,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                          fontFamily: "Roboto-Regular",
                          color: "#09C98F",
                        }}
                      >
                        Cool email :)
                      </Text>
                    </View>
                  )} */}
                </View>
                <InputText
                  name="mobile"
                  label="Mobile Number"
                  placeholder="For ex:9898989898"
                  onChangeText={handleChange("mobile")}
                  onBlur={handleBlur("mobile")}
                  error={touched.mobile && errors.mobile}
                  value={values.mobile}
                  keyboard={"numeric"}
                  disabled={this.props.route.params?.data?.mobile?true:false}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.mobile && errors.mobile && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.mobile}
                    </Text>
                  )}
                  {touched.mobile && !errors.mobile && (
                    <View>
                      <FontAwesome
                        name="check"
                        style={{
                          color: "#09C98F",
                          fontSize: 22,
                          alignSelf: "flex-end",
                          position: "absolute",
                          top: -40,
                          right: -10,
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "right",
                          fontFamily: "Roboto-Regular",
                          color: "#09C98F",
                        }}
                      >
                        Ph No is good to go :)
                      </Text>
                    </View>
                  )}
                </View>
                <InputText
                  name="referral"
                  label="Referral code"
                  placeholder="For ex: NEWUSER"
                  onChangeText={handleChange('referral')}
                  onBlur={handleBlur('referral')}
                  error={touched.referral && errors.referral}
                  value={values.referral}
                  disabled={this.props.route.params?.code?true:false}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}} />
              </View>
                <TouchableOpacity
                  onPress={() =>
                    // Linking.openURL('https://iamvouched.com/terms')
                    {
                      this.props.navigation.navigate("TermsAndConditions");
                    }
                  }
                  style={{ marginTop: 5 ,paddingHorizontal:30}}
                >
                  <Text style={{ fontFamily: "OpenSans-SemiBold" }}>
                    By Proceeding , You accept the{" "} 
                    <Text style={{ color: "blue" }}>Terms and conditions</Text>
                  </Text>
                </TouchableOpacity>
              <View style={{ margin: 20, marginTop: 0 }}>
                <PrimaryButton
                  content={"Get OTP"}
                  loading={this.props.isLoading}
                  clicked={handleSubmit}
                />
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    fontSize: 14,
                    color: "#464646",
                    paddingLeft: 20,
                    paddingTop: 15,
                  }}
                >
                  You can login with Phone Number{"\n"}
                  No need to remember passwords{"\n"}
                  Just login with OTP every time
                </Text>
              </View>
            </ScrollView>
            <AwesomeAlert
        show={this.state.alertVisible}
        title=""
        message=""
        messageStyle={{ textAlign: 'center' }}
        customView={(
          <View
            style={{
              alignItems: 'center',
              paddingVertical: 10,
              lineHeight: 21,
              paddingHorizontal: 10,
            }}>
            <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 16,
                color: '#707070',
                lineHeight: 21,
              }}>
                    {this.state.alertText}
            </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          this.setState({alertVisible: false});
        }}
      />
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    fontSize: 30,
    marginRight: 30,
    marginTop: 5,
    alignItems: "flex-start",
  },
  inputContainer: {
    padding: "8%",
    paddingBottom: 0,
  },
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    flex:0.4
  },
  radioText: {
      marginRight: 35,
      fontSize: 16,
      color: '#747373',
      fontFamily:'Roboto-Regular',marginLeft:10
    },
  radioCircle: {
  height: 20,
  width: 20,
  borderRadius: 100,
  borderWidth: 1,
  borderColor: '#707070',
  alignItems: 'center',
  justifyContent: 'center',
  },
  selectedRb: {
  width: 10,
  height: 10,
  borderRadius: 50,
  backgroundColor: '#707070',
  },
});

//make this component available to the app
export default connect((state) => ({ ...state.signup }), {
  signUp,
  verifyOTPInit,
})(SignUp);
