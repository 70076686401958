import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView, ScrollView, Image
} from 'react-native'
import config from '../config'
import PrimaryButton from './PrimaryButton'
import * as yup from 'yup'
import AIcon from 'react-native-vector-icons/AntDesign'
// import ImageView from "react-native-image-viewing";


export default class ViewDeliveryDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modifications: '',
      imageVisible:false
    }
  }

  

  render () {
    var {modifications} = this.state
    const validationSchema = yup.object().shape({
      modifications: yup.string().label('modifications'),
    })
    return (

          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: '#707070',
              justifyContent: 'flex-end',
            }}>
            <View
              style={{
                backgroundColor: '#FFF',
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems:'flex-end',
                  padding: 20,
                }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => this.props.onGoBack('close')}>
                  <AIcon name='left' style={styles.backButton} />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: 'Roboto-Bold',
                    color: config.primary_color,
                  }}>
                  Delivery Details
                </Text>
              </View>
              <ScrollView style={styles.inputContainer} >
                {/* **********************************  Modifications Input *********************************** */}

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    // paddingLeft: 5,
                  }}>
                  Details
                </Text>

                {/* <Textarea
                  name='modifications'
                  rowSpan={4}
                  bordered
                  placeholder={this.props.data}
                  borderRadius={10}
                  placeholderTextColor={'#000'}
                  style={{fontSize: 14, padding: 15}}
                  disabled
                /> */}
                <View style={{padding:'3%',paddingLeft:0}}>
                    <Text style={{lineHeight:18}}>
                        {this.props.data}
                    </Text>
                </View>
                {/* <Image source={{
                      uri: this.props.imagePath,
                    }} style={{width:'100%',height:200}}/> */}
                    {this.props.imagePath?
                  <TouchableOpacity onPress={()=>{window.open(this.props.imagePath)}}>
                    <Text style={{color:config.primary_color,fontFamily: 'Roboto-Bold'}}>
                      View Attachment
                    </Text>
                  </TouchableOpacity>:null
  }
                <PrimaryButton content={'Ok'} clicked={()=>{this.props.onGoBack('close')}} />
                
              </ScrollView>
            </View>
            {/* <ImageView
                images={[
                  {
                    uri:this.props.imagePath
                  }
                ]}
                imageIndex={0}
                visible={this.state.imageVisible}
                onRequestClose={() => {this.setState({imageVisible:false})}}
                />  */}
          </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    height: 30,
    marginRight: 15,
    fontSize:25
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    paddingHorizontal: '8%',
    paddingBottom: '10%',
  },
})