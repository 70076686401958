import { LINK_ACCOUNT_DETAILS, LINK_ACCOUNT_DETAILS_SUCCESS, LINK_ACCOUNT_DETAILS_ERROR, LOAD_ACCOUNT, LOAD_ACCOUNT_SUCCESS, LOAD_ACCOUNT_ERROR } from '../constants/constants';
  
  const initialState = {
    isLoading: false,
    linkedSuccess: null,
    accountDetails:[],
    moneyToBeEarned:null,
    error: '',
  };
  
  const reducer = function linkAccountReducer(state = initialState, action) {
    switch (action.type) {
      case LINK_ACCOUNT_DETAILS: {
        return {
          ...state,
          isLoading: true,
          error: '',
          linkedSuccess: null,
        };
      }
      case LINK_ACCOUNT_DETAILS_SUCCESS: {
        return {
          ...state,
          linkedSuccess:action.response.successfullyLinked,
          isLoading: false,
        };
      }
      case LINK_ACCOUNT_DETAILS_ERROR: {
        return {
          ...state,
          error: action.response.message,
          isLoading: false,
        };
      }
      case LOAD_ACCOUNT: {
        return {
          ...state,
          isLoading: true,
          error: '',
        };
      }
      case LOAD_ACCOUNT_SUCCESS: {
        return {
          ...state,
          accountDetails:action.response.accountDetail,
          moneyToBeEarned:action.response.sumToBeEarned,
          isLoading: false,
        };
      }
      case LOAD_ACCOUNT_ERROR: {
        return {
          ...state,
          error: action.response.message,
          isLoading: false,
        };
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  