import config from '../config';
import Interceptor from './Interceptor';


class ChatService {
    initChat = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const data = await axiosObj.post(
            `${config.node_api_endpoint}/chats/init`,
            params,
          );
          return data.data.data;
        } catch (error) {
          // console.log(error);
          throw error;
        }
      };
      initTopicChat = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const data = await axiosObj.post(
            `${config.node_api_endpoint}/chats/topic/init`,
            params,
          );
          return data.data.data;
        } catch (error) {
          // console.log(error);
          throw error;
        }
      };
      fetchMessages = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const data = await axiosObj.post(
            `${config.node_api_endpoint}/chats/fetch`,
            params,
          );
          return data.data.data;
        } catch (error) {
          // console.log(error);
          throw error;
        }
      };
      updateChatPageState = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const data = await axiosObj.post(
            `${config.node_api_endpoint}/chats/change/state`,
            params,
          );
          return data.data.data;
        } catch (error) {
          // console.log(error);
          throw error;
        }
      };
}

export default new ChatService();