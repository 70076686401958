import { CREATE_USER_CHAT, LOAD_USER_PROFILE , LOAD_USER_PROFILE_BY_KEY, RESET_PROFILE} from "../constants/constants";

export function loadProfile(){
    return{
        type:LOAD_USER_PROFILE
    }
}

export function loadProfileByKey(obj){
    return{
        type:LOAD_USER_PROFILE_BY_KEY,
        data:obj
    }
}

export function createUserchatThread(toUser){
    return{
        type:CREATE_USER_CHAT,
        toUser:toUser
    }
}

export function resetProfile(){
    return{
        type:RESET_PROFILE,
    }
}