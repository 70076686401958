import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ImageBackground,
} from 'react-native';
import {Textarea} from 'native-base';
import config from '../../config';
import InputText from '../InputText';
import {Formik} from 'formik';
import * as yup from 'yup';
import AIcon from 'react-native-vector-icons/AntDesign';
import SVG2 from '../../assets/images/paydetails2.svg';
import PrimaryButton from '../PrimaryButton';
import AwesomeAlert from 'react-native-awesome-alerts';
import imageService from '../../services/image.service';

class EditPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackVisible: false,
      alertText: '',
      imageModal:false,
      images:[],
      errorAlert:false,
      oldImages:[],
      removedImagesArray:[]
    };
  }

  componentDidMount(){
      this.setState({oldImages:this.props.images})
  }



  handleSubmit = values => {
    if(this.state.images.length+this.state.oldImages.length==0){
      this.setState({errorAlert:true});
      this.setState({alertText:'Please upload an image'})
    }
    else{
      this.props.onClose(
        values,
        this.state.images,this.state.removedImagesArray)
    }
  }

  renderHeader= () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={()=>{this.setState({feedbackVisible:true})}}>
          <AIcon name="left" style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: 'OpenSans-Semibold',
            color: config.primary_color,
          }}>
          Edit portfolio 
        </Text>
      </View>
    );
  }

  imageUpload = () => {
    var el = document.getElementById("profile-pic");
    el.click();
    el.addEventListener("change", ($event) => {
      var file = $event.target.files?.length ? $event.target.files[0] : null;
      if (file) {
        if(file && !file.type.includes("image")
          // && !file.type.includes("pdf")
          ){
          el.value = null;
          alert("Unsupported File Format!");
          return;
        }
        if (file.size < 2000000) {
          imageService.toBase64(file).then((data) => {
            file.data = data
            var arr=this.state.images
            arr.push(file)
            arr=arr.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
            this.setState({images:arr})
          });
        } else {
          window.alert("Max image size is 2 MB");
        }
      }
    });
  }

  
  render() {
    const validationSchema = yup.object().shape({
      title: yup
        .string()
        .required()
        .trim()
        .label('title'),
      description:yup
        .string()
        .required()
        .trim()
        .label('description')
    });
    return (
      <Formik
        initialValues={{
          title: this.props.title,
          description:this.props.description,
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
                <input type="file" id="profile-pic" hidden accept='image/jpeg, image/png'/>
            <ScrollView showsVerticalScrollIndicator={false}>
            {this.renderHeader()}
              <View style={styles.inputContainer}>

                {/* **********************************  title Input *********************************** */}

                <InputText
                  name="title"
                  label="Title"
                  placeholder="Enter the title"
                  onChangeText={handleChange('title')}
                  onBlur={handleBlur('title')}
                  error={touched.title && errors.title}
                  value={values.title}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.title && errors.title && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      title is required
                    </Text>
                  )}
                </View>

                {/* **********************************  title Input End   *********************************** */}

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Details
                </Text>

                <Textarea
                  rowSpan={4}
                  bordered
                  name="description"
                  placeholder={'Enter a description of the portfolio item'}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('description')}
                  onBlur={handleBlur('description')}
                  error={touched.description && errors.description}
                  value={values.description}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.description && errors.description && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify details
                    </Text>
                  )}
                </View>

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                    Add image
                </Text>

                <View style={{flexDirection:'row',justifyContent:'flex-start',flexWrap:'wrap',marginVertical:10}}>
                        {this.state.oldImages.map((item,index)=>{
                               return(<ImageBackground 
                                    source={{uri:item}}
                                    imageStyle={{borderRadius:15}}
                                    style={{width:100,height:100,alignItems:'flex-end',paddingTop:5,paddingRight:5,marginRight:10,marginTop:10}}>
                                        <AIcon name='closecircle' style={{fontSize:15}} onPress={() => {
                                            this.setState({removedImagesArray:[...this.state.removedImagesArray,item]})
                                            var a=this.state.oldImages
                                            delete a[index];
                                            a=a.filter(i => i);
                                            this.setState({oldImages:a})
                                        }}/>
                                    </ImageBackground>)
                        })}
                        {this.state.images.map((item,index)=>{
                               return(<ImageBackground 
                                    source={{uri:item.data}}
                                    imageStyle={{borderRadius:15}}
                                    style={{width:100,height:100,alignItems:'flex-end',paddingTop:5,paddingRight:5,marginRight:10,marginTop:10}}>
                                        <AIcon name='closecircle' style={{fontSize:15}} onPress={() => {
                                            var a=this.state.images
                                            delete a[index];
                                            a=a.filter(i => i);
                                            this.setState({images:a})
                                        }}/>
                                    </ImageBackground>)
                        })}
                        {this.state.images.length+this.state.oldImages.length<5?
                    <TouchableOpacity 
                    onPress={()=>{this.imageUpload()}}
                    style={{width:100,height:100,justifyContent:'center',alignItems:'center',marginTop:10,backgroundColor:'#FFF',borderRadius:15,elevation:2,shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,}}>
                      <AIcon name='pluscircleo' style={{color:config.primary_color,fontSize:40}} />
                    </TouchableOpacity>:null}
                </View>
              </View>
            </ScrollView>
            <View style={{paddingHorizontal:'8%',paddingBottom:30}}>
              <PrimaryButton
                content={'Save'}
                clicked={handleSubmit}
                loading={this.props.isLoading}
              />
            </View>
            <AwesomeAlert
                show={this.state.errorAlert}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                    style={{
                      alignItems: 'center',
                      // paddingVertical: 10,
                      lineHeight: 21,
                      paddingHorizontal: 30,
                    }}>
                  <SVG2 style={{marginBottom: 20}} />
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  marginTop: 20,
                  fontSize: 16,
                  color: '#707070',
                }}>
                {this.state.alertText}
              </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          this.setState({errorAlert: false});
        }}
      />
            <AwesomeAlert
                show={this.state.feedbackVisible}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                  style={{
                    alignItems: 'center',
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}>
                  <SVG2 style={{marginBottom: 20}} />
                  <Text
                    style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#707070',
                      lineHeight: 21,
                    }}>
                    The details you entered will be lost. Are you sure you want
                    to exit ?
                  </Text>
                </View>
        )}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No'
        confirmText="Yes"
        confirmButtonColor={config.primary_color}
        onCancelPressed={()=>{this.setState({feedbackVisible: false});}}
        onConfirmPressed={() =>{
          this.props.cameBack()
        }}
      />
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    height: 30,
    fontSize: 25,
    marginRight: 20,
    marginTop: 5,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    padding: '8%',
    paddingBottom: 0,
  },
});

export default EditPortfolio;
