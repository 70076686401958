//import liraries
import React, {Component} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import config from '../../config'
import {connect} from 'react-redux'
// import {loadPaticularUserTransactions} from '../../state-management/actions/paumentstab.action'
import PaymentCard from '../PaymentCard'
// import PaymentsTabLoader from '../SkeletonLoaders/PaymentsTabLoader'
import { setContactInfo } from '../../state-management/actions/category.action'

class PaymentsTab extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  renderPayment = (item, index) => {
    return (
      <PaymentCard
        name={`${item.gig_name}`}
        amount={`${item.total_amount}`}
        status={`${item.order_status}`}
        formatedDate={`${item.formattedDate}`}
        processedPaymentStatus={`${item.Processed_Payment_status}`}
        mode={`${item.mode}`}
        isActionPending={item?.isActionPending}
        autoCompleteFlag={item?.autoCompleteFlag}
        orderCompletelySettled={item?.orderCompletelySettled}
        clicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.uid}`})
        }}
        chatClicked={() => {
          this.props.navigation.navigate('GigDetails', {uid: `${item.uid}`,initiallPage:1})
        }}
      />
    )
  }
  render () {
    return (
      <View style={{flex: 1, backgroundColor: '#fff'}}>
        <View style={{paddingBottom: '10%', flex: 1}}>
          {this.props.isLoading?
          <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size="large" color={config.primary_color} />
        </View>
          :
          <FlatList
            showsVerticalScrollIndicator={false}
            style={{width: '100%', marginBottom: 30}}
            data={this.props.payments}
            renderItem={({item, index}) => this.renderPayment(item, index)}
            keyExtractor={(item, index) => index.toString()}
            removeClippedSubviews={true}
            initialNumToRender={20}
            maxToRenderPerBatch={10}
          />}
        </View>
        <View
          style={{
            paddingVertical: 20,
            paddingHorizontal: '15%',
            position: 'absolute',
            width: '100%',
            bottom: 0,
          }}>
          <TouchableOpacity
            style={styles.buttonPayment}
            onPress={() => {
              this.props.setContactInfo(
                this.props.clickedUser?.company_name?this.props.clickedUser?.company_name:this.props.clickedUser?.firstname,
                this.props.clickedUser?.mobile,
                this.props.clickedUser?.seller_count,
                this.props.clickedUser?.seller_review,
                this.props.clickedUser?.buyer_count,
                this.props.clickedUser?.buyer_review)
                this.props.navigation.navigate('Category',{title:'Go ahead, select a\nCategory',description:'You can protect payments for Goods and Services by paying through Vouch.',flow_code:0})
            }}>
            <Text
              style={{
                color: '#F7F6F9',
                fontSize: 18,
                fontFamily: 'Roboto-Regular',
              }}>
              + New Protected Payment
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  clicked = () => {
    this.props.navigation.navigate('PaymentResult')
  }
}

// define your styles
const styles = StyleSheet.create({
  button: {
    color: config.primary_color,
    fontSize: 30,
    marginRight: 20,
  },
  buttonPayment: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: config.primary_color,
    padding: 10,
    borderRadius: 25,
    zIndex: 100,
    elevation: 30,
    borderColor: '#fff',
    shadowColor: '#fff',
  },
})

//make this component available to the app
export default connect(state => ({...state.paymentstab}), {
  setContactInfo
})(PaymentsTab)
