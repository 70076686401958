import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, SafeAreaView, ScrollView } from "react-native";
import { CommonActions } from "@react-navigation/native";
import { connect } from "react-redux";
import {
  initPaymentCheckout,
  resetPaymentCheckoutState,
  paymentCompleted,
  createVirtualAccount,
} from "../../state-management/actions/paymentcheckout.action";
import config from "../../config";
import PrimaryButton from "../../components/PrimaryButton";
import LottieView from "lottie-react-web";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";
import loadingAnim from "../../assets/lottie-animations/payment-processing.json";
import Header from "../../components/Header";
import UpiOption from "../../components/custom_checkout/UpiOption";
import BankTransferOption from "../../components/custom_checkout/BankTransferOption";
import OtherPaymentOptions from "../../components/custom_checkout/OtherPaymentOptions";
import miscService from "../../services/misc.service";
import { Modal } from "react-native-paper";
import AddUpi from "../../components/custom_checkout/AddUpi";
import MoreBanksOverlay from "../../components/custom_checkout/MoreBanksOverlay";
import CardDetailsOverlay from "../../components/custom_checkout/CardDetailsOverlay";
import logsService from "../../services/logs.service";
import NetBanking from "../../components/custom_checkout/NetBanking";
import CardPayment from "../../components/custom_checkout/CardPayment";

const PaymentCheckout = (props) => {
  const [savedUpis, setSavedUpis] = useState([]);
  const [addUpi, setAddUpi] = useState(false);
  const [moreBanks, setMoreBanks] = useState(false);
  const [cardOverlay, setCardOverlay] = useState(false);

  useEffect(() => {
    getPreview();
    return () => {
      props.resetPaymentCheckoutState();
    };
  }, []);

  const getPreview = async () => {
    var temp = await miscService.getCheckoutPreview();
    setSavedUpis(temp);
  };

  if (props.isLoading) {
    return (
      <View style={styles.container}>
        <View style={{ width: 400, height: 400 }}>
          <LottieView
            options={{ animationData: loadingAnim, autoplay: true, loop: true }}
          />
        </View>
        <Text style={[styles.text, { fontFamily: "OpenSans-Bold" }]}>
          Protecting your payment
        </Text>
      </View>
    );
  }

  if (props.error && props.paymentFailed == false) {
    return (
      <View style={styles.container}>
        <View style={{ width: 400, height: 400 }}>
          <LottieView
            options={{
              animationData: failedAnim,
              autoplay: true,
              loop: false,
            }}
            style={styles.animationSize}
          />
        </View>
        <Text style={styles.text}>{props.error}</Text>
      </View>
    );
  }

  if (props.paymentFailed) {
    const { data } = props.route.params;
    return (
      <View style={styles.container}>
        <View style={{ width: 400, height: 400 }}>
          <LottieView
            options={{
              animationData: failedAnim,
              autoplay: true,
              loop: false,
            }}
            style={styles.animationSize}
          />
        </View>
        <Text style={[styles.text, { fontFamily: "OpenSans-Bold" }]}>
          Payment has been cancelled
        </Text>
        <PrimaryButton
          content={"Retry "}
          styles={false}
          clicked={() => props.resetPaymentCheckoutState()}
        />
      </View>
    );
  }

  if (props.createAccountSuccess) {
    props.resetPaymentCheckoutState();
    props.navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: "Main" },
          {
            name: "BankTransferDetails",
            params: {
              data: props.virtualAccountData,
            },
          },
        ],
      })
    );
  }

  if (props.success) {
    switch (props.type) {
      case "Gig_Order":
        props.resetPaymentCheckoutState();
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: "Main" },
              {
                name: "PaymentResult",
                params: {
                  mode: props.gigDetails.mode,
                  uid: props.gigDetails.to_user,
                  link: props.link,
                },
              },
            ],
          })
        );
        break;
      case "Fund_Milestone":
      case "Fund_Full_Payment":
        {
          props.resetPaymentCheckoutState();
          if (props?.route?.params?.data?.merchant_details) {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: "Main" },
                  {
                    name: "MerchantPaymentResult",
                    params: {
                      mode: "fund_milestone",
                      uid: props.gigDetails.payment_id,
                      merchant_details:
                        props?.route?.params?.data?.merchant_details,
                    },
                  },
                ],
              })
            );
          } else {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: "Main" },
                  {
                    name: "PaymentResult",
                    params: {
                      mode: "fund_milestone",
                      uid: props.gigDetails.payment_id,
                      merchant_details:
                        props?.route?.params?.data?.merchant_details,
                    },
                  },
                ],
              })
            );
          }
        }
        break;
      default:
        break;
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Header title={"Payment Options"} navigation={props.navigation} />
        <View style={{ paddingHorizontal: "8%" }}>
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 18,
              color: "#000",
              marginVertical: 25,
            }}
          >
            Paying {"\u20B9"} {props.route.params.data.amount}
          </Text>
          {/* <UpiOption
            amount={props.route.params.data.amount}
            addUpiPressed={() => {
              setAddUpi(true);
            }}
            saved={savedUpis}
            proceed={(data) => {
              logsService.logCustomEvent("upi_payment_button_clicked", {});
              props.initPaymentCheckout(props.route.params, {
                method: "UPI",
                vpa: data,
              });
            }}
          /> */}
          <View style={{backgroundColor:'#F3F3F3',borderRadius:20,paddingHorizontal:20,paddingVertical:20}}>
            <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#707070',marginBottom:20}}>Recommended method</Text>
            <UpiOption
              amount={props.route.params.data.amount}
              addUpiPressed={() => {
                setAddUpi(true);
              }}
              saved={savedUpis}
              proceed={(data) => {
                logsService.logCustomEvent("upi_payment_button_clicked", {});
                props.initPaymentCheckout(props.route.params, {
                  method: "UPI",
                  vpa: data,
                });
              }}
              showNetBanking = {false} 
              />
          </View>
          <View style={{ height: 40 }}></View>
          {props?.route?.params?.data?.merchant_details?.merchant_platform ==
          "woocommerce" ? null : (
            <BankTransferOption
              amount={props.route.params.data.amount}
              createAccount={() => {
                // if(props.route.params.data.amount>=20000){
                //   logsService.logCustomEvent('smart_collect_button_clicked',{})
                //   props.createVirtualAccount(props.route.params.data)
                // }
              }}
            />
          )}
          <View
            style={{
              height: 40,
              borderBottomColor: "#707070",
              borderBottomWidth: 2,
            }}
          ></View>
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 18,
              color: "#000",
              marginTop: 40,
              marginBottom: 10,
            }}
          >
            Paying{" "}
            {`\u20B9 ${Math.round(
              parseInt(props.route.params.data.amount) +
                (2 / 100) * parseInt(props.route.params.data.amount)
            )}`}
          </Text>
          <Text
            style={{
              fontSize: 10,
              fontFamily: "Roboto-Regular",
              color: "#707070",
              paddingBottom: 25,
            }}
          >
            (includes 2% payment gateway charges)
          </Text>
          <View style={{backgroundColor:'#F3F3F3',borderRadius:20,paddingHorizontal:20,paddingVertical:20,marginBottom:25}}>
            <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#707070',marginBottom:20}}>Other payment methods</Text>
            <CardPayment 
              cardSelected={() => {
                setCardOverlay(true);
              }}
            />
            <NetBanking 
            moreBanksSelected = {()=>{setMoreBanks(true)}}
            netBankingSelected={(data) => {
              logsService.logCustomEvent("netbanking_payment_button_clicked", {
                bankName: data,
              });
              props.initPaymentCheckout(props.route.params, {
                method: "NETBANKING",
                bank: data,
              });
            }}
            />
          </View>
          {/* <OtherPaymentOptions
            moreBanksSelected={() => {
              setMoreBanks(true);
            }}
            cardSelected={() => {
              setCardOverlay(true);
            }}
            bankSelected={(data) => {
              logsService.logCustomEvent("netbanking_payment_button_clicked", {
                bankName: data,
              });
              props.initPaymentCheckout(props.route.params, {
                method: "NETBANKING",
                bank: data,
              });
            }}
            netBankingSelected={(values) => {
              logsService.logCustomEvent("card_payment_button_clicked", {});
              props.initPaymentCheckout(props.route.params, {
                method: "CARD",
                values: values,
              });
            }}
            navigation={props.navigation}
          /> */}
        </View>
      </ScrollView>
      <Modal
        theme={{
          colors: {
            backdrop: "transparent",
          },
        }}
        visible={addUpi}
        contentContainerStyle={{ padding: 20, flex: 1 }}
        onDismiss={() => {
          setAddUpi(false);
        }}
      >
        <AddUpi
          onClose={(data) => {
            setAddUpi(false);
          }}
          onSuccess={(data) => {
            setAddUpi(false);
            logsService.logCustomEvent("upi_payment_button_clicked", {});
            props.initPaymentCheckout(props.route.params, {
              method: "UPI",
              vpa: data,
            });
          }}
        />
      </Modal>
      <Modal
        theme={{
          colors: {
            backdrop: "transparent",
          },
        }}
        visible={moreBanks}
        contentContainerStyle={{ padding: 20, flex: 1 }}
        onDismiss={() => {
          setMoreBanks(false);
        }}
      >
        <MoreBanksOverlay
          comeBack={(data) => {
            setMoreBanks(false);
          }}
          onBankSelected={(data) => {
            logsService.logCustomEvent("netbanking_payment_button_clicked", {
              bankName: data,
            });
            setMoreBanks(false);
            props.initPaymentCheckout(props.route.params, {
              method: "NETBANKING",
              bank: data,
            });
          }}
        />
      </Modal>
      <Modal
        theme={{
          colors: {
            backdrop: "transparent",
          },
        }}
        visible={cardOverlay}
        contentContainerStyle={{ padding: 20, flex: 1 }}
        onDismiss={() => {
          setCardOverlay(false);
        }}
      >
        <CardDetailsOverlay
          comeBack={(data) => {
            setCardOverlay(false);
          }}
          onSuccess={(data) => {
            setCardOverlay(false);
            logsService.logCustomEvent("card_payment_button_clicked", {});
            props.initPaymentCheckout(props.route.params, {
              method: "CARD",
              values: data,
            });
          }}
        />
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: 20,
    color: config.primary_color,
    padding: 20,
  },
  animationSize: {
    width: 300,
    height: 300,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingHorizontal: 20,
  },
});

export default connect((state) => ({ ...state.paymentcheckout }), {
  initPaymentCheckout,
  resetPaymentCheckoutState,
  paymentCompleted,
  createVirtualAccount,
})(PaymentCheckout);
