import { View } from "native-base";
import React, { Component } from "react";

const demo = () => {
    return (
        <View style={{flex:1,backgroundColor:'#FFF',justifyContent:'center',alignItems:'center'}}>
        <a 
        style={{backgroundColor:'#481380',color:'white',textDecoration:'none',padding:10,borderRadius:20}}
        href="upi://pay?pa=8870885588@upi&pn=undefined&am=10.00&cu=INR&mode=02&purpose=00&tn=Dgdjfkfbf" class="upi-pay1">Pay Now !</a>
        </View>
    );
}

export default demo;