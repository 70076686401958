import React, {Component} from 'react'
import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  FlatList,
  TouchableOpacity
} from 'react-native'
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import Header from '../Header';

export default class MoreBanksOverlay extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
}


renderItem = (item, index) => {
    return (
      <TouchableOpacity
          key={index}
          onPress={() => this.props.onBankSelected(item.code)}
          style={styles.dropDownViewTouchable}>
          <Text style={styles.txt4}>{item.name}</Text>
      </TouchableOpacity>
    )
  }


render() {
  return (
    <SafeAreaView style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                    justifyContent: 'flex-end',
                    }}>
                        <View
                        style={{
                            flex:0.84,
                            backgroundColor: '#FFF',
                            borderTopLeftRadius: 14,
                            borderTopRightRadius: 14,
                            paddingTop:10,
                        }}>
                            
                        <View style={{flexDirection:'row',alignItems:'center',marginTop:20,marginLeft:20}}>
                        <TouchableOpacity  onPress={()=>{this.props.comeBack()}}>
                          <AIcon
                            name="left"
                            style={{fontSize: 22, color:this.props?.color ? this.props.color: config.primary_color }}
                          />
                        </TouchableOpacity>
                        <Text style={{color:this.props?.color ? this.props.color: config.primary_color,fontSize:20,fontFamily:'Roboto-Bold',marginLeft:10}}>
                            Select your bank
                        </Text>
                        </View>
                            <FlatList 
                            showsHorizontalScrollIndicator={false}
                            data={[
                              {code:3003 ,name:"Axis Bank"},
                              {code:3005 ,name:"Bank of Baroda - Retail Banking"},
                              {code:3006 ,name:"Bank of India"},
                              {code:3007 ,name:"Bank of Maharashtra"},
                              {code:3009 ,name:"Canara Bank"},
                              {code:3010 ,name:"Catholic Syrian Bank"},
                              {code:3011 ,name:"Central Bank of India"},
                              {code:3012 ,name:"City Union Bank"},
                              // {code:3016 ,name:"Deutsche Bank"},
                              // {code:3017 ,name:"DBS Bank Ltd"},
                              {code:3018 ,name:"DCB Bank-Personal"},
                              {code:3019 ,name:"Dhanlakshmi Bank"},
                              {code:3020 ,name:"Federal Bank"},
                              // {code:3021 ,name:"HDFC Bank"},
                              {code:3022 ,name:"ICICI Bank"},
                              {code:3023 ,name:"IDBI Bank"},
                              {code:3024 ,name:"IDFC FIRST Bank"},
                              {code:3026 ,name:"Indian Bank"},
                              {code:3027 ,name:"Indian Overseas Bank"},
                              {code:3028 ,name:"IndusInd Bank"},
                              {code:3029 ,name:"Jammu and Kashmir Bank"},
                              {code:3030 ,name:"Karnataka Bank Ltd"},
                              {code:3031 ,name:"Karur Vysya Bank"},
                              {code:3032 ,name:"Kotak Mahindra Bank"},
                              {code:3033 ,name:"Laxmi Vilas Bank-Retail Net Banking"},
                              {code:3037 ,name:"Punjab & Sind Bank"},
                              {code:3038 ,name:"Punjab National Bank-Retail Net Banking"},
                              {code:3039 ,name:"RBL Bank"},
                              // {code:3040 ,name:"Saraswat Bank"},
                              {code:3042 ,name:"South Indian Bank"},
                              {code:3043 ,name:"Standard Chartered Bank"},
                              {code:3044 ,name:"State Bank Of India"},
                              // {code:3052 ,name:"Tamilnad Mercantile Bank Ltd"},
                              {code:3054 ,name:"UCO Bank"},
                              {code:3055 ,name:"Union Bank of India"},
                              {code:3058 ,name:"Yes Bank Ltd"},
                              // {code:3060 ,name:"Bank of Baroda -Corporate"},
                              // {code:3061 ,name:"Bank of India -Corporate"},
                              // {code:3062 ,name:"DCB Bank -Corporate"},
                              // {code:3064 ,name:"Lakshmi Vilas Bank -Corporate"},
                              // {code:3065 ,name:"Punjab National Bank -Corporate"},
                              // {code:3066 ,name:"State Bank of India -Corporate"},
                              // {code:3067 ,name:"Union Bank of India -Corporate"},
                              // {code:3071 ,name:"Axis Bank Corporate"},
                              // {code:3072 ,name:"Dhanlaxmi Bank Corporate"},
                              // {code:3073 ,name:"ICICI Corporate Netbanking"},
                              // {code:3074 ,name:"Ratnakar Corporate Banking"},
                              // {code:3075 ,name:"Shamrao Vithal Bank Corporate"},
                              // {code:3076 ,name:"Equitas Small Finance Bank"},
                              // {code:3077 ,name:"Yes Bank Corporate"},
                              // {code:3079 ,name:"Bandhan Bank-Corporate banking"},
                              // {code:3080 ,name:"Barclays Corporate- Corporate Banking -Corporate"},
                              // {code:3081 ,name:"Indian Overseas Bank Corporate"},
                              // {code:3083 ,name:"City Union Bank of Corporate"},
                              // {code:3084 ,name:"HDFC Corporate"},
                              // {code:3086 ,name:"Shivalik Bank"},
                              // {code:3087 ,name:"AU Small Finance"},
                              // {code:3088 ,name:"Bandhan Bank -Retail Net Banking"},
                              // {code:3041 ,name:"Shamrao Vitthal Co-operative Bank"},
                              // {code:3051 ,name:"Tamil Nadu State Co-operative Bank"},
                              // {code:3089 ,name:"Utkarsh Small Finance Bank"},
                              // {code:3090 ,name:"The Surat People’s Co-operative Bank Limited"},
                              // {code:3091 ,name:"Gujarat State Co-operative Bank Limited"},
                              // {code:3092 ,name:"HSBC Retail Netbanking"},
                              // {code:3102 ,name:"Jana Small Finance Bank"},
                              ]}
                            renderItem={({item, index}) => this.renderItem(item, index)}
                            keyExtractor={(item, index) => index.toString()}
                            style={{paddingLeft:55,marginTop:20}}
                            contentContainerStyle={{
                            flexGrow: 1,
                            }}/>
                        </View>
    </SafeAreaView>
  )
};
}

const styles = StyleSheet.create({
    dropDownViewTouchable: {
        paddingTop: 15,
        paddingBottom:20,
        borderBottomWidth: 2,
        borderBottomColor: '#faf6f5',
        marginBottom:5
        // #4a4a4a
      },
    txt4: {
        fontSize: 14,
        lineHeight:21,
        color: '#3f5261',
        fontFamily: 'Roboto-Regular',
      },
})