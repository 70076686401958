import { ADD_GIG_FOR_SALE, ADD_NEW_PORTFOLIO, CHANGE_ACTIVE_TAB, DELETE_FOR_SALE, DELETE_PORTFOLIO, EDIT_FOR_SALE, EDIT_PORTFOLIO, EDIT_USER_PROFILE, LOAD_INITIALL_DATA, SET_FOR_SALE, SET_PORTFOLIO } from "../constants/constants";

export function editProfile(imgData,key,value){
    return{
        type:EDIT_USER_PROFILE,
        imgData:imgData,
        key:key,
        value:value
     }
}

export function changeActiveTab(tab){
    return{
        type:CHANGE_ACTIVE_TAB,
        tab:tab
    }
}

export function addGigForSale(data,milestones,milestone_mode,classification,category,images){
    return{
        type:ADD_GIG_FOR_SALE,
        data:data,
        milestones:milestones,
        milestone_mode:milestone_mode,
        classification:classification,
        category:category,
        images:images
    }
}

export function addNewPortfolio(data,images) {
    return {
        type:ADD_NEW_PORTFOLIO,
        data:data,
        images:images
    }
}

export function loadInitiallProfileData(){
    return{
        type:LOAD_INITIALL_DATA
    }
}


export function editPortFolioAction(data,images,removed_images) {
    return {
        type:EDIT_PORTFOLIO,
        data:data,
        images:images,
        removed_images:removed_images,
    }
}

export function setPortfolio(data) {
    return {
        type:SET_PORTFOLIO,
        data:data,
    }
}

export function editGigForSaleAction(data,milestones,milestone_mode,classification,category,images,removed_images){
    return {
        type:EDIT_FOR_SALE,
        data:data,
        milestones:milestones,
        milestone_mode:milestone_mode,
        classification:classification,
        category:category,
        images:images,
        removed_images:removed_images,
    }
}

export function setForSale(data) {
    return {
        type:SET_FOR_SALE,
        data:data,
    }
}

export function deletePortfolio(id){
    return {
        type:DELETE_PORTFOLIO,
        id:id
    }
}

export function deleteGigForSale(id){
    return {
        type:DELETE_FOR_SALE,
        id:id
    }
}
