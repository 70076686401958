import { LINK_ACCOUNT_DETAILS, LOAD_ACCOUNT } from "../constants/constants";

export function linkAccount(mode,acNumber,ifsc,address,upi,name){
    return{
        type:LINK_ACCOUNT_DETAILS,
        mode:mode,
        acNumber:acNumber,
        ifsc:ifsc,
        address:address,
        upi:upi,
        name:name
    }
}

export function loadAccount(){
    return{
        type:LOAD_ACCOUNT
    }
}