//import liraries
import React, {Component, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  FlatList,
  ActivityIndicator,
} from 'react-native';
import {
  Body,
  Left,
  ListItem,
  Radio,
  Header,
  Button,
  Icon,
  Title,
  Right,
  Item,
  Thumbnail,
} from 'native-base';
import config from '../../config';
import FIcon from 'react-native-vector-icons/Fontisto';
import {connect} from 'react-redux';
import {
  searchContacts,
  initContacts,
} from '../../state-management/actions/searchcontacts.action';
import ContactItem from '../../components/ContactItem';
import AIcon from 'react-native-vector-icons/AntDesign';

const PeopleSearch = props => {
  const [numVal,setNumVal] = useState('')
  useEffect(() => {
    return () => {};
  }, []);

  function renderContact({item, index}) {
    return (
      <ContactItem
        {...props}
        color={`${config.colors[index % config.colors.length]}`}
        thumbnail={false}
        vouched={false}
        name={`${item.name}`}
        mobile={`${item.mobile}`}
        clicked={() =>
          props.navigation.navigate('InviteUser', {
            name: item.name,
            mobile: item.mobile,
          })
        }
      />
    );
  }

  const searchNumber=(value) =>{
    setNumVal(value)
    if(value.length==10){
      props.searchContacts(value)
    }
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      {/* <View style={{marginHorizontal: '8%'}}> */}
        <FlatList
          keyboardShouldPersistTaps="handled"
          data={props.filteredContacts}
          ListHeaderComponent={
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 20,
                }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => props.navigation.goBack()}>
                  <AIcon name="left" style={styles.backButton} />
                </TouchableOpacity>

                {/* ************************  Search Bar ************************* */}

                <Item searchBar style={styles.searchItem}>
                  <Button transparent style={{marginRight: 10, padding: 0}}>
                    <FIcon name="search" style={styles.icon} />
                  </Button>
                  <TextInput
                    placeholder="Enter Mobile Number/ Username"
                    placeholderTextColor={config.textDullColor}
                    autoFocus={true}
                    onChangeText={val => searchNumber(val)}
                    value={numVal}
                    style={{
                      fontSize: 14,
                      color: '#AAAAAA',
                      flex: 1,
                      paddingVertical:12
                    }}
                  />
                </Item>

                {/* ************************  Search Bar End    ************************* */}
              </View>
              
              <View>
                {
              props.isLoading?
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                  <ActivityIndicator size={'large'} color={config.primary_color} />
                </View>:
                !props.searchResult.length?
                  numVal?
                  <ContactItem
                    color={`${config.colors[0]}`}
                    thumbnail={false}
                    vouched={false}
                    name={'New Number'}
                    mobile={numVal}
                    clicked={() =>
                      props.navigation.navigate('InviteUser', {
                        mobile: numVal,
                      })
                    }
                  />:null
                : null}
                {
                  props.searchResult.map((item, index) => {
                    return (
                      <ContactItem
                        color={`${config.colors[index % config.colors.length]}`}
                        thumbnail={false}
                        vouched={true}
                        name={`${item.firstname} ${item.lastname}`}
                        mobile={`${item.mobile}`}
                        clicked={() =>
                          props.navigation.navigate('PaymentDetails', {
                            mobile: item.mobile,
                            name: `${item.firstname} ${item.lastname}`,
                          })
                        }
                      />
                    );
                  })
                  }
                
              </View>
            </View>
          }
          renderItem={renderContact}
          keyExtractor={(item, index) => index.toString()}
          removeClippedSubviews={true}
          initialNumToRender={5}
          maxToRenderPerBatch={10}
          windowSize={10}
        />
      {/* </View> */}
    </SafeAreaView>
  );
};

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    height: 30,
    marginRight: 5,
    fontSize: 25,
  },
  inputContainer: {
    padding: '8%',
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  searchItem: {
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'center',
    flex: 1,
    paddingHorizontal: 15,
    paddingVertical: 0,
    borderRightWidth: 1,
    borderRightColor: '#DCDCDC',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderTopColor: '#DCDCDC',
    borderLeftColor: '#DCDCDC',
    backgroundColor: '#FFF',
    borderRadius: 30,
    elevation: 0,
    alignSelf: 'center',
  },
});

//make this component available to the app
export default connect(
  state => ({contacts: {...state.contacts}, ...state.searchcontacts}),
  {searchContacts, initContacts},
)(PeopleSearch);
