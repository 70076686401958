import React, { useEffect } from 'react'
import {StyleSheet,View,TouchableOpacity,Text,FlatList} from 'react-native'
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../config';
import moment from 'moment';
import { Badge } from 'native-base';

const TimelineComponent = props => {
  return (
    props.data.map((item, index) => {
                return (
                    <View style={{flexDirection:'row'}}>
                        <View style={{alignItems:'center',flex:0.15,height:70}}>
                            {item.color=='#707070'?
                            <View style={{width:19,height:19,borderRadius:50,backgroundColor:'#FFF',borderColor:'#707070',borderRadius:20,borderWidth:2}}></View>
                            :<View style={{width:19,height:19,borderRadius:50,backgroundColor:item.color}}></View>
                            }
                            
                            {item.isDotted?
                            // <Dash style={{width:1, flex:1, flexDirection:'column'}} dashLength={2} dashGap={3} dashThickness={2} dashColor={'#707070'}/>
                            <View style={{flex:1,width:2,backgroundColor:'#fff',borderColor:'#707070',borderLeftWidth:1.9,borderStyle:'dashed'}}></View>
                            :<View style={{flex:1,width:2,backgroundColor:item.strokeColor}}></View>
                            }
                        </View>
                        <View style={{flex:0.85,height:60}}>
                            <Text numberOfLines={2} style={{fontFamily:'OpenSans-SemiBold',color:item.color,fontSize:14}}>{item.message}</Text>
                            {item.date && item.color != '#E89C04'?
                            <Text style={{fontFamily:'OpenSans-Regular',color:'#464646',fontSize:10,marginTop:12}}> 
                                {moment(item.date).format('HH:mm , MMM DD YYYY')}
                            </Text>:null}
                                <View style={{flexDirection:'row', marginTop:12,alignItems:'center'}}>
                                {item.color=='#E89C04'?
                            <Text style={{fontFamily:'OpenSans-Regular',color:'#464646',fontSize:10}}>                                
                            Current status
                            </Text>:null}
                                {item.color=='#E89C04' && item.actionBy==props.mode?
                                <TouchableOpacity onPress={()=>{
                                    if(item.hasOwnProperty('sellerAccountAdded')){
                                        props.routeToMyBank()
                                    }else{
                                        props.scrollBottom()
                                    }
                                    }}>
                                    <Text style={{fontFamily:'OpenSans-Regular',marginLeft:30,padding:5,borderRadius:15,color:'#FFF',fontSize:8,backgroundColor:'#D92E0B'}}>                                
                                        Action Pending
                                    </Text>
                                </TouchableOpacity>
                                :null}
                                </View>
                        </View>
                    </View>
                )
            })
  )
}

const styles = StyleSheet.create({
    backButton: {
        color: '#473BB3',
        fontSize: 22,
        paddingRight: 15,
      },
})

export default TimelineComponent;