import {
  INIT_CHECKOUT_DETAILS,
  RESET_PAYMENT_CHECKOUT_STATE,
  PAYMENT_COMPLETED,
  CHECKOUT_PAYMENT,
  CREATE_VIRTUALL_ACCOUNT,
} from '../constants/constants';

export function initPaymentCheckout(routeData,options) {
  return {
    type: INIT_CHECKOUT_DETAILS,
    data: routeData,
    options:options,
  };
}

export function resetPaymentCheckoutState() {
  return {
    type: RESET_PAYMENT_CHECKOUT_STATE,
  };
}

export function paymentCompleted(paymentData) {
  return {
    type: PAYMENT_COMPLETED,
    data: paymentData,
  };
}

export function processCheckout() {
  return {
    type: CHECKOUT_PAYMENT,
  };
}

export function createVirtualAccount(data) {
  return{
    type: CREATE_VIRTUALL_ACCOUNT,
    data:data
  }
}