import {
  INIT_REQUEST_PAYMENT_STATE,
  REQUEST_PAYMENT_SUCCESS,
  REQUEST_PAYMENT_ERROR,
  MAKE_REQUEST_PAYMENT,
  RESET_REQUEST_PAYMENT_STATE,
} from '../constants/constants'

const initialState = {
  isLoading: true,
  error: '',
  transaction_details: {},
  milestones: [],
  success: false,
  link:''
}

const reducer = function requestPaymentReducer (state = initialState, action) {
  switch (action.type) {
    case INIT_REQUEST_PAYMENT_STATE: {
      return {
        ...state,
        transaction_details: action.transaction_details,
        milestones: action.transaction_details.milestones,
        isLoading: false,
        error: '',
        success: false,
      }
    }
    case MAKE_REQUEST_PAYMENT: {
      return {
        ...state,
        isLoading: true,
        error: '',
        success: false,
      }
    }
    case REQUEST_PAYMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: true,
        link:action.response.link
      }
    }
    case REQUEST_PAYMENT_ERROR: {
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.response.message,
      }
    }
    case RESET_REQUEST_PAYMENT_STATE: {
      return {
        ...state,
        success: false,
      }
    }
    default:
      return state
  }
}

export default reducer
