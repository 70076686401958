import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  FlatList,
  BackHandler,
} from "react-native";
import { Icon, Thumbnail, Textarea, Picker } from "native-base";
import config from "../../config";
import InputText from "../../components/InputText";
import EIcon from "react-native-vector-icons/Entypo";
import MilestoneCard from "../../components/MilestoneCard";
import MileStoneOverlay from "../../components/MilestoneOverlay";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {
  addMileStone,
  deleteMileStone,
  editMileStone,
  initState,
  makeTransaction,
  changeMilestoneMode,
} from "../../state-management/actions/paymentdetail.action";
import PaymentDetailsButton from "../../components/PaymentDetailsButton";
import AIcon from "react-native-vector-icons/AntDesign";
import { Svg, Image } from "react-native-svg";
import AwesomeAlert from "react-native-awesome-alerts";
import { Modal } from "react-native-paper";
import SVG1 from "../../assets/images/paydetails1.svg";
import SVG2 from "../../assets/images/paydetails2.svg";
import AddInvoiceOverlay from "../../components/AddInvoiceOverlay";
class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false,
      addAttachmentVisible: false,
      Attachments: [],
      alertVisible: false,
      amount: "",
      gigName: "",
      requirements: "",
      mode: "add",
      currentMilestone: {},
      totalMilestoneAmount: 0,
      feedbackVisible: false,
      alertText: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.proceedToPayment != prevProps.proceedToPayment) {
      if (this.props.proceedToPayment == true) {
        this.props.navigation.navigate("MakePayment", {
          transaction_details: this.props.transaction_details,
        });
      }
    }
    if (this.props.proceedToGig != prevProps.proceedToGig) {
      if (this.props.proceedToGig == true) {
        this.props.navigation.navigate("RequestPayment", {
          transaction_details: this.props.transaction_details,
        });
      }
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener("blur", e => {
      this.unsubscribeBackHandler.remove();
    });
    this.unsubscribeFocus = this.props.navigation.addListener("focus", e => {
      this.unsubscribeBackHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        () => {
          this.setState({ feedbackVisible: true });
          return true;
        }
      );
    });
    this.props.initState();
    this.setState({
      selected: this.props.category.isServiceSelected
        ? this.props.category.selectedService
        : this.props.category.selectedProduct,
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribeFocus();
    this.unsubscribeBackHandler.remove();
  }

  handleAmountChange = amount => {
    this.setState({ amount });
  };
  handleGigNameChange = gigName => {
    this.setState({ gigName });
  };
  handlerequirements = requirements => {
    this.setState({ requirements });
  };
  makeOverlaytrue = (mode, data, fullAmount) => {
    var totalMilestoneAmount = this.props.milestones.reduce((prev, cur) => {
      return parseFloat(prev) + parseFloat(cur.milestoneAmount);
    }, 0);
    switch (mode) {
      case "add":
        this.setState({
          filterVisible: true,
          mode: "add",
          totalMilestoneAmount: totalMilestoneAmount,
          fullAmount: fullAmount,
        });
        break;
      case "edit":
        this.setState({
          filterVisible: true,
          mode: "edit",
          currentMilestone: data,
          totalMilestoneAmount: totalMilestoneAmount,
          fullAmount: fullAmount,
        });
      default:
        break;
    }
  };
  handleSubmit = values => {
    if (
      values.amount.trim() != "" &&
      values.gigName.trim() != "" &&
      values.requirements.trim() != ""
    ) {
      const { mobile } = this.props.route.params;
      const total_amount = parseFloat(values.amount);
      if (total_amount < 10) {
        // alert('Minimum amount should be 10rs');
        this.setState({ alertVisible: true });
        this.setState({ alertText: "Minimum amount should be \u20B910" });
        return;
      }
      const totalMilestoneAmount = this.props.milestones.reduce((prev, cur) => {
        return parseFloat(prev) + parseFloat(cur.milestoneAmount);
      }, 0);
      const balance = total_amount - totalMilestoneAmount;
      if (this.props.milestones.length) {
        if (balance) {
          // alert(
          //   'Enter additional milestones till the value of all milestones equals order value. You may remove Milestones if this is not a Milestone based payment.',
          // )
          this.setState({ alertVisible: true });
          this.setState({
            alertText:
              "Enter additional milestones till the value of all milestones equals order value. You may remove Milestones if this is not a Milestone based payment.",
          });
          return;
        }
      }
      this.props.makeTransaction(
        this.props.contacts.mode,
        values,
        this.props.milestones,
        this.props.mode,
        this.props.category.isServiceSelected ? "service" : "product",
        mobile,
        this.state.selected,
        this.state.Attachments
      );
    } else {
      alert("Please fill all the fields");
    }
  };
  onValueChange(value) {
    this.setState({
      selected: value,
    });
  }
  render() {
    const { amount, gigName, requirements } = this.state;
    const { name, mobile } = this.props.route.params;
    const validationSchema = yup.object().shape({
      amount: yup
        .string()
        .label("amount")
        .min(2, "Minimum amount should be \u20B910")
        .required("This field is required")
        .trim()
        .matches(/^[0-9-+()]+$/, "This field is not Valid :("),
      gigName: yup.string().label("gigName").required(),
      requirements: yup.string().label("requirements").required(),
    });

    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    function niceBytes(x) {
      let l = 0,
        n = parseInt(x, 10) || 0;

      while (n >= 1024 && ++l) {
        n = n / 1024;
      }

      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
    }

    return (
      <Formik
        initialValues={{
          amount: this.props.category.paymentAmount
            ? this.props.category.paymentAmount
            : "",
          gigName: "",
          requirements: "",
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
            <ScrollView showsVerticalScrollIndicator={false}>
              <View
                style={{
                  borderBottomRightRadius: 40,
                  borderBottomLeftRadius: 40,
                  elevation: 5,
                  backgroundColor: "#fff",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    paddingBottom: 10,
                    backgroundColor: "#fff",
                  }}
                >
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => {
                      this.setState({ feedbackVisible: true });
                    }}
                  >
                    <AIcon name='left' style={styles.backButton} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 25,
                      fontFamily: "Roboto-Medium",
                      color: config.primary_color,
                    }}
                  >
                    I am{" "}
                    {this.props.contacts.mode == "buying"
                      ? "Buying from"
                      : "selling to"}
                  </Text>
                </View>

                {/* ***********************************  Parterner name   ************************************ */}

                <View style={styles.partnerElement}>
                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("UserProfile", {
                        value: this.props.route?.params?.username,
                      });
                    }}
                    style={{
                      backgroundColor: "#B80D57",
                      width: 55,
                      height: 55,
                      justifyContent: "center",
                      borderRadius: 50,
                      alignSelf: "center",
                      borderWidth: 1.5,
                      borderColor: config.primary_color,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Roboto-Bold",
                      }}
                    >
                      {name ? name[0] : "U"}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      this.props.navigation.navigate("UserProfile", {
                        value: this.props.route?.params?.username,
                      });
                    }}
                    style={{ flex: 1, justifyContent: "space-evenly" }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "Roboto-Bold",
                        color: config.primary_color,
                        paddingHorizontal: 10,
                      }}
                    >
                      {name ? name : "undefined"}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: "Roboto-Regular",
                        color: "#707070",
                        paddingHorizontal: 10,
                      }}
                    >
                      {mobile}
                    </Text>
                  </TouchableOpacity>

                  {this.props.contacts.mode == "selling" ? (
                    this.props.route.params.seller_count != null ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          alignSelf: "flex-end",
                          marginBottom: 5,
                        }}
                      >
                        <Icon
                          name='md-star-outline'
                          style={{ color: config.primary_color, fontSize: 25 }}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: config.primary_color,
                            paddingLeft: 5,
                          }}
                        >
                          {Math.round(
                            this.props.route.params.seller_review * 10
                          ) / 10}
                        </Text>
                        <Text
                          style={{
                            fontSize: 12,
                            color: config.primary_color,
                            paddingLeft: 5,
                          }}
                        >
                          ({this.props.route.params.seller_count})
                        </Text>
                      </View>
                    ) : null
                  ) : this.props.route.params.buyer_count != null ? (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        alignSelf: "flex-end",
                        marginBottom: 5,
                      }}
                    >
                      <Icon
                        name='md-star-outline'
                        style={{ color: config.primary_color, fontSize: 25 }}
                      />
                      <Text
                        style={{
                          fontSize: 16,
                          color: config.primary_color,
                          paddingLeft: 5,
                        }}
                      >
                        {Math.round(this.props.route.params.buyer_review * 10) /
                          10}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: config.primary_color,
                          paddingLeft: 5,
                        }}
                      >
                        ({this.props.route.params.buyer_count})
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>

              <View style={styles.inputContainer}>
                {/* *****************************  Select  **************************** */}

                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    fontSize: 16,
                    color: "#464646",
                    paddingLeft: 5,
                  }}
                >
                  Category
                </Text>
                <View
                  style={{
                    width: "100%",
                    // borderColor: '#DCDCDC',
                    // borderWidth: 1,
                    borderRadius: 10,
                    elevation: 0,
                  }}
                >
                  {this.props.category.isServiceSelected ? (
                    <Picker
                      note
                      mode='dropdown'
                      style={{
                        width: "100%",
                        height: 50,
                        borderColor: "#DCDCDC",
                        borderWidth: 0.5,
                        elevation: 0,
                        borderRadius: 10,
                      }}
                      selectedValue={this.state.selected}
                      onValueChange={this.onValueChange.bind(this)}
                    >
                      {this.props.category.services.map(x => {
                        return (
                          <Picker.Item
                            value={x.title}
                            label={`Service - ${x.title}`}
                          />
                        );
                      })}
                    </Picker>
                  ) : (
                    <Picker
                      note
                      mode='dropdown'
                      style={{
                        width: "100%",
                        height: 50,
                        borderColor: "#DCDCDC",
                        borderWidth: 0.5,
                        elevation: 0,
                        borderRadius: 10,
                      }}
                      selectedValue={this.state.selected}
                      onValueChange={this.onValueChange.bind(this)}
                    >
                      {this.props.category.products.map(x => {
                        return (
                          <Picker.Item
                            value={x.title}
                            label={`Product - ${x.title}`}
                          />
                        );
                      })}
                    </Picker>
                  )}
                </View>
                <View style={{ height: 20, width: "95%", paddingTop: 3 }} />

                {/* **********************************  Amount Input *********************************** */}

                <InputText
                  name='amount'
                  label='Amount'
                  placeholder='Full Amount'
                  onChangeText={handleChange("amount")}
                  onBlur={handleBlur("amount")}
                  error={touched.amount && errors.amount}
                  value={values.amount}
                  keyboard='numeric'
                  disabled={this.props.milestones.length ? true : false}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.amount && errors.amount && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.amount}
                    </Text>
                  )}
                </View>

                {/* **********************************  Amount Input  End *********************************** */}

                {/* **********************************  Gig Name Input *********************************** */}

                <InputText
                  name='gigName'
                  label='Name'
                  placeholder='Name your Payment/Gig'
                  onChangeText={handleChange("gigName")}
                  onBlur={handleBlur("gigName")}
                  error={touched.gigName && errors.gigName}
                  value={values.gigName}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.gigName && errors.gigName && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      GIG Name is required
                    </Text>
                  )}
                </View>

                {/* **********************************  Gig Name Input End   *********************************** */}

                {/* **********************************  Requirements Input *********************************** */}

                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    fontSize: 16,
                    color: "#464646",
                    paddingLeft: 5,
                  }}
                >
                  Requirements/Scope of work
                </Text>

                <Textarea
                  rowSpan={7}
                  bordered
                  name='requirements'
                  placeholder={
                    "Please describe what you will be " +
                    (this.props.contacts.mode == "selling"
                      ? "selling to "
                      : "Buying from ") +
                    (this.props.route.params.name
                      ? this.props.route.params.name
                      : "your client")
                  }
                  borderRadius={10}
                  placeholderTextColor={"#AAAAAA"}
                  onChangeText={handleChange("requirements")}
                  onBlur={handleBlur("requirements")}
                  error={touched.requirements && errors.requirements}
                  value={values.requirements}
                  style={{ fontSize: 14, padding: 15 }}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.requirements && errors.requirements && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      Please specify requirements
                    </Text>
                  )}
                </View>
                {this.state.Attachments.length > 0 ? (
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 30,
                    }}
                  >
                    {this.state.Attachments.map((item, index) => {
                      return (
                        <View style={styles.showAttachments}>
                          <Text
                            style={{
                              fontFamily: "Roboto-Regular",
                              fontSize: 16,
                              color: "#464646",
                            }}
                          >
                            {item.name} - {niceBytes(item.size)}
                          </Text>
                          <AIcon
                            name='delete'
                            style={{
                              color: config.primary_color,
                              fontSize: 25,
                            }}
                            onPress={() => {
                              let filterAttachments =
                                this.state.Attachments.filter(
                                  i => i.name !== item.name
                                );
                              this.setState({
                                Attachments: filterAttachments,
                              });
                            }}
                          />
                        </View>
                      );
                    })}
                  </View>
                ) : null}
                {this.state.Attachments.length <= 1 ? (
                  <TouchableOpacity
                    style={styles.addAttachments}
                    onPress={() => {
                      this.setState({ addAttachmentVisible: true });
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto-Regular",
                        fontSize: 16,
                        color: "#464646",
                      }}
                    >
                      Attach contract/Invoice, etc{" "}
                    </Text>
                    <EIcon
                      name='Attachment'
                      style={{ color: config.primary_color, fontSize: 25 }}
                    />
                  </TouchableOpacity>
                ) : null}

                <View style={styles.milestoneContainer}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "5%",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Roboto-Bold",
                        fontSize: 18,
                        color: config.primary_color,
                      }}
                    >
                      Milestone
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Roboto-Regular",
                        fontSize: 14,
                        color: "#828282",
                        marginLeft: 5,
                      }}
                    >
                      (Optional)
                    </Text>
                    {/* <Tooltip 
                    containerStyle={{borderWidth:1,borderColor:'#000'}}
                    height={65} width={160} backgroundColor={'#FFF'} popover={<Text style={{fontFamily: 'OpenSans-Regular',color:config.primary_color}}>You can breakdown your gig into several smaller milestones</Text>}> */}
                    <AIcon
                      name='questioncircleo'
                      style={{
                        fontSize: 16,
                        color: "#707070",
                        marginLeft: 2,
                        marginBottom: 15,
                      }}
                    />
                    {/* </Tooltip> */}
                    <View style={{ flex: 1 }}></View>
                    <View
                      style={{ alignItems: "center", height: 17, flex: 0.2 }}
                    >
                      <TouchableOpacity
                        onPress={() => this.props.changeMilestoneMode()}
                        style={[
                          styles.toggleContainer,
                          this.props.mode == false
                            ? { borderColor: "#707070" }
                            : { borderColor: config.primary_color },
                        ]}
                      >
                        <View
                          style={[
                            styles.default,
                            this.props.mode == false
                              ? {
                                  backgroundColor: "#707070",
                                  flex: 0.65,
                                }
                              : {
                                  // backgroundColor: '#FFF',
                                  flex: 0.35,
                                  borderRadius: 50,
                                },
                          ]}
                        ></View>
                        <View
                          style={[
                            styles.default,
                            this.props.mode == true
                              ? {
                                  backgroundColor: config.primary_color,
                                  flex: 0.65,
                                }
                              : {
                                  // backgroundColor: '#FFF',
                                  flex: 0.35,
                                  borderRadius: 50,
                                },
                          ]}
                        ></View>
                      </TouchableOpacity>
                    </View>
                  </View>

                  {this.props.mode ? (
                    <View>
                      {this.props.milestones.length ? (
                        <FlatList
                          data={this.props.milestones}
                          renderItem={({ item, index }) => {
                            return (
                              <MilestoneCard
                                {...item}
                                onEdit={() =>
                                  this.makeOverlaytrue(
                                    "edit",
                                    item,
                                    parseFloat(values.amount)
                                  )
                                }
                                onDelete={() =>
                                  this.props.deleteMileStone(index)
                                }
                              />
                            );
                          }}
                          keyExtractor={(item, index) => index.toString()}
                        />
                      ) : null}

                      <TouchableOpacity
                        style={styles.addMilestone}
                        onPress={() => {
                          if (
                            values.amount.trim() == "" ||
                            parseFloat(values.amount) <= 0
                          ) {
                            alert(
                              "Please add full amount before adding milestones"
                            );
                            return;
                          }
                          this.makeOverlaytrue(
                            "add",
                            null,
                            parseFloat(values.amount)
                          );
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Roboto-Regular",
                            fontSize: 16,
                            color: "#464646",
                          }}
                        >
                          Add Milestone
                        </Text>
                        <AIcon
                          name='pluscircleo'
                          style={{ color: config.primary_color, fontSize: 25 }}
                        />
                      </TouchableOpacity>

                      <Text
                        style={{
                          fontFamily: "Roboto-Bold",
                          fontSize: 18,
                          color: config.primary_color,
                          textAlign: "center",
                          marginBottom: "3%",
                        }}
                      >
                        Balance Amount: {" \u20B9"}
                        {values.amount -
                          this.props.milestones.reduce((prev, cur) => {
                            return (
                              parseFloat(prev) + parseFloat(cur.milestoneAmount)
                            );
                          }, 0)}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </ScrollView>
            <View>
              <PaymentDetailsButton
                content={"Next"}
                clicked={handleSubmit}
                loading={this.props.isLoading}
              />
            </View>
            <Modal
              visible={this.state.addAttachmentVisible}
              contentContainerStyle={{
                backgroundColor: "white",
                padding: 20,
                flex: 1,
              }}
              onDismiss={() => {
                this.setState({ addAttachmentVisible: false });
              }}
            >
              <AddInvoiceOverlay
                OldAttachments={this.state.Attachments}
                close={() => {
                  this.setState({ addAttachmentVisible: false });
                }}
                OnClose={data => {
                  this.setState({
                    addAttachmentVisible: false,
                    Attachments: data,
                  });
                }}
              />
            </Modal>

            <Modal
              visible={this.state.filterVisible}
              contentContainerStyle={{
                backgroundColor: "white",
                padding: 20,
                flex: 1,
              }}
              onDismiss={() => {
                this.setState({ filterVisible: false });
              }}
            >
              <MileStoneOverlay
                mode={this.state.mode}
                milestones={this.props.milestones}
                data={this.state.currentMilestone}
                totalMilestoneAmount={this.state.totalMilestoneAmount}
                fullAmount={this.state.fullAmount}
                onMilestoneClose={(mode, data, index) => {
                  if (mode == "add") {
                    this.props.addMileStone(data);
                    this.setState({ filterVisible: false });
                  } else if (mode == "edit") {
                    this.props.editMileStone(index, data);
                    this.setState({ filterVisible: false });
                  } else {
                    this.setState({ filterVisible: false });
                  }
                }}
              />
            </Modal>
            <AwesomeAlert
              show={this.state.alertVisible}
              title=''
              message=''
              messageStyle={{ textAlign: "center" }}
              customView={
                <View
                  style={{
                    alignItems: "center",
                    // paddingVertical: 10,
                    lineHeight: 21,
                    paddingHorizontal: 30,
                  }}
                >
                  <SVG1 style={{ marginBottom: 20 }} />
                  <Text
                    style={{
                      fontFamily: "Roboto-Regular",
                      marginTop: 20,
                      fontSize: 16,
                      color: "#707070",
                    }}
                  >
                    Enter additional milestones till the value of all milestones
                    equals order value.
                  </Text>
                </View>
              }
              showCancelButton={false}
              showConfirmButton={true}
              confirmText='Ok'
              confirmButtonColor={config.primary_color}
              onConfirmPressed={() => {
                this.setState({ alertVisible: false });
              }}
            />
            <AwesomeAlert
              show={this.state.feedbackVisible}
              title=''
              message=''
              messageStyle={{ textAlign: "center" }}
              customView={
                <View
                  style={{
                    alignItems: "center",
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}
                >
                  <SVG2 style={{ marginBottom: 20 }} />
                  <Text
                    style={{
                      fontFamily: "Roboto-Regular",
                      fontSize: 16,
                      color: "#707070",
                      lineHeight: 21,
                    }}
                  >
                    The details you entered will be lost. Are you sure you want
                    to exit ?
                  </Text>
                </View>
              }
              showCancelButton={true}
              showConfirmButton={true}
              cancelText='No'
              confirmText='Yes'
              confirmButtonColor={config.primary_color}
              onCancelPressed={() => {
                this.setState({ feedbackVisible: false });
              }}
              onConfirmPressed={() => {
                if(history && history.back){
                  history.back();
                }else{
                  props.navigation.goBack()
                }
              }}
            />
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    height: 30,
    fontSize: 25,
    marginRight: 20,
    marginTop: 5,
  },
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  partnerElement: {
    flexDirection: "row",
    borderColor: "#AAA",
    marginBottom: 10,
    paddingBottom: 12,
    paddingHorizontal: "10%",
  },
  inputContainer: {
    padding: "8%",
    paddingBottom: 0,
  },
  milestoneContainer: {
    borderRadius: 1,
    paddingTop: "3%",
    borderBottomColor: "#707070",
    borderTopColor: "#707070",
    borderStyle: "dashed",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    marginVertical: "3%",
  },
  addAttachments: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: "2%",
    paddingHorizontal: 30,
    width: "90%",
    borderRadius: 50,
    elevation: 3,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignSelf: "center",
    marginBottom: "5%",
    backgroundColor: "#fff",
  },
  showAttachments: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: "2%",
    width: "90%",
  },
  addMilestone: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "90%",
    borderColor: "#AAA",
    paddingVertical: "4%",
    paddingHorizontal: 30,
    borderRadius: 50,
    elevation: 3,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignSelf: "center",
    marginBottom: "5%",
    backgroundColor: "#fff",
  },
  default: {
    paddingVertical: "2%",
    height: 17,
    borderRadius: 100,
  },
  toggleContainer: {
    marginHorizontal: "8%",
    alignItems: "center",
    width: "80%",
    justifyContent: "space-around",
    borderRadius: 50,
    flexDirection: "row",
    backgroundColor: "#FFF",
    height: 17,
    borderWidth: 1,
  },
});

export default connect(
  state => ({
    category: { ...state.category },
    contacts: { ...state.contacts },
    ...state.paymentdetail,
  }),
  {
    addMileStone,
    deleteMileStone,
    editMileStone,
    initState,
    makeTransaction,
    changeMilestoneMode,
  }
)(PaymentDetails);
