// import React, {Component,useEffect,useRef,useState} from 'react'
// import {Dimensions, SafeAreaView} from 'react-native'
// import WebView from 'react-native-webview';

// const VideoWalkThrough = (props) => {
//   const player = useRef(null);


//     return (
//         <SafeAreaView style={{flex: 1, backgroundColor: '#000',justifyContent:'center'}}>
//         <WebView
//         style={{width:Dimensions.get('window').width,backgroundColor:'#000'}}
//         javaScriptEnabled={true}
//         source={{html:`
//         <html>
//         <body style="display:flex;flex-direction:column">
//         <div style="flex:1"></div>
//         <iframe width="100%" height="100%" src=${props.link} frameborder="0" allowfullscreen>
//         </iframe>
//         <div style="flex:1"></div>
//         </body>
//         </html>
//         `}}
// />
//         </SafeAreaView>  
//     );
// }

// export default VideoWalkThrough;

import React, {Component,useEffect,useRef,useState} from 'react'
import {Dimensions, SafeAreaView, TouchableOpacity, View} from 'react-native'
import WebView from 'react-native-webview';
import {AntDesign} from '@expo/vector-icons';

const VideoWalkThrough = (props) => {
    useEffect(()=>{
        // console.log(props.link);
    },[])


    return props.link?(
        <SafeAreaView style={{flex: 1, backgroundColor: '#000',justifyContent:'center'}}>
            <TouchableOpacity 
            onPress={()=>props.onGoBack()}>
              <AntDesign name='left' style={{
                 color: '#FFF',
                 fontSize: 23,
                 paddingRight: 15,
                 paddingTop:15
              }} />
            </TouchableOpacity>
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/eO5zN7Yfjow?modestbranding=0&autohide=0&autoplay=0&showinfo=0&controls=0&rel=0" frameborder="0" allowfullscreen>
            </iframe>
            {/* <TouchableOpacity 
            onPress={()=>props.onGoBack()}
            style={{flex:1}}></TouchableOpacity> */}
        </SafeAreaView>  
    ):null;
}

export default VideoWalkThrough;