//import liraries
import React, {Component, useEffect} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  StatusBar,
  FlatList,
} from 'react-native'
import {connect} from 'react-redux'
import {Icon} from 'native-base'
import config from '../../config'
import {fetchAllNotifications} from '../../state-management/actions/allnotifications.action'
import NotificationsCard from '../../components/NotificationsCard'
import AIcon from 'react-native-vector-icons/AntDesign';
import { setFloatingData , resetFloatingData } from '../../state-management/actions/floating.action'

const AllNotifications = props => {
  useEffect(() => {
    props.resetFloatingData()
    props.fetchAllNotifications()
    return () => {}
  }, [])


  const navigateTo = (item)=>{
      switch (item.data.type) {
        case 'Payment':
          props.navigation.navigate('PaymentUser', {uid: item.data.uid})
          break
        case 'PaymentDetail':
          props.navigation.navigate('GigDetails', {uid: item.data.p_id})
          break
        default:
          break
      }
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <FlatList
      showsVerticalScrollIndicator={false}
        ListHeaderComponent={
          <View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                padding: 20,
              }}>
              <TouchableOpacity
                activeOpacity={0.6}
                onPress={() => { 
                  if(history && history.back ) history.back()
                  else props.navigation.goBack() 
                }}>
                <AIcon name='left' style={styles.backButton} />
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 22,
                  fontFamily: 'Roboto-Medium',
                  color: config.primary_color,
                }}>
                All Notifications
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                borderTopWidth: 1,
                borderTopColor: '#DCDCDC',
                paddingHorizontal: 20,
              }}>
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  fontSize: 14,
                  color: '#464646',
                  textAlign: 'center',
                  paddingVertical: 20,
                }}>
                Here are all the Notifications
              </Text>
            </View>
          </View>
        }
        data={props.allNotifications}
        renderItem={({item}) => {
          return <NotificationsCard {...item} clicked={()=>{navigateTo(item)}}/>
        }}
        keyExtractor={(item, index) => index.toString()}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    fontSize:25,
    height: 30,
    marginRight: 30,
  },
})

export default connect(state => ({...state.allNotificationsReducer}), {
    fetchAllNotifications,
    resetFloatingData
})(AllNotifications)
