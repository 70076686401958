import {takeLatest,select, put, call} from 'redux-saga/effects'
import {
  VERIFY_OTP,
  RE_VERIFY_OTP,
  RE_VERIFY_OTP_ERROR,
  RE_VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  AUTH_SUCCESS,
} from '../constants/constants'
import {setFloatUserId} from '../actions/floating.action'
import firebase from 'firebase/compat/app'
import authService from '../../services/auth.service'
import logsService from '../../services/logs.service'
import { loadProfile } from '../actions/userprofile.action'

const auth = firebase.auth;
const firestore = firebase.firestore;

const executeVerifyOTP = async (confirm, otp, page, data) => {
  try {
    if (!auth().currentUser) {
      const res = await confirm.confirm(otp)
    }
    if (page == 'signup') {
      const uid = auth().currentUser.uid
      const storeUser = await authService.registerUser({
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
        username: data.username,
        email: data.email,
        accountType:data.accountType,
        companyName:data.accountType=='company'?data.companyName:null,
        referral_code:data.referral,
      })
      logsService.logCustomEvent('sign_up',{userId:uid,username: data.username,mobile: data.mobile});
      gtag('event', 'conversion', {'send_to': 'AW-580246236/zucWCMf3sPcCENy115QC'});
    }else{
      logsService.logCustomEvent('login',{userId:firebase.auth().currentUser.uid})
    }
    var currentUserId = await auth().currentUser.uid
    return currentUserId
  } catch (error) {
    // console.log(error);
    throw error
  }
}

function * verifyOtp (action) {
  try {
    const {confirm, otp, page, signupData} = action
    const getItems = state => state.floating
    const currentState = yield select(getItems)
    const res = yield call(executeVerifyOTP, confirm, otp, page, signupData)
    if(currentState.isFloatingPresent && (currentState.data.mode=='Make_Payment' || currentState.data.mode=='Request_Payment'  || currentState.data.mode=='For_Sale_Make_Payment')){
      yield(put(setFloatUserId(res)))
    }
    yield put({
      type: AUTH_SUCCESS,
      response: {intro: true, auth: true},
    })
    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    yield put(loadProfile());
  } catch (error) {
    
    console.log(error, error.code);
    if (error.code && error.code.includes("auth/invalid")) {
      alert("Invalid OTP ! Please Try Again.");
      yield put({
        type: VERIFY_OTP_ERROR,
        response: {message: 'Invalid OTP'}
      })
    } else if (error.code && error.code.includes("auth/session-expired") ) {
      alert("Session Expired. Please Try Again !");
      yield put({
        type: VERIFY_OTP_ERROR,
        response: {
          message: 'Session has been expired , Please click on Resend OTP',
        },
      })
    } else {
      alert("Something went Wrong. Please Try Again !");
      yield put({
        type: VERIFY_OTP_ERROR,
        response: {message: 'Something went wrong,Please try again later'},
      })
    }
  }
}

const executeReVerifyOTP = async mobile => {
  try {
    const confirm = await auth().signInWithPhoneNumber(`+91${mobile}`, window.captchaWeb);
    return {code: 200, confirm: confirm}
  } catch (error) {
    throw error
  }
}

function * reverifyOTP (action) {
  try {
    const res = yield call(executeReVerifyOTP, action.mobile)
    if (res.code == 200) {
      yield put({
        type: RE_VERIFY_OTP_SUCCESS,
        response: {confirm: res.confirm},
      })
    }
  } catch (error) {
    yield put({type: RE_VERIFY_OTP_ERROR, response: {message: error.message}})
  }
}

function * watchOTP () {
  yield takeLatest(VERIFY_OTP, verifyOtp)
  yield takeLatest(RE_VERIFY_OTP, reverifyOTP)
}

export default watchOTP
