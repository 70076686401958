import {
  LOAD_GIG_DETAILS,
  LOAD_GIG_DETAILS_SUCCESS,
  LOAD_GIG_DETAILS_ERROR,
  GIG_ORDER_STATUS,
  GIG_ORDER_STATUS_ERROR,
  GIG_ORDER_STATUS_SUCCESS,
  GIG_MILESTONE_STATUS,
  GIG_MILESTONE_STATUS_ERROR,
  GIG_MILESTONE_STATUS_SUCCESS,
  BUYER_FUND_FULL_AMOUNT,
  BUYER_FUND_FULL_AMOUNT_SUCCESS,
  BUYER_FUND_FULL_AMOUNT_ERROR,
  BUYER_FUND_MILESTONE,
  BUYER_FUND_MILESTONE_SUCCESS,
  BUYER_FUND_MILESTONE_ERROR,
  REDO_UPDATE,
  REDO_UPDATE_ERROR,
  REDO_UPDATE_SUCCESS,
  REFUND_GIG_ORDER,
  REFUND_GIG_ORDER_SUCCESS,
  REFUND_GIG_ORDER_ERROR,
  ADD_REVIEWS,
  ADD_REVIEWS_ERROR,
  ADD_REVIEWS_SUCCESS,
} from '../constants/constants';
import {takeLatest, put, call, delay} from 'redux-saga/effects';
import {
  loadGigDetails,
  refundOrder,
  showAlert,
} from '../actions/gigdetails.action';
import PaymentsService from '../../services/payments.service';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import logsService from '../../services/logs.service';
import {navigate} from '../../navigation/Navigator';

const execLoadGigDetails = async actionData => {
  try {
    const result = await PaymentsService.getGigDetails({
      paymentid: actionData.uid,
    });
    if((firebase.auth().currentUser.uid!=result.gigDetails.request_from) && (firebase.auth().currentUser.uid!=result.gigDetails.to_user)){
      navigate('Main')
      throw {message: 'Something went wrong'};
    }
    return result;
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'};
  }
};

function* loadGigDetailsGen(action) {
  try {
    const res = yield call(execLoadGigDetails, action);

    yield put({
      type: LOAD_GIG_DETAILS_SUCCESS,
      response: {
        gigDetails: res.gigDetails,
        gigMilestoneDetails: res.gigMilestoneDetails,
        clickedUser: res.clickedUser,
        timeline:res.timerArray
      },
    });
  } catch (error) {
    yield put({
      type: LOAD_GIG_DETAILS_ERROR,
      response: {message: error.message},
    });
  }
}

const updateGigOrderStatus = async actionData => {
  try {
    var uid = actionData.uid;
    var status = actionData.status;
    var result = await PaymentsService.updateOrderStatus({
      mode: 'order',
      paymentId: uid,
      status: status,
    });
    logsService.logCustomEvent('order_status',{
      actionBy:firebase.auth().currentUser.uid,
      status: status,
      gigId:uid
    })
    return result.message;
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'};
  }
};
function* changeGigOrderStatus(action) {
  try {
    const res = yield call(updateGigOrderStatus, action);
    yield put({
      type: GIG_ORDER_STATUS_SUCCESS,
      response: {
        message: res,
      },
    });
    if (action.status == 'rejected') {
      yield put(refundOrder(action.uid, 'seller'));
    } else {
      yield put(showAlert());
      yield put(loadGigDetails(action.uid));
    }
  } catch (error) {
    yield put({
      type: GIG_ORDER_STATUS_ERROR,
      response: {message: error.message},
    });
  }
}

const b64toBlob = (base64, type = 'application/octet-stream') => 
  fetch(`${base64}`).then(res => res.blob())

const updateGigMilestoneStatus = async actionData => {
  try {
    const {uid, status, milestones, isLast, pid, details ,imageData} = actionData;
    var image = null
    var formData = new FormData();
    // console.log(imageData);
    if(imageData){
    image = {
      uri: imageData.uri,
      type: imageData.type,
      name: imageData.fileName,
      size: imageData.fileSize,
    };
    logsService.logCustomEvent('milestone_status',{
      actionBy:firebase.auth().currentUser.uid,
      status: status,
      milestoneId:uid,
      gigId:pid,
    })
    const b = await b64toBlob(image.uri);
    // console.log(b);
    formData.append('delivery_attachment', b, imageData.fileName);
  }
  formData.append('mode','milestone')
  formData.append('milestoneId',uid)
  formData.append('paymentId',pid)
  formData.append('status',status)
  formData.append('isLast',isLast)
  formData.append('details',details)
    var result = await PaymentsService.updateOrderStatus(formData);
    return result.message;
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'};
  }
};

function* changeGigMilestoneStatus(action) {
  try {
    const res = yield call(updateGigMilestoneStatus, action);
    yield put({
      type: GIG_MILESTONE_STATUS_SUCCESS,
      response: {
        message: res,
      },
    });
    // yield put(showAlert())
    yield put(loadGigDetails(action.pid));
  } catch (error) {
    // console.log(error)

    yield put({
      type: GIG_MILESTONE_STATUS_ERROR,
      response: {message: error.message},
    });
  }
}

const buyerFundFull = async actionData => {
  // try {
  //   var uid = actionData.uid;
  //   var status = actionData.status;
  //   var q = await paymentRef
  //     .doc(uid)
  //     .update({order_status: status, total_amount_paid: true});
  //   var payments1 = await paymentRef.doc(uid).get();
  //   var gigDetails = payments1.data();
  //   var payments2 = await paymentRef.where('paymentRef', '==', uid).get();
  //   var gigMilestoneDetails = [];
  //   if (payments2.length != 0) {
  //     payments2.docs.forEach(element => {
  //       gigMilestoneDetails.push({uid: element.id, ...element.data()});
  //     });
  //   }

  //   for (var i = 0; i < gigMilestoneDetails.length; i++) {
  //     var q = await milestoneRef
  //       .doc(gigMilestoneDetails[i].uid)
  //       .update({paid: true});
  //   }
  //   return 'Success';
  // } catch (error) {
  //   throw {message: 'Something went wrong'};
  // }
  return
};

function* fundFull(action) {
  try {
    const res = yield call(buyerFundFull, action);
    yield put({
      type: BUYER_FUND_FULL_AMOUNT_SUCCESS,
      response: {
        fundFullMilestoneMessage: res,
      },
    });
    yield put(loadGigDetails(action.uid));
  } catch (error) {
    // console.log(error)
    yield put({
      type: BUYER_FUND_FULL_AMOUNT_ERROR,
      response: {message: error.message},
    });
  }
}

const execFundMilestone = async actionData => {
  // try {
  //   var uid = actionData.uid;
  //   var pid = actionData.pid;
  //   var status = actionData.status;
  //   var q = await milestoneRef.doc(uid).update({paid: true});
  //   var p = await paymentRef.doc(pid).update({order_status: status});
  //   return 'Success';
  // } catch (error) {
  //   throw {message: 'Something went wrong'};
  // }
  return
};

function* fundMilestone(action) {
  try {
    const res = yield call(execFundMilestone, action);
    yield put({
      type: BUYER_FUND_MILESTONE_SUCCESS,
      response: {
        fundMilestoneSuccess: res,
      },
    });
    yield put(loadGigDetails(action.pid));
  } catch (error) {
    // console.log(error)
    yield put({
      type: BUYER_FUND_MILESTONE_ERROR,
      response: {message: error.message},
    });
  }
}

const updateRedoMessage = async actionData => {
  try {
    const uid = actionData.uid;
    var q = await PaymentsService.updateOrderStatus({
      mode: 'redo',
      milestoneId: uid,
      status: actionData.status,
      modifications: actionData.modifications,
      pid:actionData.pid,
    });
    logsService.logCustomEvent('milestone_status',{
      actionBy:firebase.auth().currentUser.uid,
      status: 'requested_modification',
      milestoneId:uid,
      gigId:actionData.pid,
    })
    return 'Requested modifications have been sent successfully and ';
  } catch (error) {
    throw {message: 'Something went wrong'};
  }
};

function* updateRedo(action) {
  try {
    const res = yield call(updateRedoMessage, action);
    yield put({
      type: REDO_UPDATE_SUCCESS,
      response: {
        redoSuccess: res,
      },
    });
    yield put(showAlert());
    yield put(loadGigDetails(action.pid));
  } catch (error) {
    // console.log(error)
    yield put({
      type: REDO_UPDATE_ERROR,
      response: {message: error.message},
    });
  }
}

const execRefund = async actionData => {
  try {
    var pid = actionData.pid;
    var action_by = actionData.action_by;
    var new_order_status;
    var message;
    if (action_by == 'buyer') {
      new_order_status = 'cancelled';
      message =
        'The order has been cancelled and the refund has been initiated ';
    } else if (action_by == 'seller') {
      new_order_status = 'rejected';
      message = 'The order has been cancelled and ';
    }

    var getOrder = await PaymentsService.getGig({id: pid});
    if (
      getOrder.order_status == 'yet_to_confirm' ||
      getOrder.order_status == 'rejected'
    ) {
      const result = await PaymentsService.processRefund({
        pid: pid,
        new_order_status: new_order_status,
      });
      logsService.logCustomEvent('refund',{
        userId:firebase.auth().currentUser.uid,
        paymentId:actionData.pid,
        status:new_order_status
      })
    }
    return message;
  } catch (error) {
    // console.log(error);
    logsService.logCustomEvent('refunds_Error',{
      userId:firebase.auth().currentUser.uid,
      paymentId:actionData.pid,
      description:error?.data?.error?.description
    })
    throw {message: 'Something went wrong'};
  }
};

function* refund(action) {
  try {
    const res = yield call(execRefund, action);
    yield put({
      type: REFUND_GIG_ORDER_SUCCESS,
      response: {
        refundMessage: res,
      },
    });
    yield put(showAlert());
    yield put(loadGigDetails(action.pid));
  } catch (error) {
    // console.log(error)
    yield put({
      type: REFUND_GIG_ORDER_ERROR,
      response: {message: error.message},
    });
  }
}

const execAddreviews = async actionData => {
  try {
    var rev3=(actionData.rev1+actionData.rev2)/2
    var formData = new FormData();
    if(actionData.attachment){
      var ext = actionData.attachment.mime?actionData.attachment.mime.split('/').pop():actionData.attachment.type?actionData.attachment.type.split('/').pop():'png';
      var name = `${new Date().getTime().toString()}.${ext}`
    var image = {
      uri: actionData.attachment.uri,
      type: actionData.attachment.mime?actionData.attachment.mime:actionData.attachment.type,
      name: name,
      size: actionData.attachment.size,
    };
    const b = await b64toBlob(image.uri);
    formData.append('review_attachment', b, image.name);
    formData.append('attachment_type',actionData.attachment_type)
  }
  formData.append('current_user_role',actionData.mode)
  formData.append('toUser',actionData.toUser)
  formData.append('rev1',actionData.rev1)
  formData.append('rev2',actionData.rev2)
  formData.append('rev3',rev3)
  formData.append('complement',actionData.complementSelected)
  formData.append('complement_code',actionData.complementCodeSelected)
  formData.append('text',actionData.text)
  formData.append('paymentid',actionData.pid)
    
    // var result = await PaymentsService.setReview({
    //   current_user_role: actionData.mode,
    //   toUser: actionData.toUser,
    //   rev1: actionData.rev1,
    //   rev2: actionData.rev2,
    //   rev3: rev3,
    //   complement:actionData.complementSelected,
    //   complement_code:actionData.complementCodeSelected,
    //   text: actionData.text,
    //   paymentid: actionData.pid,
    // });
    var result = await PaymentsService.setReview(formData);
    var message = 'Your review has been updated';
    return message;
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'};
  }
};

function* addReview(action) {
  try {
    const res = yield call(execAddreviews, action);
    yield put({
      type: ADD_REVIEWS_SUCCESS,
      response: {
        message: res,
      },
    });
    yield put(showAlert());
    yield put(loadGigDetails(action.pid));
  } catch (error) {
    yield put({
      type: ADD_REVIEWS_ERROR,
      response: {message: error.message},
    });
  }
}

function* watchGigDetails() {
  yield takeLatest(LOAD_GIG_DETAILS, loadGigDetailsGen);
  yield takeLatest(GIG_ORDER_STATUS, changeGigOrderStatus);
  yield takeLatest(GIG_MILESTONE_STATUS, changeGigMilestoneStatus);
  yield takeLatest(BUYER_FUND_FULL_AMOUNT, fundFull);
  yield takeLatest(BUYER_FUND_MILESTONE, fundMilestone);
  yield takeLatest(REDO_UPDATE, updateRedo);
  yield takeLatest(REFUND_GIG_ORDER, refund);
  yield takeLatest(ADD_REVIEWS, addReview);
}

export default watchGigDetails;
