import axios, { AxiosRequestHeaders } from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import config from "../config";

const apiService = async ({ method, endpoint, params }) => {
  try {
    const user = await firebase.auth().currentUser;

    const token = user ? await user.getIdToken(false) : null;
    var headers = {
      "Content-Type": "application/json",
    };
    if (user && token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const result = await axios({
      method,
      url: `${config.node_api_endpoint}${endpoint}`,
      data: params,
      headers,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default apiService;
