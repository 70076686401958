import {SEARCH_CONTACTS, INIT_CONTACTS} from '../constants/constants'

export function searchContacts (searchTerm) {
  return {
    type: SEARCH_CONTACTS,
    searchTerm: searchTerm,
  }
}

export function initContacts (vouchUsers, phoneContacts) {
  return {
    type: INIT_CONTACTS,
    vouchUsers: vouchUsers,
    phoneContacts: phoneContacts,
  }
}
