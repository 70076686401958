import React, {Component} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import config from '../../config';
import {Icon, Textarea} from 'native-base';
import InputText from '../InputText';
import PrimaryButton from '../PrimaryButton';
import {Formik} from 'formik';
import * as yup from 'yup';
import AIcon from 'react-native-vector-icons/AntDesign';

export default class LinkBankAccountOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmAccountNumber: '',
      alertVisible: false,
    };
  }

  componentDidMount() {}

  handleConfirmAccountNumber = confirmAccountNumber => {
    this.setState({confirmAccountNumber});
  };
  handleSubmit = values => {
    if (values.confirmAccountNumber.trim() != '') {
      if (values.confirmAccountNumber.trim() == this.props.values.acNumber) {
        this.props.onClose('success');
      } else {
        // alert('this is not matching with waht u gave in the previous slide')
        this.setState({alertVisible: true});
      }
    } else {
      alert('please enter proper values');
    }
  };

  render() {
    var {confirmAccountNumber} = this.state;
    const validationSchema = yup.object().shape({
      confirmAccountNumber: yup
        .string()
        .label('confirmAccountNumber')
        .required()
        .oneOf(
          [this.props.values.acNumber, null],
          "Account number didn't match",
        ),
    });
    return (
      <Formik
        initialValues={{
          confirmAccountNumber: confirmAccountNumber,
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <View style={{flex: 1}}>
            {/* <View style={[{alignItems: 'center',position:'absolute',zIndex:100,justifyContent:'center',width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.7)'},{zIndex:this.state.alertVisible?100:0}]}>
              <View style={{backgroundColor: '#FFF',borderRadius:10,alignItems:'center',paddingTop:30,paddingVertical:20,lineHeight:21,paddingHorizontal:30,marginHorizontal:"5%"}}>
                  <Text style={{fontFamily:'Roboto-Regular',fontSize:16,color:'#707070'}}>
                  The Account Number is not Matching
                  </Text>
                  <View style={{paddingTop:20,alignSelf:'flex-end'}}>
                  <TouchableOpacity onPress={()=>{this.setState({alertVisible:false})}} style={{borderRadius:20,paddingVertical:5,margin:10,paddingHorizontal:35,backgroundColor:config.primary_color}}>
                    <Text style={{color:'#FFF',fontSize:15,fontFamily:'Roboto-Regular'}}>OK</Text>
                  </TouchableOpacity>
                  </View>
              </View>
          </View> */}
            <SafeAreaView
              style={{
                flex: 1,
                backgroundColor: '#707070',
                justifyContent: 'flex-end',
              }}>
              <View
                style={{
                  backgroundColor: '#FFF',
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: 20,
                  }}>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => {
                      this.props.onClose();
                    }}>
                    <AIcon name="left" style={styles.backButton} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: 'Roboto-Medium',
                      color: config.primary_color,
                    }}>
                    Re enter Bank A/C
                  </Text>
                </View>

                <View style={styles.inputContainer}>
                  {/* **********************************  Amount Input *********************************** */}
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: 'Roboto-Regular',
                      color: '#464646',
                      marginTop: 20,
                      marginBottom: 10,
                    }}>
                    {this.props.values.accountHolderName}
                  </Text>
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: 'Roboto-Regular',
                      color: '#464646',
                      marginBottom: 20,
                    }}>
                    IFSC : {this.props.values.ifsc}
                  </Text>

                  <InputText
                    name="confirmAccountNumber"
                    label=" A/c No"
                    placeholder="Re enter Bank account number"
                    onChangeText={handleChange('confirmAccountNumber')}
                    onBlur={handleBlur('confirmAccountNumber')}
                    error={
                      touched.confirmAccountNumber &&
                      errors.confirmAccountNumber
                    }
                    value={values.confirmAccountNumber}
                  />
                  <View style={{height: 20, width: '95%', paddingTop: 3}}>
                    {touched.confirmAccountNumber &&
                      errors.confirmAccountNumber && (
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: 'right',
                            fontFamily: 'Roboto-Regular',
                            color: '#F90000',
                          }}>
                          Please Enter Proper Account Number.
                        </Text>
                      )}
                  </View>

                  <PrimaryButton content={'confirm'} clicked={handleSubmit} />
                </View>
              </View>
            </SafeAreaView>
          </View>
        )}
      </Formik>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginTop: 5,
    marginRight: 20,
    fontSize: 25,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    paddingHorizontal: '8%',
    paddingBottom: '10%',
  },
});
