//import liraries
import React, {Component} from 'react'
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native'
import config from '../config'
import PrimaryButton from './AnimatedButton'
class GigMilestoneCard extends Component {
  componentDidMount(){
    // console.log(this.props?.previous_paid);
  }
  render () {
    return (
      <View>
      <View style={styles.container}>
        <Text
          style={{
            fontFamily: 'Roboto-Bold',
            fontSize: 22,
            color: '#000',
            marginHorizontal: 5,
          }}>
          {this.props.index}
        </Text>
        <View style={{paddingHorizontal: '3%', flex: 1}}>
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 16,
              color: '#707070',
              marginVertical: 4,
              width: '80%',
            }}>
            {this.props.details}
          </Text>
          {this.props.gigDetails.mode == 'buying' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          this.props.milestone_status == 'delivered' ?(
            <View style={styles.buttonsContainer}>

          <PrimaryButton
                content={'View Delivery Details'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={() => {
                  this.props.showDeliveredDetails()
                }}
              />
              </View>
              ):null}
                    
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 14,
              color: config.primary_color,
              marginTop: 10,
              width: '80%',
              textTransform:'capitalize'
            }}>
            {this.props.milestone_status=='yet_to_start'?
                    'Yet To Start Work'
                    :this.props.milestone_status=='started'?
                    'Milestone Started'
                    :this.props.milestone_status=='delivered'?
                    'Milestone Deliverd'
                    :this.props.milestone_status=='requested_modification'?
                    'Modification Requested'
                    :this.props.milestone_status=='cancelled'?
                    'Cancelled'
                    :this.props.milestone_status=='rejected'?
                    'Rejected'
                    :this.props.milestone_status=='completed'?
                    'Milestone Completed':null
                    }
          </Text>

   
          {/* ***************************************** WHOLE BUNCH OF CONDITIONS *********************************************** */}

   
          {this.props.gigDetails.mode == 'selling' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          this.props.milestone_status == 'yet_to_start' &&
          (this.props.paid || this.props.gigDetails.total_amount_paid) ? (
            <View style={styles.buttonsContainer}>
              <PrimaryButton
                content={'Start'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={() => {
                  this.props.onActionCalled('started')
                }}
              />
            </View>
          ) : null}
          {this.props.gigDetails.mode == 'selling' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          (this.props.milestone_status == 'started' ||
            this.props.milestone_status == 'requested_modification') ? (
              <View>
              <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 14,
                color: '#707070',
                marginVertical: 4,
                width: '80%',
              }}>
              Modifications :{this.props.modifications}
            </Text>
            <View style={styles.buttonsContainer}>
              <PrimaryButton
                content={'Mark as Complete'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={() => {
                  this.props.onDeliveredCalled('delivered')
                }}
              />
            </View>
            </View>
          ) : null}
          {this.props.gigDetails.mode == 'buying' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          this.props.milestone_status == 'delivered' ?(
            <View>
            <View style={styles.buttonsContainer}>
              <PrimaryButton
                content={this.props.gigDetails?.classification=='product'?'Replace':'Redo'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={() => {
                  this.props.redoMilestone('requested_modification')
                }}
              />
              <PrimaryButton
                content={'Accept'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={() => {
                  this.props.onCompleteCalled('completed')
                }}
              />
            </View>
            <Text style={{color:'#D92E0B',fontFamily: 'Roboto-Regular',fontSize:12,lineHeight:16,textAlign:'center',marginTop:10}}>You have 3 days to request a change or accept the order, after 3 days your order will be marked as accepted automatically</Text>
            </View>
          ) : null}
          {this.props.gigDetails.mode == 'buying' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          this.props.milestone_status == 'yet_to_start' &&
          this.props.paid == false &&
          this.props?.previous_paid == true &&
          this.props.gigDetails.total_amount_paid == false ? (
            <View style={styles.buttonsContainer}>
              <PrimaryButton
                content={'Fund Milestone'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={() => {
              window.alert("Sorry for the inconvenience! We're working behind the scenes to make things better.We'll be back shortly. Thank you for your patience!")
                  // this.props.fund('confirmed')
                }}
              />
            </View>
          ) : null}
          {/* ***************************************** WHOLE BUNCH OF CONDITIONS END*********************************************** */}
        </View>
        <Text
          style={{
            fontFamily: 'Roboto-Medium',
            fontSize: 16,
            color: '#707070',
            marginVertical: 4,
            textAlign: 'right',
          }}>
          {' \u20B9'} {this.props.milestoneAmount}
        </Text>
      </View>
      {this.props.gigDetails.mode == 'selling' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          this.props.milestone_status == 'yet_to_start' &&
          (this.props.paid || this.props.gigDetails.total_amount_paid) ? (
            <View style={{width:'50%',alignSelf:'center',paddingVertical:5,backgroundColor:'#D92E0B',borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
            <Text style={{fontFamily: 'OpenSans-SemiBold',color:'#FFF',textAlign:'center',fontSize:12}}>Action pending</Text>
          </View>
          ):null}
          {this.props.gigDetails.mode == 'selling' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          (this.props.milestone_status == 'started' ||
            this.props.milestone_status == 'requested_modification') ? (
            <View style={{width:'50%',alignSelf:'center',paddingVertical:5,backgroundColor:'#D92E0B',borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
            <Text style={{fontFamily: 'OpenSans-SemiBold',color:'#FFF',textAlign:'center',fontSize:12}}>Action pending</Text>
          </View>):null}
          {this.props.gigDetails.mode == 'buying' &&
          this.props.gigDetails.order_status == 'confirmed' &&
          this.props.milestone_status == 'delivered' ?(
            <View style={{width:'50%',alignSelf:'center',paddingVertical:5,backgroundColor:'#D92E0B',borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
            <Text style={{fontFamily: 'OpenSans-SemiBold',color:'#FFF',textAlign:'center',fontSize:12}}>Action pending</Text>
          </View>):null}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  cardButton: {
    borderRadius: 20,
    paddingHorizontal: 15,
    paddingVertical: 4,
    backgroundColor: config.primary_color,
    marginRight: 5,
    marginTop: 20,
  },
  buttonsContainer: {
    marginBottom: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    backgroundColor: '#F2F2F2',
    padding: '5%',
    borderRadius: 10,
    elevation: 5,
    marginTop: '6%',
    shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,
  },
})

export default GigMilestoneCard
