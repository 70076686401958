import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native'
import config from '../config'
export default class RejectedButton extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <TouchableOpacity
        disabled={this.props.loading || this.props.disabled ? true : false}
        style={styles.button}
        onPress={this.props.clicked}>
        {this.props.loading ? (
          <ActivityIndicator size='small' color='#fff' />
        ) : (
          <Text
            style={{
              color: config.primary_color,
              fontSize: 18,
              fontFamily: 'Roboto-Regular',
            }}>
            {' '}
            {this.props.content}
          </Text>
        )}
      </TouchableOpacity>
    )
  }
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "#FFF",
    padding: 10,
    borderRadius: 25,
    marginTop: 25,
    zIndex: 100,
    borderColor:config.primary_color,
    borderWidth:2
  },
})
