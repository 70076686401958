import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  BackHandler,
  Alert,
} from 'react-native';
import {Icon, Textarea, Picker} from 'native-base';
import config from '../../config';
import InputText from '../../components/InputText';
import {Formik} from 'formik';
import * as yup from 'yup';
import {connect} from 'react-redux';
import PaymentDetailsButton from '../../components/PaymentDetailsButton';
import AIcon from 'react-native-vector-icons/AntDesign';
import SVG2 from '../../assets/images/paydetails2.svg';
import { resetContactInfo } from '../../state-management/actions/category.action'
import { requestRecommendation,initRecommendation } from '../../state-management/actions/recommendation.action'
import LottieView from 'lottie-react-web'
import TickAnim from '../../assets/lottie-animations/tick-anim.json'
import {CommonActions} from '@react-navigation/native'
import AwesomeAlert from 'react-native-awesome-alerts';


class RecommendationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false,
      alertVisible: false,
      amount: '',
      gigName: '',
      requirements: '',
      feedbackVisible: false,
      alertText: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isSuccess != prevProps.isSuccess) {
      if (this.props.isSuccess == true) {
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {name: 'Main'},
              {
                name: 'PaymentResult',
                params: {
                  mode: 'recommendation',
                  link:this.props.link
                },
              },
            ],
          }),
        )
      }
    }
    if (this.props.error != prevProps.error) {
      if (this.props.error != '') {
        alert(this.props.error)
      }
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.navigation.addListener('blur', e => {
      this.unsubscribeBackHandler.remove();
    });
    this.unsubscribeFocus = this.props.navigation.addListener('focus', e => {
      this.unsubscribeBackHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          this.setState({feedbackVisible: true});
          return true;
        },
      );
    });
    this.props.initRecommendation();
    this.setState({
      selected: this.props.category.isServiceSelected
        ? this.props.category.selectedService
        : this.props.category.selectedProduct,
    });
  }

  componentWillUnmount() {
    this.props.resetContactInfo()
    this.unsubscribe();
    this.unsubscribeFocus();
    this.unsubscribeBackHandler.remove();
  }

  handleAmountChange = amount => {
    this.setState({amount});
  };
  handleGigNameChange = gigName => {
    this.setState({gigName});
  };
  handlerequirements = requirements => {
    this.setState({requirements});
  };

  handleSubmit = values => {
    if (values.amount.trim() != '' && values.gigName.trim() != '' && values.requirements.trim() != '' ) {
      const {mobile} = this.props.route.params;
      this.props.requestRecommendation(
        this.props.contacts.mode,
        values,
        this.props.category.isServiceSelected?'service':'product',
        mobile,
        this.state.selected,)
    } else {
      alert('Please fill all the fields');
    }
  };

  onValueChange(value) {
    this.setState({
      selected: value,
    });
  }
  render() {
    const {amount, gigName, requirements} = this.state;
    const {name, mobile} = this.props.route.params;
    const validationSchema = yup.object().shape({
      amount: yup
        .string()
        .label('amount')
        .min(2, 'Minimum amount should be \u20B910')
        .required('This field is required')
        .trim()
        .matches(/^[0-9-+()]+$/, 'This field is not Valid :('),
      gigName: yup
        .string()
        .label('gigName')
        .required(),
      requirements: yup
        .string()
        .label('requirements')
        .required(),
      message:yup
      .string()
      .label('message')
      .required('This field is required')
    });

    return (
      <Formik
        initialValues={{
          amount: this.props.category.paymentAmount?this.props.category.paymentAmount:'',
          gigName: '',
          requirements: '',
          message:''
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
              <View
                style={{
                  borderBottomRightRadius: 40,
                  borderBottomLeftRadius: 40,
                  elevation: 5,
                  backgroundColor: '#fff',
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    paddingBottom: 10,
                    backgroundColor: '#fff',
                  }}>
                  <TouchableOpacity
                    activeOpacity={0.6}
                    onPress={() => this.setState({feedbackVisible: true})}>
                    <AIcon name="left" style={styles.backButton} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 25,
                      fontFamily: 'Roboto-Medium',
                      color: config.primary_color,
                    }}>
                    {this.props.contacts.mode == 'buying'
                      ? 'Purchased from'
                      : 'Sold to'}
                  </Text>
                </View>

                {/* ***********************************  Parterner name   ************************************ */}

                <View style={styles.partnerElement}>
                  <TouchableOpacity
                  onPress={()=>{this.props.navigation.navigate('UserProfile',{key:'mobile',value:mobile})}}
                    style={{
                      backgroundColor: '#B80D57',
                      width: 55,
                      height: 55,
                      justifyContent: 'center',
                      borderRadius: 50,
                      alignSelf: 'center',
                      borderWidth: 1.5,
                      borderColor: config.primary_color,
                    }}>
                    <Text
                      style={{
                        fontSize: 20,
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Roboto-Bold',
                      }}>
                      {name[0]}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                  onPress={()=>{this.props.navigation.navigate('UserProfile',{key:'mobile',value:mobile})}}
                  style={{flex: 1, justifyContent: 'space-evenly'}}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Bold',
                        color: config.primary_color,
                        paddingHorizontal: 10,
                      }}>
                      {name}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Regular',
                        color: '#707070',
                        paddingHorizontal: 10,
                      }}>
                      {mobile}
                    </Text>
                  </TouchableOpacity>
                  {this.props.contacts.mode == 'selling' ? (
                    this.props.route.params.seller_count != null ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          alignSelf: 'flex-end',
                          marginBottom: 5,
                        }}>
                        <Icon
                          name="md-star-outline"
                          style={{color: config.primary_color, fontSize: 25}}
                        />
                        <Text
                          style={{
                            fontSize: 16,
                            color: config.primary_color,
                            paddingLeft: 5,
                          }}>
                          {Math.round(
                            this.props.route.params.seller_review * 10,
                          ) / 10}
                        </Text>
                        <Text
                          style={{
                            fontSize: 12,
                            color: config.primary_color,
                            paddingLeft: 5,
                          }}>
                          ({this.props.route.params.seller_count})
                        </Text>
                      </View>
                    ) : null
                  ) : this.props.route.params.buyer_count != null ? (
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'flex-end',
                        marginBottom: 5,
                      }}>
                      <Icon
                        name="md-star-outline"
                        style={{color: config.primary_color, fontSize: 25}}
                      />
                      <Text
                        style={{
                          fontSize: 16,
                          color: config.primary_color,
                          paddingLeft: 5,
                        }}>
                        {Math.round(this.props.route.params.buyer_review * 10) /
                          10}
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          color: config.primary_color,
                          paddingLeft: 5,
                        }}>
                        ({this.props.route.params.buyer_count})
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>

              <View style={styles.inputContainer}>
                {/* *****************************  Select  **************************** */}
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Category
                </Text>
                <View
                  style={{
                    width: '100%',
                    borderColor: '#DCDCDC',
                    borderWidth: 0.8,
                    borderRadius: 10,
                  }}>
                  {this.props.category.isServiceSelected ? (
                    <Picker
                      note
                      mode="dropdown"
                      style={{
                        width: '100%',
                        height:50,
                        borderColor: '#DCDCDC',
                        borderWidth: 0.5,
                        elevation: 0,
                        borderRadius: 10,
                      }}
                      selectedValue={this.state.selected}
                      onValueChange={this.onValueChange.bind(this)}>
                      {this.props.category.services.map(x => {
                        return (
                          <Picker.Item
                            value={x.title}
                            label={`Service - ${x.title}`}
                          />
                        );
                      })}
                    </Picker>
                  ) : (
                    <Picker
                      note
                      mode="dropdown"
                      style={{
                        width: '100%',
                        borderColor: '#AAA',
                        borderWidth: 0.5,
                        elevation: 0,
                      }}
                      selectedValue={this.state.selected}
                      onValueChange={this.onValueChange.bind(this)}>
                      {this.props.category.products.map(x => {
                        return (
                          <Picker.Item
                            value={x.title}
                            label={`Product - ${x.title}`}
                          />
                        );
                      })}
                    </Picker>
                  )}
                </View>
                <View style={{height: 20, width: '95%', paddingTop: 3}} />

                {/* **********************************  Amount Input *********************************** */}

                <InputText
                  name="amount"
                  label="Amount"
                  placeholder="Full Amount"
                  onChangeText={handleChange('amount')}
                  onBlur={handleBlur('amount')}
                  error={touched.amount && errors.amount}
                  value={values.amount}
                  keyboard="numeric"
                  disabled={false}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.amount && errors.amount && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      {errors.amount}
                    </Text>
                  )}
                </View>

                {/* **********************************  Amount Input  End *********************************** */}

                {/* **********************************  Gig Name Input *********************************** */}

                <InputText
                  name="gigName"
                  label="Name"
                  placeholder="Name your Payment/Gig"
                  onChangeText={handleChange('gigName')}
                  onBlur={handleBlur('gigName')}
                  error={touched.gigName && errors.gigName}
                  value={values.gigName}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.gigName && errors.gigName && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      GIG Name is required
                    </Text>
                  )}
                </View>

                {/* **********************************  Gig Name Input End   *********************************** */}

                {/* **********************************  Requirements Input *********************************** */}

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                    Details of the transaction
                </Text>

                <Textarea
                  rowSpan={7}
                  bordered
                  name="requirements"
                  placeholder={
                    'Please describe what you have ' +
                    (this.props.contacts.mode == 'selling'
                      ? 'solt to '
                      : 'purchased from ') +
                    (this.props.route.params.name
                      ? this.props.route.params.name
                      : 'your client')
                  }
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('requirements')}
                  onBlur={handleBlur('requirements')}
                  error={touched.requirements && errors.requirements}
                  value={values.requirements}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.requirements && errors.requirements && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify requirements
                    </Text>
                  )}
                </View>

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Personal message
                </Text>

                <Textarea
                  rowSpan={7}
                  bordered
                  name="message"
                  placeholder={`Type your personal message to person your are requesting message from`}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('message')}
                  onBlur={handleBlur('message')}
                  error={touched.message && errors.message}
                  value={values.message}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 30, width: '95%', paddingTop: 3}}>
                  {touched.message && errors.message && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify message
                    </Text>
                  )}
                </View>
              </View>
            </ScrollView>
            <View>
              <PaymentDetailsButton
                content={'Send Request'}
                clicked={handleSubmit}
                loading={this.props.isLoading}
              />
            </View>
            <AwesomeAlert
                show={this.state.feedbackVisible}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                  style={{
                    alignItems: 'center',
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}>
                  <SVG2 style={{marginBottom: 20}} />
                  <Text
                    style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#707070',
                      lineHeight: 21,
                    }}>
                    The details you entered will be lost. Are you sure you want
                    to exit ?
                  </Text>
                </View>
        )}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No'
        confirmText="Yes"
        confirmButtonColor={config.primary_color}
        onCancelPressed={()=>{this.setState({feedbackVisible: false});}}
        onConfirmPressed={() =>{
          this.props.navigation.goBack();
        }}
      />
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    height: 30,
    fontSize: 25,
    marginRight: 20,
    marginTop: 5,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  partnerElement: {
    flexDirection: 'row',
    borderColor: '#AAA',
    marginBottom: 10,
    paddingBottom: 12,
    paddingHorizontal: '10%',
  },
  inputContainer: {
    padding: '8%',
    paddingBottom: 0,
  },
  default: {
    paddingVertical: '2%',
    height:17,
    borderRadius:100
  },
});

export default connect(
  state => ({
    category: {...state.category},
    contacts: {...state.contacts},
    ...state.recommendations,
}),
  {resetContactInfo,requestRecommendation,initRecommendation},
)(RecommendationDetails)