import {SERVICE_SELECTED, PRODUCT_SELECTED, RESET, LOAD_CATEGORIES, SET_PAYMENT_AMOUNT, SET_CONTACT_INFO, RESET_CONTACT_INFO} from '../constants/constants'

export function loadObjects(){
    return {
        type:LOAD_CATEGORIES
    }
}

export function serviceSelected(service){
    return {
        type:SERVICE_SELECTED,
        service:service
    }
}

export function productSelected(product){
    return {
        type:PRODUCT_SELECTED,
        product:product
    }
}

export function setPaymentAmount(amount){
    return {
        type:SET_PAYMENT_AMOUNT,
        amount:amount
    }
}


export function reset(){
    return {
        type:RESET
    }
}

export function setContactInfo(name,number,username,s_count,s_review,b_count,b_review){
    return {
        type:SET_CONTACT_INFO,
        name:name,
        number:number,
        username:username,
        seller_count:s_count,
        seller_review:s_review,
        buyer_count:b_count,
        buyer_review:b_review,
    }
}

export function resetContactInfo(){
    return {
        type:RESET_CONTACT_INFO
    }
}