import {takeLatest, put, call} from 'redux-saga/effects'
import {
  REQUEST_RECOMMENDATIONS,
  REQUEST_RECOMMENDATIONS_SUCCESS,
  REQUEST_RECOMMENDATIONS_ERROR,
  FETCH_RECOMMENDATIONS,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_ERROR,
  SUBMIT_RECOMMENDATIONS,
  SUBMIT_RECOMMENDATIONS_SUCCESS,
  SUBMIT_RECOMMENDATIONS_ERROR,
} from '../constants/constants'
import userService from '../../services/user.service'
import miscService from '../../services/misc.service'
import firebase from 'firebase/compat/app'
import "firebase/auth";
import "firebase/firestore";

const execAskForRecommendation = async actionData => {
  try {
    const {data, mode, request_to_mobile, category,classification} = actionData
    const {amount, gigName, requirements,message} = data
    var request_to_user = await userService.getUser({
      key:'mobile',
      value:request_to_mobile
    })
    if (!request_to_user) {
      throw {message: 'User not found'}
    }
    /* -------------------- Getting request to user details / end ------------------- */

    /* ---------------------- Constructing the transaction ---------------------- */
    var currentUserId = await firebase.auth().currentUser?.uid
    var recommendation_details = {}
    recommendation_details.request_from = currentUserId?currentUserId:null,
    recommendation_details.mode = mode
    recommendation_details.to_user = request_to_user.id
    recommendation_details.gig_name = gigName
    recommendation_details.transaction_details = requirements
    recommendation_details.personal_message = message
    recommendation_details.category = category
    recommendation_details.total_amount = amount
    recommendation_details.classification = classification
    var result = await miscService.requestRecommendationsService({recommendation_details:recommendation_details})
    return result
  } catch (error) {
    // console.log(error);
    throw error
  }
}

function * askForRecommendation (action) {
  try {
    const res = yield call(execAskForRecommendation, action)
    yield put({
      type: REQUEST_RECOMMENDATIONS_SUCCESS,
      response: res,
    })
  } catch (error) {
    yield put({type: REQUEST_RECOMMENDATIONS_ERROR, response: {message: error.message}})
  }
}

const execFetchRecommendation = async actionData => {
  try {
    var result = await miscService.fetchRecommendationsService({id:actionData.id})
    return result
  } catch (error) {
    // console.log(error);
    throw error
  }
}

function * fetchRecommendation (action) {
  try {
    const res = yield call(execFetchRecommendation, action)
    yield put({
      type: FETCH_RECOMMENDATIONS_SUCCESS,
      response: res,
    })
  }catch(error){
    yield put({type: FETCH_RECOMMENDATIONS_ERROR, response: {message: error.message}})
  }
}

const execSubmitRecommendation = async actionData => {
  try {
    var result = await miscService.submitRecommendationsService({id:actionData.id,recommendation_text:actionData.data})
    return result
  } catch (error) {
    // console.log(error);
    throw error
  }
}

function * submitRecommendation (action) {
  try {
    const res = yield call(execSubmitRecommendation, action)
    yield put({
      type: SUBMIT_RECOMMENDATIONS_SUCCESS,
      response: res,
    })
  }catch(error){
    yield put({type: SUBMIT_RECOMMENDATIONS_ERROR, response: {message: error.message}})
  }
}

function * recommendationsWatcher () {
  yield takeLatest(REQUEST_RECOMMENDATIONS, askForRecommendation)
  yield takeLatest(FETCH_RECOMMENDATIONS, fetchRecommendation)
  yield takeLatest(SUBMIT_RECOMMENDATIONS, submitRecommendation)
}

export default recommendationsWatcher
