import React, { useEffect,useState } from 'react';
import {View, Text, Modal, TouchableOpacity,} from 'react-native'
import config from '../../config';

const CardPayment = props => {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 8,
      }}
    >
      <Text
        style={{ fontSize: 18, fontFamily: "OpenSans-Regular", color: "#000" }}
      >
        Cards
      </Text>
      <TouchableOpacity
        onPress={() => {
          props.cardSelected();
        }}
        style={{
          backgroundColor: props?.color? props.color: config.primary_color,
          borderRadius: 100,
          justifyContent: "center",
          paddingHorizontal: 23,
          paddingVertical: 4,
        }}>
        <Text
          style={{ fontSize: 16, fontFamily: "Roboto-Regular", color: "#FFF" }}
        >
          Pay
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default CardPayment;
