import AuthSaga from './auth.saga'
import SignupSaga from './signup.saga'
import OTPSaga from './otp.saga'
import LoginSaga from './login.saga'
import ContactSaga from './contacts.saga'
import PaymentDetailSaga from './paymendetail.saga'
import MakePaymentSaga from './makepayment.saga'
import RequestPaymentSaga from './requestpayment.saga'
import NotificationSaga from './notification.saga'
import PaymentUsersSage from './payments.saga'
import RecentContactsSage from './recentcontacts.saga'
import GigDetailsSage from './gigdetails.saga'
import ActivitySaga from './activity.saga'
import ProtectedPaymentsSaga from './protectedpayments.saga'
import AllNotificationsSaga from './allnotifications.saga'
import MessagesSaga from './messages.saga'
import MyMoneySaga from './mymoney.saga'
import TopicMessagesSaga from './topicmessages.saga'
import InviteUserSaga from './inviteuser.saga'
import PaymentCheckout from './paymentcheckout.saga'
import UserProfile from './userprofile.saga'
import EditProfile from './editprofile.saga'
import DeepLinkingSaga from './deeplinking.saga'
import AccountsSaga from './accounts.saga'
import {all} from 'redux-saga/effects'
import HelperSaga from './helper.saga'
import CategorySaga from './category.saga'
import SearchContactsSaga from './searchcontacts.saga'
import PaymentPreviewSaga from './paymentpreview.saga'
import SmartCollectSaga from './smartcollect.saga'
import RecommendationsSaga from './recommendation.saga'

export default function * IndexSaga () {
  yield all([
    NotificationSaga(),
    DeepLinkingSaga(),
    AuthSaga(),
    SignupSaga(),
    OTPSaga(),
    LoginSaga(),
    ContactSaga(),
    PaymentDetailSaga(),
    MakePaymentSaga(),
    RequestPaymentSaga(),
    PaymentUsersSage(),
    RecentContactsSage(),
    GigDetailsSage(),
    ActivitySaga(),
    ProtectedPaymentsSaga(),
    AllNotificationsSaga(),
    MessagesSaga(),
    MyMoneySaga(),
    TopicMessagesSaga(),
    InviteUserSaga(),
    PaymentCheckout(),
    UserProfile(),
    EditProfile(),
    AccountsSaga(),
    HelperSaga(),
    CategorySaga(),
    SearchContactsSaga(),
    PaymentPreviewSaga(),
    SmartCollectSaga(),
    RecommendationsSaga()
  ])
}
