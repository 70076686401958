import {
  ADD_MILESTONE,
  DELETE_MILESTONE,
  EDIT_MILESTONE,
  INIT_STATE,
  MAKE_TRANSACTION,
  TRANSACTION_SUCCESS,
  TRANSACTION_FAILURE,
  CHANGE_MILESTONE_MODE
} from '../constants/constants'

const initialState = {
  milestones: [],
  isLoading: false,
  error: '',
  proceedToPayment: false,
  proceedToGig: false,
  transaction_details: {},
  mode: false,
}

const reducer = function paymentDetailReducer (state = initialState, action) {
  switch (action.type) {
    case INIT_STATE: {
      return {
        milestones: [],
        isLoading: false,
        error: '',
        proceedToPayment: false,
        proceedToGig: false,
        mode:false
      }
    }
    case CHANGE_MILESTONE_MODE: {
      return {
        ...state,
        mode: !state.mode,
      }
    }
    case ADD_MILESTONE: {
      return {
        ...state,
        milestones: [
          ...state.milestones,
          {index: state.milestones.length + 1, ...action.milestone},
        ],
      }
    }
    case EDIT_MILESTONE: {
      var index = state.milestones.indexOf(
        state.milestones.find(x => x.index == action.milestone_id),
      )
      var prevMilestones = state.milestones
      prevMilestones[index].milestoneAmount = action.milestone.milestoneAmount
      prevMilestones[index].details = action.milestone.details
      return {
        ...state,
        milestones: prevMilestones,
      }
    }
    case DELETE_MILESTONE: {
      var prevMilestones = state.milestones
      prevMilestones.splice(action.milestone_id, 1)
      return {
        ...state,
        milestones: prevMilestones,
      }
    }

    case MAKE_TRANSACTION: {
      return {
        ...state,
        isLoading: true,
        error: '',
        proceedToGig: false,
        proceedToPayment: false,
      }
    }

    case TRANSACTION_SUCCESS: {
      return {
        ...state,
        proceedToPayment: action.response.proceedToPayment,
        proceedToGig: action.response.proceedToGig,
        transaction_details: action.response.transaction_details,
        error: '',
        isLoading: false,
      }
    }

    case TRANSACTION_FAILURE: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default reducer
