import React, { Component } from "react";
import {
  View,
  Text,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
  Dimensions,
  Animated,
  Easing,
  BackHandler,
  Platform,
  ImageBackground,
} from "react-native";
import config from "../../config";
import { connect } from "react-redux";
import { changeMode } from "../../state-management/actions/contacts.action";
import {
  serviceSelected,
  productSelected,
  reset,
  setPaymentAmount,
  loadObjects,
} from "../../state-management/actions/category.action";
import ContactsBadge from "../../components/ContactsBadge";
import OffersCarousal from "../../components/OffersCarousal";
import {
  logout,
  switchAuth,
  resetLoginLater,
} from "../../state-management/actions/auth.action";
import { setFloatingData } from "../../state-management/actions/floating.action";
import Bell from "../../assets/images/bell";
import ActionPending from "../../assets/images/actionPending";
import StartPayment from "../../assets/images/startPayment";
import RequestPayment from "../../assets/images/requestPayment";
import Question from "../../assets/images/question";
import VideoWalkthrough from "../../assets/images/videoWalkthrough";
import Help from "../../assets/images/help";
import Whoisusing from "../../assets/images/whoisusing";
import VouchedBusiness from "../../assets/images/vouchedBusiness";
// import Carousel, { Pagination }   from 'react-native-snap-carousel';
import AwesomeAlert from "react-native-awesome-alerts";
import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";
import BuildProfile from "../../assets/images/buildProfile";
import BuildReputation from "../../assets/images/buildReputation";
import Badge from "../../assets/images/badge";
import { Modal } from "react-native-paper";
import VideoModal from "../../components/VideoWalkThrough";
import logsService from "../../services/logs.service";

const { height, width } = Dimensions.get("window");
const ITEM_WIDTH = 0.8 * width;

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);

class NewHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      carouselItems: [],
      activeIndex: 0,
      showVouchedBussinessButton: false,
      showVideoModal: false,
      link: "",
    };
  }

  componentDidMount() {
    this.props.loadObjects();
    firebase
      .remoteConfig()
      .fetch(0)
      .then(result => {
        firebase
          .remoteConfig()
          .fetchAndActivate()
          .then(result2 => {
            var offers = firebase.remoteConfig().getValue("offers");
            var showButton = firebase
              .remoteConfig()
              .getValue("show_vouched_bussiness_button_in_home_screen");
            var link = firebase.remoteConfig().getValue("walkthrough_link");
            this.setState({
              carouselItems: JSON.parse(offers.asString()),
              showVouchedBussinessButton: showButton.asBoolean(),
              link: link.asString(),
            });
          })
          .catch(err => {});
      })
      .catch(err => {});
    // const remoteConfig = firebase.remoteConfig().fet;
    // remoteConfig.settings.minimumFetchIntervalMillis = 0;
    // fetchAndActivate(remoteConfig)
    // .then(() => {
    //   var offers = getValue(remoteConfig,'offers');
    //   var showButton = getValue(remoteConfig,'show_vouched_bussiness_button_in_home_screen');
    //   var link = getValue(remoteConfig,'walkthrough_link');
    //   this.setState({
    //     carouselItems:JSON.parse(offers.asString()),
    //     showVouchedBussinessButton:showButton.asBoolean(),
    //     link:link.asString()
    // });
    // })
    // .catch((err) => {
    // });
  }

  componentWillUnmount() {}

  _renderItem({ item, index }) {
    return (
      <TouchableOpacity
        style={{
          backgroundColor: "floralwhite",
          borderRadius: 5,
          height: 100,
          elevation: 3,
          marginBottom: 10,
          backgroundColor: "#FFF",
          justifyContent: "center",
          alignItems: "center",
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontFamily: "OpenSans-Regular",
            color: "#707070",
          }}
        >
          {item.title}
        </Text>
      </TouchableOpacity>
    );
  }

  render() {
    return this.props.recentContacts.isLoading ||
      this.props.profile.isLoading ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size='large' color={config.primary_color} />
      </View>
    ) : (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: 50 }}
        >
          <View style={{ paddingHorizontal: "5%" }}>
            <Text
              style={{
                fontFamily: "OpenSans-Regular",
                color: "#707070",
                fontSize: 14,
                textAlign: "center",
                marginTop: 36,
                marginBottom: 24,
                lineHeight: 19,
              }}
            >
              Being vouched is something special{"\n"}Claim your badge and learn
              more!!!
            </Text>

            <ImageBackground
              source={require("../../assets/images/vouchPodiam.png")}
              imageStyle={{
                width: "100%",
                height: 200,
                borderRadius: 10,
                resizeMode: "cover",
              }}
              style={{
                width: "90%",
                height: 200,
                borderRadius: 10,
                backgroundColor: "red",
                alignSelf: "center",
                marginBottom: 32,
              }}
            >
              <View
                style={{
                  flex: 0.3,
                  alignItems: "flex-end",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flex: 0.25,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto-Regular",
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >{`Build\nProfile`}</Text>
                </View>
                <View
                  style={{
                    flex: 0.5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></View>
                <View
                  style={{
                    flex: 0.25,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Roboto-Regular",
                      fontSize: 12,
                      fontStyle: "italic",
                    }}
                  >{`Build\nReputation`}</Text>
                </View>
              </View>
              <View
                style={{
                  flex: 0.4,
                  alignItems: "flex-end",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <TouchableOpacity
                  activeOpacity={1}
                  onPress={() => {
                    if (this.props.auth.isLoginLater) {
                      this.props.switchAuth();
                    } else {
                      this.props.navigation.navigate("ProfileStack", {
                        screen: "Profile",
                        params: {
                          key: "username",
                          value: this.props.profile?.userProfile?.username,
                          store: false,
                          tab: null,
                          showShortCode: false,
                        },
                      });
                    }
                  }}
                  style={{
                    flex: 0.25,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BuildProfile />
                </TouchableOpacity>
                <View
                  style={{
                    flex: 0.5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Badge />
                </View>
                <TouchableOpacity
                  activeOpacity={1}
                  onPress={() => {
                    if (this.props.auth.isLoginLater) {
                      this.props.switchAuth();
                    } else {
                      this.props.navigation.navigate("ProfileStack", {
                        screen: "Profile",
                        params: {
                          key: "username",
                          value: this.props.profile?.userProfile?.username,
                          store: false,
                          tab: null,
                          showShortCode: false,
                        },
                      });
                    }
                  }}
                  style={{
                    flex: 0.25,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BuildReputation />
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flex: 0.3,
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flex: 0.25,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></View>
                <View
                  style={{
                    flex: 0.5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></View>
                <View
                  style={{
                    flex: 0.25,
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      if (this.props.auth.isLoginLater) {
                        this.props.switchAuth();
                      } else {
                        this.props.navigation.navigate("ProfileStack", {
                          screen: "Profile",
                          params: {
                            key: "username",
                            value: this.props.profile?.userProfile?.username,
                            store: false,
                            tab: null,
                            showShortCode: false,
                          },
                        });
                      }
                    }}
                    style={{
                      backgroundColor: config.primary_color,
                      borderRadius: 100,
                      paddingHorizontal: 10,
                      paddingVertical: 3,
                      marginRight: "15%",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "OpenSans-Regular",
                        color: "#fff",
                        fontSize: 10,
                        textAlign: "center",
                      }}
                    >
                      Claim
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </ImageBackground>
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                width: "95%",
                maxWidth: "98%",
                flexWrap: "wrap",
                alignSelf: "center",
                justifyContent: "flex-start",
                marginBottom: 50,
              }}
            >
              <ContactsBadge
                clicked={() => {
                  logsService.logCustomEvent(
                    "all_notifications_button_clicked",
                    {}
                  );
                  if (this.props.auth.isLoginLater) {
                    this.props.setFloatingData({
                      mode: "All_Notifications",
                    });
                    this.props.switchAuth();
                  } else {
                    this.props.navigation.navigate("AllNotifications");
                  }
                }}
                iconPresent={true}
                textContent={"Notifications"}
                iconName={() => {
                  return <Bell />;
                }}
              />
              <ContactsBadge
                clicked={() => {
                  logsService.logCustomEvent(
                    "pending_actions_button_clicked",
                    {}
                  );
                  if (this.props.auth.isLoginLater) {
                    this.props.switchAuth();
                  } else {
                    this.props.navigation.navigate("Payments", {
                      screen: "ProtectedPayments",
                      params: {},
                    });
                  }
                }}
                iconPresent={true}
                iconName={() => {
                  return <ActionPending />;
                }}
                textContent={"Pending Actions"}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Start Payment"}
                iconName={() => {
                  return <StartPayment />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "start_payment_button_clicked",
                    {}
                  );
                  this.props.changeMode("buying");
                  this.props.navigation.navigate("HowVouchWorks");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Request Money"}
                iconName={() => {
                  return <RequestPayment style={{ marginTop: 17 }} />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "request_money_button_clicked",
                    {}
                  );
                  this.props.changeMode("selling");
                  this.props.navigation.navigate("HowVouchWorks");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"How vouch works"}
                iconName={() => {
                  return <Question />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "how_vouch_works_button_clicked",
                    {}
                  );
                  this.props.navigation.navigate("HowVouchWorks");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Video walk through"}
                iconName={() => {
                  return <VideoWalkthrough />;
                }}
                clicked={() => {
                  logsService.logCustomEvent("video_walk_through_clicked", {});
                  this.setState({ showVideoModal: true });
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Help"}
                iconName={() => {
                  return <Help />;
                }}
                clicked={() => {
                  logsService.logCustomEvent("help_button_clicked", {});
                  this.props.navigation.navigate("WhatsApp");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Who's using vouch"}
                iconName={() => {
                  return <Whoisusing />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "who_is_using_vouch_button_clicked",
                    {}
                  );
                  this.props.navigation.navigate("WhoIsUsing");
                }}
              />
            </View>
            {this.state.showVouchedBussinessButton ? (
              <TouchableOpacity
                onPress={() => {
                  this.setState({
                    showAlert: true,
                  });
                }}
                style={{
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  backgroundColor: "#FFF",
                  borderRadius: 15,
                  elevation: 2,
                  paddingVertical: 15,
                  marginHorizontal: 5,
                  marginBottom: 50,
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    color: "#000",
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  View
                </Text>
                <VouchedBusiness />
              </TouchableOpacity>
            ) : null}
          </View>
          {this.state.carouselItems.length > 0 ? (
            <OffersCarousal items={this.state.carouselItems} />
          ) : null}
        </ScrollView>

        <AwesomeAlert
          show={this.state.showAlert}
          title=''
          message=''
          messageStyle={{ textAlign: "center" }}
          customView={
            <View
              style={{
                alignItems: "center",
                paddingVertical: 10,
                lineHeight: 21,
                paddingHorizontal: 20,
                paddingBottom: 0,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Medium",
                  fontSize: 16,
                  color: "#000",
                  lineHeight: 21,
                }}
              >
                Comming Soon
              </Text>
            </View>
          }
          showCancelButton={false}
          showConfirmButton={true}
          confirmText='Ok'
          confirmButtonColor={config.primary_color}
          onConfirmPressed={() => {
            this.setState({ showAlert: false });
          }}
        />
        <Modal
          visible={this.state.showVideoModal}
          contentContainerStyle={{
            backgroundColor: "white",
            padding: 20,
            flex: 1,
          }}
          onDismiss={() => {
            this.setState({ showVideoModal: false });
          }}
        >
          <VideoModal
            link={"https://www.youtube.com/embed/eO5zN7Yfjow"}
            onGoBack={data => {
              this.setState({ showVideoModal: false });
            }}
          />
        </Modal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    backgroundColor: "#FFF",
    borderRadius: 15,
    elevation: 3,
    flex: 0.48,
    justifyContent: "flex-end",
    alignItems: "center",
    paddingVertical: 10,
    marginHorizontal: 4,
  },
});

export default connect(
  state => ({
    recentContacts: { ...state.recentContacts },
    profile: { ...state.userProfile },
    category: { ...state.category },
    auth: { ...state.auth },
    ...state.contacts,
  }),
  {
    changeMode,
    serviceSelected,
    productSelected,
    reset,
    setPaymentAmount,
    logout,
    setFloatingData,
    switchAuth,
    loadObjects,
    resetLoginLater,
  }
)(NewHome);
