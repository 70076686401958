import {INIT_REQUEST_PAYMENT_STATE, MAKE_REQUEST_PAYMENT, RESET_REQUEST_PAYMENT_STATE} from '../constants/constants'

export function initState (data) {
  return {
    type: INIT_REQUEST_PAYMENT_STATE,
    transaction_details: data,
  }
}

export function resetState(){
  return {
    type:RESET_REQUEST_PAYMENT_STATE
  }
}

export function makeRequest (transaction_details, requestFullPay) {
  return {
    type: MAKE_REQUEST_PAYMENT,
    transaction_details: transaction_details,
    requestFullPay: requestFullPay,
  }
}
