import React, {Component} from 'react';
import { Image , SafeAreaView , ScrollView , ActivityIndicator ,StyleSheet , View , Text , TouchableOpacity} from 'react-native';
import AIcon from 'react-native-vector-icons/AntDesign'
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import EditPortfolio from '../../components/Profile/EditPortfolio';
import {connect} from 'react-redux';
import {editPortFolioAction,deletePortfolio} from '../../state-management/actions/editprofile.action'
import config from '../../config';
import { Modal } from 'react-native-paper';
import AwesomeAlert from 'react-native-awesome-alerts';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

class PortfolioDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrl:'',
            imageVisible:false,
            editPortfolioFlag:false,
            conformDeleteFlag:false
        }
    }

    render(){
    return this.props.isLoading?(
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
        <ActivityIndicator size="large" color={config.primary_color} />
      </View>
    ):(
        <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <TouchableOpacity 
                onPress={()=>{this.props.navigation.goBack()}}
                style={{position:'absolute',width:30,height:30,margin:'3%',zIndex:100,backgroundColor:'rgba(0,0,0,0.5)',justifyContent:'center',alignItems:'center',borderRadius:50}}>
                <AIcon name="left" style={styles.backButton} />
                </TouchableOpacity>
                {
                    this.props?.selectedPortfolio?.images?.map((item,index) => {
                        return(
                            <TouchableOpacity onPress={()=>{
                                this.setState({imageUrl:item})
                                this.setState({imageVisible:true})
                            }}>
                                <Image
                                source={{ uri: item }}
                                style={{ height: 300, left: 0, right: 0 }}
                                resizeMode="cover"
                                />
                            </TouchableOpacity>
                        )
                    })
                }
                <View style={{margin:'7%'}}>
                    <View style={{flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingBottom: 15}}>
                        <Text style={{fontFamily:'OpenSans-SemiBold',fontSize:16,color:'#000',flex:0.8}}>
                            {this.props?.selectedPortfolio?.title}
                        </Text>
                        {this.props?.selectedPortfolio?.uid == firebase.auth()?.currentUser?.uid?
                            <View style={{flex:0.2,flexDirection:'row',justifyContent:'space-around'}}>
                                <TouchableOpacity onPress={()=>{this.setState({editPortfolioFlag:true})}}>
                                    <MIcon name='pencil' style={{color:'#318EF8',fontSize:20}} />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={()=>{this.setState({conformDeleteFlag:true})}} >
                                    <MIcon name='delete' style={{color:'#318EF8',fontSize:20}} />
                                </TouchableOpacity>
                            </View>:null
                        }
                    </View>
                    <Text style={{fontFamily:'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:30}}>
                        {this.props?.selectedPortfolio?.description}
                    </Text>     
            </View>
            </ScrollView>
        <AwesomeAlert
                show={this.state.conformDeleteFlag}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                  style={{
                    alignItems: 'center',
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}>
                  <Text
                    style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#707070',
                      lineHeight: 21,
                    }}>
                    Are you sure you want to permanently remove this item?
                  </Text>
                </View>
        )}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No'
        confirmText="Yes"
        confirmButtonColor={config.primary_color}
        onCancelPressed={()=>{this.setState({conformDeleteFlag:false})}}
        onConfirmPressed={() =>{
            this.props.deletePortfolio(this.props?.selectedPortfolio?.id)
            this.setState({conformDeleteFlag:false});
            this.props.navigation.goBack()
        }}
      />
      <Modal
            visible={this.state.editPortfolioFlag}
            contentContainerStyle={{backgroundColor: 'white', flex:1}}
            onDismiss = {() => {
                this.setState({editPortfolioFlag:false});
            }}>
            <EditPortfolio
                cameBack={() => {
                this.setState({editPortfolioFlag:false});
                }}
                onClose={(data,images,removed_images) => {
                this.props.editPortFolioAction({...data,id:this.props?.selectedPortfolio?.id},images,removed_images)
                this.setState({editPortfolioFlag:false});
                }}
                {...this.props?.selectedPortfolio}
            />
            </Modal>
        </SafeAreaView>
    )
        }
}

const styles = StyleSheet.create({
    backButton: {
        color: '#FFF',
        fontSize: 20,
      },
});


export default connect(
    state => ({
      ...state.editProfile,
    }),
    {editPortFolioAction,deletePortfolio},
  )(PortfolioDetail)