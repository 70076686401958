import {call, takeLatest, put} from 'redux-saga/effects'
import { CANCEL_VIRTUALL_ACCOUNT, CANCEL_VIRTUALL_ACCOUNT_ERROR, CANCEL_VIRTUALL_ACCOUNT_SUCCESS, LOAD_VIRTUALL_ACCOUNT, LOAD_VIRTUALL_ACCOUNT_ERROR, LOAD_VIRTUALL_ACCOUNT_SUCCESS } from '../constants/constants'
import MiscService from '../../services/misc.service'
import orderService from '../../services/order.service';

const execCancelSmartCollect = async (action) => {
  try {
    var temp = await orderService.cancelVirtuallAccount({id:action.account_id})
    return
  }catch (error) {
    throw {message: 'Something went wrong'}
  }
}

function * cancelSmartCollect (action) {
  try {
    const res = yield call(execCancelSmartCollect,action)
    yield put({type: CANCEL_VIRTUALL_ACCOUNT_SUCCESS, response:res})
  } catch (error) {
    yield put({type: CANCEL_VIRTUALL_ACCOUNT_ERROR, response: {message: error.message}})
  }
}

const execLoadVA = async (action) => {
    try {
      var temp = await orderService.fetchVirtuallAccount({id:action.account_id})
      return temp.accountDetails
    }catch (error) {
      throw {message: 'Something went wrong'}
    }
  }
  
  function * loadVA (action) {
    try {
      const res = yield call(execLoadVA,action)
      yield put({type: LOAD_VIRTUALL_ACCOUNT_SUCCESS, response: res})
    } catch (error) {
      yield put({type: LOAD_VIRTUALL_ACCOUNT_ERROR, response: {message: error.message}})
    }
  }

function * watchSmartCollect () {
  yield takeLatest(CANCEL_VIRTUALL_ACCOUNT, cancelSmartCollect)
  yield takeLatest(LOAD_VIRTUALL_ACCOUNT, loadVA)
}

export default watchSmartCollect