import {
  EDIT_USER_PROFILE_ERROR,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE,
  CHANGE_ACTIVE_TAB,
  ADD_GIG_FOR_SALE,
  ADD_GIG_FOR_SALE_ERROR,
  ADD_GIG_FOR_SALE_SUCCESS,
  LOAD_INITIALL_DATA,
  LOAD_INITIALL_DATA_SUCCESS,
  LOAD_INITIALL_DATA_ERROR,
  ADD_NEW_PORTFOLIO,
  ADD_NEW_PORTFOLIO_SUCCESS,
  ADD_NEW_PORTFOLIO_ERROR,
  EDIT_PORTFOLIO,
  EDIT_PORTFOLIO_SUCCESS,
  EDIT_PORTFOLIO_ERROR,
  SET_PORTFOLIO,
  SET_FOR_SALE,
  EDIT_FOR_SALE,
  EDIT_FOR_SALE_SUCCESS,
  EDIT_FOR_SALE_ERROR,
  DELETE_FOR_SALE,
  DELETE_FOR_SALE_SUCCESS,
  DELETE_FOR_SALE_ERROR,
  DELETE_PORTFOLIO,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_ERROR
} from '../constants/constants';

const initialState = {
  status: '',
  isLoading: false,
  error: '',
  initialTab:0,
  forSaleData:[],
  portfolioData:[],
  selectedPortfolio:{},
  selectedForSale:{},
};

const reducer = function editProfileReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_USER_PROFILE: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case EDIT_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        status: action.response.status,
        isLoading: false,
      }
    }
    case EDIT_USER_PROFILE_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    case ADD_GIG_FOR_SALE: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case ADD_GIG_FOR_SALE_SUCCESS: {
      return {
        ...state,
        forSaleData: action.response.for_sale_data,
        isLoading: false,
      }
    }
    case ADD_GIG_FOR_SALE_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    case ADD_NEW_PORTFOLIO: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case ADD_NEW_PORTFOLIO_SUCCESS: {
      return {
        ...state,
        portfolioData: action.response.portfolio_data,
        isLoading: false,
      }
    }
    case ADD_NEW_PORTFOLIO_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    case CHANGE_ACTIVE_TAB: {
      return {
        ...state,
        initialTab:action.tab
      }
    }
    case LOAD_INITIALL_DATA:{
      return{
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case LOAD_INITIALL_DATA_SUCCESS:{
      return{
        ...state,
        isLoading: false,
        forSaleData: action.response.for_sale_data,
        portfolioData:action.response.portfolio_data
      }
    }    
    case LOAD_INITIALL_DATA_ERROR:{
      return{
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    case SET_PORTFOLIO:{
      return{
        ...state,
        isLoading: false,
        selectedPortfolio:action.data
      }
    }    
    case EDIT_PORTFOLIO:{
      return{
        ...state,
        isLoading:true
      }
    }
    case EDIT_PORTFOLIO_SUCCESS:{
      return{
        ...state,
        isLoading: false,
        selectedPortfolio:action.response.edited_portfolio,
        portfolioData: action.response.portfolio_data,
      }
    }    
    case EDIT_PORTFOLIO_ERROR:{
      return{
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    case SET_FOR_SALE:{
      return{
        ...state,
        isLoading: false,
        selectedForSale:action.data
      }
    }    
    case EDIT_FOR_SALE:{
      return{
        ...state,
        isLoading:true
      }
    }
    case EDIT_FOR_SALE_SUCCESS:{
      return{
        ...state,
        isLoading: false,
        selectedForSale:action.response.edited_forsale,
        forSaleData: action.response.for_sale_data,
      }
    }    
    case EDIT_FOR_SALE_ERROR:{
      return{
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    case DELETE_FOR_SALE:{
      return{
        ...state,
        isLoading:true
      }
    }
    case DELETE_FOR_SALE_SUCCESS:{
      return{
        ...state,
        isLoading: false,
        forSaleData: action.response.for_sale_data,
      }
    }    
    case DELETE_FOR_SALE_ERROR:{
      return{
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    case DELETE_PORTFOLIO:{
      return{
        ...state,
        isLoading:true
      }
    }
    case DELETE_PORTFOLIO_SUCCESS:{
      return{
        ...state,
        isLoading: false,
        portfolioData: action.response.portfolio_data,
      }
    }    
    case DELETE_PORTFOLIO_ERROR:{
      return{
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    default:
      return state;
  }
};

export default reducer;
