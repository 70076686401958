import {
  LOAD_USER_PROFILE,
  LOAD_USER_PROFILE_SUCCESS,
  LOAD_USER_PROFILE_ERROR,
  LOAD_USER_PROFILE_BY_KEY,
  LOAD_USER_PROFILE_SUCCESS_BY_KEY,
  LOAD_USER_PROFILE_ERROR_BY_KEY,
  CREATE_USER_CHAT,
  CREATE_USER_CHAT_SUCCESS,
  CREATE_USER_CHAT_ERROR,
  RESET_PROFILE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_DETAILS,
  UPDATE_USER_PROFILE_PORTFOLIO,
  UPDATE_USER_PROFILE_FOR_SALE,
} from "../constants/constants";

const initialState = {
  userProfile: [],
  requestedProfile: [],
  isLoading: false,
  error: "",
  success: false,
  chatcreatedFlag: false,
  forSaleData: [],
  portfolioData: [],
  scores: [],
  complement_data: [],
  review_data: [],
  recommendation_data: [],
};

const reducer = function userProfileReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_PROFILE: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case LOAD_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: action.response.userProfile,
        isLoading: false,
      };
    }
    case LOAD_USER_PROFILE_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      };
    }
    case UPDATE_USER_PROFILE_DETAILS: {
      return {
        ...state,
        userProfile: action.response.userProfile,
        requestedProfile: action.response.userProfile,
        isLoading: false,
      };
    }
    case UPDATE_USER_PROFILE_PORTFOLIO: {
      return {
        ...state,
        portfolioData: action.response.portfolio_data,
        isLoading: false,
      };
    }
    case UPDATE_USER_PROFILE_FOR_SALE: {
      return {
        ...state,
        forSaleData: action.response.for_sale_data,
        isLoading: false,
      };
    }
    case LOAD_USER_PROFILE_BY_KEY: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case LOAD_USER_PROFILE_SUCCESS_BY_KEY: {
      return {
        ...state,
        requestedProfile: action.response.userProfile,
        forSaleData: action.response.for_sale_data,
        portfolioData: action.response.portfolio_data,
        scores: action.response.scores,
        review_data: action.response.review_data,
        complement_data: action.response.complement_data,
        recommendation_data: action.response.recommendation_data,
        isLoading: false,
      };
    }
    case LOAD_USER_PROFILE_ERROR_BY_KEY: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      };
    }
    case CREATE_USER_CHAT: {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    }
    case CREATE_USER_CHAT_SUCCESS: {
      return {
        ...state,
        success: true,
        chatcreatedFlag: action.response,
        isLoading: false,
      };
    }
    case CREATE_USER_CHAT_ERROR: {
      return {
        ...state,

        error: action.response.message,
        isLoading: false,
      };
    }
    case RESET_PROFILE: {
      return {
        ...state,
        success: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
