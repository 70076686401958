import React, { useEffect, useState } from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import Swiper from "react-native-web-swiper";
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';

const OffersCarousal = (props) => {
  const [items,setItems] = useState([])
  useEffect(()=>{
    setItems(props.items);
  },[props.items])
        return (
          <View style={{width:'100%',justifyContent:'space-around',flexDirection:'row',}}>
            <View style={{overflow: 'hidden', padding: 5,paddingLeft:0,marginTop:12 }}>
            <View
            style={{
              width:20,
              height:90,
              borderColor:'#ccc',
              borderLeftWidth:0,
              borderTopRightRadius:5,
              borderBottomRightRadius:5,
              shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4.84,
            }}
            ></View>
            </View>
              <View style={{width:'80%',height:160}}>
                  <Swiper
                    // controlsEnabled={false}
                    loop
                    from={0}
                    timeout={2.5}
                    controlsProps={{
                      prevTitle: '',
                      nextTitle: '',
                    }}                  
                    minDistanceForAction={0.1}>
                      {
                        props.items.map((item,index)=>{
                          return(
                            <ImageBackground 
                            source = {{
                              uri:item.image
                            }}
                            imageStyle={{
                              // resizeMode:'contain',
                              height:100,
                              width:'99%',
                              elevation:3,
                              shadowColor: "#000",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.25,
                              shadowRadius: 3.84,
                              borderRadius: 5,
                              borderWidth:3,
                              borderColor:'#eee'
                            }}
                            style={{
                              backgroundColor:'floralwhite',
                              borderRadius: 5,
                              height: 100,
                              margin:10,
                              backgroundColor:'#FFF',
                              justifyContent:'center',
                              alignItems:'center',
                              }}>
                            {/* <Text style={{fontSize: 14,fontFamily:'OpenSans-Regular',color:'#707070'}}>{item.title}</Text> */}
                          </ImageBackground>
                          )
                        })
                      }
                  </Swiper>
              </View>
              <View style={{overflow: 'hidden', padding: 5,paddingRight:0 ,marginTop:12 }}>
            <View
            style={{
              width:20,
              height:90,
              borderTopLeftRadius:5,
              borderBottomLeftRadius:5,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 4.84,
              }}
            ></View>
            </View>
          </View>
        )
    }

export default OffersCarousal