import {
  LOAD_ESSENTIALS,
  LOAD_ESSENTIALS_ERROR,
  LOAD_ESSENTIALS_SUCCESS,
} from '../constants/constants';
import {takeLatest, call, put} from 'redux-saga/effects';
// import RNSecureStorage, {ACCESSIBLE} from 'rn-secure-storage';
import miscService from '../../services/misc.service';
const execLoadEssentials = async () => {
  try {
    // ApiService.getHelper()
    //   .then(res => {
    //     // console.log('Key ',res);
    //     var k = res.data.vhk;
    //     RNSecureStorage.set('helper', k, {
    //       accessible: ACCESSIBLE.ALWAYS,
    //     })
    //       .then(res => {
    //         // console.log("Stored",res);
    //       })
    //       .catch(err => {
    //         // console.log("Not Stored",res);
    //       });
    //   })
    //   .catch(err => {
    //     // console.log('Key error',error);
    //   });

    var getKey = await miscService.getHelperKey();
    getKey = getKey.vhk;
    // const secure = await RNSecureStorage.set('helper', getKey, {
    //   accessible: ACCESSIBLE.ALWAYS,
    // });
  } catch (error) {
    // console.log('Key error', error);
  }
};

function* loadEssentials() {
  try {
    const res = yield call(execLoadEssentials);
    yield put({type: LOAD_ESSENTIALS_SUCCESS});
  } catch (error) {
    yield put({type: LOAD_ESSENTIALS_ERROR});
  }
}

function* watchHelper() {
  yield takeLatest(LOAD_ESSENTIALS, loadEssentials);
}

export default watchHelper;
