import config from '../config';
import Interceptor from './Interceptor';

class DirectPayHttpsService {
    getOrderdata = async params => {
        try {
            let axiosObj = await Interceptor.getObject();
            const result = await axiosObj.post(
              `${config.node_api_endpoint}/direct/pay/order/get`,
              params,
            );
            return result.data.data;
          } catch (error) {
            throw error.response.data;
          }
    }
    acceptOrder = async params => {
      try {
          let axiosObj = await Interceptor.getObject();
          const result = await axiosObj.post(
            `${config.node_api_endpoint}/direct/pay/order/accept`,
            params,
          );
          return result.data.data;
        } catch (error) {
          throw error.response.data;
        }
  }
}

export default new DirectPayHttpsService()