import * as React from 'react'
import { View, Text, StyleSheet} from 'react-native'

const ActivityCard = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.activityText}>
        {props.activity_name}
      </Text>
      <Text style={styles.activityStatus}>{props.activity_status}</Text>
      {/* <Text style={styles.activityTime}>{props.activity_time}</Text> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderWidth: 1,
    borderColor: '#DCDCDC',
    padding: '5%',
    borderRadius: 15,
    margin: 10,
  },
  activityText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    color: '#473BB3',
    marginBottom: 10,
    lineHeight:22
  },
  activityStatus: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#545455',
    // marginBottom: 10,
  },
  activityTime: {fontFamily: 'Roboto-Regular', fontSize: 10, color: '#545455'},
})


export default ActivityCard