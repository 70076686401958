import config from '../config';
import Interceptor from './Interceptor';


class AccountService {
  editProfile = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/account/stats`,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      return error.response.data;
    }
  };
  pendingSettlements = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/account/pending/settlements`,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      return error.response.data;
    }
  };
  linkAccount = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const data = await axiosObj.post(
        `${config.node_api_endpoint}/account/add`,
        params,
      );

      return data.data;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };
}

export default new AccountService();
