// const CollectSuccessRedirect = () => {
//     return (
        
//     );
// }

// export default CollectSuccessRedirect;


import React, {Component, useEffect, useState} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  SafeAreaView,
} from 'react-native';
import config from '../../config';
import MerchantImage from '../../assets/images/merchantImage';



const CollectSuccessRedirect = props => {
  
const [timer,setTimer] = useState(10)


  useEffect(() => {
    setTimeout(() => {
        window.location = props.redirect_url
    }, 10000)
  }, [])

  useEffect(()=>{
      setInterval(()=>{
          setTimer(timer=>timer-1);
      },1000)
  },[])

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        {/* <form id="myForm" action="https://fancyshop.unaux.com/wc-api/WC_Gateway_Vouch/" hidden method="GET"> */}
        <form id="myForm" hidden method="POST">

        </form>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{justifyContent: 'center',}}>
            <Text style={{fontFamily:'Roboto-Medium',fontSize:24,textAlign:'center',marginTop:80,marginBottom:20}}>
              Awesome! Your payment is successful
            </Text>
            <MerchantImage style={{alignSelf:'center'}}/>
            {/* <ExpressIntrestImage /> */}

            <View style={{flexDirection:'row'}}>
              <View style={{flex:1}}></View>
              <View>
              {/* <Text style={{fontFamily:'Roboto-Regular',fontSize:16,textAlign:'left'}}>
                We have sent an SMS with an app download link
              </Text> */}
              <Text style={{fontFamily:'Roboto-Bold',fontSize:16,marginTop:30,marginBottom:5}}>
              The Vouch app offers 
              </Text>
              <Text style={{fontFamily:'Roboto-Regular',fontSize:16}}>
              1.Tracking, order updates and direct customer support{'\n'}
              2.Release the money when you get the delivery
              </Text>
              </View>
              <View style={{flex:1}}></View>
            </View>
            {timer>=0?
            <Text style={{textAlign:'center',marginTop:50}}>
            Redirecting in {timer} seconds
            </Text>:null}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  animationSize: {height: 400, aspectRatio: 16 / 9},
  text: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: 24,
    textAlign: 'center',
    color: '#000000',
    lineHeight:26,
    letterSpacing:1
  },
})

export default CollectSuccessRedirect