export default {
  primary_color: "#481380",
  dull_color: "#545454",
  danger: "#DB235B",
  colors: [
    "#DB235B",
    "#473BB3",
    "#09C98F",
    "#5956FE",
    "#6E23DB",
    "#481380",
    "#DEAA01",
  ],
  senderID: "776737643024",

  // Local
  // node_api_endpoint: "http://localhost:3000/v1",
  // chat_server: "ws://192.168.31.202:3000",

  // // Test
  // node_api_endpoint: "https://test.api.getvouched.co/v1",
  // chat_server: "https://test.api.getvouched.co",
  // env:'sandbox',
  // firebase : {
  //   apiKey: "AIzaSyD-dVDO1aiver2FvoqrREWlq-UtnO2Q8OY",
  //   authDomain: "newvouchtest.firebaseapp.com",
  //   projectId: "newvouchtest",
  //   storageBucket: "newvouchtest.appspot.com",
  //   messagingSenderId: "726682252124",
  //   appId: "1:726682252124:web:c6ba3aeb556f6eaeeac799",
  //   measurementId: "G-LJLKLMWMRK"
  // },
  // prod Sim
  // node_api_endpoint: "https://prodtrans.api.getvouched.co/v1",
  // chat_server: "https://prodtrans.api.getvouched.co",
  // env:'sandbox',
  // firebase: {
  //   apiKey: "AIzaSyDKE_PBjQRv0iM93VnanAp4HQ5sgTYBSiU",
  //   authDomain: "vouch-prod-transfer.firebaseapp.com",
  //   projectId: "vouch-prod-transfer",
  //   storageBucket: "vouch-prod-transfer.appspot.com",
  //   messagingSenderId: "99743990758",
  //   appId: "1:99743990758:web:1c0dcb87de945c94e04c27",
  //   measurementId: "G-C0NK6YKY5R"
  // },
  // Prod
  node_api_endpoint: 'https://prod.api.getvouched.co/v1',
  chat_server:'https://prod.api.getvouched.co',
  env:'prod',
  // firebase:{
  // apiKey: "AIzaSyDeBwHNDjQRxKJj-GYKd9tOeF0QsKoZZ4Y",
  // authDomain: "vout-263411.firebaseapp.com",
  // databaseURL: "https://vout-263411.firebaseio.com",
  // projectId: "vout-263411",
  // storageBucket: "vout-263411.appspot.com",
  // messagingSenderId: "776737643024",
  // appId: "1:776737643024:web:8fbb8c2b43be5aa843c895",
  // measurementId: "G-F24G6QX424",
  // }
  firebase:{
    apiKey: "AIzaSyDEWSVzqrY16-2x8AuMR1NIAUKdMjvvGAw",
    authDomain: "vouch-temp.firebaseapp.com",
    projectId: "vouch-temp",
    storageBucket: "vouch-temp.appspot.com",
    messagingSenderId: "206209604689",
    appId: "1:206209604689:web:dc1a1c1f9091a8f0d36c70",
    measurementId: "G-SCYG3L280T"
  }
};
