import { CANCEL_VIRTUALL_ACCOUNT , LOAD_VIRTUALL_ACCOUNT } from "../constants/constants";

export function cancelVirtuallAccount(account_id){
    return {
        type:CANCEL_VIRTUALL_ACCOUNT,
        account_id:account_id
    }
}

export function getVirtuallAccountDetails(account_id){
    return {
        type:LOAD_VIRTUALL_ACCOUNT,
        account_id:account_id
    }
}