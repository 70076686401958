//import liraries
import React, {Component} from 'react'
import {View, Text, StyleSheet,TouchableOpacity} from 'react-native'
import MIcon from 'react-native-vector-icons/MaterialIcons'
import FIcon from 'react-native-vector-icons/AntDesign'
import config from '../config'
class MilestoneCard extends Component {
  render () {
    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: '#F2F2F2',
          padding: '5%',
          borderRadius: 10,
          elevation: 5,
          shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,
          marginVertical: '6%',
          marginHorizontal:4
        }}>
        <Text
          style={{
            fontFamily: 'Roboto-Bold',
            fontSize: 22,
            color: '#000',
            marginHorizontal: 5,
          }}>
          {this.props.index}
        </Text>
        <View style={{paddingHorizontal: '3%', flex: 1}}>
          <Text
            style={{
              fontFamily: 'Roboto-Medium',
              fontSize: 14,
              color: '#464646',
              marginVertical: 5,
            }}>
            Milestone Detail
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto-Medium',
              fontSize: 14,
              color: '#707070',
              marginVertical: 5,
              width: '80%',
            }}>
            {this.props.details}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}>
            <View>
              <Text
                style={{
                  fontFamily: 'Roboto-Medium',
                  fontSize: 14,
                  color: '#464646',
                  marginVertical: 5,
                }}>
                Amount
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto-Medium',
                  fontSize: 14,
                  color: '#707070',
                  marginVertical: 5,
                }}>
                {' \u20B9'} {this.props.milestoneAmount}
              </Text>
            </View>
            {this.props?.onEdit?
            <View style={{flexDirection: 'row'}}>
              <TouchableOpacity
                onPress={this.props.onEdit}
                style={{marginHorizontal: 5}}>
                <MIcon
                  name='edit'
                  style={{
                    color: config.primary_color,
                    fontSize: 25,
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{marginHorizontal: 5}}
                onPress={this.props.onDelete}>
                <FIcon
                  name='delete'
                  style={{
                    color: config.primary_color,
                    fontSize: 25,
                  }}
                />
              </TouchableOpacity>
            </View>:null}
          </View>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({})

export default MilestoneCard
