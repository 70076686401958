import {
  LOAD_GIG_DETAILS,
  LOAD_GIG_DETAILS_SUCCESS,
  LOAD_GIG_DETAILS_ERROR,
  GIG_SCROLL_DOWN,
  GIG_ORDER_STATUS,
  GIG_ORDER_STATUS_ERROR,
  GIG_ORDER_STATUS_SUCCESS,
  GIG_MILESTONE_STATUS,
  GIG_MILESTONE_STATUS_ERROR,
  GIG_MILESTONE_STATUS_SUCCESS,
  BUYER_FUND_MILESTONE,
  BUYER_FUND_FULL_AMOUNT,
  BUYER_FUND_FULL_AMOUNT_ERROR,
  BUYER_FUND_FULL_AMOUNT_SUCCESS,
  BUYER_FUND_MILESTONE_SUCCESS,
  BUYER_FUND_MILESTONE_ERROR,
  TOGGLE_OVERLAY_REDO,
  REDO_UPDATE_ERROR,REDO_UPDATE_SUCCESS,REDO_UPDATE,
  REFUND_GIG_ORDER,
  REFUND_GIG_ORDER_ERROR,
  REFUND_GIG_ORDER_SUCCESS,
  SHOW_ALERT,
  HIDE_ALERT,
  ADD_REVIEWS,
  ADD_REVIEWS_SUCCESS,
  ADD_REVIEWS_ERROR,
  ACTION_PENDING_SET,
  ACTION_PENDING_RESET,
} from '../constants/constants'

const initialState = {
  gigDetails: [],
  gigMilestoneDetails: [],
  clickedUser:[],
  isLoading: true,
  scrollDown: true,
  redoOverlay:false,
  gigStatusUpdated: '',
  total_amount_paid:'',
  error: '',
  gigMilestoneStatusUpdated:'',
  fundFullMilestoneMessage:'',
  fundMilestoneSuccess:'',
  redoSuccess:'',
  refundSuccess:'',
  alertVisible:false,
  alertMessage:'',
  timeline:[]
}

const reducer = function gigDetailsReducer (state = initialState, action) {
  switch (action.type) {
    case SHOW_ALERT:{
      return{
        ...state,
        alertVisible:true
      }
    }
    case HIDE_ALERT:{
      return{
        ...state,
        alertVisible:false
      }
    }
    case LOAD_GIG_DETAILS: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case REDO_UPDATE: {
      return {
        ...state,
        isLoading: true,
        error: '',
        redoSuccess: '',
      }
    }
    case REDO_UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        redoSuccess: action.response.redoSuccess,
        alertMessage:action.response.redoSuccess
      }
    }
    case REDO_UPDATE_ERROR: {
      return {
        ...state,
        redoSuccess: action.response.redoSuccess,
        error: action.response.message,
        alertMessage:action.response.message,
        isLoading: false,
      }
    }
    case BUYER_FUND_MILESTONE: {
      return {
        ...state,
        isLoading: true,
        error: '',
        fundMilestoneSuccess: '',
      }
    }
    case BUYER_FUND_MILESTONE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fundMilestoneSuccess: action.response.fundMilestoneSuccess,
      }
    }
    case BUYER_FUND_MILESTONE_ERROR: {
      return {
        ...state,
        fundMilestoneSuccess: action.response.fundMilestoneSuccess,
        error: action.response.message,
        isLoading: false,
      }
    }
    case BUYER_FUND_FULL_AMOUNT: {
      return {
        ...state,
        isLoading: true,
        error: '',
        fundFullMilestoneMessage: '',
      }
    }
    case BUYER_FUND_FULL_AMOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        fundFullMilestoneMessage: action.response.fundFullMilestoneMessage,
      }
    }
    case BUYER_FUND_FULL_AMOUNT_ERROR: {
      return {
        ...state,
        fundFullMilestoneMessage: action.response.fundFullMilestoneMessage,
        error: action.response.message,
        isLoading: false,
      }
    }
    case ADD_REVIEWS:{
      return{
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case ADD_REVIEWS_SUCCESS:{

      return{
        ...state,
        isLoading: false,
        error: '',
        alertMessage:action.response.message,
      }
    }
    case ADD_REVIEWS_ERROR:{
      return{
        ...state,
        isLoading: false,
        error: action.response.message,
        alertMessage:action.response.message,
      }
    }
    case GIG_ORDER_STATUS: {
      return {
        ...state,
        isLoading: true,
        error: '',
        gigStatusUpdated: '',
      }
    }
    
    case GIG_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        gigStatusUpdated: action.response.message,
        alertMessage:action.response.message,
      }
    }
    case GIG_ORDER_STATUS_ERROR: {
      return {
        ...state,
        gigStatusUpdated: action.response.message,
        error: action.response.message,
        alertMessage:action.response.message,
        isLoading: false,
      }
    }
    case GIG_MILESTONE_STATUS : {
      return {
        ...state,
        isLoading: true,
        error: '',
        gigMilestoneStatusUpdated: '',
      }
    }
    case GIG_MILESTONE_STATUS_SUCCESS : {
      return {
        ...state,
        isLoading: false,
        gigMilestoneStatusUpdated: action.response.message,
        alertMessage:action.response.message,
      }
    }
    case GIG_MILESTONE_STATUS_ERROR : {
      return {
        ...state,
        gigMilestoneStatusUpdated: action.response.message,
        error: action.response.message,
        alertMessage:action.response.message,
        isLoading: false,
      }
    }
    case GIG_SCROLL_DOWN: {
     

      return {
        ...state,
        scrollDown: !state.scrollDown,
      }
    }
    case TOGGLE_OVERLAY_REDO: {
      return {
        ...state,
        redoOverlay: !state.redoOverlay,
      }
    }
    case LOAD_GIG_DETAILS_SUCCESS: {

      return {
        ...state,
        gigDetails: action.response.gigDetails,
        gigMilestoneDetails: action.response.gigMilestoneDetails,
        clickedUser:action.response.clickedUser,
        timeline:action.response.timeline,
        isLoading: false,
      }
    }
    case LOAD_GIG_DETAILS_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    case REFUND_GIG_ORDER: {
      return{
        ...state,
        error:'',
        isLoading: true,
        refundSuccess:''
      }
    }
    case REFUND_GIG_ORDER_SUCCESS: {
      return{
        ...state,
        isLoading:false,
        refundSuccess:action.response.refundMessage,
        alertMessage:action.response.refundMessage
      }
    }
    case REFUND_GIG_ORDER_ERROR: {
      return{
        ...state,
        isLoading:false,
        error : action.response.message,
        alertMessage : action.response.message
      }
    }
    case ACTION_PENDING_SET: {
      return{
        ...state,
        actionPendingFlag:true
      }
    }
    case ACTION_PENDING_RESET: {
      return{
        ...state,
        actionPendingFlag:false
      }
    }
    default:
      return state
  }
}

export default reducer
