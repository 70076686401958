import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import {Icon} from 'native-base'
import config from '../../config'
import AIcon from 'react-native-vector-icons/AntDesign'
import FIcon from 'react-native-vector-icons/Feather'
import BIcon from 'react-native-vector-icons/FontAwesome5'
import PrimaryButton from '../PrimaryButton';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

class ProtectedPaymentsCard extends Component {
    constructor (props) {
      super(props)
      this.state = {}
    }
    render () {
        return this.props.order_status=='yet_to_confirm' || this.props.order_status=='confirmed' || this.props.order_status=='yet_to_pay'?(
          <View>
          <View style={styles.mainContainer}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between',alignItems:'center',paddingBottom: '2%',}}>
                  <Text style={styles.text2}>
                    {this.props.mode}
                  </Text>
                  <Text style={styles.text4}>
                    {this.props.formattedDate}
                  </Text>
                </View>
                <Text numberOfLines={1}
                style={styles.text1}>
                  {this.props.gig_name}
                </Text>
                <View style={{flex: 1}}>
                <Text style={styles.text3}>
                    for {' \u20B9'} {this.props.total_amount}
                  </Text>
                {this.props.order_status=='yet_to_confirm' || this.props.order_status=='confirmed'?
                <View style={{flexDirection:'row',alignItems:'center'}}>
                  <FIcon name='clock' style={{color:config.primary_color,paddingRight:10,fontSize:25}}/>
                  <Text style={{
                    fontFamily: 'Roboto-Medium',
                    fontSize: 14,
                    color: config.primary_color,
                    textTransform:'capitalize'
                  }}>
                    {this.props.order_status=='yet_to_confirm'?'Awating confirmation':'Order Confirmed'}
                  </Text>
                </View>
                :null}
                  
                  <View style={styles.buttonsContainer}>
              <PrimaryButton
                content={'Details'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={this.props.clicked}
              />
              <PrimaryButton
                content={'Chat'}
                styles={styles.cardButton}
                loading={this.props.loading}
                clicked={this.props.chatClicked}
              />
            </View>
                </View>
          </View>
          {this.props.isActionPending || this.props?.action_pending_from?.includes(firebase.auth().currentUser.uid)?
          <TouchableOpacity onPress={this.props.clicked} style={{width:'57%',alignSelf:'center',paddingVertical:5,backgroundColor:'#D92E0B',borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
            <Text style={{fontFamily: 'OpenSans-SemiBold',color:'#FFF',textAlign:'center',fontSize:12}}>Action pending.Click for details</Text>
          </TouchableOpacity>:<View style={{height:10}}></View>}
          </View>
        ):(
            <TouchableOpacity onPress={this.props.clicked} style={[styles.mainContainer,{marginBottom:10,paddingVertical:'6%'}]}>
                <View
                style={{flexDirection: 'row',paddingBottom: '2%', justifyContent: 'space-between'}}>
                  <Text style={styles.text2}>
                    {this.props.mode}
                  </Text>
                  <Text style={styles.text4}>
                    {this.props.formattedDate}
                  </Text>
                </View>
                <Text numberOfLines={1}
                  style={styles.text1}>
                  {this.props.gig_name}
                </Text>
                <View style={{flex: 1}}>
                <Text style={styles.text3}>
                  for {' \u20B9'} {this.props.total_amount}
                </Text>
                {this.props.order_status=='cancelled' || this.props.order_status=='rejected'?
                    <View style={{flexDirection:'row',alignItems:'center'}}>
                    <BIcon name='exclamation-triangle' style={{color:'#F90000',marginRight:10,fontSize:18}}/>
                    <Text style={{
                      fontFamily: 'Roboto-Medium',
                      fontSize: 14,
                      color: '#F90000',
                      textTransform:'capitalize'
                    }}>
                      {this.props.order_status=='cancelled'?'Order Cancelled':'Order Rejected'}
                    </Text>
                    </View> 
                :null}
                {this.props.order_status=='completed'?<View style={{flexDirection:'row',alignItems:'center'}}>
                    <AIcon name='checkcircleo' style={{color:'#0BA888',marginRight:10,fontSize:25}}/>
                    <Text style={{
                      fontFamily: 'Roboto-Medium',
                      fontSize: 14,
                      color: '#0BA888',
                      textTransform:'capitalize'
                    }}>
                      Order Completed
                    </Text>
                </View> :null}

                  
                </View>
            </TouchableOpacity>
        )
    }
}

// define your styles
const styles = StyleSheet.create({
  cardButton: {
    borderRadius: 13,
    paddingHorizontal: 15,
    paddingVertical: 7,
    backgroundColor: config.primary_color,
    marginRight: 5,
    marginTop: 10,
    flex:0.45
  },
  buttonsContainer: {
    marginBottom: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent:'space-between',
    marginTop:10
  },
    button: {
      color: config.primary_color,
      fontSize: 25,
      marginRight: 20,
    },
    mainContainer:{
      width: '95%',
      alignSelf:"center",
      marginTop: '6%',
      backgroundColor: '#FFF',
      elevation: 5,
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      borderRadius: 20,
      paddingVertical: '4%',
      // marginBottom:10,
      paddingHorizontal:'7%'
    },
    text1:{
      fontFamily: 'OpenSans-Regular',
      fontSize: 18,
      color: config.primary_color,
      
      textTransform:'capitalize',
      marginTop:12
    },
    text2:{
      fontFamily: 'OpenSans-Regular',
      fontSize: 14,
      color: '#000',
      
      textTransform:'capitalize'
    },
    text3:{
      fontFamily: 'OpenSans-SemiBold',
      fontSize: 14,
      color: '#707070',
      // paddingVertical: '5%',
      
      marginTop:15,
      marginBottom:15
    },
    text4:{
      fontFamily: 'OpenSans-Regular',
      fontSize: 10,
      color: '#747373',
      paddingRight: '5%',
    }
})

export default ProtectedPaymentsCard;