import {INIT_PAYMENT_STATE, MAKE_PAYMENT, RESET_PAYMENT_STATE} from '../constants/constants'

export function initState (data) {
  return {
    type: INIT_PAYMENT_STATE,
    transaction_details: data,
  }
}

export function resetState(){
  return {
    type:RESET_PAYMENT_STATE
  }
}

export function makePayment (transaction_details, isPayingFull) {
  return {
    type: MAKE_PAYMENT,
    transaction_details: transaction_details,
    isPayingFull: isPayingFull,
  }
}
