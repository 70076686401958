import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  SIGNUP,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  AUTH_SUCCESS,
} from "../constants/constants";
import firebase from "firebase/compat/app";
import { Platform } from "react-native";
import {setFloatUserId} from '../actions/floating.action'
import AuthService from '../../services/auth.service'
import logsService from '../../services/logs.service'
import config from "../../config";

const auth = firebase.auth;
const firestore = firebase.firestore;

const executeSignup = async (data) => {
  try {
    const {firstName, lastName, mobile, username, email,companyName,accountType,referral} = data

    var response = await AuthService.checkSignUp({
      username:username.trim(),
      mobile:mobile.trim(),
      email:email.trim()
    })
    var uRef = response.uRef
    var mRef = response.mRef
    var eRef = response.eRef

    if (uRef.length)
      throw {
        code: 400,
        message: "Oops, looks like this username is already taken.",
      };
    else if (mRef.length) {
      if (mRef.length > 1)
        throw {
          code: 400,
          message: "Oops, looks like this mobile number is already taken.",
        };
      else {
        const isVirtual = mRef[0]
        if (
          !isVirtual.hasOwnProperty("dynamic_link") ||
          !isVirtual.hasOwnProperty("virtual_id")
        ) {
          throw {
            code: 400,
            message: "Oops, looks like this mobile number is already taken.",
          };
        }
      }
    } else if (eRef.length && email.trim())
      throw {
        code: 400,
        message: "Oops, looks like this email is already taken.",
      };

    var confirmation = null;

    if (Platform.OS == "web") {
      if(config.env == 'sandbox') firebase.auth().settings.appVerificationDisabledForTesting = true;
      else firebase.auth().settings.appVerificationDisabledForTesting = false;
      var container = document.createElement("div");
      container.id = "captcha";
      document.body.appendChild(container);
      var captchaWeb = new firebase.auth.RecaptchaVerifier("captcha", {
        size: "invisible",
        callback: function (token) {
          callback(token);
        },
        "expired-callback": function () {
          callback("");
        },
      });

      confirmation = await auth().signInWithPhoneNumber(
        `+91${mobile}`,
        captchaWeb
      );
      document.body.removeChild(container);
    } else {
      var captchaVerifier = new FirebaseRecaptchaVerifier(captcha);
      confirmation = await auth().signInWithPhoneNumber(
        `+91${mobile}`,
        captchaVerifier
      );
    }

    if (confirmation.verificationId)
      return { code: 200, confirmation: confirmation };
    else {
      const uid = auth().currentUser.uid;
      const storeUser = await AuthService.registerUser({
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        username: username,
        email: email,
        accountType:accountType,
        companyName:accountType=='company'?companyName:null,
        referral_code:referral,
      })
      logsService.logCustomEvent('sign_up',{userId:uid,username: data.username,mobile: data.mobile})
      return { code: 201 ,uid:uid};
    }
  } catch (error) {
    // console.log(error);
    if (error.hasOwnProperty("code")) {
      if(error.code==400){
        logsService.logCustomEvent('auth_error',{description:error.message})
        throw error
      }
    }
    throw { message: "Something went wrong,Please try again later" };
  }
};

function* signUp(action) {
  try {
    const res = yield call(executeSignup, action.data, action.recaptcha);
    if (res.code == 201) {
      const getItems = state => state.floating
      const currentState = yield select(getItems)
      if(currentState.isFloatingPresent && !(currentState.data.mode=='All_Notifications')){
        yield(put(setFloatUserId(res.uid)))
      }
      yield put({
        type: AUTH_SUCCESS,
        response: { intro: true, auth: true },
      });
    } else if (res.code == 200) {
      yield put({
        type: SIGNUP_SUCCESS,
        response: { confirmation: res.confirmation },
      });
    }
  } catch (error) {
    yield put({ type: SIGNUP_ERROR, response: { message: error.message } });
  }
}

function* signUpWatcher() {
  yield takeLatest(SIGNUP, signUp);
}

export default signUpWatcher;
