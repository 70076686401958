import { FETCH_RECOMMENDATIONS, INIT_RECOMMENDATIONS, REQUEST_RECOMMENDATIONS, SUBMIT_RECOMMENDATIONS } from "../constants/constants";

export function requestRecommendation(mode,data,classification,request_to_mobile,category){
    return{
        type:REQUEST_RECOMMENDATIONS,
        data:data,
        request_to_mobile:request_to_mobile,
        mode:mode,
        category:category,
        classification:classification,
    }
}

export function initRecommendation(){
    return{
        type:INIT_RECOMMENDATIONS,
    }
}

export function fetchRecommendation(id){
    return{
        type:FETCH_RECOMMENDATIONS,
        id:id
    }
}

export function submitRecommendation(id,data){
    return{
        type:SUBMIT_RECOMMENDATIONS,
        id:id,
        data:data,
    }
}