import {
  INIT_TOPIC_MESSAGE_STATE,
  INIT_TOPIC_MESSAGE_STATE_SUCCESS,
  INIT_TOPIC_MESSAGE_STATE_ERROR,
  SEND_TOPIC_MESSAGE,
  SEND_TOPIC_MESSAGE_SUCCESS,
  SEND_TOPIC_MESSAGE_ERROR,
  SUBSCRIBE_TOPIC_MESSAGES,
  ON_TOPIC_MESSAGE_RECEIVED,
  TOPIC_PAGE_STATE,
  TOPIC_PAGE_STATE_ERROR,
  TOPIC_PAGE_STATE_SUCCESS,
  FETCH_TOPIC_CHAT_MESSAGES,
  FETCH_TOPIC_CHAT_MESSAGES_ERROR,
  FETCH_TOPIC_CHAT_MESSAGES_SUCCESS
} from '../constants/constants';

const initialState = {
  current_user_id: null,
  to_user_id: null,
  thread_id: null,
  isLoading: true,
  error: null,
  messages: [],
  isLoadingMore: false,
  chatId: null,
  token:null,
  isFetchingMessages: false,
  isMessageSending:false,
};

const reducer = function topicMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_TOPIC_MESSAGE_STATE: {
      return {
        ...initialState,
      };
    }
    case INIT_TOPIC_MESSAGE_STATE_SUCCESS: {
      return {
        current_user_id: action.response.current_user_id,
        to_user_id: action.response.to_user_id,
        thread_id: action.response.thread_id,
        chatId: action.response.chatId,
        isLoading: false,
        isLoadingMore: false,
        error: '',
        messages: [],
        token:action.response.token,
      };
    }
    case INIT_TOPIC_MESSAGE_STATE_ERROR: {
      return {
        ...initialState,
        error: action.response.message,
      };
    }
    case SUBSCRIBE_TOPIC_MESSAGES: {
      return {
        ...state,
      };
    }
    case ON_TOPIC_MESSAGE_RECEIVED: {
      return {
        ...state,
        messages: [...state.messages, action.data],
      };
    }
    case SEND_TOPIC_MESSAGE: {
      return {
        ...state,
        isLoading: false,
        error: '',
        isMessageSending:true,
      };
    }
    case SEND_TOPIC_MESSAGE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
        isMessageSending:false,
      };
    }
    case SEND_TOPIC_MESSAGE_SUCCESS: {
      return {
        ...state,
        messages: [...state.messages, action.response],
        error: '',
        isMessageSending:false,
      };
    }
    case TOPIC_PAGE_STATE: {
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    }
    case TOPIC_PAGE_STATE_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
      };
    }
    case TOPIC_PAGE_STATE_SUCCESS: {
      return {
        ...state,
        error: '',
      };
    }
    case FETCH_TOPIC_CHAT_MESSAGES: {
      return {
        ...state,
        isFetchingMessages: true,
      };
    }
    case FETCH_TOPIC_CHAT_MESSAGES_SUCCESS: {
      return {
        ...state,
        isFetchingMessages: false,
        messages: [...action.response, ...state.messages],
      };
    }
    case FETCH_TOPIC_CHAT_MESSAGES_ERROR: {
      // console.log('FETCH_CHAT_MESSAGES_ERROR');
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
        isFetchingMessages: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
