import React, {Component} from 'react'
import {View, Text, TouchableOpacity} from 'react-native'
import config from '../config'

export default class MyMoneyCard extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <TouchableOpacity
      onPress={this.props.clicked}
        style={{
          marginHorizontal: '8%',
          marginVertical: '4%',
          backgroundColor: '#FFF',
          elevation: 5,
          borderRadius: 10,
          shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,
          padding: '4%',
        }}>
        <View style={{flexDirection: 'row'}}>
          <View style={{flex: 0.23, justifyContent: 'space-around'}}>
            <View
              style={{
                backgroundColor: this.props.header_color,
                width: 43,
                height: 43,
                justifyContent: 'center',
                borderRadius: 50,
                alignSelf: 'center',
              }}>
              <Text
                style={{
                  fontSize: 20,
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily:'Roboto-Regular'
                }}>
                {this.props.to_user_name[0]}
              </Text>
            </View>
            <Text
              numberOfLines={1}
              style={{
                fontSize: 14,
                color: config.primary_color,
                textAlign: 'center',
                fontFamily: 'Roboto-Regular',
              }}>
              {this.props.to_user_name}
            </Text>
          </View>
          <View style={{flex: 0.77}}>
            <View style={{flexDirection: 'row'}}>
              <View style={{flex: 0.5, paddingLeft: '2%'}}>
                <Text
                  numberOfLines={1}
                  style={{
                    fontSize: 14,
                    color: '#464646',
                    fontFamily: 'Roboto-Regular',
                  }}>
                  {this.props.details}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{
                    fontSize: 14,
                    color: '#464646',
                    fontFamily: 'Roboto-Regular',
                    marginVertical: 20,
                  }}>
                  {this.props.formattedDate}
                </Text>
              </View>
              <View style={{flex: 0.5, paddingLeft: '2%'}}>
                <Text
                  numberOfLines={1}
                  style={{
                    fontSize: 16,
                    textAlign: 'right',
                    color: this.props.amount_color,
                    fontFamily: 'Roboto-Regular',
                  }}>
                  {this.props.procressed_amount}
                </Text>
                <Text
                  numberOfLines={1}
                  style={{
                    fontSize: 16,
                    textAlign: 'right',
                    color: config.primary_color,
                    fontFamily: 'Roboto-Regular',
                    marginVertical: 20,
                  }}>
                  {this.props.status_text}
                </Text>
              </View>
            </View>
            <Text
              numberOfLines={1}
              style={{
                fontSize: 13,
                textAlign: 'right',
                color: '#464646',
                fontFamily: 'Roboto-Regular',
                fontStyle:'italic'
              }}>
                {this.props.status_text1}
              {/* Settled to Seller bank A/c */}

            </Text>
          </View>
        </View>
      </TouchableOpacity>
    )
  }
}
