import {combineReducers} from 'redux'
import authReducer from '../reducers/auth.reducer'
import signupReducer from '../reducers/signup.reducer'
import otpReducer from '../reducers/otp.reducer'
import loginReducer from '../reducers/login.reducer'
import categoryReducer from '../reducers/category.reducer'
import contactReducer from '../reducers/contacts.reducer'
import contactSearchReducer from '../reducers/searchcontacts.reducer'
import paymentDetailReducer from '../reducers/paymentdetail.reducer'
import paymentuserReducer from '../reducers/paymentuser.reducer'
import paymentsTabReducer from '../reducers/paymentstab.reducer'
import protectedpaymentsReducer from '../reducers/protectedpayments.reducer'
import makePaymentReducer from '../reducers/makepayment.reducer'
import requestPaymentReducer from '../reducers/requestpayment.reducer'
import notificationReducer from '../reducers/notification.reducer'
import recentContactsReducer from '../reducers/recentcontacts.reducer'
import gigDetailsReducer from '../reducers/gigdetails.reducer'
import activityReducer from '../reducers/activity.reducer'
import allNotificationsReducer from '../reducers/allnotifications.reducer'
import messagesReducer from '../reducers/messages.reducer'
import myMoneyReducer from '../reducers/mymoney.reducer'
import topicMessagesReducer from '../reducers/topicmessages.reducer'
import inviteUserReducer from '../reducers/inviteuser.reducer'
import paymentCheckoutReducer from '../reducers/paymentcheckout.reducer'
import userProfileReduer from '../reducers/userprofile.reducer'
import editProfileReduer from '../reducers/editprofile.reducer'
import deepLinkingReducer from '../reducers/deeplinking.reducer'
import accountsReducer from '../reducers/accounts.reducer'
import helperReducer from '../reducers/helper.reducer'
import floatingReducer from '../reducers/floating.reducer'
import paymentPreviewReducer from '../reducers/paymentpreview.reducer'
import smartCollectReducer from '../reducers/smartcollect.reducer'
import recommendationsReducer from '../reducers/recommendation.reducer'

const reducer = combineReducers({
  auth: authReducer,
  signup: signupReducer,
  otp: otpReducer,
  login: loginReducer,
  category: categoryReducer,
  contacts: contactReducer,
  searchcontacts: contactSearchReducer,
  paymentdetail: paymentDetailReducer,
  paymentuser: paymentuserReducer,
  protectedpayments: protectedpaymentsReducer,
  makepayment: makePaymentReducer,
  requestpayment: requestPaymentReducer,
  notification: notificationReducer,
  paymentstab: paymentsTabReducer,
  recentContacts: recentContactsReducer,
  gigDetails: gigDetailsReducer,
  activities: activityReducer,
  allNotificationsReducer: allNotificationsReducer,
  messages: messagesReducer,
  myMoney: myMoneyReducer,
  topicmessages: topicMessagesReducer,
  inviteuser: inviteUserReducer,
  paymentcheckout:paymentCheckoutReducer,
  userProfile:userProfileReduer,
  editProfile:editProfileReduer,
  deeplinks:deepLinkingReducer,
  accounts:accountsReducer,
  helper:helperReducer,
  floating:floatingReducer,
  paymentpreview:paymentPreviewReducer,
  smartcollect:smartCollectReducer,
  recommendations:recommendationsReducer
})

export default reducer
