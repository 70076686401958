import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from "react-native";
import config from "../../config";
import { Icon, Textarea } from "native-base";
import InputText from "../InputText";
import PrimaryButton from "../PrimaryButton";
import { Formik } from "formik";
import * as yup from "yup";
import AIcon from "react-native-vector-icons/AntDesign";

export default class AddUpi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upiNumber: "",
      alertVisible: false,
    };
  }

  handleConfirmUpiNumber = (upiNumber) => {
    this.setState({ upiNumber });
  };
  handleSubmit = (values) => {
    if (values.upiNumber.trim() != "") {
      this.props.onSuccess(values.upiNumber);
    } else {
      alert("please enter proper value");
    }
  };

  render() {
    var { upiNumber } = this.state;
    const validationSchema = yup.object().shape({
      upiNumber: yup
        .string()
        .label("upiNumber")
        .required("This is a required field")
        .trim("This is a required field")
        .matches("[a-zA-Z0-9-+.-_]{2,256}@[a-zA-Z]{2,64}", "Invalid UPI ID"),
    });
    return (
      <Formik
        initialValues={{
          upiNumber: upiNumber,
        }}
        onSubmit={(values) => {
          this.handleSubmit(values);
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: "rgba(0,0,0,0.7)",
              justifyContent: "flex-end",
            }}
          >
            <View
              style={{
                backgroundColor: "#FFF",
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
                paddingTop: 10,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 20,
                }}
              >
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => {
                    this.props.onClose();
                  }}
                >
                  <AIcon
                    name="left"
                    style={{
                      color: this.props?.color ? this.props.color: config.primary_color,
                      width: 16,
                      height: 30,
                      marginTop: 5,
                      marginRight: 20,
                      fontSize: 25,
                    }}
                  />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Roboto-Medium",
                    color: this.props?.color ? this.props.color: config.primary_color,
                  }}
                >
                  Add UPI
                </Text>
              </View>

              <View style={styles.inputContainer}>
                <Text
                  style={{
                    fontSize: 14,
                    fontFamily: "Roboto-Regular",
                    color: "#AAA",
                    marginBottom: 20,
                  }}
                >
                  UPI ID is in the format of username@bank
                </Text>
                <InputText
                  name="upiNumber"
                  label=" Add Existing UPI ID"
                  placeholder="xyz@abc"
                  onChangeText={handleChange("upiNumber")}
                  onBlur={handleBlur("upiNumber")}
                  error={touched.upiNumber && errors.upiNumber}
                  value={values.upiNumber}
                />
                <View style={{ height: 20, width: "95%", paddingTop: 3 }}>
                  {touched.upiNumber && errors.upiNumber && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: "right",
                        fontFamily: "Roboto-Regular",
                        color: "#F90000",
                      }}
                    >
                      {errors.upiNumber}
                    </Text>
                  )}
                </View>
                <PrimaryButton 
                content={"confirm"} 
                clicked={handleSubmit}
                color={this.props?.color}
                />
              </View>
            </View>
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginTop: 5,
    marginRight: 20,
    fontSize: 25,
  },
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  inputContainer: {
    paddingHorizontal: "8%",
    paddingBottom: "10%",
  },
});
