import React, { Component } from "react";
import { View, Text, TextInput } from "react-native";

export default class InputText extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <View>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 16,
            color: "#464646",
            paddingLeft: 5,
          }}
        >
          {this.props.label}
          {this.props.label == "Email" ||
          this.props.label == "Referral code" ? (
            <Text style={{ fontSize: 12 }}> (Optional)</Text>
          ) : null}
        </Text>
        <View
          regular
          style={{
            marginTop: 3,
            backgroundColor: this.props.disabled ? "#AAA" : "#FFF",
            borderRadius: 10,
            height: 50,
            borderColor: "#DCDCDC",
            borderWidth: 1,
          }}
        >
          <TextInput
            placeholderTextColor={"#AAAAAA"}
            placeholder={this.props.placeholder}
            textContentType={this.props.type ? this.props.type : "name"}
            style={{
              fontSize: 14,
              padding: 15,
              borderRadius: 10,
              backgroundColor: this.props.disabled ? "#DCDCDC" : null,
            }}
            onPress={this.props.clicked}
            onChangeText={this.props.onChangeText}
            onBlur={this.props.onBlur}
            value={this.props.value}
            keyboardType={this.props.keyboard ? this.props.keyboard : "default"}
            autoFocus={this.props.autoFocus ? true : false}
            disabled={this.props.disabled ? this.props.disabled : false}
            maxLength={this.props.maxLength ? this.props.maxLength : null}
          />
        </View>
      </View>
    );
  }
}
