import {takeLatest, put, call, delay} from 'redux-saga/effects'
import {
  LOAD_PAYMENTS,
  CHANGE_PAGE,
  LOAD_PAYMENT_ERROR,
  LOAD_PAYMENT_SUCCESS,
} from '../constants/constants'
import PaymentsService from '../../services/payments.service'

const execPaticularUserTransactions = async actionData => {
  try {
    const result = await PaymentsService.getUserPayments({
      partnerid:actionData.uid
    })
    return result
  } catch (error) {
    // console.log(error)
    throw {message: 'Something went wrong'}
  }
}

function * loadPaticularUserTransactions (action) {
  try {
    const res = yield call(execPaticularUserTransactions, action)
    yield put({
      type: LOAD_PAYMENT_SUCCESS,
      response: {
        paymentDetails: res.paymentDetails,
        clickedUser: res.clickedUser,
      },
    })
  } catch (error) {
    yield put({type: LOAD_PAYMENT_ERROR, response: {message: error.message}})
  }
}

function * watchPayments () {
  yield takeLatest(LOAD_PAYMENTS, loadPaticularUserTransactions)
}

export default watchPayments
