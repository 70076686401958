import config from '../config';
import Interceptor from './Interceptor';

class UserService {
    getUser = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const result = await axiosObj.post(
              `${config.node_api_endpoint}/user`,
              params
              );
          return result.data.data;
        } catch (error) {
          // console.log(error);
          throw error.response.data;
        }
      };
      addFcmToken = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const result = await axiosObj.post(
              `${config.node_api_endpoint}/user/fcm`,
              params);
          return result.data.data;
        } catch (error) {
          // console.log(error);
          throw error.response.data;
        }
      };
      deleteFcmToken = async params => {
        try {
          let axiosObj = await Interceptor.getObject();
          const result = await axiosObj.post(
              `${config.node_api_endpoint}/user/fcm/delete`,
              params);
          return result.data.data;
        } catch (error) {
          // console.log(error);
          throw error.response.data;
        }
      };
}

export default new UserService();
