import React, { useEffect, useState } from "react";
import { Text, View, SafeAreaView, ScrollView } from "react-native";
import directpayService from "../../services/directpay.service";
import moment from "moment";
import config from "../../config";
import ProfileBadge from "../../assets/images/profileBadge";
import PrimaryButton from "../../components/PrimaryButton";
import { Badge } from "native-base";
import { ActivityIndicator } from "react-native-web";
import Logo from "../../assets/images/darkpurple";

const DirectPay = (props) => {
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOrderData();
  }, []);

  const getOrderData = async () => {
    setIsLoading(true);
    const data = await directpayService.getOrderdata({
      id: props.route.params.uid,
    });
    setOrder(data);
    setIsLoading(false);
  };

  const processPayout = async () => {
    try {
      setIsLoading(true);
      const {payout_status,order_status,vouch_reason} = await directpayService.acceptOrder({
        id: props.route.params.uid,
      });
      setOrder((order) => ({ ...order,seller_attempted:true, payout_status,order_status,vouch_reason }));
      setIsLoading(false);
    } catch (error) {
      window.alert(error.data.message);
      setOrder((order) => ({ ...order,seller_attempted:true }));
      setIsLoading(false);
    }
  };

  const utr = order?.transferDetails?.data?.utr || order?.transferDetails?.transfer_result?.[0]?.data.bankref
  const orderInfo = (key, value) => {
    return (
      <View style={{ flexDirection: "row", marginTop: 5 }}>
        <View style={{ flex: 0.4 }}>
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              color: "#000",
              marginBottom: 10,
              fontSize: 14,
            }}
          >
            {key}
          </Text>
        </View>
        <View style={{ flex: 0.1 }}>
        <Text style={{ fontFamily: "OpenSans-Regular" }}>:</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 16,
              color: "#000",
              marginBottom: 10,
              fontSize: 16,
            }}
          >
            {value}
          </Text>
        </View>
      </View>
    );
  };

  const key_value_pair = (key,value) => (
    <View style={{ flexDirection: "row", marginTop: 5 }}>
              <View>
                <Text
                  style={{
                    fontFamily: "OpenSans-Regular",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                    fontSize: 16,
                  }}
                >
                  {key}
                </Text>
              </View>
              <View style={{ paddingLeft: 10, flex: 1 }}>
                <Text
                  style={{
                    fontFamily: "OpenSans-Regular",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                    fontSize: 16,
                  }}
                >
                  : {value}
                </Text>
              </View>
            </View>
  )

  if (isLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size="large" color={config.primary_color} />
      </View>
    );
  }
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          style={{
            paddingVertical: "5%",
            paddingHorizontal: 30,
          }}
        >
          <Logo width="110" height="110" />
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 16,
              color: "#444",
              marginTop: 25,
              lineHeight: 24,
              marginBottom: 15,
            }}
          >
            You have received a payment from
            <Text
              style={{
                fontSize: 18,
                color: "#000",
                fontFamily: "OpenSans-Bold",
              }}
            >
              {" " + order?.brand_name}
            </Text>
          </Text>
          {/* {orderInfo('You have received a payment from:','\t'+order?.brand_name)} */}
          {orderInfo("Payment name", order?.gig_name)}
          {orderInfo("Amount", "\u20B9 " + order?.total_amount)}
          {orderInfo("Payment for", order?.requirements)}
          {utr && orderInfo("UTR", utr)}
          {order?.paid == true &&
          order?.settled == false &&
          order?.order_status == "paid" &&
          order?.payout_status == "yet_to_process" ? (
            <Text
              style={{
                fontFamily: "Roboto-Regular",
                fontSize: 16,
                color: "#000",
                lineHeight: 24,
                marginTop: 20,
              }}
            >
              Please verify your account details mentioned below and accept the
              payment.
            </Text>
          ) : null}
          <Text
            style={{
              fontFamily: "Roboto-Bold",
              fontSize: 16,
              color: "#000",
              marginTop: 15,
              lineHeight: 24,
            }}
          >
            Your{" "}
            {order?.beneficiary_details?.type == "vpa"
              ? "UPI_ID"
              : "Bank Details"}{" "}
            for money transfer
          </Text>
          {order?.beneficiary_details?.type == "vpa" ? (
            key_value_pair('UPI ID', order?.beneficiary_details?.upi_id)
          ) : (
            <View style={{ flexDirection: "row", marginTop: 10 }}>
              <View>
                <Text
                  style={{
                    fontFamily: "OpenSans-Regular",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                    fontSize: 16,
                  }}
                >
                  A/c No
                </Text>
                <Text
                  style={{
                    fontFamily: "OpenSans-Regular",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                    fontSize: 16,
                  }}
                >
                  IFSC
                </Text>
              </View>
              <View style={{ paddingLeft: 10, flex: 1 }}>
                <Text
                  style={{
                    fontFamily: "OpenSans-Regular",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                    fontSize: 16,
                  }}
                >
                  : {order?.beneficiary_details?.acNumber}
                </Text>
                <Text
                  style={{
                    fontFamily: "OpenSans-Regular",
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 10,
                    fontSize: 16,
                  }}
                >
                  : {order?.beneficiary_details?.ifsc}
                </Text>
              </View>
            </View>
          )}
          {order?.paid == true &&
          order?.settled == false &&
          order?.order_status == "paid" &&
          order?.payout_status == "yet_to_process"
          ? (
          order?.seller_attempted == false?
            <View style={{ paddingBottom: 20 }}>
              <PrimaryButton
                content={"Accept Payment"}
                loading={isLoading}
                clicked={processPayout}
              />
              <Text
                style={{
                  fontFamily: "OpenSans-Regular",
                  fontStyle: "italic",
                  fontSize: 12,
                  color: "#595959",
                  lineHeight: 20,
                  marginTop: 10,
                }}
                onPress={() => props.navigation.navigate("TermsAndConditions")}
              >
                By accepting the payment you agree to our{" "}
                <Text style={{ color: "blue" }}>Terms and conditions</Text>{" "}
                {"\n"}
                {"\n"}
                <Text style={{fontSize:14,fontStyle:'normal'}}>
                In case you need to make any changes to the payment details,
                term please contact {order?.brand_name} brand
                </Text>
              </Text>
            </View>:
              <Text style={{fontSize:14,fontStyle:'normal',marginTop:15}}>
              Please contact vouch for processing payout!
              </Text>
          ) : null}
          {order?.payout_status != "yet_to_process" ? (
            <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                marginBottom: "3%",
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Bold",
                  fontSize: 16,
                  color: "#000",
                  textAlign: "left",
                  flex: 0.9,
                  lineHeight: 22,
                }}
              >
                Payout Status
              </Text>
              {order.order_status == "completed" && order.payout_status == "processed" && (
                <Badge success>
                  <Text>success</Text>
                </Badge>
              )}
              {order.order_status == "failed" && order.payout_status == "failed" && (
                <Badge danger>
                  <Text style={{color:'#FFF'}}>Failed</Text>
                </Badge>
              )}
              {order.order_status == "paid" && order.payout_status == "initiated" && (
                <Badge warning>
                  <Text>initiated</Text>
                </Badge>
              )}
            </View>
          ) : null}
          {
            order?.vouch_reason && key_value_pair('remarks',order?.vouch_reason)
          }
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default DirectPay;
