//import liraries
import React, {Component, useState, useEffect, useRef} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator
} from 'react-native'
import {
  Icon,Tab, Tabs
} from 'native-base'
import {connect} from 'react-redux'
import config from '../../config'
import InProgressTab from '../../components/protected_payments/InProgressTab'
import CompletedTab from '../../components/protected_payments/CompletedTab'
import RejectedTab from '../../components/protected_payments/RejectedTab'
import AIcon from 'react-native-vector-icons/AntDesign';
import { TabView, SceneMap ,TabBar} from 'react-native-tab-view';
import {
  changeProtectedPage,
  loadProtectedPayments,
} from '../../state-management/actions//protectedpayments.action'


const ProtectedPayments = props => {

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'Pending', title: 'Pending' },
    { key: 'InProgress', title: 'In Progress' },
    { key: 'Completed', title: 'Completed' },
    { key: 'Rejected', title: 'Rejected' },
  ]);

  const renderScene = ({ route }) => {
    switch (route.key) {
      case 'Pending':
        return <InProgressTab {...props} showOnlyPending={true}/>
      case 'InProgress':
        return <InProgressTab {...props} showOnlyPending={false}/>
      case 'Completed':
        return <CompletedTab {...props} />
      case 'Rejected':
        return <RejectedTab {...props} />
      default:
        return null;
    }
  };
  const renderTabBar = props => (
    <TabBar

      {...props}
      renderLabel={({ route, focused, color }) => (
        <Text style={{ color, margin: 8 ,fontFamily:'Roboto-Bold'}}>
          {route.title}
        </Text>
      )}
      indicatorStyle={{ backgroundColor: 'white' }}
      style={{ backgroundColor: '#FFF',
      shadowOffset: { height: 0, width: 0 }, 
      shadowColor: 'transparent',
      shadowOpacity: 0,
      elevation: 0  }}
      activeColor = {config.primary_color}
      inactiveColor = {'#000'}
    />
  )

  const _renderLazyPlaceholder = ({ route }) => <LazyPlaceholder route={route} />;

  const LazyPlaceholder = ({ route }) => (
    <View style={styles.scene}>
      <Text>Loading {route.title}…</Text>
    </View>
  );

  useEffect(() => {
    const unsubscribe = props.navigation.addListener('focus', () => {
    props.loadProtectedPayments()
    })
    return unsubscribe
  }, [])

  if (props.error) {
    alert(props.error)
  }
  return props.isLoading?
  <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',backgroundColor:'#FFF'}}>
    <ActivityIndicator size="large" color={config.primary_color} />
  </View>:(
    <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
      <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            padding: 20,
            paddingBottom: 30,
          }}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => props.navigation.goBack()}>
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: 24,
              fontFamily: 'Roboto-Medium',
              color: config.primary_color,
            }}>
            Protected payments
          </Text>
        </View>

        <TabView
    lazy={true}
    renderTabBar={renderTabBar}
      navigationState={{ index, routes }}
      renderScene={renderScene}
      onIndexChange={setIndex}
      // initialLayout={initialLayout}
      renderLazyPlaceholder={_renderLazyPlaceholder}
      style={{
        shadowOffset: { height: 0, width: 0 }, 
 shadowColor: 'transparent',
 shadowOpacity: 0,
 elevation: 0 
      }}
    />
      

    </SafeAreaView>
  )
}

// onPress={() => {
//   this.setState({mode: true}, () => {
//     this.pager.setPage(1);
//   });

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    height: 30,
    marginRight: 20,
    fontSize:25
  },
  inputContainer: {
    padding: '8%',
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  partnerElement: {
    flexDirection: 'row',
    borderColor: '#AAA',
    marginBottom: 10,
  },
  default: {
    textTransform: 'capitalize',
  },
  viewPager: {
    flex: 1,
  },
  scene: {
    flex: 1,
  },
})

//make this component available to the app
export default connect(state => ({...state.protectedpayments}), {
  changeProtectedPage,
  loadProtectedPayments,
})(ProtectedPayments)
