import React, { Component } from "react";
import {
  Image,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  View,
  Text,
  TouchableOpacity,
  Share,
} from "react-native";
import PrimaryButton from "../../components/PrimaryButton";
import moment from "moment";
import {
  initState,
  makeTransaction,
} from "../../state-management/actions/paymentdetail.action";
import { connect } from "react-redux";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../../config";
import MIcon from "react-native-vector-icons/MaterialCommunityIcons";
import EditForSale from "../../components/Profile/EditForSale";
import {
  editGigForSaleAction,
  deleteGigForSale,
} from "../../state-management/actions/editprofile.action";
import { Modal } from "react-native-paper";
import AwesomeAlert from "react-native-awesome-alerts";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as Clipboard from "expo-clipboard";

class ForSaleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "",
      imageVisible: false,
      editForsaleFlag: false,
      conformDeleteFlag: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.proceedToPayment != prevProps.proceedToPayment) {
      if (this.props.proceedToPayment == true) {
        this.props.navigation.navigate("ForSaleBuying", {
          transaction_details: this.props.transaction_details,
        });
      }
    }
  }

  componentDidMount() {
    this.props.initState();
  }

  render() {
    return this.props.editProfile.isLoading ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
        }}
      >
        <ActivityIndicator size='large' color={config.primary_color} />
      </View>
    ) : (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff"}}>
      
        <ScrollView showsVerticalScrollIndicator={false}>
          <TouchableOpacity
            onPress={() => {
              if(history && history.back){
                history.back()
              }else this.props.navigation.goBack();
            }}
            style={{
              position: "absolute",
              width: 35,
              height: 35,
              margin: "3%",
              zIndex: 100,
              backgroundColor: "rgba(0,0,0,0.5)",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 50,
            }}
          >
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          {this.props?.editProfile.selectedForSale?.images?.map(
            (item, index) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    let container = document.getElementById("imagePopContainer")
                    if(container){
                      container.style.display = "none";
                      let imagePopup = document.getElementById("popupViewImage")
                      if(imagePopup){
                        imagePopup.src = item;
                      }
                    }
                    let popupDiv = document.createElement("div");
                    popupDiv.style = "display:flex; align-items:center; justify-content:center; background: rgb(0,0,0,0.8); width:100%; height:100%, position:fixed; z-index:11; cursor:pointer;"
                    popupDiv.id = "PopContainer";
                    popupDiv.onclick = (e) => { popupDiv.style.display = "none" } ;
                    popupDiv.innerHTML = `<img src="${item}" style="padding: 1rem; max-width:80%; max-height:"80%;" />`;

                    document.getElementById("root").appendChild(popupDiv);

                  }}
                >
                  <Image
                    source={{ uri: item }}
                    style={{ height: 300, left: 0, right: 0, margin:"1rem", marginTop : (index == 0) ? "5rem" : "1.5rem", border:"4px solid white", boxShadow : "0px 0px 5px 5px #aaaaaa" }}
                    resizeMode='cover'
                  />
                </TouchableOpacity>
              );
            }
          )}
          <View style={{ margin: "7%" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 15,
              }}
            >
              <Text
                style={{
                  fontFamily: "OpenSans-SemiBold",
                  fontSize: 16,
                  color: "#000",
                  flex: 0.8,
                }}
              >
                {this.props?.editProfile.selectedForSale?.gig_name}
              </Text>
              {this.props?.editProfile?.selectedForSale.to_user ==
              firebase.auth()?.currentUser?.uid ? (
                <View
                  style={{
                    flex: 0.2,
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ editForsaleFlag: true });
                    }}
                  >
                    <MIcon
                      name='pencil'
                      style={{ color: "#318EF8", fontSize: 20 }}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      this.setState({ conformDeleteFlag: true });
                    }}
                  >
                    <MIcon
                      name='delete'
                      style={{ color: "#318EF8", fontSize: 20 }}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}
            </View>
            <Text
              style={{
                fontFamily: "OpenSans-Regular",
                fontSize: 10,
                color: "#000",
                marginBottom: 12,
              }}
            >
              {moment(
                this.props?.editProfile.selectedForSale?.createdAt
              ).format("DD-MM-YYYY")}
            </Text>
            <Text
              style={{
                fontFamily: "OpenSans-Regular",
                fontSize: 16,
                color: "#000",
                marginBottom: 30,
              }}
            >
              {this.props?.editProfile.selectedForSale?.description}
            </Text>
            {firebase.auth().currentUser.uid !=
            this.props.profile.requestedProfile.id ? (
              <View>
                <TouchableOpacity
                  onPress={() => {
                    Clipboard.setString(
                      `https://app.getvouched.co/${this.props.profile.requestedProfile.username}/store/${this.props?.editProfile?.selectedForSale?.id}`
                    );
                    Share.share({
                      message: `https://app.getvouched.co/${this.props.profile.requestedProfile.username}/store/${this.props?.editProfile?.selectedForSale?.id}`,
                    });
                  }}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 10,
                    marginTop: 25,
                  }}
                >
                  <AIcon
                    name='sharealt'
                    style={{ color: "#0C76EF", fontSize: 25 }}
                  />
                  <Text
                    style={{
                      fontFamily: "Roboto-Regular",
                      color: "#0C76EF",
                      fontSize: 16,
                      textAlign: "center",
                      marginLeft: 12,
                    }}
                  >
                    Share
                  </Text>
                </TouchableOpacity>
                <PrimaryButton
                  content={`Buy for \u20B9 ${this.props?.editProfile.selectedForSale?.total_amount}`}
                  loading={this.props.isLoading}
                  clicked={() => {
                    var { mobile } = this.props.route.params;
                    var data = this.props?.editProfile?.selectedForSale;
                    this.props.makeTransaction(
                      "buying",
                      {
                        amount: data.total_amount,
                        gigName: data.gig_name,
                        requirements: data.requirements,
                      },
                      data.milestones,
                      data.milestones.length != 0 ? true : false,
                      data.category,
                      mobile,
                      data.classification
                    );
                  }}
                />
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  Clipboard.setString(
                    `https://app.getvouched.co/${this.props.profile.requestedProfile.username}/store/${this.props?.editProfile?.selectedForSale?.id}`
                  );
                  Share.share({
                    message: `https://app.getvouched.co/${this.props.profile.requestedProfile.username}/store/${this.props?.editProfile?.selectedForSale?.id}`,
                  })
                    .then(result => {})
                    .catch(err => {});
                }}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 40,
                  marginTop: 5,
                }}
              >
                <AIcon
                  name='sharealt'
                  style={{ color: "#0C76EF", fontSize: 25 }}
                />
                <Text
                  style={{
                    fontFamily: "Roboto-Regular",
                    color: "#0C76EF",
                    fontSize: 16,
                    textAlign: "center",
                    marginLeft: 12,
                  }}
                >
                  Share
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </ScrollView>
        <AwesomeAlert
          show={this.state.conformDeleteFlag}
          title=''
          message=''
          messageStyle={{ textAlign: "center" }}
          customView={
            <View
              style={{
                alignItems: "center",
                lineHeight: 21,
                paddingHorizontal: 5,
              }}
            >
              <Text
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 16,
                  color: "#707070",
                  lineHeight: 21,
                }}
              >
                Are you sure you want to permanently remove this item?
              </Text>
            </View>
          }
          showCancelButton={true}
          showConfirmButton={true}
          cancelText='No'
          confirmText='Yes'
          confirmButtonColor={config.primary_color}
          onCancelPressed={() => {
            this.setState({ conformDeleteFlag: false });
          }}
          onConfirmPressed={() => {
            this.props.deleteGigForSale(
              this.props?.editProfile?.selectedForSale?.id
            );
            this.setState({ conformDeleteFlag: false });
            this.props.navigation.goBack();
          }}
        />
        <Modal
          visible={this.state.editForsaleFlag}
          contentContainerStyle={{ backgroundColor: "white", flex: 1 }}
          onDismiss={() => {
            this.setState({ editForsaleFlag: false });
          }}
        >
          <EditForSale
            cameBack={() => {
              this.setState({ editForsaleFlag: false });
            }}
            onClose={(
              data,
              milestones,
              milestone_mode,
              classification,
              category,
              images,
              removed_images
            ) => {
              this.props.editGigForSaleAction(
                { ...data, id: this.props?.editProfile?.selectedForSale?.id },
                milestones,
                milestone_mode,
                classification,
                category,
                images,
                removed_images
              );
              this.setState({ editForsaleFlag: false });
            }}
            data={this.props?.editProfile?.selectedForSale}
          />
        </Modal>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: "#FFF",
    fontSize: 20,
  },
});

export default connect(
  state => ({
    profile: { ...state.userProfile },
    editProfile: { ...state.editProfile },
    ...state.paymentdetail,
  }),
  { initState, makeTransaction, editGigForSaleAction, deleteGigForSale }
)(ForSaleDetail);
