import React, { useEffect, useState } from "react";
import { Text, View } from "react-native";
import LottieView from "lottie-react-web";

import loadingAnim from "../../assets/lottie-animations/payment-processing.json";
import config from "../../config";
import miscService from "../../services/misc.service";

const VouchUPITimer = (props) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((oldtimer) => oldtimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);



  useEffect(()=>{
    if(timer>320) props?.onCollectFailed()
    else{
        if( (timer > 10 && timer <=600) && timer%15 == 0) checkCollectStatus()
    }
  },[timer]);




  const checkCollectStatus = async () => {
    const statusPayload = await miscService.check_upi_collection_status({
        collect_ref: props.collect_ref,
        order_id:props.order_id,
        timer:timer
    });
    if(statusPayload?.txStatus == "SUCCESS") props?.onCollectSuccess(statusPayload);
    if(statusPayload?.txStatus == "collect_declined") props?.onCollectFailed()
  }


  return (
    <>
      <View style={{ width: 300, height: 300 }}>
        <LottieView
          options={{ animationData: loadingAnim, autoplay: true, loop: true }}
        />
      </View>


      <View style={{width:300,height:20,backgroundColor:"#DDD",borderRadius:100,marginBottom:50,overflow:"hidden"}}>
            <View style={{width:`${(timer*100)/300}%`,height:20,backgroundColor:config.primary_color,borderRadius:100}}></View>
      </View>

      
      <Text
        style={[
          {
            fontSize: 16,
            color: config.primary_color,
            padding: 14,
            textAlign:'left',
          },
          { fontFamily: "OpenSans-Bold" },
        ]}
      >
        Waiting for confirmation
      </Text>

      <Text
      style={{
        fontFamily: "OpenSans-Regular",
        color:"#555",
        fontSize: 12,
        lineHeight:30
      }}
      >
      1. Open your UPI application on your mobile{'\n'}2. Enter the MPIN to complete the payment{'\n'}This UPI payment request will expire in 10 minute(s).
      </Text>



      <Text style={{
        fontFamily: "OpenSans-Regular",
        fontSize:18,
        marginTop:"10%",
        color:"#f44336"
      }}>
        Do not Refresh the Page or navigate Away
      </Text>

    </>
  );
};

export default VouchUPITimer;
