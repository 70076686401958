import {SERVICE_SELECTED, PRODUCT_SELECTED, RESET,LOAD_CATEGORIES,LOAD_CATEGORIES_ERROR,LOAD_CATEGORIES_SUCCESS, SET_PAYMENT_AMOUNT, SET_CONTACT_INFO, RESET_CONTACT_INFO} from '../constants/constants'

const initialState = {
  isServiceSelected: false,
  isProductSelected: false,
  selectedService: '',
  selectedProduct: '',
  paymentAmount:null,
  name:null,
  mobile:null,
  seller_count:null,
  seller_review:null,
  buyer_review:null,
  buyer_count:null,
  username:null,
  services: [
    {
      title: 'Graphics and Design',
    },
    {
      title: 'Digital Marketing',
    },
    {
      title: 'Writing & Translation',
    },
    {
      title: 'Video & Animation',
    },
    {
      title: 'Music & Audio',
    },
    {
      title: 'Coding & Tech',
    },
    {
      title: 'Domains',
    },
    {
      title: 'Other',
    },
  ],
  products: [
    {
      title: 'Electronics',
    },
    {
      title: 'Fashion',
    },
    {
      title: 'Jewellery',
    },
    {
      title: 'Shoes',
    },
    {
      title: 'Art',
    },    
    {
      title: 'Motorcycles',
    },
    {
      title: 'Cars',
    },
    {
      title: 'Books',
    },
    {
      title: 'Furniture',
    },
    {
      title: 'Sports Equipment',
    },
    {
      title: 'Real Estate',
    },
    {
      title: 'Other',
    },  
  ],
}

const reducer = function categoryReducer (state = initialState, action) {

  switch (action.type) {
    case LOAD_CATEGORIES:{
      return{
        ...state,
        isLoading:true
      }
    }
    case LOAD_CATEGORIES_SUCCESS:{
      return{
        ...state,
        services:action.response.services,
        products:action.response.products,
        isLoading:false
      }
    }
    case LOAD_CATEGORIES_ERROR:{
      return{
        ...state,
        isLoading:false
      }
    }
    case SERVICE_SELECTED: {
      return {
        ...state,
        isServiceSelected: true,
        isProductSelected: false,
        selectedProduct: '',
        selectedService: action.service,
      }
    }
    case PRODUCT_SELECTED: {
      return {
        ...state,
        isServiceSelected: false,
        isProductSelected: true,
        selectedProduct: action.product,
        selectedService: '',
      }
    }
    case SET_PAYMENT_AMOUNT: {
      return {
        ...state,
        paymentAmount:action.amount
      }
    }
    case SET_CONTACT_INFO: {
      return {
        ...state,
        name:action.name,
        mobile:action.number,
        username:action.username,
        seller_count:action.seller_count,
        seller_review:action.seller_review,
        buyer_review:action.buyer_review,
        buyer_count:action.buyer_count,      
      }
    }
    case RESET_CONTACT_INFO: {
      return {
        ...state,
        isServiceSelected: false,
        isProductSelected: false,
        selectedService: '',
        selectedProduct: '',
        paymentAmount:null,
        name:null,
        mobile:null,
        username:null,
        seller_count:null,
        seller_review:null,
        buyer_review:null,
        buyer_count:null,
      }
    }
    case RESET: {
      return {
        ...state,
        isServiceSelected: false,
        isProductSelected: false,
        selectedService: '',
        selectedProduct: '',
        paymentAmount:null
      }
    }
    default:
      return state
  }
}

export default reducer
