import {takeLatest, put, call, delay} from 'redux-saga/effects'
import {
  LOAD_RECENT_CONTACTS,
  LOAD_RECENT_CONTACTS_ERROR,
  LOAD_RECENT_CONTACTS_SUCCESS,
  SHOW_TOAST,
} from '../constants/constants'
import ProfileService from '../../services/profile.service'

const execLoadRecentContacts = async actionData => {
  try {
    var result = await ProfileService.getRecentContacts()
    return result
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'}
  }
}

function * loadRecentContacts (action) {
  try {
    const res = yield call(execLoadRecentContacts, action)
    yield put({
      type: LOAD_RECENT_CONTACTS_SUCCESS,
      response: {
        recentsContacts: res.transctions_with_duplicates_removed,
        total_payments_length: res.total_payments_length,
        transactions_completed_length:res.transactions_completed_length,
        expressIntrestUsers:res.express_intrest_users,
        accountAdded:res.account_added,
        sum: res.sum,
      },
    })
  } catch (error) {
    // console.log(error);
    yield put({
      type: LOAD_RECENT_CONTACTS_ERROR,
      response: {message: error.message},
    })
  }
}

function * watchRecentContacts () {
  yield takeLatest(LOAD_RECENT_CONTACTS, loadRecentContacts)
}

export default watchRecentContacts
