import React, {Component} from 'react'
import {View, Text, ScrollView, SafeAreaView} from 'react-native'
import Header from '../../components/Header'
import moment from 'moment'

export default class TransactionDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      text1: '',
      text2: '',
      date: null,
      id: null,
    }
  }
  componentDidMount () {
    this.getCategory()
  }
  getCategory = () => {
    try {
      var category = this.props.route.params.transactionData.category
      switch (category) {
        case 'paidList':
          this.setState({
            text1: 'Paid to',
            text2: 'Securely received and held by vouch',
            date: moment(
              this.props.route.params.transactionData.payment_details.txTime,
            ).format('DD MMM YYYY hh:mm a'),
            id: this.props.route.params.transactionData.payment_details.orderId,
          })
          break
        case 'recievedList':
          this.setState({
            text1: 'Received from',
            text2: 'Settled to your bank A/c',
            date: moment(
              this.props.route.params.transactionData.transferDetails.createdAt.toDate(),
            ).format('DD MMM YYYY hh:mm a'),
            id: this.props.route.params.transactionData.transferDetails
              .transferId,
          })
          break
        case 'toBeRecievedList':
          this.setState({
            text1: 'Received by vouch from',
            text2: 'Securely received and held by vouch',
            date: moment(
              this.props.route.params.transactionData.payment_details.txTime,
            ).format('DD MMM YYYY hh:mm a'),
            id: this.props.route.params.transactionData.payment_details.orderId,
          })
          break
        case 'settledList':
          this.setState({
            text1: 'Settled to',
            text2: 'Settled to Seller bank A/c',
            date: moment(
              this.props.route.params.transactionData.transferDetails.createdAt.toDate(),
            ).format('DD MMM YYYY hh:mm a'),
            id: this.props.route.params.transactionData.transferDetails
              .transferId,
          })
          break
        default:
          break
      }
    } catch (error) {
      // console.log(error)
    }
  }
  render () {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Header title={'Transaction'} navigation={this.props.navigation} />
          <View style={{alignItems: 'center'}}>
            <View
              style={{
                backgroundColor: this.props.route.params.transactionData
                  .header_color,
                width: 50,
                height: 50,
                justifyContent: 'center',
                borderRadius: 50,
                alignSelf: 'center',
                marginVertical: 20,
              }}>
              <Text
                style={{
                  fontSize: 21,
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily:'Roboto-Regular'
                }}>
                {this.props.route.params.transactionData.to_user_name[0]}
              </Text>
            </View>
            <Text
              style={{
                fontSize: 47,
                color: '#473BB3',
                fontFamily: 'Roboto-Regular',
              }}>
              {`\u20B9${this.props.route.params.transactionData.milestoneAmount}`}
            </Text>
            <Text
              style={{
                fontSize: 16,
                color: '#464646',
                fontFamily: 'Roboto-Regular',
                marginBottom: 8,
                marginTop: 15,
              }}>
              {this.state.text1}
            </Text>
            <Text
              style={{
                fontSize: 16,
                color: '#464646',
                fontFamily: 'Roboto-Regular',
              }}>
              {this.props.route.params.transactionData.to_user_name}
            </Text>
            <View
              style={{
                width: '85%',
                borderRadius: 20,
                borderWidth: 1,
                borderColor: '#DCDCDC',
                padding: '5%',
                marginTop: 30,
              }}>
              <Text
                style={{
                  fontSize: 14,
                  color: '#464646',
                  fontFamily: 'Roboto-Regular',
                }}>
                {this.state.text2}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: '#828282',
                  fontFamily: 'Roboto-Regular',
                  marginTop: 10,
                  marginBottom: 20,
                }}>
                {this.state.date}
              </Text>
              {/* <Text
                style={{
                  fontSize: 14,
                  color: '#464646',
                  fontFamily: 'Roboto-Regular',
                }}>
                Transaction ID
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: '#828282',
                  fontFamily: 'Roboto-Regular',
                  marginTop: 10,
                  marginBottom: 20,
                }}>
                {this.state.id}
              </Text> */}
              <Text
                numberOfLines={1}
                style={{
                  fontSize: 14,
                  color: '#464646',
                  fontFamily: 'Roboto-Regular',
                }}>
                {this.props.route.params.transactionData.details}
              </Text>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    )
  }
}
