import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, ImageBackground } from "react-native";
import config from "../../config";
const ProfileCard = props => {
  return (
    <TouchableOpacity
      onPress={() => {
        props.clicked();
      }}
      style={{
        backgroundColor: "#FFF",
        borderRadius: 10,
        marginBottom: 25,
        marginLeft: 20,
        marginRight: 20,
        elevation: 4,
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      }}
    >
      <ImageBackground
        source={{ uri: props.images[0] }}
        imageStyle={{
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomLeftRadius: props?.gig_name ? null : 10,
          borderBottomRightRadius: props?.gig_name ? null : 10,
        }}
        style={{ width: "100%", height: props?.gig_name ? 200 : 230 }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            backgroundColor: "rgba(0,0,0,0.6)",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomLeftRadius: props?.gig_name ? null : 10,
            borderBottomRightRadius: props?.gig_name ? null : 10,
            paddingVertical: 20,
            paddingHorizontal: 15,
          }}
        >
          <Text
            numberOfLines={1}
            style={{
              fontFamily: "OpenSans-Bold",
              fontSize: 15,
              color: "#FFF",
              textAlign: props?.gig_name ? "center" : "left",
              lineHeight: 21,
            }}
          >
            {props?.gig_name ? props?.gig_name : props?.title}
          </Text>
        </View>
      </ImageBackground>
      {props?.title ? null : (
        <View>
          <Text
            style={{
              fontFamily: "OpenSans-SemiBold",
              fontSize: 16,
              color: config.primary_color,
              textAlign: "center",
              marginTop: 10,
            }}
          >
            Buy for {"\u20B9"} {props.total_amount}
          </Text>
          <Text
            style={{
              fontFamily: "OpenSans-Regular",
              fontSize: 14,
              color: config.primary_color,
              textAlign: "center",
              marginTop: 5,
              marginBottom: 10,
            }}
          >
            View Details
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

export default ProfileCard;
