import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  FlatList,
  ImageBackground,
} from 'react-native';
import {Textarea} from 'native-base';
import config from '../../config';
import InputText from '../InputText';
import FIcon from 'react-native-vector-icons/AntDesign';
import MilestoneCard from '../MilestoneCard';
import MileStoneOverlay from '../MilestoneOverlay';
import {Formik} from 'formik';
import * as yup from 'yup';
import {connect} from 'react-redux';
import {
  addMileStone,
  deleteMileStone,
  editMileStone,
  initState,
  makeTransaction,
  changeMilestoneMode
} from '../../state-management/actions/paymentdetail.action';
import PaymentDetailsButton from '../PaymentDetailsButton';
import AIcon from 'react-native-vector-icons/AntDesign';
import SVG1 from '../../assets/images/paydetails1.svg';
import SVG2 from '../../assets/images/paydetails2.svg';
import { resetContactInfo } from '../../state-management/actions/category.action'
import Picker from '../Picker'
import {
    serviceSelected,
    productSelected,
  } from '../../state-management/actions/category.action'
import { Modal } from 'react-native-paper';
import AwesomeAlert from 'react-native-awesome-alerts';
import imageService from '../../services/image.service';

class EditForSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterVisible: false,
      alertVisible: false,
      amount: '',
      gigName: '',
      requirements: '',
      mode: 'add',
      currentMilestone: {},
      totalMilestoneAmount: 0,
      feedbackVisible: false,
      alertText: '',
      imageModal:false,
      images:[],
      errorAlert:false,
      oldImages:[],
      removedImagesArray:[]
    };
  }

  componentDidMount() {
    this.props.initState();
    this.props.resetContactInfo()
    this.setState({oldImages:this.props?.data?.images})
    if(this.props.data.classification=='service') this.props.serviceSelected(this.props.data.category);
    else this.props.productSelected(this.props.data.category)
    if(this.props.data.milestones.length){
      this.props.changeMilestoneMode()
      this.props.data.milestones.forEach(element => {
        this.props.addMileStone(element)
      });
    }
  }


  handleAmountChange = amount => {
    this.setState({amount});
  };
  handleGigNameChange = gigName => {
    this.setState({gigName});
  };
  handlerequirements = requirements => {
    this.setState({requirements});
  };
  makeOverlaytrue = (mode, data, fullAmount) => {
    var totalMilestoneAmount = this.props.milestones.reduce((prev, cur) => {
      return parseFloat(prev) + parseFloat(cur.milestoneAmount);
    }, 0);
    switch (mode) {
      case 'add':
        this.setState({
          filterVisible: true,
          mode: 'add',
          totalMilestoneAmount: totalMilestoneAmount,
          fullAmount: fullAmount,
        });
        break;
      case 'edit':
        this.setState({
          filterVisible: true,
          mode: 'edit',
          currentMilestone: data,
          totalMilestoneAmount: totalMilestoneAmount,
          fullAmount: fullAmount,
        });
      default:
        break;
    }
  };
  handleSubmit = values => {
    if(!(this.props.categoryData.isServiceSelected || this.props.categoryData.isProductSelected)){
      this.setState({errorAlert:true});
      this.setState({alertText:'Please select a category'})
    }else if(this.state.images.length+this.state.oldImages.length==0){
      this.setState({errorAlert:true});
      this.setState({alertText:'Please upload an image'})
    }
    else{
      const mobile = 10;
      const total_amount = parseFloat(values.amount);
      if (total_amount < 10) {
        this.setState({alertVisible: true});
        this.setState({alertText: 'Minimum amount should be \u20B910'});
        return;
      }
      const totalMilestoneAmount = this.props.milestones.reduce((prev, cur) => {
        return parseFloat(prev) + parseFloat(cur.milestoneAmount);
      }, 0);
      const balance = total_amount - totalMilestoneAmount;
      if (this.props.milestones.length) {
        if (balance) {
          this.setState({alertVisible: true});
          this.setState({alertText:'Enter additional milestones till the value of all milestones equals order value. You may remove Milestones if this is not a Milestone based payment.'});
          return;
        }
      }
      this.props.onClose(
        values,
        this.props.milestones,
        this.props.mode,
        this.props.categoryData.isServiceSelected?'service':'product',
        this.props.categoryData.isServiceSelected ? this.props.categoryData.selectedService : this.props.categoryData.selectedProduct,
        this.state.images,
        this.state.removedImagesArray
        )
    }
  };
  onValueChange(value) {
    this.setState({
      selected: value,
    });
  }

  renderHeader= () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={()=>{this.setState({feedbackVisible:true})}}>
          <AIcon name="left" style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: 'OpenSans-Semibold',
            color: config.primary_color,
          }}>
          Edit gig for sale 
        </Text>
      </View>
    );
  };

  imageUpload = () => {
    var el = document.getElementById("profile-pic");
    el.click();
    el.addEventListener("change", ($event) => {
      var file = $event.target.files?.length ? $event.target.files[0] : null;
      if (file) {
        if(file && !file.type.includes("image")
          // && !file.type.includes("pdf")
          ){
          el.value = null;
          alert("Unsupported File Format!");
          return;
        }
        if (file.size < 2000000) {
          imageService.toBase64(file).then((data) => {
            file.data = data
            var arr=this.state.images
            arr.push(file)
            arr=arr.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
            this.setState({images:arr})
          });
        } else {
          window.alert("Max image size is 2 MB");
        }
      }
    });
  }
  
  render() {
    const {amount, gigName, requirements} = this.state;
    const validationSchema = yup.object().shape({
      amount: yup
        .string()
        .label('amount')
        .min(2, 'Minimum amount should be \u20B910')
        .required('This field is required')
        .trim()
        .matches(/^[0-9-+()]+$/, 'This field is not Valid :('),
      gigName: yup
        .string()
        .required()
        .trim()
        .label('gigName'),
      requirements: yup
        .string()
        .required()
        .trim()
        .label('requirements'),
      description:yup
        .string()
        .required()
        .trim()
        .label('description')
    });

    return (
      <Formik
        initialValues={{
          amount: this.props?.data?.total_amount.toString(),
          gigName: this.props?.data?.gig_name,
          requirements: this.props?.data?.requirements,
          description:this.props?.data?.description
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
            <input type="file" id="profile-pic" hidden accept='image/jpeg, image/png'/>
            {this.renderHeader()}
              <View style={styles.inputContainer}>

                                  {/* **********************************  Gig Name Input *********************************** */}

                <InputText
                  name="gigName"
                  label="Name"
                  placeholder="Enter name of gig"
                  onChangeText={handleChange('gigName')}
                  onBlur={handleBlur('gigName')}
                  error={touched.gigName && errors.gigName}
                  value={values.gigName}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.gigName && errors.gigName && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      GIG Name is required
                    </Text>
                  )}
                </View>

                {/* **********************************  Gig Name Input End   *********************************** */}
                {/* *****************************  Select  **************************** */}

                <View style={{marginBottom: 40,marginTop:15}}>
            <Text style={styles.txt2}>Services</Text>
            <Picker
              name='Select Service'
              mode='0'
              data={this.props.categoryData.services}
              onServiceSelected={serv => this.props.serviceSelected(serv)}
              imgName={require('../../assets/images/Group84.png')}
              deSelect={this.props.categoryData.isProductSelected}
              
            />
            <Text
              style={{
                textAlign: 'center',
                fontFamily: 'OpenSans-Bold',
                fontSize: 20,
                marginVertical: '5%',
              }}>
              Or
            </Text>
            <Text style={styles.txt2}>Products</Text>
            <Picker
              name='Select Product'
              mode='1'
              data={this.props.categoryData.products}
              onServiceSelected={prod => this.props.productSelected(prod)}
              imgName={require('../../assets/images/Group76.png')}
              deSelect={this.props.categoryData.isServiceSelected}
            />
          </View>



                {/* **********************************  Requirements Input *********************************** */}

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Details to display on profile
                </Text>

                <Textarea
                  rowSpan={4}
                  bordered
                  name="description"
                  placeholder={'Enter a description of the gig you offer for sale.'}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('description')}
                  onBlur={handleBlur('description')}
                  error={touched.description && errors.description}
                  value={values.description}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3,marginBottom:20}}>  
                  {touched.description && errors.description && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify description
                    </Text>
                  )}
                </View>



                {/* **********************************  Requirements Input *********************************** */}


                                {/* **********************************  Amount Input *********************************** */}

                <InputText
                  name="amount"
                  label="Price"
                  placeholder="Please Enter Amount"
                  onChangeText={handleChange('amount')}
                  onBlur={handleBlur('amount')}
                  error={touched.amount && errors.amount}
                  value={values.amount}
                  keyboard="numeric"
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.amount && errors.amount && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      {errors.amount}
                    </Text>
                  )}
                </View>

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                    marginTop:20
                  }}>
                    Add image
                </Text>

                <View style={{flexDirection:'row',justifyContent:'flex-start',flexWrap:'wrap',marginVertical:10}}>
                        {this.state.oldImages.map((item,index)=>{
                               return(<ImageBackground 
                                    source={{uri:item}}
                                    imageStyle={{borderRadius:15}}
                                    style={{width:100,height:100,alignItems:'flex-end',paddingTop:5,paddingRight:5,marginRight:10,marginTop:10}}>
                                        <AIcon name='closecircle' style={{fontSize:15}} onPress={() => {
                                            this.setState({removedImagesArray:[...this.state.removedImagesArray,item]})
                                            var a=this.state.oldImages
                                            delete a[index];
                                            a=a.filter(i => i);
                                            this.setState({oldImages:a})
                                        }}/>
                                    </ImageBackground>)
                        })}
                        {this.state.images.map((item,index)=>{
                               return(<ImageBackground 
                                    source={{uri:item.data}}
                                    imageStyle={{borderRadius:15}}
                                    style={{width:100,height:100,alignItems:'flex-end',paddingTop:5,paddingRight:5,marginRight:10,marginTop:10}}>
                                        <AIcon name='closecircle' style={{fontSize:15}} onPress={() => {
                                            var a=this.state.images
                                            delete a[index];
                                            a=a.filter(i => i);
                                            this.setState({images:a})
                                        }}/>
                                    </ImageBackground>)
                        })}
                        {this.state.images.length+this.state.oldImages.length<5?
                    <TouchableOpacity 
                    onPress={()=>{this.imageUpload()}}
                    style={{width:100,height:100,justifyContent:'center',alignItems:'center',marginTop:10,backgroundColor:'#FFF',borderRadius:15,elevation:2,shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,}}>
                      <AIcon name='pluscircleo' style={{color:config.primary_color,fontSize:40}} />
                    </TouchableOpacity>:null}
                </View>


                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                    marginTop:25
                  }}>
                    Details for payment
                </Text>

                <Textarea
                  rowSpan={4}
                  bordered
                  name="requirements"
                  placeholder={"Enter a breakdown of the gig for the buyer to know what the expected delivery will be"}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('requirements')}
                  onBlur={handleBlur('requirements')}
                  error={touched.requirements && errors.requirements}
                  value={values.requirements}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.requirements && errors.requirements && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify requirements
                    </Text>
                  )}
                </View>



                {/* **********************************  Requirements Input *********************************** */}

                <View style={styles.milestoneContainer}>
                  <View style={{flexDirection: 'row', alignItems: 'center',marginBottom:'5%'}}>
                    <Text
                      style={{
                        fontFamily: 'Roboto-Bold',
                        fontSize: 18,
                        color: config.primary_color,
                      }}>
                      Milestone
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto-Regular',
                        fontSize: 14,
                        color: '#828282',
                        marginLeft: 5,
                      }}>
                      (Optional)
                    </Text>
                      <AIcon name="questioncircleo" style={{fontSize:16,color:'#707070',marginLeft:2,marginBottom:15}}/>
                    <View style={{flex:0.8}}></View>
                    <View style={{alignItems: 'center',height:17,flex:0.3,alignSelf:'flex-end'}}>
                    <TouchableOpacity onPress={() => this.props.changeMilestoneMode()} style={[styles.toggleContainer,
                    this.props.mode == false?{borderColor:'#707070'}:{borderColor:config.primary_color}
                    ]}>
                      <View
                        style={[
                          styles.default,
                          this.props.mode == false
                            ? {
                                backgroundColor: '#707070',
                                flex: 0.65,
                              }
                            : {
                                // backgroundColor: '#FFF',
                                flex: 0.35,
                                borderRadius: 50,
                              },
                        ]}>
                      </View>
                      <View
                        style={[
                          styles.default,
                          this.props.mode == true
                            ? {
                                backgroundColor: config.primary_color,
                                flex: 0.65,
                              }
                            : {
                                // backgroundColor: '#FFF',
                                flex: 0.35,
                                borderRadius: 50,
                              },
                        ]}>
                      </View>
                    </TouchableOpacity>

                    </View>
                  </View>


                  {this.props.mode?
                    <View>
                {this.props.milestones.length ? (
                  <FlatList
                    data={this.props.milestones}
                    renderItem={({item, index}) => {
                      return (
                        <MilestoneCard
                          {...item}
                          onEdit={() =>
                            this.makeOverlaytrue(
                              'edit',
                              item,
                              parseFloat(values.amount),
                            )
                          }
                          onDelete={() => this.props.deleteMileStone(index)}
                        />
                      );
                    }}
                    keyExtractor={(item, index) => index.toString()}
                  />
                ) : null}

                  <TouchableOpacity
                    style={styles.addMilestone}
                    onPress={() => {
                      if (
                        values.amount.trim() == '' ||
                        parseFloat(values.amount) <= 0
                      ) {
                        alert(
                          'Please add an amount before adding milestones',
                        );
                        return;
                      }
                      this.makeOverlaytrue(
                        'add',
                        null,
                        parseFloat(values.amount),
                      );
                    }}>
                    <Text
                      style={{
                        fontFamily: 'Roboto-Regular',
                        fontSize: 16,
                        color: '#464646',
                      }}>
                      Add Milestone
                    </Text>
                    <FIcon
                      name="pluscircleo"
                      style={{color: config.primary_color, fontSize: 25}}
                    />
                  </TouchableOpacity>

                  <Text
                    style={{
                      fontFamily: 'Roboto-Bold',
                      fontSize: 18,
                      color: config.primary_color,
                      textAlign: 'center',
                      marginBottom:10
                    }}>
                    Balance Amount: {' \u20B9'}
                    {values.amount -
                      this.props.milestones.reduce((prev, cur) => {
                        return (
                          parseFloat(prev) + parseFloat(cur.milestoneAmount)
                        );
                      }, 0)}
                  </Text>
                  </View> :null}
        
                </View>
              </View>
            </ScrollView>
            <View>
              <PaymentDetailsButton
                content={'Save'}
                clicked={handleSubmit}
                loading={this.props.isLoading}
              />
            </View>
            <Modal
              visible={this.state.filterVisible}
              contentContainerStyle={{backgroundColor: 'white', flex:1}}
              onDismiss={() => {
                this.setState({filterVisible: false});
              }}>
              <MileStoneOverlay
                mode={this.state.mode}
                milestones={this.props.milestones}
                data={this.state.currentMilestone}
                totalMilestoneAmount={this.state.totalMilestoneAmount}
                fullAmount={this.state.fullAmount}
                onMilestoneClose={(mode, data, index) => {
                  if (mode == 'add') {
                    this.props.addMileStone(data);
                    this.setState({filterVisible: false});
                  } else if (mode == 'edit') {
                    this.props.editMileStone(index, data);
                    this.setState({filterVisible: false});
                  } else {
                    this.setState({filterVisible: false});
                  }
                }}
              />
            </Modal>
            <AwesomeAlert
                show={this.state.errorAlert}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                    style={{
                      alignItems: 'center',
                      // paddingVertical: 10,
                      lineHeight: 21,
                      paddingHorizontal: 30,
                    }}>
                  <SVG2 style={{marginBottom: 20}} />
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  marginTop: 20,
                  fontSize: 16,
                  color: '#707070',
                }}>
                {this.state.alertText}
              </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          this.setState({errorAlert: false});
        }}
      />

            <AwesomeAlert
                show={this.state.alertVisible}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                    style={{
                      alignItems: 'center',
                      // paddingVertical: 10,
                      lineHeight: 21,
                      paddingHorizontal: 30,
                    }}>
                  <SVG1 style={{marginBottom: 20}} />
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  marginTop: 20,
                  fontSize: 16,
                  color: '#707070',
                }}>
                Enter additional milestones till the value of all milestones equals order value.
              </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          this.setState({alertVisible: false});
        }}
      />
      <AwesomeAlert
                show={this.state.feedbackVisible}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                  style={{
                    alignItems: 'center',
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}>
                  <SVG2 style={{marginBottom: 20}} />
                  <Text
                    style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#707070',
                      lineHeight: 21,
                    }}>
                    The details you entered will be lost. Are you sure you want
                    to exit ?
                  </Text>
                </View>
        )}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No'
        confirmText="Yes"
        confirmButtonColor={config.primary_color}
        onCancelPressed={()=>{this.setState({feedbackVisible: false});}}
        onConfirmPressed={() =>{
          this.props.cameBack()
        }}
      />
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 20,
    height: 30,
    fontSize: 25,
    marginRight: 20,
    marginTop: 5,
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  partnerElement: {
    flexDirection: 'row',
    borderColor: '#AAA',
    marginBottom: 10,
    paddingBottom: 12,
    paddingHorizontal: '10%',
  },
  inputContainer: {
    padding: '8%',
    paddingBottom: 0,
  },
  milestoneContainer: {
    borderRadius: 1,
    paddingTop: '3%',
    borderBottomColor:'#707070',
    borderTopColor:'#707070',
    borderStyle:'dashed',
    borderTopWidth:2,
    borderBottomWidth:2,
    marginVertical:'4%'
  },
  addMilestone: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    borderColor: '#AAA',
    paddingVertical: '4%',
    paddingHorizontal: 30,
    borderRadius: 50,
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    alignSelf: 'center',
    marginBottom: '5%',
    backgroundColor: '#fff',
  },
  default: {
    paddingVertical: '2%',
    height:17,
    borderRadius:100
  },
  toggleContainer: {
    marginHorizontal: '8%',
    alignItems: 'center',
    width: '80%',
    justifyContent: 'space-around',
    borderRadius: 50,
    flexDirection: 'row',
    backgroundColor: '#FFF',
    height:17,
    borderWidth:1
  },
  txt2: {
    fontSize: 16,
    color: config.primary_color,
    fontFamily: 'Roboto-Medium',
    paddingLeft: 5,
    marginBottom: 10,
  },
});

export default connect(
  state => ({
    categoryData: {...state.category},
    contacts: {...state.contacts},
    ...state.paymentdetail,
  }),
  {addMileStone, deleteMileStone, editMileStone, initState, makeTransaction,changeMilestoneMode,resetContactInfo,serviceSelected,productSelected},
)(EditForSale);
