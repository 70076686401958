import React, { useState, useEffect } from 'react';
import {
View,
Text,
ScrollView,
StyleSheet,
FlatList,
SafeAreaView,
TouchableOpacity} from 'react-native';
import config from '../../config';
import {connect} from 'react-redux';
import PrimaryButton from '../../components/PrimaryButton';
import {initState} from '../../state-management/actions/makepayment.action'
import AIcon from 'react-native-vector-icons/AntDesign'
import MilestoneCard from '../../components/MilestoneCard';

const ForSaleBuying = props => {
    useEffect(() => {
          const {transaction_details} = props.route.params
        props.initState(transaction_details)
        return () => {}
      },[])    
    
    const renderMilestones = () => {
        return(
            <FlatList
                    data={props.milestones}
                    renderItem={({item, index}) => {
                      return (
                        <MilestoneCard
                          {...item}
                        />
                      )}}
                    keyExtractor={(item, index) => index.toString()}
                  />
        )
      };
    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
          <ScrollView showsVerticalScrollIndicator={false}>
          <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: 15,
                    paddingTop: 20,
                    paddingBottom: 10,
                    backgroundColor: '#fff',
                  }}>
                  <TouchableOpacity
                  onPress={()=>{props.navigation.goBack()}}
                    activeOpacity={0.6}>
                    <AIcon name="left" style={styles.backButton} />
                  </TouchableOpacity>
                  <Text
                    style={{
                      fontSize: 25,
                      fontFamily: 'Roboto-Medium',
                      color: config.primary_color,
                    }}>
                    I am Buying from
                  </Text>
                </View>
                <View style={styles.partnerElement}>
                <View
                    style={{
                      backgroundColor: '#B80D57',
                      width: 55,
                      height: 55,
                      justifyContent: 'center',
                      borderRadius: 50,
                      alignSelf: 'center',
                      borderWidth: 1.5,
                      borderColor: config.primary_color,
                    }}>
                    <Text
                      style={{
                        fontSize: 20,
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Roboto-Bold',
                      }}>
                      {props.profile.requestedProfile.company_name?props.profile.requestedProfile.company_name[0]:props.profile.requestedProfile.firstname[0]}
                    </Text>
                  </View>

                  <View style={{flex: 1, justifyContent: 'space-evenly'}}>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Bold',
                        color: config.primary_color,
                        paddingHorizontal: 10,
                      }}>
                      {props.profile.requestedProfile?.company_name?props.profile.requestedProfile?.company_name:`${props.profile.requestedProfile.firstname} ${props.profile.requestedProfile.lastname}`}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Roboto-Regular',
                        color: '#707070',
                        paddingHorizontal: 10,
                      }}>
                      {props.profile.requestedProfile.mobile}
                    </Text>
                  </View>
                  </View>
                <View
                style={{
                  paddingHorizontal:40
                }}>
                  <Text style={{fontFamily: 'OpenSans-SemiBold',fontSize:16,marginTop:10,color:config.primary_color,lineHeight:24}}>
                    {props.transaction_details.gig_name}
                  </Text>
                  <View style={{flexDirection: 'row', marginTop:'7%',marginBottom:'3%'}}>
                    <Text
                      style={{
                        fontFamily: 'OpenSans-SemiBold',
                        fontSize: 16,
                        color: config.primary_color,
                        textAlign: 'left',
                        flex: 0.9,
                        lineHeight:22
                      }}>
                      Amount
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'OpenSans-SemiBold',
                        fontSize: 16,
                        color: config.primary_color,
                        lineHeight:22
                      }}>
                      {`\u20B9${props.transaction_details.total_amount}`}
                    </Text>
                  </View>
                  <Text style={{fontFamily: 'Roboto-Regular',fontSize:12,color:'#0BA888',lineHeight:16}}>
                  Your funds are safe and secure with us. We will release the money 
                  only after you approve the payment after {props.profile.requestedProfile?.company_name?props.profile.requestedProfile?.company_name:`${props.profile.requestedProfile.firstname} ${props.profile.requestedProfile.lastname}`} completes the gig/order.
                  </Text>
                    <View>
                      <PrimaryButton
                        content={'Fund full Amount'}
                        loading={props.isLoading}
                        clicked={() => {
                            // props.navigation.navigate('PaymentCheckout', {
                            //     data: {
                            //       transaction_details:{...props.transaction_details,isPayingFull:true},
                            //       type: 'Gig_Order',
                            //       amount:props?.transaction_details?.total_amount
                            //     },
                            //   })
                        }}
                      />
                      {props.transaction_details.isVirtual == false ? (
                        <View>
                          <Text
                            style={{
                              fontFamily: 'OpenSans-Bold',
                              fontSize: 20,
                              color: config.primary_color,
                              textAlign: 'center',
                              marginTop: 30,
                            }}>
                            Or
                          </Text>
                          <PrimaryButton
                            content={'Fund 1st Milestone'}
                            loading={props.isLoading}
                            clicked={() => {
                                // props.navigation.navigate('PaymentCheckout', {
                                //     data: {
                                //       transaction_details:{...props.transaction_details,isPayingFull:false},
                                //       type: 'Gig_Order',
                                //       amount:props?.milestones[0]?.milestoneAmount
                                //     },
                                //   })
                            }}
                          />
                        </View>
                      ) : null}
                    </View>
                  <Text style={{fontFamily: 'OpenSans-SemiBold',fontSize:16,marginTop:40,color:config.primary_color,lineHeight:19}}>
                    Details for payment
                  </Text>
                  <Text style={{fontSize: 12,fontFamily: 'Roboto-Regular',color: '#828282',marginTop: 10,}}>
                  {props.transaction_details.requirements}
                  </Text>

                  {props.transaction_details.isVirtual? null :(
                <View style={{marginTop:30}}>
                  <Text
                    style={{
                      fontFamily: 'Roboto-Bold',
                      fontSize: 18,
                      color: config.primary_color,
                    }}>
                    Milestone
                  </Text>
                  {renderMilestones()}
                </View>
              )}


                </View>

          </ScrollView>
        
        </SafeAreaView>        
    );
}

const styles = StyleSheet.create({
    backButton: {
      color: config.primary_color,
      width: 20,
      height: 30,
      fontSize: 25,
      marginRight: 20,
      marginTop: 5,
    },
    icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
    partnerElement: {
      flexDirection: 'row',
      borderColor: '#AAA',
      marginBottom: 10,
      paddingBottom: 12,
      paddingHorizontal: '10%',
    },
    inputContainer: {
      padding: '8%',
      paddingBottom: 0,
    },
    milestoneContainer: {
      borderRadius: 1,
      paddingVertical: '3%',
    },
    addMilestone: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '90%',
      borderColor: '#AAA',
      paddingVertical: '4%',
      paddingHorizontal: 30,
      borderRadius: 50,
      elevation: 3,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      alignSelf: 'center',
      marginBottom: '5%',
      backgroundColor: '#fff',
    },
    default: {
      paddingVertical: '2%',
      height:17,
      borderRadius:100
    },
    toggleContainer: {
      marginHorizontal: '8%',
      alignItems: 'center',
      width: '80%',
      justifyContent: 'space-around',
      borderRadius: 50,
      flexDirection: 'row',
      backgroundColor: '#FFF',
      height:17,
      borderWidth:1
    },
  });

export default connect(state => ({auth:{...state.auth},profile: {...state.userProfile},...state.makepayment}), {
    initState,
})(ForSaleBuying)