import React, {Component, useState, useEffect} from 'react'
import {
  View, SafeAreaView, TouchableOpacity,StyleSheet,Text,ScrollView
} from 'react-native'
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import {Textarea} from 'native-base'
import PrimaryButton from '../PrimaryButton';


const RandomInsights = props => {
  const [text1,setText1] = useState(null)
  const [text2,setText2] = useState(null)
  const [text3,setText3] = useState(null)
useEffect(()=>{
  if(props.insightsData){
  setText1(props.insightsData?.text1)
  setText2(props.insightsData?.text2)
  setText3(props.insightsData?.text3)
  }
},[])
   const renderHeader= () => {
        return (
            <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 20,
              paddingTop: 20,
            }}>
            <TouchableOpacity
              activeOpacity={0.6}
              onPress={()=>{props.cameBack()}}>
              <AIcon name="left" style={styles.backButton} />
            </TouchableOpacity>
            <Text
              numberOfLines={1}
              style={{
                flex: 1,
                fontSize: 26,
                fontFamily: 'OpenSans-Semibold',
                color: config.primary_color,
              }}>
              Random Insights about me
            </Text>
          </View>
        );
      };
      return(
            <SafeAreaView  style={{flex: 1,backgroundColor: '#FFF',}}>
                <ScrollView showsVerticalScrollIndicator={false}>
                {renderHeader()}
                <View style={{flex:1,marginHorizontal:'10%',paddingTop:50}}>
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  How would you eat an elephant?
                </Text>
                <Textarea
                  rowSpan={4}
                  bordered
                  onChangeText={(txt)=>{setText1(txt)}}
                  value={text1}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  style={{fontSize: 14, padding: 15 , marginBottom:15}}
                />
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  What superhero character are you?
                </Text>
                <Textarea
                  rowSpan={4}
                  bordered
                  onChangeText={(txt)=>{setText2(txt)}}
                  value={text2}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  style={{fontSize: 14, padding: 15 , marginBottom:15}}
                />
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                What would you do if you win a lottery?                
                </Text>
                <Textarea
                  rowSpan={4}
                  bordered
                  onChangeText={(txt)=>{setText3(txt)}}
                  value={text3}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  style={{fontSize: 14, padding: 15 , marginBottom:15}}
                />
                <View style={{ marginVertical: 40}}>
                            <PrimaryButton content={'Save'} clicked={()=>{props.onClose({text1:text1,text2:text2,text3:text3})}}/>
                </View>
                </View>
                </ScrollView>
            </SafeAreaView>
            )
}
    const styles = StyleSheet.create({
        backButton: {
          color: '#473BB3',
          fontSize: 22,
          paddingRight: 15,
        }
    })

    export default RandomInsights