import React, { useEffect, useRef, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Swiper from "react-native-web-swiper";
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import BIcon from 'react-native-vector-icons/FontAwesome5'
import FIcon from 'react-native-vector-icons/FontAwesome'
import OffersCarousal from '../OffersCarousal';
import config from "../../config";
import { Video, Audio } from 'expo-av';

const ReviewsCarousal = (props) => {
    const swiperRef = useRef(null);
  useEffect(()=>{
    // console.log(props);
    return()=>{
    }
  })
        return (
          <View style={{width:'100%',justifyContent:'space-around',flexDirection:'row',alignItems:'center'}}>
            <MIcon name='arrow-left-bold-circle' style={{fontSize:25,color:'rba(0,0,0,0.5)'}} onPress={()=>{
                swiperRef.current.goToPrev()}}/>
              <View style={{width:'80%',height:props.items.find(a=>a.hasOwnProperty('attachment_type'))?400:200}}>
                  <Swiper
                    ref={swiperRef}
                    from={0}
                    controlsEnabled={false}
                    minDistanceForAction={0.1}>
                      {
                        props.items.map((item,index)=>{
                          return(
                            <View style={{backgroundColor:'#fff',padding:5,justifyContent:'center',flex:1,alignContent:'center'}}>
                            <View style={styles.partnerElement}>
                                  <View
                                  onPress={()=>{this.props.navigation.navigate('UserProfile',{key:'mobile',value:mobile})}}
                                    style={{
                                      backgroundColor: '#EB330D',
                                      width: 55,
                                      height: 55,
                                      justifyContent: 'center',
                                      borderRadius: 50,
                                      alignSelf: 'center',
                                    }}>
                                    <Text
                                      style={{
                                        fontSize: 20,
                                        color: '#FFF',
                                        textAlign: 'center',
                                        fontFamily: 'Roboto-Bold',
                                      }}>{item?.from_user_name[0]}</Text>
                                  </View>
                      
                                  <View
                                  style={{flex: 1, justifyContent: 'space-evenly'}}>
                                    <Text
                                      style={{
                                        fontSize: 16,
                                        fontFamily: 'Roboto-Bold',
                                        color: '#464646',
                                        paddingHorizontal: 10,
                                      }}>
                                      {item?.from_user_name}
                                    </Text>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        fontFamily: 'Roboto-Regular',
                                        color: '#707070',
                                        paddingHorizontal: 10,
                                      }}>
                                      {item?.from_user_location?item.from_user_location:'India'}
                                    </Text>
                                  </View>
                                  <View>
                                      <View
                                        style={{
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                          alignSelf: 'flex-end',
                                          marginTop: 10,
                                        }}>
                                        <Text
                                          style={{
                                            fontSize: 16,
                                            color: config.primary_color,
                                            paddingLeft: 5,
                                          }}>
                                            {item?.overall_review}
                                        </Text>
                                        <FIcon name='star' style={{fontSize: 20, color: '#E89C04',marginLeft:3}} />
                                      </View>
                                      <Text style={{fontFamily:'OpenSans-SemiBold',color:'#464646',fontSize:12}}>For {item?.to_user_role}</Text>
                                  </View>
                              </View>
                              {
                                    item.attachment_type=='audio'?
                                <View style={{alignItems:'center',height:175,width:'99%',borderColor:'#ccc',borderRadius:7,borderWidth:1,backgroundColor:'#ea4e42',borderBottomLeftRadius:20,borderBottomRightRadius:20}}>
                                <View style={{flex:1}}></View>
                                <View style={{width:100,height:100,justifyContent:'center',alignItems:'center',borderRadius:5}}>
                                  <BIcon name='headphones' style={{color:'#FFF',fontSize:80}}/>
                                </View>
                                <View style={{flex:1}}></View>
                                <Video
                                style={{height:30,alignSelf:'flex-end',width:'100%',borderColor:'#ccc'}}
                                source={{
                                  uri: item.attachment_path,
                                }}
                                useNativeControls
                                resizeMode="contain"
                                isLooping
                              />
                                </View>:
                                item.attachment_type=='video'?
                                <Video
                                style={{height:175,width:'99%',borderColor:'#ccc',borderRadius:7}}
                                source={{
                                  uri: item.attachment_path,
                                }}
                                useNativeControls
                                resizeMode="contain"
                                isLooping
                              />:null
                                  }
                              <Text numberOfLines={4} style={{fontFamily:'Roboto-Regular',color:'#464646',fontSize:14,marginHorizontal:10,lineHeight:22,marginTop:10}}>{item?.review_text}</Text>
                              <View style={{flexDirection:'row',justifyContent:'center'}}>
                                <TouchableOpacity onPress={()=>{props.routeToChat(item.from_user)}} style={{borderRadius:100,borderColor:'#481380',borderWidth:1,padding:8,marginTop:30}}> 
                                  <Text style={{fontFamily:'Roboto-Regular',color:'#481380',fontSize:12}}>Ask {item?.from_user_name} more about this review</Text>
                                </TouchableOpacity>
                              </View>
                              <Text style={{fontFamily:'OpenSans-SemiBold',fontSize:12,color:'#000',textAlign:'center',marginTop:20}}>
                                {`${index+1}/${props.items.length}`}
                                </Text>
                              </View>
                          )
                        })
                      }
                  </Swiper>
              </View>
              <MIcon name='arrow-right-bold-circle' style={{fontSize:25,color:'rba(0,0,0,0.5)'}} onPress={()=>{
                  swiperRef.current.goToNext()}}/>
                  
          </View>
        )
    }


    const styles = {
        count:{
            borderWidth:1,
            borderColor:'#707070',
            width:20,
            height:20,
            textAlign:'center',
            borderRadius:20,
            fontFamily:'OpenSans-SemiBold',
            fontSize:12,
            padding:2,
            position:'absolute',
            zIndex:10,
            backgroundColor:'#fff',
            right:0,
            marginTop:0
        },
        partnerElement: {
          flexDirection: 'row',
          borderColor: '#AAA',
          marginBottom: 10,
          paddingBottom: 12,
          backgroundColor:'#FFF'
        },
      }
    
export default ReviewsCarousal