import { CHANGE_PAGE, RESET_PAYMENT_USER_STATE } from "../constants/constants";


export function changePage(pageNum){
    return {
        type:CHANGE_PAGE,
        pageNum:pageNum
    }
}

export function resetState(){
    return {
        type:RESET_PAYMENT_USER_STATE
    }
}