import config from "../config";
import Interceptor from "./Interceptor";

class ProfileService {
  getProfile = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(`${config.node_api_endpoint}/profile`);
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  getRecentContacts = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/profile/recent/contacts`
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  getAllNotifications = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/profile/notifications`
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  editProfile = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/`,
        params
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  updateProfilePhoto = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/photo`,
        params
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  getProfileByKey = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/view`,
        params
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  createUserChat = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/userchat/create`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };
  loadProfileNewData = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/profile/get/sale/portfolio`
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  addForSaleGig = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/sale/add`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };
  addNewPortfolio = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/portfolio/add`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };
  editPortfolioService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/portfolio/edit`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };

  editForSaleService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/sale/edit`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };

  deletePortFolioService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/portfolio/delete`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };

  deleteGigForSaleService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/profile/sale/delete`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error;
    }
  };
}

export default new ProfileService();
