import React, {Component} from 'react';
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Text,
  Image,
  Alert,
} from 'react-native';
import config from '../../config';
import PrimaryButton from '../PrimaryButton';
import AIcon from 'react-native-vector-icons/AntDesign';
import FIcon from 'react-native-vector-icons/FontAwesome';
import Camera from '../../assets/images/camera.svg';
import Gallery from '../../assets/images/Gallery.svg';

export default class AddPhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filepath: {
        data: '',
        uri: '',
      },
      fileData: '',
      fileUri: '',
      cameraSelected: false,
      gallerySelected: false,
      fileName: '',
      uploadedBy: '',
      isMaxSize:false
    };
  }
  renderGalleryUri() {
    if (this.state.fileUri && this.state.gallerySelected) {
      return (
        <Image
          source={{uri: this.state.fileUri}}
          resizeMode="contain"
          style={{
            width: 60,
            height: 50,
            borderColor: 'black',
            borderWidth: 1,
            marginHorizontal: 3,
          }}
        />
      );
    } else {
      return (
        // <AIcon name='picture' style={{color:config.primary_color,fontSize:65}} />
        <Gallery width={60} height={60} />
      );
    }
  }
  renderCameraUri() {
    if (this.state.fileUri && this.state.cameraSelected) {
      return (
        <Image
          source={{uri: this.state.fileUri}}
          resizeMode="contain"
          style={{
            width: 60,
            height: 50,
            borderColor: 'black',
            borderWidth: 1,
            marginHorizontal: 3,
          }}
        />
      );
    } else {
      return (
        // <FIcon name='camera-retro' style={{color:config.primary_color,fontSize:60}} />
        <Camera width={60} height={60} />
      );
    }
  }
  // launchImageLibrary = () => {
  //   let options = {
  //     storageOptions: {
  //       skipBackup: true,
  //       path: 'images',
  //     },
  //   };
  //   ImagePicker.launchImageLibrary(options, response => {
  //     if (response.didCancel) {
  //       // console.log('User cancelled image picker');
  //     } else if (response.error) {
  //       // console.log('ImagePicker Error: ', response.error);
  //     } else if (response.customButton) {
  //       // console.log('User tapped custom button: ', response.customButton);
  //       // alert(response.customButton);
  //     } else {
  //       const source = {uri: response.uri};
  //       // console.log('response', JSON.stringify(response));
  //       // console.log(response.fileName);
  //       // console.log(`VOUCH_${Date.now()}`);
  //       // console.log(response);
  //       this.setState({
  //         cameraSelected: false,
  //         gallerySelected: true,
  //         // filePath: response,
  //         // fileData: response.data,
  //         fileUri: response.uri,
  //         fileName: response.fileName,
  //         uploadedBy: 'Gallery',
  //         file:response,
  //         isMaxSize:response.fileSize>8000000?true:false,
  //       });
  //     }
  //   });
  // };
  // launchCamera = () => {
  //   let options = {
  //     storageOptions: {
  //       skipBackup: true,
  //       path: 'images',
  //     },
  //   };
  //   ImagePicker.launchCamera(options, response => {
  //     // console.log('Response = ', response);

  //     if (response.didCancel) {
  //       // console.log('User cancelled image picker');
  //     } else if (response.error) {
  //       // console.log('ImagePicker Error: ', response.error);
  //     } else if (response.customButton) {
  //       // console.log('User tapped custom button: ', response.customButton);
  //       // alert(response.customButton);
  //     } else {
  //       const source = {uri: response.uri};
  //       // console.log(response);

  //       this.setState({
  //         cameraSelected: true,
  //         gallerySelected: false,
  //         // filePath: response,
  //         // fileData: response.data,
  //         fileUri: response.uri,
  //         fileName: response.fileName,
  //         uploadedBy: 'Camera',
  //         file:response,
  //         isMaxSize:response.fileSize>8000000?true:false,
  //       });
  //     }
  //   });
  // };
  renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            this.props.cameBack();
          }}>
          <AIcon name="left" style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: 'OpenSans-Semibold',
            color: config.primary_color,
          }}>
          Select Source
        </Text>
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={{flex: 1, backgroundColor: '#FFF'}}>
        {this.renderHeader()}
        <View style={{flex: 1, marginHorizontal: '10%', paddingTop: 50}}>
          <TouchableOpacity
            onPress={() => {
              // this.launchCamera();
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: '10%',
            }}>
            {this.renderCameraUri()}
            <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 20,
                color: '#464646',
                marginLeft: 35,
              }}>
              Camera
            </Text>
          </TouchableOpacity>
          {/* <Dash
            style={{marginTop: 0, marginBottom: 0}}
            dashGap={3}
            dashLength={6}
            dashColor={'#707070'}
          /> */}
          <TouchableOpacity
            onPress={() => {
              // this.launchImageLibrary();
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: '10%',
            }}>
            {this.renderGalleryUri()}
            <Text
              style={{
                fontFamily: 'Roboto-Regular',
                fontSize: 20,
                color: '#464646',
                marginLeft: 35,
              }}>
              Gallery
            </Text>
          </TouchableOpacity>
          {/* <Dash
            style={{marginTop: 0, marginBottom: 0}}
            dashGap={3}
            dashLength={6}
            dashColor={'#707070'}
          /> */}

          <View style={{margin: 20, marginBottom: 40}}>
            <PrimaryButton
              content={'Save'}
              clicked={() => {
                if(!this.state.isMaxSize){
                  this.props.onClose({
                    uri: this.state.fileUri,
                    name: this.state.fileName,
                    uploadedBy: this.state.uploadedBy,
                    file:this.state.file
                  });  
                }
                else{
                  Alert.alert('Note','Max image size is 5 MB')
                }

              }}
            />
          </View>
        </View>
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  backButton: {
    color: '#473BB3',
    fontSize: 22,
    paddingRight: 15,
  },
});
