import React, { Component } from "react";
import { View, Text } from "react-native";
import { Item, Input } from "native-base";

export default class SocialInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <View>
        <Text
          style={{
            fontFamily: "Roboto-Regular",
            fontSize: 16,
            color: "#464646",
            paddingLeft: 5,
          }}
        >
          {this.props.label}
        </Text>
        <Item
          regular
          style={{ marginTop: 3, borderRadius: 10, height: 50, marginLeft: 8 }}
        >
          <Input
            placeholderTextColor={"#AAAAAA"}
            placeholder={this.props.placeholder}
            textContentType={this.props.type ? this.props.type : "name"}
            style={{ fontSize: 14, padding: 15 }}
            onPress={this.props.clicked}
            onChangeText={this.props.onChangeText}
            onBlur={this.props.onBlur}
            value={this.props.value}
            keyboardType={this.props.keyboard ? this.props.keyboard : "default"}
            autoFocus={this.props.autoFocus ? true : false}
            disabled={this.props.disabled ? this.props.disabled : false}
            maxLength={this.props.maxLength ? this.props.maxLength : null}
          />
        </Item>
      </View>
    );
  }
}
