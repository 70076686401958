import {CHANGE_PAGE, RESET_PAYMENT_USER_STATE} from '../constants/constants'

const initialState = {
  pageNum: 0,
}

const reducer = function paymentUserReducer (state = initialState, action) {
  switch (action.type) {
    case CHANGE_PAGE: {
      return {
        ...state,
        pageNum: action.pageNum,
      }
    }
    case RESET_PAYMENT_USER_STATE:{
      return{
        ...initialState
      }
    }
    default:
      return state
  }
}

export default reducer