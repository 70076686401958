import { LOAD_PAYMENT_PREVIEW, LOAD_PAYMENT_PREVIEW_ERROR, LOAD_PAYMENT_PREVIEW_SUCCESS } from '../constants/constants'
  
  const initialState = {
    gigDetails: {},
    gigMilestoneDetails: [],
    requestedUser:[],
    toUser:[],
    isLoading: true,
    showData:false,
    error: '',
  }
  
  const reducer = function paymentPreviewReducer(state = initialState, action) {
    switch (action.type) {
      case LOAD_PAYMENT_PREVIEW: {
        // console.log(LOAD_PAYMENT_PREVIEW);
        return {
          ...state,
          isLoading: true,
          error: '',
        }
      }
      case LOAD_PAYMENT_PREVIEW_SUCCESS: {
        // console.log(LOAD_PAYMENT_PREVIEW_SUCCESS);
        // console.log(action.response.gigDetails);
        // console.log(action.response.gigMilestoneDetails);
        // console.log(action.response.toUser);
        // console.log(action.response.showData);
        return {
          ...state,
          isLoading: false,
          gigDetails: action.response.gigDetails,
          gigMilestoneDetails: action.response.gigMilestoneDetails,
          toUser: action.response.toUser,
          requestedUser: action.response.requestedUser,
          showData:action.response.showData
        }
      }
      case LOAD_PAYMENT_PREVIEW_ERROR: {
        // console.log(LOAD_PAYMENT_PREVIEW_ERROR);
        return {
          ...state,
          isLoading: false,
          error: action.response.message,
        }
      }
      default:
        return state
    }
  }
  
  export default reducer
  