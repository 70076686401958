import {takeLatest, put, call, delay} from 'redux-saga/effects'
import { SEARCH_CONTACTS, SEARCH_ERROR, SEARCH_SUCCESS } from '../constants/constants'
import userService from '../../services/user.service'

const execSearchContact = async actionData => {
  try {
      // console.log(actionData.searchTerm);
    var result = await userService.getUser({
        key:'mobile',
        value:actionData.searchTerm
      })
      if(result){
        return [result]
      }
      else{
        return []
      }
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'}
  }
}

function * searchContact (action) {
  try {
    const res = yield call(execSearchContact, action)
    yield put({
      type: SEARCH_SUCCESS,
      response: {
        searchResult:res
      },
    })
  } catch (error) {
    // console.log(error);
    yield put({
      type: SEARCH_ERROR,
      response: {message: error.message},
    })
  }
}

function * watchSearchContact () {
  yield takeLatest(SEARCH_CONTACTS, searchContact)
}

export default watchSearchContact
