import React, {useEffect} from 'react'
import {
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Animated,
  Easing
} from 'react-native'
import { connect } from 'react-redux'
import config from '../config'
import logsService from '../services/logs.service'
import { resetActionPending } from '../state-management/actions/gigdetails.action';

const AnimatedButton = (props) => {
  const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity);
  const springValue = new Animated.Value(1);
  const width = new Animated.Value(1)

  const spring = () => {
    springValue.setValue(0.7)
    Animated.spring(
      springValue,
      {
        toValue: 1,
        friction: 1,
      }
    ).start(()=>{
      props.resetActionPending()
    })
    // width.setValue(0)
    // Animated.timing(width, {
    //   toValue: 1,
    //   duration:200,
    //   easing:Easing.linear
    // }).start(()=>{
    //   props.resetActionPending()
    // })
  }

  useEffect(()=>{
    if(props.actionPendingFlag){
      spring()
    }
  },[props.actionPendingFlag])

    return (
        <AnimatedTouchable
        disabled={props.loading || props.disabled ? true : false}
        style={[
          props.styles ? props.styles : styles.button,
          {
            transform: [{
              // rotate: width.interpolate({
              //   inputRange: [0,0.25,0.5,0.75,1],
              //   outputRange: ['0deg', '8deg','-8deg','8deg','0deg']
              // }),
              scale:springValue
            }],
            backgroundColor:
              props.loading || props.disabled
                ? `${config.primary_color}55`
                : config.primary_color,
          },
        ]}
        onPress={() => {
          logsService.logCustomEvent(`${props.content.replace(/ /g,"_").toLowerCase()}_button_clicked`,{})
          props.clicked()
          }}>
        {props.loading ? (
          <ActivityIndicator size='small' color='#fff' />
        ) : (
          <Text
            style={{
              color: '#F7F6F9',
              fontSize: props.styles?14:18,
              fontFamily: props.styles?'Roboto-Medium':'Roboto-Regular',
              textAlign:'center'
            }}>
            {' '}
            {props.content}
          </Text>
        )}
        </AnimatedTouchable>
    );
}

const styles = {
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: config.primary_color,
    padding: 10,
    borderRadius: 25,
    marginTop: 25,
    zIndex: 100,
  },
}

export default connect(
  state => ({...state.gigDetails}),
  {
    resetActionPending 
  },
)(AnimatedButton);