import React, {Component, useEffect, useState} from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
  ActivityIndicator,
} from 'react-native'
import Header from '../../components/Header'
import SVG2 from '../../assets/images/paydetails2.svg';
import config from '../../config';
import PrimaryButton from '../../components/PrimaryButton';
import { cancelVirtuallAccount , getVirtuallAccountDetails} from '../../state-management/actions/smartcollect.action'
import { connect } from 'react-redux';
import {CommonActions} from '@react-navigation/native'
import {dispatch, navigate} from '../../navigation/Navigator';
import Clipboard from 'expo-clipboard';
import AwesomeAlert from 'react-native-awesome-alerts';

const BankTransferDetails = (props) => {
    const [alert,setAlert] = useState(false)
    const [t1,setT1] = useState(false)
    const [t2,setT2] = useState(false)

    useEffect(() => {
      props.getVirtuallAccountDetails(props.route.params.data.account_id)
      return () => {}
    }, [])


  const skip = () => {

    dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {name: 'Home'},
        ],
      }),
    );
    setTimeout(()=>{
      navigate('PaymentUser',
      {
        uid: props.route.params.data.user_id,
      })
    },700)
  }
    return (
        <SafeAreaView style={{flex: 1, backgroundColor: '#fff'}}>
        <ScrollView showsVerticalScrollIndicator={false}>
        <Header title={'Bank Transfer'} navigation={props.navigation} />
        <View style={{paddingHorizontal:'10%'}}>
            <Text style={{fontFamily: 'OpenSans-Regular',lineHeight:30,fontSize:18,color:'#000',marginVertical:25}}>
                Transfer {'\u20B9'} {props.route.params.data.gigData.payment_amount_expected} to this bank account using IMPS/NEFT/RTGS
            </Text>
            <View style={{backgroundColor:'#F3F3F3',borderRadius:20,paddingLeft:20,paddingVertical:20,paddingRight:10}}>
            <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#707070',marginBottom:20}}>Account details</Text>
            <View style={{flexDirection: 'row'}}>
              <View>
                  <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>A/c Name</Text>
                  <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>A/c No</Text>
                  <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>IFSC</Text>
              </View>
              <View style={{paddingLeft: 10,flex:1}}>
                  <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>:  Vouch</Text>
                  <View style={{flexDirection:'row',marginBottom:10,justifyContent:'space-between'}}>
                  <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000'}}>:  {props.accountData?.receivers[0]?.account_number}</Text>
                  <TouchableOpacity 
                  onPress={()=>{Clipboard.setString(props.accountData?.receivers[0]?.account_number);setT1(true)}}
                  style={{backgroundColor:t1?'#06993e':'#1f48c2',paddingHorizontal:8,justifyContent:'center',borderRadius:10}}>
                  <Text style={{fontSize:10,color:'#fff',textDecorationStyle:'solid'}}>{t1?'Copied!':"Copy"}</Text>
                  </TouchableOpacity>
                  </View>
                  <View style={{flexDirection:'row',marginBottom:10,justifyContent:'space-between'}}>
                  <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000'}}>:  {props.accountData?.receivers[0]?.ifsc}</Text>
                  <TouchableOpacity 
                  onPress={()=>{Clipboard.setString(props.accountData?.receivers[0]?.ifsc);setT2(true)}}
                  style={{backgroundColor:t2?'#06993e':'#1f48c2',paddingHorizontal:8,justifyContent:'center',borderRadius:10}}>
                  <Text style={{fontSize:10,color:'#fff',textDecorationStyle:'solid'}}>{t2?'Copied!':"Copy"}</Text>
                  </TouchableOpacity>
                  </View>
              </View>
            </View>
            </View>
            <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:20,paddingBottom:8,marginTop:50,borderBottomColor:'#707070',borderBottomWidth:1}}>Important</Text>
            <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>1.Transfer exactly ₹ {props.route.params.data.gigData.payment_amount_expected} , any other amount will be rejected</Text>
            <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>2.Transfer within 120 hours</Text>
            <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',marginBottom:10}}>3.Payment status is automatically updated upon receipt of  ₹ {props.route.params.data.gigData.payment_amount_expected} into the above account</Text>
            <PrimaryButton 
             content={'Proceed'}
             clicked={()=>{skip()}}
             />
            <Text style={{fontFamily: 'OpenSans-Regular',fontSize:16,color:'#000',textAlign:'center',marginTop:30,marginBottom:10}}>Cancel payment and pay through other methods</Text>

            <TouchableOpacity 
            onPress={()=>{
              setAlert(true);
            }}
            style={{borderColor:'#D92E0B',borderWidth:2,borderRadius:100,paddingVertical:10,marginBottom:40,alignItems:'center',justifyContent:'center'}}>

              {props.isLoading?
          <ActivityIndicator size='small' color={config.primary_color} />
              :
              <Text style={{fontFamily:'Roboto-Regular',color:'#D92E0B',fontSize:15,textAlign:'center'}}>Cancel payment</Text>
              }
            </TouchableOpacity>
        </View>
        </ScrollView>
        <AwesomeAlert
                show={alert}
                title=""
                message=""
                messageStyle={{ textAlign: 'center' }}
                customView={(
                  <View
                  style={{
                    alignItems: 'center',
                    lineHeight: 21,
                    paddingHorizontal: 5,
                  }}>
                  <SVG2 style={{marginBottom: 20}} />
                  <Text
                    style={{
                      fontFamily: 'Roboto-Regular',
                      fontSize: 16,
                      color: '#707070',
                      lineHeight: 21,
                      textAlign:'center'
                    }}>
                    Are you sure you want to cancel the payment, any money transferred to the mentioned account will be refunded.
                  </Text>
                </View>
        )}
        showCancelButton={true}
        showConfirmButton={true}
        cancelText='No'
        confirmText="Yes"
        confirmButtonColor={config.primary_color}
        onCancelPressed={()=>{setAlert(false)}}
        onConfirmPressed={() =>{
          setAlert(false)
          props.cancelVirtuallAccount(props.route.params.data.account_id)
          skip()
        }}
      />
        </SafeAreaView>
    );
}

export default connect(state => ({...state.smartcollect}), {
  cancelVirtuallAccount,
  getVirtuallAccountDetails
})(BankTransferDetails)