//import liraries
import React, { Component, useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Share,
} from "react-native";
import { CommonActions } from "@react-navigation/native";
import config from "../../config";
import LottieView from "lottie-react-web";
import TickAnim from "../../assets/lottie-animations/tick-anim.json";
import AIcon from "react-native-vector-icons/AntDesign";
// import Share from 'react-native-share'
import { connect } from "react-redux";
// import dynamicLinks from '@react-native-firebase/dynamic-links'
import Clipboard from "expo-clipboard";

const PaymentResult = props => {
  const [link, setLink] = useState("");
  const shareLogic = message => {
    try {
      // Clipboard.setString(message);
      navigator.clipboard.writeText(message).then(function() {
        Share.share({
          message: message,
        });
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const ShareMessage = () => {
    if (props.route.params.mode == "buying") {
      shareLogic(
        `${
          props.userProfile.company_name
            ? props.userProfile.company_name
            : props.userProfile.firstname
        } has paid you Rs.${
          props.paymentDetails.transaction_details.total_amount
        } through vouch for ${
          props.paymentDetails.transaction_details.category
        },${
          props.paymentDetails.transaction_details.gig_name
        }. Click here to accept the payment & start the Order ${
          props.route.params?.link
        }`
      );
    } else if (props.route.params.mode == "recommendation") {
      shareLogic(
        `${
          props.userProfile.company_name
            ? props.userProfile.company_name
            : props.userProfile.firstname
        } has requested your recommendation . Make your recommendation count. \n${
          props.route.params.link
        }`
      );
    } else {
      console.log(props.userProfile);
      shareLogic(
        `${
          props.userProfile.company_name
            ? props.userProfile.company_name
            : props.userProfile.firstname
        } has request you to pay Rs.${
          props.paymentDetails.transaction_details.total_amount
        } through vouch for ${
          props.paymentDetails.transaction_details.category
        },${
          props.paymentDetails.transaction_details.gig_name
        }. Click here to pay ${
          props.userProfile.company_name
            ? props.userProfile.company_name
            : props.userProfile.firstname
        } so he can start the work ${
          props.requestpayment.link
        }\n Your funds are safe and secure with us, we will release the money only after you approve payment after ${
          props.userProfile.company_name
            ? props.userProfile.company_name
            : props.userProfile.firstname
        } delivers as per your satisfaction`
      );
    }
  };

  const notifyMerchant = async (link, data, return_url) => {
    try {
      var form = document.getElementById("myForm");
      var input1 = document.createElement("input");
      input1.setAttribute("name", "custom");
      input1.setAttribute("value", data["custom"]);
      input1.setAttribute("type", "hidden");
      form.appendChild(input1);
      var input2 = document.createElement("input");
      input2.setAttribute("name", "payment_status");
      input2.setAttribute("value", data["payment_status"]);
      input2.setAttribute("type", "hidden");
      form.appendChild(input2);
      var input3 = document.createElement("input");
      input3.setAttribute("name", "currency_type");
      input3.setAttribute("value", data["currency_type"]);
      input3.setAttribute("type", "hidden");
      form.appendChild(input3);
      var input4 = document.createElement("input");
      input4.setAttribute("name", "gross");
      input4.setAttribute("value", data["gross"]);
      input4.setAttribute("type", "hidden");
      form.appendChild(input4);
      var input5 = document.createElement("input");
      input5.setAttribute("name", "return_url");
      input5.setAttribute("value", return_url);
      input5.setAttribute("type", "hidden");
      form.action = link;
      form.appendChild(input5);
      form.submit();
      // window.location=return_url;
    } catch (error) {
      console.log("Not Done: ", error);
    }
  };

  useEffect(() => {
    props.route.params.mode != "buying" &&
    props.route.params.mode != "selling" &&
    props.route.params.mode != "recommendation"
      ? setTimeout(() => {
          if (
            props.route.params.mode == "fund_milestone" ||
            props.route.params.mode == "fund_full_payment"
          ) {
            if (props.route.params.merchant_details) {
              notifyMerchant(
                props?.route?.params?.merchant_details?.notify_url,
                {
                  custom: JSON.stringify({
                    order_id: props?.route?.params?.merchant_details?.order_id,
                    order_key:
                      props?.route?.params?.merchant_details?.order_key,
                  }),
                  payment_status: "completed",
                  currency_type: "INR",
                  gross: props?.route?.params?.merchant_details?.total_amount,
                },
                props?.route?.params?.merchant_details?.return_url
              );
            } else {
              props.navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [
                    { name: "Main" },
                    // {
                    //   name: 'GigDetails',
                    //   params: {
                    //     uid: props.route.params.uid,
                    //   },
                    // },
                  ],
                })
              );
            }
          } else {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  { name: "Main" },
                  // {
                  //   name: 'PaymentUser',
                  //   params: {
                  //     uid: props.route.params.uid,
                  //   },
                  // },
                ],
              })
            );
          }
        }, 4500)
      : null;
  }, []);

  const skip = () => {
    if (props.route.params.mode == "recommendation") {
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{ name: "Main" }],
        })
      );
    } else if (props.route.params.mode != "fund_milestone") {
      if (props.route.params.merchant_details) {
        notifyMerchant(
          props?.route?.params?.merchant_details?.notify_url,
          {
            custom: JSON.stringify({
              order_id: props?.route?.params?.merchant_details?.order_id,
              order_key: props?.route?.params?.merchant_details?.order_key,
            }),
            payment_status: "completed",
            currency_type: "INR",
            gross: props?.route?.params?.merchant_details?.total_amount,
          },
          props?.route?.params?.merchant_details?.return_url
        );
      } else {
        props.navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              { name: "Main" },
              {
                name: "PaymentUser",
                params: {
                  uid: props.route.params.uid,
                },
              },
            ],
          })
        );
      }
    } else {
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: "Main" },
            {
              name: "GigInfo",
              params: {
                uid: props.route.params.uid,
              },
            },
          ],
        })
      );
    }
  };

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      {/* <form id="myForm" action="https://fancyshop.unaux.com/wc-api/WC_Gateway_Vouch/" hidden method="GET"> */}
      <form id='myForm' hidden method='POST'></form>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "OpenSans-Bold",
              fontSize: 20,
              textAlign: "center",
              color: "#2109F4",
              paddingVertical: 20,
              paddingBottom: 0,
            }}
          >
            Vouch
          </Text>
          {/* <AIcon name="checkcircle" style={{fontSize:100,color:'#34eb3a',alignSelf:'center'}}/> */}

          <LottieView
            options={{
              animationData: TickAnim,
              loop: false,
              autoplay: true,
            }}
            style={{ height: 250, width: 300 }}
          />
          {props.route.params.mode == "buying" ? (
            <Text style={styles.text}>
              Awesome! You are all set. Seller{"\n"}has been notified.{"\n\n"}We
              will let you know as soon as{"\n"}Seller accepts the order and
              {"\n"}starts the Order
            </Text>
          ) : props.route.params.mode == "fund_milestone" ? (
            <Text style={styles.text}>
              Awesome! You are all set. Seller{"\n"}has been notified.{"\n\n"}We
              will let you know as soon as{"\n"}Seller accepts the milestone and
              {"\n"}starts the Order
            </Text>
          ) : props.route.params.mode == "recommendation" ? (
            <Text style={styles.text}>
              Awesome! You are all set.{"\n"}Your recommendation{"\n"}has been
              requested.{"\n\n"}We will let you know as soon as{"\n"}the
              recommendation has{"\n"}been shared.
            </Text>
          ) : (
            <Text style={styles.text}>
              Awesome! You are all set. Buyer{"\n"} has been notified.{"\n\n"}We
              will let you know as soon as{"\n"} Buyer funds the {"\n"}Order.
            </Text>
          )}
          {props.route.params.mode == "recommendation" ? null : (
            <Text style={styles.text}>
              Note: Money is released only{"\n"}after the order is delivered.
            </Text>
          )}
          {props.route.params.mode == "buying" ||
          props.route.params.mode == "selling" ||
          props.route.params.mode == "recommendation" ? (
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              {props.route.params.mode == "recommendation" ? null : (
                <Text
                  style={{
                    fontFamily: "OpenSans-SemiBold",
                    fontSize: 15,
                    textAlign: "center",
                    color: config.primary_color,
                    marginTop: 30,
                  }}
                >
                  Share the Order details with the{" "}
                  {props.route.params.mode == "buying" ? "seller" : "buyer"}
                </Text>
              )}
              <TouchableOpacity
                onPress={ShareMessage}
                style={{
                  marginTop: 20,
                  backgroundColor: config.primary_color,
                  borderRadius: 100,
                  flexDirection: "row",
                  paddingHorizontal: 20,
                  paddingVertical: 8,
                }}
              >
                <AIcon
                  name='sharealt'
                  style={{ fontSize: 20, color: "#FFF" }}
                />
                <Text
                  style={{
                    fontFamily: "Roboto-Bold",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#fff",
                    marginLeft: 10,
                  }}
                >
                  Share
                </Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={skip}>
                <Text
                  style={{
                    fontFamily: "Roboto-Bold",
                    fontSize: 16,
                    textAlign: "center",
                    color: config.primary_color,
                    marginLeft: 10,
                    marginBottom: 30,
                    marginTop: 10,
                  }}
                >
                  Skip
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  animationSize: { height: 400, aspectRatio: 16 / 9 },
  text: {
    fontFamily: "OpenSans-SemiBold",
    fontSize: 17,
    textAlign: "center",
    color: "#000000",
    // paddingVertical: 40,
    paddingTop: 20,
    lineHeight: 26,
    letterSpacing: 1,
  },
});

export default connect(
  state => ({
    category: { ...state.category },
    paymentDetails: { ...state.paymentdetail },
    checkout: { ...state.paymentcheckout },
    requestpayment: { ...state.requestpayment },
    ...state.userProfile,
  }),
  {}
)(PaymentResult);
