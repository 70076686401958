import {call, takeLatest, put} from 'redux-saga/effects'
import {
LOAD_CATEGORIES_SUCCESS,
LOAD_CATEGORIES_ERROR,
LOAD_CATEGORIES
} from '../constants/constants'
import MiscService from '../../services/misc.service'

const execloadCategories = async () => {
  try {
    
    var temp =await MiscService.getCategories()
    return temp

  } catch (error) {
    throw {message: 'Something went wrong'}
  }
}

function * loadCategories () {
  try {
    const res = yield call(execloadCategories)
    yield put({type: LOAD_CATEGORIES_SUCCESS, response: res})
  } catch (error) {
    // console.log(error)
    yield put({type: LOAD_CATEGORIES_ERROR, response: {message: error.message}})
  }
}

function * watchCategories () {
  yield takeLatest(LOAD_CATEGORIES, loadCategories)
}

export default watchCategories
