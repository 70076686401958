import { MY_MONEY_CHANGE_PAGE, MY_MONEY_LOAD } from "../constants/constants";


export function changeMoneyPage(pageNum){
    // console.log(pageNum);
    
    return {
        type:MY_MONEY_CHANGE_PAGE,
        pageNum:pageNum
    }
}

export function loadMyMoney(){
    
    return {
        type:MY_MONEY_LOAD,
    }
}

