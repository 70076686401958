import React, { Component } from "react";
import { View, Text, SafeAreaView, ScrollView } from "react-native";
import Header from "../../components/Header";

export default class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Header
            title={"Terms And Conditions"}
            navigation={this.props.navigation}
          />
          <View style={{ marginHorizontal: "8%" }}>
            <Text
              style={{
                fontSize: 16,
                textAlign: "justify",
                fontFamily: "Roboto-Medium",
                color: "#464646",
                marginTop: 20,
              }}
            >
              We all hate fine print and agreements and terms and conditions
              that are long and make us feel stupid since they are either too
              difficult to understand or designed in such a way that it is
              neither convenient to understand or agree to them. You are not
              alone and we feel the same. While, to protect both of us, we do
              have a long version of the agreement, we have summarized the key
              points of the longer agreement here.
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                1.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Vouch provides a trusted payment and ratings/recommendation
                services to sellers/providers (for eg. Free lancers) and buyers.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                2.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Vouch provides this as a way to establish trust quickly, do
                business quickly and protect payments for all parties involved.
                The intent is to reduce the time and cost of doing business as
                well as increase the amount of business conducted.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 0 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                3.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Vouch shall not be used for illegal purposes
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                4.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Vouch shall not be responsible in anyway for under or
                non-delivered goods or services
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                5.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Seller or provider is responsible to ensure their
                representations are accurate and they are aware of what they are
                either representing or agreeing to deliver. They are also
                responsible to ensure their services or goods provided or sold
                is free from defects and meets the representations or agreed
                upon terms with the buyer. The seller is also responsible to
                meet all timelines and any other obligations agreed to with the
                buyer
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                6.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Buyer is responsible to ensure adequate response and feedback as
                well as timely acceptance is provided to the seller or provider.
                Buyer is further responsible to ensure that no false
                representations or under or non-performance are provided
              </Text>
            </View>
            <Text
              style={{
                fontSize: 16,
                textAlign: "justify",
                fontFamily: "Roboto-Medium",
                color: "#464646",
                marginTop: 20,
              }}
            >
              These Terms of Use or Terms and Conditions or Agreement shall be
              the agreement between all transaction parties as Vouch, a service
              of Summit Paycom Pvt Ltd. You are bound by these terms and
              conditions when you initiate a transaction. You will continue to
              be bound by these transactions even after completing the
              transaction where your obligations go beyond this transaction. If
              you don’t wish to agree to these terms and conditions, you must
              stop using our services{" "}
            </Text>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                1.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Definitions – “Account” means (i) an account of a Buyer from
                which payment for the Transaction and related fees will be
                obtained, (ii) an account of a Seller to which payment for the
                Transaction and other payments will be credited, or (iii) an
                account of an intermediary to which/whom payment for the
                Transaction and other payments will be credited and/or related
                fees will be obtained. “Agreement” refers to this Agreement, the
                then current operating rules contained on the Site and the
                Transaction Money Hold Instructions. “Transaction Detail
                Screens” means those screens on the Site where Users provide all
                requested information in connection with a Transaction. “Money
                Holding Instructions” or “General Instructions” means the
                document on the Site that contains the terms agreed upon on the
                Transaction Detail Screens, as well as the other terms and
                conditions of the business transaction including these Terms of
                Use. “User” means Buyer(s), Seller(s), and Broker(s)
                participating in a Transaction. “Site” refers to the website for
                the Services which can be found at Vouch. Capitalized terms not
                defined herein shall have the same meaning as set forth in the
                General Money Instructions.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                2.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Description of the Service – The Services are payment protection
                and transaction management services performed by Vouch., a
                service of Summit Paycom Pvt Ltd which acts as a trusted
                intermediary in a Transaction which holds the money on behalf of
                the buyer and/or the seller/provider, to facilitate the
                completion of the underlying transaction under the terms of this
                Agreement, the Site and the applicable Transaction Instructions.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                3.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Limitation of Liability: Vouch has no liability on the
                underlying transaction and shall not be responsible for the
                non-performance or non-delivery by the seller or service
                provider or the buyer. Where the Buyer and the seller/ service
                provider have mutually agreed to both the contractual terms and
                the final terms of release of payment, Vouch acting as an agent
                for both parties, upon this mutual agreement is freed from any
                and all liability for the money received. The money is held in a
                fiduciary responsibility and Vouch shall return the money to the
                buyer in the event of non-performance. In the event of under
                performance, Vouch shall act as an intermediary and provide a
                resolution service to ensure both parties come to a mutually
                acceptable agreement. Vouch shall in no way be responsible for
                any shortcomings of this mutually agreed agreement.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                4.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Vouch will receive payment either as an advance or partial
                payment or as a security fee from the buyer for a transaction
                that is initiated either by the buyer or the seller or the
                service provider. Vouch will hold these funds on behalf of the
                transacting parties as an interest free amount. Vouch shall
                release the payment to the buyer in full, in the event of
                cancellation of the transaction, after mutual consent of both
                the parties. Vouch may release the payment to the seller in
                full, in the event of successful delivery or completion of goods
                or services or the contract as mutually agreed upon by both
                parties. Vouch may also release partial payments to both parties
                (buyer and seller) in the event of partial delivery or under
                delivery for the contracted goods and/or services.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                5.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Vouch is neither responsible nor does it guarantee the
                performance or suitability of the products and/or services sold
                or purchases. The buyer and seller shall themselves be wholly
                responsible. Vouch neither endorses nor denies the
                representations of the transacting parties. Vouch acts as a
                trusted impartial intermediary that only acts as an agent that
                is holding funds on behalf of the transacting parties to enable
                them to build trust, reduce friction or time taken to do
                business and provide a means to guarantee performance or
                delivery to the satisfaction of both parties.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                6.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Illegal goods or transactions or services: Vouch shall not be
                used to transact in illegal goods or illegal services or for
                illegal transactions. The definition of illegality shall be the
                same as defined from time to time by Governments and law
                enforcement authorities. Further, Vouch shall also not be used
                for money laundering or pyramid or ponzi or investment schemes.
                If it is brought to Vouch’s attention that the funds or the
                transactions are related to illegal purposes, Vouch reserves the
                right to cease the funds and notify the authorities.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                7.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                General Conditions of Use – You represent and warrant that all
                information you provide to Vouch to be true, accurate and
                complete. The party entering into this Agreement on behalf of
                any User represents and warrants that he/she is authorized to do
                so and to bind the User and is a natural person of at least
                eighteen (18) years of age. In order to initiate and commence a
                Transaction, all Users to a Transaction must register at the
                Site, agree to all terms in the General Instructions and agree
                to the Transaction Instructions.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                8.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Obligations of Sellers – On the Transaction Detail Screens, each
                Seller to a Transaction must designate an Account to which
                payment for the Transaction will be made. Each Seller authorizes
                Vouch and its authorized agents to initiate credit entries to
                such Seller’s Account for payment of the purchase price, or
                applicable balance due, and to debit Seller’s Account to
                discharge Seller’s obligations. Each Seller in a Transaction
                shall deliver the items set forth in Transaction Detail Screens
                directly to the Buyer (or Buyers), at the address specified by
                such Buyer as shown on the Vouch website and on the terms and
                conditions set forth in the Transaction Instructions and General
                Instructions. Seller shall use a delivery service that provides
                a confirmation of delivery and Seller shall provide Vouch with a
                tracking or reference number for the shipment of the goods.
                Seller gives Vouch permission to act as its agent in
                communicating with the shipping company regarding the notice of
                the delivery of the goods. In the event Vouch does not receive
                notice of shipment from Seller within ten (10) calendar days of
                notification from Vouch’s notice to Seller to ship the items,
                Seller authorizes Vouch to return the funds (excluding Vouch
                fees) to Buyer. In the event of a return of the items by Buyer,
                Seller shall notify Vouch of the receipt of the returned items.
                Upon receipt of such notice from Seller, the Seller’s five (5)
                day inspection period shall commence. In the event Seller
                accepts the returned items within the inspection period or fails
                to act within the inspection period, Vouch shall remit the held
                funds (excluding Vouch fees) to Buyer. If Seller notifies Vouch
                of its non-acceptance of any returned items within the Seller’s
                inspection period, then Vouch will retain the held funds pending
                resolution of the dispute or take other action as authorized.
                Notwithstanding anything to the contrary above, if all Users to
                a Transaction agree on the Transaction Detail Screens that there
                is no shipping required, then no party hereto will have any
                obligation under this Agreement with respect to shipping.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                9.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Obligations of Buyers – On the Transaction Detail Screens, Buyer
                must designate a payment mechanism and an Account from which the
                purchase price and related fees (unless such fees are to be paid
                by Seller) will be obtained for the deposit into hold account.
                Depending on the amount of the Transaction and the currency
                selected for the Transaction, Buyer may remit the necessary
                funds via various methods, which may include corporate credit
                card, charge card, debit card or purchasing card, check
                (cheque), demand draft, money order, or bank transfer. In the
                case of bank transfers, Buyer will initiate the wire to an
                account designated by Vouch on or before the date set forth in
                the Transaction Detail Screens. Regardless of the payment
                method, Buyer authorizes Vouch and Vouch authorized agents to
                initiate credit or debit transactions, as applicable, to obtain
                the purchase price and fees due for a Transaction and to
                initiate any debit or credit entries or reversals, as the case
                may be, as may be necessary to correct any error in a payment or
                transfer and to discharge Buyer’s obligations. Vouch will
                deposit funds received from Buyer into an trust account
                maintained by Vouch (the “Trust Account”). Amounts held in trust
                do not earn interest for Buyer or Seller. Buyer shall notify
                Vouch of the receipt or non-receipt of the items on the date the
                merchandise is received or the Buyer Inspection Period is
                started. Buyer shall notify Vouch of the Buyer’s acceptance or
                rejection of the items before the Buyer’s Inspection Period
                expires. Upon receipt of notice from Buyer that the items have
                been received and accepted, Vouch shall transfer the payment
                amount (less any amount payable to Vouch for Vouch fees) to
                Seller’s Account. Transfer to a Seller generally will be
                initiated within the next business day from the day on which
                notice of acceptance of the items is received from the Buyer. If
                Buyer has not notified Vouch of the non-receipt or rejection of
                the items during the Buyer’s Inspection Period, then Buyer
                authorizes Vouch to remit the held funds (excluding Vouch fees)
                to the Seller. Buyer shall follow the procedures set forth on
                the Site in the event the items are rejected.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.07,
                }}
              >
                10.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.93,
                  textAlign: "justify",
                }}
              >
                Our Responsibilities – Vouch is obligated to perform only those
                duties expressly described in this Agreement and the General
                Instructions. Vouch shall not be liable for any error in
                judgment, for any act taken or not taken, or for any mistake of
                fact or law, except for gross negligence or willful misconduct.
                Vouch may rely upon any notice, demand, request, letter,
                certificate, agreement or any other document which purports to
                have been transmitted or signed by or on behalf of a User
                indicated as the sender or signatory thereof and shall have no
                duty to make any inquiry or investigation. In the event that
                Vouch is uncertain as to Vouch duties or rights under this
                Agreement, receives any instruction, demand or notice from any
                User or financial institution which, in Vouch’s opinion, is in
                conflict with any of the provisions of this Agreement, or any
                dispute arises with respect to this Agreement or the ed Funds,
                Vouch may (i) consult with counsel of our choice (including our
                own lawyers) and any actions taken or not taken based upon
                advice of counsel shall be deemed consented to by you, or (ii)
                refrain from taking any action other than to retain the funds in
                for delivery in accordance with the written agreement of the
                Users, the final decision or award of an arbitrator pursuant to
                an arbitration commenced and conducted in accordance with the
                General Instructions or a final, non-appealable judgment of a
                court of competent jurisdiction, or (iii) discharge our duties
                under this Agreement by depositing all funds by interpleader
                action with a court of competent jurisdiction in accordance with
                the procedures outlined in the General Instructions.
              </Text>
            </View>
            <Text
              style={{
                fontSize: 16,
                textAlign: "justify",
                fontFamily: "Roboto-Medium",
                color: "#464646",
                marginTop: 20,
              }}
            >
              Refund Policy
            </Text>
            <Text
              style={{
                fontSize: 16,
                textAlign: "justify",
                fontFamily: "Roboto-Medium",
                color: "#464646",
                marginTop: 20,
              }}
            >
              Vouch has a refund policy that is designed to work with your
              practical needs. Our Refund policy is refund within 2-3 Working
              days and works as under:
            </Text>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                1.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Transaction initiated and funded but not accepted by buyer: Full
                refund to your bank account
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                2.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Transaction initiated and funded. Accepted by buyer but mutually
                withdrawn: Refund to your bank account less 5% Vouch fees from
                transactions beyond 5 transactions per year. If within the 5
                transactions limit, full refund without any fees being applied.
              </Text>
            </View>
            <View style={{ flexDirection: "row", marginTop: 20 }}>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.05,
                }}
              >
                3.
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  color: "#828282",
                  fontFamily: "Roboto-Regular",
                  flex: 0.95,
                  textAlign: "justify",
                }}
              >
                Transaction initiated and funded. Accepted by buyer but in
                dispute : Dispute will be moderated by Vouch and an amount based
                on the circustances of the dispute and with agreement of all
                parties - respective amounts will be remitted less 5% Vouch fees
                from transactions beyond 5 per year
              </Text>
            </View>
            <Text
              style={{
                fontSize: 14,
                color: "#828282",
                fontFamily: "Roboto-Regular",
                flex: 0.95,
                textAlign: "justify",
              }}
            >
              Exceptions to the above will be dealt with on a case by case
              basis. In any case, refunds will be initiated within 2-3 working
              days of the final decision after consent of all transacting
              parties
            </Text>
            <Text
              style={{
                fontSize: 16,
                textAlign: "justify",
                fontFamily: "Roboto-Bold",
                color: "#464646",
                marginVertical: 20,
              }}
            >
              The above terms and conditions are not meant to be exhaustive but
              inclusive.
            </Text>
          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
