import {call, takeLatest, put} from 'redux-saga/effects'
import {
  FETCH_ACTIVITY,
  FETCH_ACTIVITY_ERROR,
  FETCH_ACTIVITY_SUCCESS,
} from '../constants/constants'
import moment from 'moment'
import MiscService from '../../services/misc.service'

const execFetchActivities = async () => {
  try {
    
    var temp = await MiscService.activityFeed()
    return temp

  } catch (error) {
    throw {message: 'Something went wrong'}
  }
}

function * fetchActivities () {
  try {
    const res = yield call(execFetchActivities)
    yield put({type: FETCH_ACTIVITY_SUCCESS, response: {activities: res}})
  } catch (error) {
    // console.log(error)

    yield put({type: FETCH_ACTIVITY_ERROR, response: {message: error.message}})
  }
}

function * watchActivities () {
  yield takeLatest(FETCH_ACTIVITY, fetchActivities)
}

export default watchActivities
