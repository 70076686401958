import {call, takeLatest, put} from 'redux-saga/effects'
import { LOAD_PAYMENT_PREVIEW_SUCCESS , LOAD_PAYMENT_PREVIEW , LOAD_PAYMENT_PREVIEW_ERROR} from '../constants/constants'
import moment from 'moment'
import MiscService from '../../services/misc.service'

const execFetchPreview = async actionData => {
  try {
    var params={paymentid:actionData.paymentId}
    var temp = await MiscService.getPaymentPreview(params)
    return temp
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'}
  }
}

function * fetchPreview(action){
  try {
    const res = yield call(execFetchPreview, action)
    yield put({type: LOAD_PAYMENT_PREVIEW_SUCCESS , response:res})
  }catch(error){
    yield put({type: LOAD_PAYMENT_PREVIEW_ERROR , response: {message: error.message}})
  }
}

function * watchPaymentPreviews () {
  yield takeLatest(LOAD_PAYMENT_PREVIEW, fetchPreview)
}

export default watchPaymentPreviews
