import {
    FETCH_NOTIFICATIONS,FETCH_NOTIFICATIONS_SUCCESS,FETCH_NOTIFICATIONS_ERROR
  } from '../constants/constants'
  
  const initialState = {
    allNotifications: [],
    isLoading: true,
    error: '',
  }
  
  const reducer = function allNotificationsReducer (state = initialState, action) {
    switch (action.type) {
      case FETCH_NOTIFICATIONS: {
        return {
          ...state,
          isLoading: true,
          error: '',
          allNotifications: [],
        }
      }
      case FETCH_NOTIFICATIONS_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          allNotifications: action.response.allNotifications,
        }
      }
      case FETCH_NOTIFICATIONS_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: action.response.message,
        }
      }
      default:
        return state
    }
  }
  
  export default reducer
  