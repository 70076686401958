import { takeLatest, put, call } from "redux-saga/effects";
import {
  MAKE_TRANSACTION,
  TRANSACTION_FAILURE,
  TRANSACTION_SUCCESS,
} from "../constants/constants";
import userService from "../../services/user.service";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const execTransaction = async actionData => {
  try {
    const {
      data,
      milestones,
      mode,
      request_to_mobile,
      category,
      milestone_mode,
      classification,
      Attachments,
    } = actionData;
    const { amount, gigName, requirements } = data;
    var request_to_user = await userService.getUser({
      key: "mobile",
      value: request_to_mobile,
    });
    if (!request_to_user) {
      throw { message: "User not found" };
    }

    /* -------------------- Getting request to user details / end ------------------- */

    /* ---------------------- Constructing the transaction ---------------------- */
    var currentUserId = await firebase.auth().currentUser?.uid;
    var transaction_details = {};
    (transaction_details.request_from = currentUserId ? currentUserId : null),
      (transaction_details.mode = mode);
    transaction_details.to_user = request_to_user.id;
    transaction_details.gig_name = gigName;
    transaction_details.requirements = requirements;
    transaction_details.isVirtual = milestone_mode
      ? milestones.length
        ? false
        : true
      : true;
    transaction_details.milestones = milestone_mode ? milestones : [];
    transaction_details.category = category;
    transaction_details.order_status = "yet_to_confirm";
    transaction_details.total_amount = amount;
    transaction_details.classification = classification;
    transaction_details.Attachments = Attachments;
    return transaction_details;
  } catch (error) {
    throw error;
  }
};

function* makeTransaction(action) {
  try {
    const res = yield call(execTransaction, action);
    yield put({
      type: TRANSACTION_SUCCESS,
      response: {
        transaction_details: res,
        proceedToPayment: action.mode == "buying" ? true : false,
        proceedToGig: action.mode == "selling" ? true : false,
      },
    });
  } catch (error) {
    yield put({
      type: TRANSACTION_FAILURE,
      response: { message: error.message },
    });
  }
}

function* paymentDetailWatcher() {
  yield takeLatest(MAKE_TRANSACTION, makeTransaction);
}

export default paymentDetailWatcher;
