import config from '../config';
import Interceptor from './Interceptor';

class AuthService {
  registerUser = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/auth/register`,
        params,
      );
      return result.data.data;
    } catch (error) {
      return error.response.data;
    }
  };
  checkSignUp = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/auth/checkSignUp`,
        params,
      );
      return result.data.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

export default new AuthService();
