import config from '../config';
import Interceptor from './Interceptor';

class OrderService {
  createOrder = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/order/create`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  createAccount = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/virtuall/account/create`,
        params);
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  fetchVirtuallAccount = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/virtuall/account/get`,
        params);
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  cancelVirtuallAccount = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/virtuall/account/cancel`,
        params);
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  };
}

export default new OrderService();
