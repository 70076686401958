import React, { Component } from "react";
import { Thumbnail } from "native-base";
import {
  Text,
  View,
  Dimensions,
  Animated,
  TouchableOpacity,
  ImageBackground,
  StatusBar,
  SafeAreaView,
} from "react-native";
import config from "../../config";
import { connect } from "react-redux";
import { logout } from "../../state-management/actions/auth.action";
import { loadRecentContacts } from "../../state-management/actions/recentcontacts.action";
import { loadProfile } from "../../state-management/actions/userprofile.action";
import { loadEssentials } from "../../state-management/actions/helper.action";
import BIcon from "react-native-vector-icons/FontAwesome";
import ContactsBadge from "../../components/ContactsBadge";
import logsService from "../../services/logs.service";
import OffersCarousal from "../../components/OffersCarousal";
import Bell from "../../assets/images/bell";
import ActionPending from "../../assets/images/actionPending";
import StartPayment from "../../assets/images/startPayment";
import RequestPayment from "../../assets/images/requestPayment";
import Question from "../../assets/images/question";
import VideoWalkthrough from "../../assets/images/videoWalkthrough";
import Help from "../../assets/images/help";
import Whoisusing from "../../assets/images/whoisusing";
import { changeMode } from "../../state-management/actions/contacts.action";
import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";
import { Modal } from "react-native-paper";
import VideoModal from "../../components/VideoWalkThrough";

import LottieView from "lottie-react-web";

import protectAnim from "../../assets/lottie-animations/protect-payment.json";

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");

class OldHome extends Component {
  scroll = new Animated.Value(0);
  headerY = Animated.multiply(Animated.diffClamp(this.scroll, 0, 56), -1);
  _draggedValue = new Animated.Value(180);
  constructor(props) {
    super(props);
    this.state = {
      carouselItems: [],
      activeIndex: 0,
      link: "",
      showVideoModal: false,
    };
  }

  componentDidMount() {
    firebase
      .remoteConfig()
      .fetch(0)
      .then(result => {
        firebase
          .remoteConfig()
          .fetchAndActivate()
          .then(result2 => {
            var offers = firebase.remoteConfig().getValue("offers");
            var link = firebase.remoteConfig().getValue("walkthrough_link");
            this.setState({
              carouselItems: JSON.parse(offers.asString()),
              link: link.asString(),
            });
          })
          .catch(err => {});
      })
      .catch(err => {});
  }

  // notifyMerchant = async (link,data) =>{
  //   try {
  //     var formData = new FormData();
  //     for (var key in data) {
  //         console.log(key);
  //         console.log(data[key]);
  //         formData.append(key, data[key])
  //     }
  //     fetch(link, {
  //       method: 'POST',
  //       // mode: 'no-cors',
  //       body: formData,
  //     }).then(response => response.json())
  //     .then(data => console.log(data));
  //   } catch (error) {
  //     console.log('Not Done: ',error);
  //   }
  // }

  //   notifyMerchant = async (link,data) =>{
  //   try {
  //     var form=document.getElementById("myForm");
  //     var input1 = document.createElement('input');
  //     input1.setAttribute('name', 'custom');
  //     input1.setAttribute('value', data['custom']);
  //     input1.setAttribute('type', 'hidden');
  //     form.appendChild(input1);
  //     var input2 = document.createElement('input');
  //     input2.setAttribute('name', 'payment_status');
  //     input2.setAttribute('value', data['payment_status']);
  //     input2.setAttribute('type', 'hidden');
  //     form.appendChild(input2);
  //     var input3 = document.createElement('input');
  //     input3.setAttribute('name', 'currency_type');
  //     input3.setAttribute('value', data['currency_type']);
  //     input3.setAttribute('type', 'hidden');
  //     form.appendChild(input3);
  //     var input4 = document.createElement('input');
  //     input4.setAttribute('name', 'gross');
  //     input4.setAttribute('value', data['gross']);
  //     input4.setAttribute('type', 'hidden');
  //     form.appendChild(input4);
  //     form.submit();
  //   } catch (error) {
  //     console.log('Not Done: ',error);
  //   }
  // }

  render() {
    const region = {
      latitude: 37.78825,
      longitude: -122.4324,
      latitudeDelta: 0.015,
      longitudeDelta: 0.0121,
    };

    const borderRadius = this._draggedValue.interpolate({
      inputRange: [screenHeight - 48 - 60, screenHeight - 60],
      outputRange: [30, 0],
      extrapolate: "clamp",
    });

    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
        {/* <form id="myForm" action="https://fancyshop.unaux.com/wc-api/WC_Gateway_Vouch/" hidden method="GET">
        <form id="myForm" action="http://localhost/wordpress/wc-api/WC_Gateway_Vouch/" hidden method="GET">

        </form> */}
        <StatusBar backgroundColor={config.primary_color} />

        <Animated.ScrollView
          scrollEventThrottle={5}
          showsVerticalScrollIndicator={false}
          style={{ zIndex: 0, backgroundColor: "#FFF" }}
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { y: this.scroll } } }],
            { useNativeDriver: true }
          )}
        >
          <Animated.View
            style={{
              height: 240,
              width: "100%",
              transform: [{ translateY: Animated.multiply(this.scroll, 0.8) }],
            }}
          >
            <ImageBackground
              source={require("../../assets/images/5123.jpg")}
              style={{ flex: 1, justifyContent: "center" }}
            >
              <LottieView
                style={{ position:"absolute", zIndex:"-1", float:"left ", alignItems:"left", width:"25%", marginLeft:"4.5%" }}
                options={{ animationData: protectAnim, autoplay: true, loop: true }}
              />
              <Text
                style={{
                  fontSize: 16,
                  paddingTop: this.props.recentContacts?.accountAdded ? 10 : 0,
                  textAlign: "center",
                  color: config.primary_color,
                  fontFamily: 'Georgia, serif',
                  fontWeight: '600',
                  // fontStyle : "italic"
                }}
              >
                Your Number of
              </Text>
              <Text
                style={{
                  fontSize: 22,
                  paddingTop: this.props.recentContacts?.accountAdded ? 5 : 0,
                  textAlign: "center",
                  color: config.primary_color,
                  fontFamily: 'Georgia, serif',
                  fontWeight: '800',
                  // fontStyle : "italic"
                }}
              >
                Protected Payments
              </Text>

              {this.props.recentContacts.total_payments_length != 0 ? (
                <View style={{ alignItems: "center" }}>
                  <TouchableOpacity
                    style={{ backgroundColor: "transparent", paddingHorizontal: 100 }}
                    onPress={() => {
                      console.log("pressed");
                      logsService.logCustomEvent(
                        "pending_actions_button_clicked",
                        {}
                      );
                      this.props.navigation.navigate("Payments", {
                        screen: "ProtectedPayments",
                        params: {},
                      });
                      // logsService.logCustomEvent('protected_payments_button_clicked',{})
                      // this.props.navigation.navigate('ProtectedPayments')
                      // this.notifyMerchant('https://fancyshop.unaux.com/wc-api/WC_Gateway_Vouch/',{
                      //   custom:JSON.stringify({
                      //     order_id:2839,
                      //     order_key:'wc_order_QGeYgturVVbfj'
                      //   }),
                      //   payment_status:'completed',
                      //   currency_type:'INR',
                      //   gross:'150',
                      // })
                      // this.notifyMerchant('http://localhost/wordpress/wc-api/WC_Gateway_Vouch/',{
                      //   custom:JSON.stringify({
                      //     order_id:2994,
                      //     order_key:'wc_order_1uqnwlkgjWbpn'
                      //   }),
                      //   payment_status:'completed',
                      //   currency_type:'INR',
                      //   gross:'200',
                      // })
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 38,
                        textAlign: "center",
                        color: "#404040",
                        fontFamily: "Georgia, serif",
                        fontWeight:"bold"
                      }}
                    >
                      {this.props.recentContacts.total_payments_length}
                    </Text>
                    <Text
                      style={{
                        fontSize: 13,
                        textAlign: "center",
                        color: "#666565",
                        fontFamily: "Verdana, sans-serif",
                        fontWeight:"bold"
                      }}
                    >
                      ( Total Amount : Rs.{this.props.recentContacts.sum} )
                    </Text>
                  </TouchableOpacity>
                  {this.props.recentContacts?.accountAdded ? null : (
                    <TouchableOpacity
                      onPress={() => {
                        this.props.navigation.navigate("MyBank");
                      }}
                      style={{
                        borderColor: "#D92E0B",
                        marginTop: 10,
                        borderRadius: 15,
                        borderWidth: 1,
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "OpenSans-Regular",
                          color: "#D92E0B",
                          fontSize: 12,
                        }}
                      >
                        Update UPI or Bank A/C details
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              ) : (
                <Text style={styles.txt2}>
                  We are excited for you to start your {"\n"} 1st Protected
                  Payment
                </Text>
              )}
            </ImageBackground>
          </Animated.View>
          <Animated.View
            style={[
              styles.main,
              {
                borderTopLeftRadius: borderRadius,
                borderTopRightRadius: borderRadius,
              },
            ]}
          >
            <View
              style={{
                width: 30,
                height: 3,
                borderBottomColor: 10,
                backgroundColor: "#D8D8D8",
                alignSelf: "center",
                marginBottom: 15,
              }}
            ></View>
            {this.state.carouselItems.length > 0 ? (
              <OffersCarousal items={this.state.carouselItems} />
            ) : null}
            <View
              style={{
                flexDirection: "row",
                width: "95%",
                maxWidth: "98%",
                flexWrap: "wrap",
                alignSelf: "center",
                justifyContent: "flex-start",
                marginBottom: 150,
                marginTop: 15,
              }}
            >
              {/* <ContactsBadge
                  clicked={() =>{
                  logsService.logCustomEvent('all_notifications_button_clicked',{})
                  this.props.navigation.navigate('AllNotifications')
                  }
                  }
                  iconPresent={true}
                  textContent={'Notifications'}
                  iconName='bell'
                /> */}
              <ContactsBadge
                clicked={() => {
                  logsService.logCustomEvent(
                    "all_notifications_button_clicked",
                    {}
                  );
                  this.props.navigation.navigate("AllNotifications");
                }}
                iconPresent={true}
                textContent={"Notifications"}
                iconName={() => {
                  return <Bell />;
                }}
              />
              <ContactsBadge
                clicked={() => {
                  logsService.logCustomEvent(
                    "pending_actions_button_clicked",
                    {}
                  );
                  this.props.navigation.navigate("Payments", {
                    screen: "ProtectedPayments",
                    params: {},
                  });
                }}
                iconPresent={true}
                iconName={() => {
                  return <ActionPending />;
                }}
                textContent={"Pending Actions"}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Start Payment"}
                iconName={() => {
                  return <StartPayment />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "start_payment_button_clicked",
                    {}
                  );
                  this.props.changeMode("buying");
                  this.props.navigation.navigate("HowVouchWorks");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Request Money"}
                iconName={() => {
                  return <RequestPayment style={{ marginTop: 17 }} />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "request_money_button_clicked",
                    {}
                  );
                  this.props.changeMode("selling");
                  this.props.navigation.navigate("HowVouchWorks");
                }}
              />
              {this.props.recentContacts.recentContacts.map((item, index) => {
                return (
                  <ContactsBadge
                    color={`${config.colors[index % config.colors.length]}`}
                    key={index}
                    iconPresent={false}
                    action_pending_from={item.action_pending_from}
                    textContent={`${item.to_user_name}`}
                    {...item}
                    iconName='bell'
                    clicked={() => {
                      logsService.logCustomEvent(
                        "payment_user_button_clicked",
                        {}
                      );
                      this.props.navigation.navigate("PaymentUser", {
                        uid: `${item.to_uid}`,
                      });
                    }}
                  />
                );
              })}
              {this.props.recentContacts.expressIntrestUsers.map(
                (item, index) => {
                  return (
                    <ContactsBadge
                      color={`${config.colors[index % config.colors.length]}`}
                      key={index}
                      iconPresent={false}
                      textContent={`${
                        item.to_user_details?.company_name
                          ? item.to_user_details?.company_name
                          : item.to_user_details.firstname
                      }`}
                      {...item}
                      clicked={() => {
                        logsService.logCustomEvent(
                          "payment_user_button_clicked",
                          {}
                        );
                        this.props.navigation.navigate("PaymentUser", {
                          uid: `${item.to_uid}`,
                          subRoute: "Messages",
                        });
                      }}
                    />
                  );
                }
              )}

              {/* <ContactsBadge
                  clicked={() => {
                    logsService.logCustomEvent('who_is_using_vouch_button_clicked',{})
                    this.props.navigation.navigate('WhoIsUsing')
                  }}
                  iconPresent={true}
                  iconName='users'
                  textContent={'Who is using Vouch ?'}
                />
                <ContactsBadge
                  iconPresent={true}
                  textContent={'Help'}
                  iconName='chatbubbles'
                  clicked={() => {
                    logsService.logCustomEvent('help_button_clicked',{})
                    this.props.navigation.navigate('WhatsApp')
                  }}
                />
                <ContactsBadge
                  iconPresent={true}
                  textContent={'How Vouch works'}
                  iconName='question'
                  changeIcon={true}
                  clicked={() =>{
                    logsService.logCustomEvent('how_vouch_works_button_clicked',{})
                    this.props.navigation.navigate('HowVouchWorks')
                  }}
                /> */}
              <ContactsBadge
                iconPresent={true}
                textContent={"How vouch works"}
                iconName={() => {
                  return <Question />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "how_vouch_works_button_clicked",
                    {}
                  );
                  this.props.navigation.navigate("HowVouchWorks");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Video walk through"}
                iconName={() => {
                  return <VideoWalkthrough />;
                }}
                clicked={() => {
                  logsService.logCustomEvent("video_walk_through_clicked", {});
                  this.setState({ showVideoModal: true });
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Help"}
                iconName={() => {
                  return <Help />;
                }}
                clicked={() => {
                  logsService.logCustomEvent("help_button_clicked", {});
                  this.props.navigation.navigate("WhatsApp");
                }}
              />
              <ContactsBadge
                iconPresent={true}
                textContent={"Who's using vouch"}
                iconName={() => {
                  return <Whoisusing />;
                }}
                clicked={() => {
                  logsService.logCustomEvent(
                    "who_is_using_vouch_button_clicked",
                    {}
                  );
                  this.props.navigation.navigate("WhoIsUsing");
                }}
              />
            </View>
          </Animated.View>
        </Animated.ScrollView>

        {/* <View
          style={{
            paddingVertical: 20,
            paddingHorizontal: 30,
            position: 'absolute',
            width: '100%',
            bottom: 0,
            backgroundColor:'#FFF'
          }}>
          {this.props.recentContacts.total_payments_length != 0 ? (
            <View>
              <TouchableOpacity
                style={{flexDirection: 'row', marginBottom: 20}}
                onPress={() => {
                  logsService.logCustomEvent('my_money_button_clicked',{})
                  this.props.navigation.navigate('MyMoney')
                }}>
                <MIcon
                  name='swap-vertical'
                  style={{fontSize: 20, color: '#473BB3'}}
                />
                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    color: '#473BB3',
                    fontSize: 16,
                    flex: 1,
                    paddingLeft: 10,
                  }}>
                  My Money
                </Text>
                <AIcon name='right' style={{fontSize: 25, color: '#473BB3'}} />
              </TouchableOpacity>
            </View>
          ) : null}
          <TouchableOpacity
            style={styles.buttonPayment}
            onPress={() => {
              if (this.props.recentContacts.total_payments_length)
                this.props.navigation.navigate('Category',{title:'Go ahead, select a\nCategory',description:'You can protect payments for Goods and Services by paying through Vouch.',flow_code:0})
              else this.props.navigation.navigate('HowVouchWorks')
            }}>
            <Text
              style={{
                color: '#F7F6F9',
                fontSize: 18,
                fontFamily: 'Roboto-Regular',
              }}>
              + New Protected Payment
            </Text>
          </TouchableOpacity>
        </View> */}
        <Modal
          visible={this.state.showVideoModal}
          contentContainerStyle={{
            backgroundColor: "white",
            padding: 20,
            flex: 1,
          }}
          onDismiss={() => {
            this.setState({ showVideoModal: false });
          }}
        >
          <VideoModal
            link={"https://www.youtube.com/embed/eO5zN7Yfjow"}
            onGoBack={data => {
              this.setState({ showVideoModal: false });
            }}
          />
        </Modal>
      </SafeAreaView>
    );
  }
}

const styles = {
  container: {
    flex: 1,
    backgroundColor: "#f8f9fa",
    alignItems: "center",
    justifyContent: "center",
  },
  panel: {
    flex: 1,
    backgroundColor: "white",
    position: "relative",
  },
  panelHeader: {
    height: 180,
    backgroundColor: "#b197fc",
    justifyContent: "flex-end",
    padding: 24,
  },
  textHeader: {
    fontSize: 28,
    color: "#FFF",
  },
  icon: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: -24,
    right: 18,
    width: 48,
    height: 48,
    zIndex: 1,
  },
  iconBg: {
    backgroundColor: "#2b8a3e",
    position: "absolute",
    top: -24,
    right: 18,
    width: 48,
    height: 48,
    borderRadius: 24,
    zIndex: 1,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  image: {
    flex: 1,
    backgroundColor: "#FFF",
  },
  txt2: {
    fontSize: 14,
    textAlign: "center",
    color: "#707070",
    fontFamily: "OpenSans-Regular",
    marginTop: 10,
  },
  txt3: {
    fontSize: 16,
    color: "#464646",
    fontFamily: "OpenSans-SemiBold",
    marginLeft: 15,
  },
  txt4: {
    fontSize: 14,
    color: "#464646",
    fontFamily: "Roboto-Regular",
    textAlign: "center",
    marginTop: 10,
  },
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  buttonPayment: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: config.primary_color,
    padding: 10,
    borderRadius: 25,
    zIndex: 100,
    elevation: 24,
    borderColor: "#fff",
    shadowColor: "#fff",
  },
  searchBar: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    paddingHorizontal: 30,
    paddingVertical: 0,
    borderRightWidth: 1,
    borderRightColor: "#DCDCDC",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderTopColor: "#DCDCDC",
    borderLeftColor: "#DCDCDC",
    backgroundColor: "#FFF",
    borderRadius: 30,
    elevation: 0,
    alignSelf: "center",
  },
  main: {
    flex: 1,
    padding: 0,
    paddingTop: 15,
    marginTop: 0,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 24,
    borderColor: "#fff",
    borderWidth: 1,
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    minHeight: screenHeight - 60,
  },
};

export default connect(
  state => ({
    recentContacts: { ...state.recentContacts },
    profile: { ...state.userProfile },
    floating: { ...state.floating },
    ...state.auth,
  }),
  {
    logout,
    loadRecentContacts,
    loadProfile,
    loadEssentials,
    changeMode,
  }
)(OldHome);
