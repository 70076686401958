import {MY_MONEY_CHANGE_PAGE , MY_MONEY_LOAD , MY_MONEY_LOAD_ERROR , MY_MONEY_LOAD_SUCCESS} from '../constants/constants'

const initialState = {
  pageNumMoney: 0,
  spentChart:[],
  earnedChart:[],
  settledList:[],
  paidList:[],
  requiredMilestonesData:[],
  recievedList:[],
  toBeRecievedList:[],
  isLoading: true,
  error: '',
}

const reducer = function myMoneyReducer (state = initialState, action) {
  switch (action.type) {
    case MY_MONEY_CHANGE_PAGE: {
        
      return {
        ...state,
        pageNumMoney: action.pageNum,
      }
    }
    case MY_MONEY_LOAD: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case MY_MONEY_LOAD_SUCCESS: {
      // console.log(action.response.requiredMilestonesData);
      // console.log(action.response.requiredMilestonesData.length);
      return {
        ...state,
        spentChart:action.response.spentChart,
             earnedChart:action.response.earnedChart,
             requiredMilestonesData:action.response.requiredMilestonesData,
             paidList:action.response.paidList,
             toBeRecievedList:action.response.toBeRecievedList,
             recievedList:action.response.recievedList,
             settledList:action.response.settledList,
             isLoading: false,
      }
    }
    case MY_MONEY_LOAD_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer