//import liraries
import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  StatusBar,
  Dimensions,
} from 'react-native'
import PrimaryButton from '../../components/PrimaryButton'
import config from '../../config'
import Icon from 'react-native-vector-icons/FontAwesome'
import {connect} from 'react-redux'
import {googleSignIn,loginLater} from '../../state-management/actions/auth.action'
import PlayStore from '../../assets/images/playStoreSvg.svg';
import PlayStoreSmall from '../../assets/images/playStoreSmall.svg';
import AppStore from '../../assets/images/appStoreSvg.svg';
import AppStoreSmall from '../../assets/images/appStoreSmall.svg';
import Logo from '../../assets/images/darkpurple';
import logsService from '../../services/logs.service'
import LottieView from "lottie-react-web";

import loadingAnim from "../../assets/lottie-animations/payments.json";
import loader1 from "../../assets/lottie-animations/loader1.json";


// create a component
class GetStarted extends Component {

  render () {
    this.props.isLoginLater ? window.location.reload() : null;
    return (
      this.props.isLoginLater ? 
      
      <View> 
        <LottieView style={{ position:"absolute", height:"auto", aspectRatio:"1", width:"22vw", alignSelf:"center", zIndex:"-1", margin:"20% 0 0 0"}}
                        options={{ animationData: loader1, autoplay: true, loop: true }}
                      />
        </View> 
    
    : 
    <View style={styles.container}>
        <StatusBar backgroundColor={config.primary_color} />
        <View style={{flex:0.3,justifyContent:Dimensions.get("window").width>600?'center':'flex-end'}}>

                  {
                    this.props.isLoginLater?
                    null:
                    <Text style={styles.txt1}>Hi There! Welcome to</Text>
                    }
                      <Logo width="110" height="110"/>

                      <LottieView style={{ position:"absolute", height:"auto", aspectRatio:"1", width:"22vw", alignSelf:"flex-end", zIndex:"-1", margin:"15% 15% 0 0"}}
                        options={{ animationData: loadingAnim, autoplay: true, loop: true }}
                      />
                      
        </View>

            
          <View style={{flex:0.46,justifyContent:Dimensions.get("window").width>600?'space-evenly':'center'}}>
                              {
                              this.props.isLoginLater?
                              <Text style={{lineHeight:40,alignSelf:'flex-start',color:config.primary_color,fontFamily:'OpenSans-Regular',fontSize:20}}>
                              To continue{'\n'}Sign up / Sign in
                              </Text>:
                              // null
                              <View style={{height:10}}>
                                </View>
                              }
                              <PrimaryButton
                                content={'Login'}
                                clicked={() => { 
                                  logsService.logCustomEvent(`login_button_clicked`,{})
                                  this.props.navigation.navigate('Login')
                                }}
                              />
                              {Dimensions.get("window").width>600?null:<View style={{height:10}}></View>}
                              <View
                                style={{
                                  flexDirection: 'row',
                                  marginTop: 20,
                                  marginBottom:0,
                                  justifyContent: 'center',
                                  fontFamily:'Roboto-Regular'
                                }}>
                                  
                                <Text style={styles.txt3}>Don't have an account?</Text>
                                <TouchableOpacity
                                onPress={() => {
                                  this.clicked() 
                                }
                                }>
                                      <Text style={styles.existed}> Sign Up</Text>
                                </TouchableOpacity>
                              </View>
                              {Dimensions.get("window").width>600?null:<View style={{height:10}}></View>}


                              {this.props.isLoginLater? null :
                              <TouchableOpacity onPress={()=>{
                                  logsService.logCustomEvent('login_later',{})
                                this.props.loginLater()}}>
                              <Text style={{fontSize: 22,paddingBottom:20,fontFamily:'OpenSans-SemiBold', marginTop: 20,color: '#473BB3', textAlign: 'center'}}>
                              Skip/Login Later
                              </Text>  
                              </TouchableOpacity>
                              }
          </View>

          
          <View style={{borderTopColor:'#707070',borderTopWidth:3,borderStyle:'dashed',flex:0.24,justifyContent:'space-evenly'}}>
                              <Text style={{fontSize: 18,fontFamily:'OpenSans-SemiBold', color: '#464646',textAlign: 'center'}}>or Download the app on</Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-evenly',
                                  alignItems: 'center',
                                }}>
                                <TouchableOpacity
                                style={{marginTop:5}}
                                  onPress={() => window.open('https://play.google.com/store/apps/details?id=com.vouch.payment.protection.marketplace', '_blank')}
                                  >
                                    {
                                      Dimensions.get("window").width>600?
                                      <PlayStore />:<PlayStoreSmall />
                                    }
                                  
                                </TouchableOpacity>
                                {/* <TouchableOpacity
                                style={{marginTop:5}}
                                  onPress={() => window.open('https://apps.apple.com/in/app/id1528795323', '_blank')}
                                  >
                                    {
                                      Dimensions.get("window").width>600?
                                      <AppStore />:<AppStoreSmall />
                                    }
                                </TouchableOpacity> */}
                              </View>
          </View>
      </View>
    )
  }
  clicked = () => {
    this.props.navigation.navigate('SignUp')
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    paddingTop: 0,
    paddingHorizontal: '10%',
    paddingBottom:'2%',
    justifyContent:'space-evenly'
  },
  imgContainer: {
    flex: 0.3,
  },
  txt1: {
    color: config.primary_color,
    fontSize: 24,
    fontFamily: '',
    fontFamily: 'OpenSans-Regular',
    marginBottom:25,
  },
  txt2: {
    color: config.primary_color,
    fontSize: 48,
    fontFamily: '',
    fontFamily: 'OpenSans-SemiBold',
  },
  txt3: {
    color: '#545455',
    fontSize: 20,
    textAlign: 'center',
    // fontFamily: 'Roboto-Medium',
  },
  existed: {
    fontSize: 18,
    color: '#473BB3',
  },
  content: {
    flex: 0.7,
    justifyContent:'space-evenly',
  },
})

//make this component available to the app
export default connect(state => ({floating:{...state.floating},...state.auth}), {googleSignIn,loginLater})(GetStarted)
