//import liraries
import React, { Component, useEffect, useState } from "react";
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Share,
} from "react-native";
import { CommonActions } from "@react-navigation/native";
import config from "../../config";
import LottieView from "lottie-react-web";
import TickAnim from "../../assets/lottie-animations/tick-anim.json";
import AIcon from "react-native-vector-icons/AntDesign";
// import Share from 'react-native-share'
import { connect } from "react-redux";
// import dynamicLinks from '@react-native-firebase/dynamic-links'
import Clipboard from "expo-clipboard";
import apiService from "../../services/api.service";
import failedAnim from "../../assets/lottie-animations/payment-failed.json";


const PaymentResult = props => {
  const [link, setLink] = useState("");
  const [payment_data,set_payment_data] = useState(link);

  useEffect(()=>{
    if(['Buyer_Create_Order','Fund_Full_Payment','Fund_Milestone'].includes(props.route.params?.mode)) fetch_details()
  },[])


  const fetch_details = async () => {
    try {
      const {data} = await apiService({
        method:'post',
        endpoint:'/app/payment/result/preview',
        params:{
          id: props.route.params.id
        }
      });
      set_payment_data(data);
    } catch (error) {
      window.alert(error)
    }
  }

  const skip = () => {
      window?.ReactNativeWebView?.postMessage('skip')
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [{ name: "Main" }],
        })
      )
  }

  const shareLogic = message => {
    try {
      window?.ReactNativeWebView?.postMessage(message);
      navigator.clipboard.writeText(message).then(function() {
        Share.share({
          message: message,
        });
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    } catch (error) {
    }
  };

  const ShareMessage = () => {
      shareLogic(
        `${payment_data.buyer_name} has paid you Rs.${payment_data.total_amount} through vouch for ${payment_data.category},${payment_data.gig_name}. Click here to accept the payment & start the Order ${payment_data.order_link}`
      );
  };



  if (props.route.params?.mode=='Failed') {
    return (
      <View style={styles.container}>
        <View style={{ width: 400, height: 400 }}>
          <LottieView
            options={{
              animationData: failedAnim,
              autoplay: true,
              loop: false,
            }}
            style={styles.animationSize}
          />
        </View>
        <TouchableOpacity 
              onPress={skip}
              >
                <Text
                  style={{
                    fontFamily: "Roboto-Bold",
                    fontSize: 16,
                    textAlign: "center",
                    backgroundColor: config.primary_color,
                    marginLeft: 10,
                    marginBottom: 30,
                    marginTop: 10,
                    padding:10,
                    color:'#FFF',
                    borderRadius:100
                  }}
                >
                  Home
                </Text>
              </TouchableOpacity>
      </View>
    );
  }


  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Text
            style={{
              fontFamily: "OpenSans-Bold",
              fontSize: 20,
              textAlign: "center",
              color: "#2109F4",
              paddingVertical: 20,
              paddingBottom: 0,
            }}
          >
            Vouch
          </Text>
          {/* <AIcon name="checkcircle" style={{fontSize:100,color:'#34eb3a',alignSelf:'center'}}/> */}

          <LottieView
            options={{
              animationData: TickAnim,
              loop: false,
              autoplay: true,
            }}
            style={{ height: 250, width: 300 }}
          />
          {["Fund_Full_Payment","Buyer_Create_Order"].includes(props.route.params?.mode) && (
            <Text style={styles.text}>
              Awesome! You are all set. Seller{"\n"}has been notified.{"\n\n"}We
              will let you know as soon as{"\n"}Seller accepts the order and
              {"\n"}starts the Order
            </Text>
          )}
          {props.route.params?.mode == "Fund_Milestone" && (
            <Text style={styles.text}>
              Awesome! You are all set. Seller{"\n"}has been notified.{"\n\n"}We
              will let you know as soon as{"\n"}Seller accepts the milestone and
              {"\n"}starts the Order
            </Text>
          )}
            <Text style={styles.text}>
              Note: Money is released only{"\n"}after the order is delivered.
            </Text>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "OpenSans-SemiBold",
                    fontSize: 15,
                    textAlign: "center",
                    color: config.primary_color,
                    marginTop: 30,
                  }}
                >
                  Share the Order details with the seller
                </Text>
              <TouchableOpacity
                onPress={ShareMessage}
                style={{
                  marginTop: 20,
                  backgroundColor: config.primary_color,
                  borderRadius: 100,
                  flexDirection: "row",
                  paddingHorizontal: 20,
                  paddingVertical: 8,
                }}
              >
                <AIcon
                  name='sharealt'
                  style={{ fontSize: 20, color: "#FFF" }}
                />
                <Text
                  style={{
                    fontFamily: "Roboto-Bold",
                    fontSize: 16,
                    textAlign: "center",
                    color: "#fff",
                    marginLeft: 10,
                  }}
                >
                  Share
                </Text>
              </TouchableOpacity>
              <TouchableOpacity 
              onPress={skip}
              >
                <Text
                  style={{
                    fontFamily: "Roboto-Bold",
                    fontSize: 16,
                    textAlign: "center",
                    color: config.primary_color,
                    marginLeft: 10,
                    marginBottom: 30,
                    marginTop: 10,
                  }}
                >
                  Skip
                </Text>
              </TouchableOpacity>
            </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
  animationSize: { height: 400, aspectRatio: 16 / 9 },
  text: {
    fontFamily: "OpenSans-SemiBold",
    fontSize: 17,
    textAlign: "center",
    color: "#000000",
    paddingTop: 20,
    lineHeight: 26,
    letterSpacing: 1,
  },
  animationSize: {
    width: 300,
    height: 300,
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingHorizontal: 20,
  },
});

export default PaymentResult;
