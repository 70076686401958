import {
  SEARCH_CONTACTS,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
  INIT_CONTACTS,
} from '../constants/constants';

const initialState = {
  searchTerm: '',
  searchResult:[],
  isLoading: false,
  error: '',
};

const reducer = function contactsSearchReducer(state = initialState, action) {
  switch (action.type) {
    case INIT_CONTACTS: {
      // console.log(action.phoneContacts);
      // console.log(action.vouchUsers);
      return {
        ...state,
        // phoneContacts: action.phoneContacts,
        // vouchUsers: action.vouchUsers,
        // filteredContacts: [],
        // filteredVouchUsers: [],
        searchResult:[],
        searchTerm: '',
        error: '',
      };
    }

    case SEARCH_CONTACTS: {
      return {
        ...state,
        isLoading:true
      };
    }

    case SEARCH_SUCCESS: {
      // console.log(action.response.searchResult);
      return {
        ...state,
        searchResult:action.response.searchResult,
        isLoading: false,
      };
    }
    case SEARCH_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
