import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity} from 'react-native'

const NotificationsCard = props => {
  return (
    <TouchableOpacity style={styles.container} onPress={props.clicked}>
      <Text style={styles.activityText}>
        {props.notification.title}
      </Text>
      <Text style={styles.activityStatus}>{props.notification.body}</Text>
      <Text style={styles.activityTime}>{props.formattedDate}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    padding: '5%',
    borderRadius: 15,
    margin: 10,
    elevation:4,
    shadowColor: "#000",
shadowOffset: {
	width: 0,
	height: 2,
},
shadowOpacity: 0.25,
shadowRadius: 3.84,
  },
  activityText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: '#473BB3',
    marginBottom: 10,
    lineHeight:22
  },
  activityStatus: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#545455',
    marginBottom: 10,
  },
  activityTime: {fontFamily: 'Roboto-Regular', fontSize: 10, color: '#545455'},
})


export default NotificationsCard