import {
  CHANGE_PROTECTED_PAGE,
  LOAD_PROTECTED_PAYMENTS,
} from '../constants/constants'

export function changeProtectedPage (pageNum) {
  return {
    type: CHANGE_PROTECTED_PAGE,
    pageNum: pageNum,
  }
}

export function loadProtectedPayments () {
  return {
    type: LOAD_PROTECTED_PAYMENTS,
  }
}
