import React, { Component } from "react";
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Text,
} from "react-native";
import AIcon from "react-native-vector-icons/AntDesign";
import config from "../../config";
import InputText from "../InputText";
import PrimaryButton from "../PrimaryButton";

export default class OneLineAboutMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txt: "",
    };
  }
  renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          paddingHorizontal: 20,
          paddingTop: 20,
        }}
      >
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            this.props.cameBack();
          }}
        >
          <AIcon name='left' style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: "OpenSans-Semibold",
            color: config.primary_color,
          }}
        >
          One liner about you
        </Text>
      </View>
    );
  };
  render() {
    return (
      <SafeAreaView style={{ flex: 1, backgroundColor: "#FFF" }}>
        {this.renderHeader()}
        <View
          style={{
            marginHorizontal: "5%",
            flex: 1,
            marginBottom: "10%",
            marginTop: "5%",
          }}
        >
          <InputText
            maxLength={140}
            onChangeText={t => this.setState({ txt: t })}
            value={this.state.txt}
            label={""}
            placeholder={"Enter a one line description about you"}
          />
          <View style={{ flex: 1 }}></View>
          <PrimaryButton
            disabled={this.state.txt.trim() == ""}
            content={"Save"}
            clicked={() => {
              if (this.state.txt.length > 140) {
                alert(
                  "Your one line description is too long max 140 characters"
                );
              } else {
                this.props.onClose(this.state.txt);
              }
            }}
          />
        </View>
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  backButton: {
    color: "#473BB3",
    fontSize: 22,
    paddingRight: 15,
  },
  icon: {
    fontSize: 20,
    marginBottom: 5,
    marginHorizontal: 15,
    color: "#473BB3",
  },
  searchItem: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: 'center',
    paddingHorizontal: 15,
    paddingVertical: 2,
    borderRightWidth: 1,
    borderRightColor: "#DCDCDC",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderTopColor: "#DCDCDC",
    borderLeftColor: "#DCDCDC",
    backgroundColor: "#FFF",
    borderRadius: 30,
    marginVertical: 25,
    elevation: 0,
    alignSelf: "center",
  },
});
