import {
CANCEL_VIRTUALL_ACCOUNT ,
CANCEL_VIRTUALL_ACCOUNT_ERROR ,
CANCEL_VIRTUALL_ACCOUNT_SUCCESS , 
LOAD_VIRTUALL_ACCOUNT, 
LOAD_VIRTUALL_ACCOUNT_ERROR, 
LOAD_VIRTUALL_ACCOUNT_SUCCESS 
} from '../constants/constants'
  
  const initialState = {
    isLoading: true,
    error: '',
    success:false
  }
  
  const reducer = function smartCollectReducer(state = initialState, action){
    switch (action.type) {
      case CANCEL_VIRTUALL_ACCOUNT: {
        return {
          ...state,
          isLoading: true,
          error: '',
        }
      }
      case CANCEL_VIRTUALL_ACCOUNT_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          success:true,
        }
      }
      case CANCEL_VIRTUALL_ACCOUNT_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: action.response.message,
        }
      }
      case LOAD_VIRTUALL_ACCOUNT: {
        return {
          ...state,
          isLoading: true,
          error: '',
        }
      }
      case LOAD_VIRTUALL_ACCOUNT_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          accountData:action.response
        }
      }
      case LOAD_VIRTUALL_ACCOUNT_ERROR: {
        return {
          ...state,
          isLoading: false,
          error: action.response.message,
        }
      }
      default:
        return state
    }
  }
  
  export default reducer
  