import React, {PureComponent} from 'react'
import {View, Text, TouchableOpacity} from 'react-native'
import {Thumbnail} from 'native-base'
import config from '../config'

class ContactItem extends PureComponent {
  render () {
    return (
      <TouchableOpacity
        onPress={this.props.clicked}
        style={{
          width: '25%',
          marginBottom: 15,
          alignItems: 'center',
        }}>
          {this.props.action_pending_from?
          <View style={{backgroundColor:'#D92E0B',width:15,height:15,borderRadius:10,marginLeft:30,zIndex:150,marginBottom:-14}}>
          </View>
          :null
        }
        {this.props.iconPresent ? (
          <View
            style={{
              borderColor: config.primary_color,
              width: 50,
              height: 50,
              justifyContent: 'center',
              borderRadius: 50,
              // borderWidth:2,
              justifyContent:'center',
              alignItems:'center'
            }}>
            {this.props.iconName()}
          </View>
        ) : this.props?.to_user_details.profile_image_path ? (
          <Thumbnail
            source={{uri: this.props.to_user_details.profile_image_path}}
            style={{
              width: 50,
              height: 50,
            }}
          />
        ) : (
          <View
            style={{
              backgroundColor: this.props.color
                ? `${this.props.color}`
                : config.primary_color,
              width: 50,
              height: 50,
              justifyContent: 'center',
              borderRadius: 50,
            }}>
            <Text
              style={{
                fontSize: 25,
                color: '#FFF',
                textAlign: 'center',
                fontFamily:'Roboto-Bold',
                textTransform: 'capitalize',
              }}>
              {this.props.textContent[0]}
            </Text>
          </View>
        )}
        <Text
          numberOfLines={2}
          style={{
            fontSize: 13,
            color: '#464646',
            fontFamily: 'Roboto-Regular',
            textAlign: 'center',
            marginTop: 10,
          }}>
          {this.props.textContent}
        </Text>
      </TouchableOpacity>
    )
  }
}

export default ContactItem
