import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { Provider } from "react-redux";
import Navigation from "./navigation/Navigation";
import store from "./state-management/store";
import config from "./config";
import helperService from "./services/helper.service";
import miscService from "./services/misc.service";
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';

if(!firebase.apps.length){
  firebase.initializeApp(config.firebase);
  const defaultAnalytics = firebase.analytics();
  // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.)
}

const setCookieAndReload = (version) =>{
  // console.log('setting Cookie');
  helperService.setCookie("local_version",version,90)
  window.location.href = window.location.href.replace(/#.*$/, '');
}

const getLocalVersion = helperService.getCookie("local_version");
if(getLocalVersion){
  // console.log('Version present');
  miscService.getWebLatestVersion().then((result) => {
    if(result.hasOwnProperty('webMin')){
      // console.log(result.webMin);
      // console.log(getLocalVersion);
      if(parseInt(getLocalVersion)<parseInt(result.webMin)){
        setCookieAndReload(result.webMin)
      }
    }else{
      setCookieAndReload(result.webMin)
    }
  }).catch((err) => {
    // console.log(err);
  });
}else{
  // console.log('Version Not present');
  miscService.getWebLatestVersion().then((result) => {
    setCookieAndReload(result.webMin)
  }).catch((err) => {
    // console.log(err);
  });
}

function App() {
  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#282c34",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default App;


