import {
  SIGNUP,
  SIGNUP_ERROR,
  SIGNUP_SUCCESS,
  AUTH_SUCCESS,
} from '../constants/constants'

const initialState = {
  isLoading: false,
  error: '',
  success: false,
  confirm: null,
  proceedWithOtp: false,
  data: {},
}

const reducer = function signUpReducer (state = initialState, action) {

  switch (action.type) {
    case SIGNUP: {
      // console.log(action.data);
      return {
        ...state,
        isLoading: true,
        proceedWithOtp: false,
        confirm: null,
        data: action.data,
        error: '',
      }
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        proceedWithOtp: true,
        confirm: action.response.confirmation,
      }
    }
    case SIGNUP_ERROR: {
      return {
        ...state,
        isLoading: false,
        proceedWithOtp: false,
        error: action.response.message,
        data: {},
      }
    }
    case AUTH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
