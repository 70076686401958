
import React, {Component} from 'react';
import {View, Text, StyleSheet, Image, TouchableOpacity} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { connect } from 'react-redux';
import Image1 from '../../assets/images/how_vouch_work.svg';
import PrimaryButton from '../../components/PrimaryButton';
import config from '../../config';

// create a component

class HowVouchWorks extends Component {
  render() {
    return (
      <View style={{
        flex: 1,
        backgroundColor: '#481380',
        paddingTop: '5%',
        paddingBottom: location.href.includes("HowVouchWorks") ? "2.5%" : "10%"
      }}>
        <TouchableOpacity style={{ zIndex:"2" }} onPress={() => {
              this.props.navigation.goBack();
              }}> 
        <svg xmlns="http://www.w3.org/2000/svg" 
              style={{ marginLeft:"2.5rem", fill:"white", height:"2.4rem", width:"2.4rem", cursor:"pointer", position:"absolute" }} 
              fill="white" class="bi bi-arrow-left-square-fill" 
              viewBox="0 0 16 16"
              >
            <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1"/>
          </svg>
          </TouchableOpacity>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            // margin:"5rem 0"
            height:"50rem"
          }}>
          <Image1 
          // width={'85%'} height={'90%'}
           style={{alignSelf: 'center'}}/>
          {/* <Image source={require('../../assets/images/how_vouch_work1.png')} style={{width:'35%',height:'80%'}}/> */}
          {/* <PrimaryButton
              content={'Continue '}
              clicked={() => {
                this.props.navigation.navigate('Category');
              }}
            /> */}
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent:'center',
            background : "none"
            // top:"20%"
            // marginRight: 40,
            // marginTop:"2rem"
          }}>
          <TouchableOpacity
            onPress={() => {
              if(this.props.isLoginLater || !(this.props.recentContacts.total_payments_length)){
                if(window) window.location.replace("/GetStarted");
                else props.navigation.navigate("Main")
              }else{
                this.props.navigation.navigate('Category',{title:'Go ahead, select a\nCategory',description:'You can protect payments for Goods and Services by paying through Vouch.',flow_code:0});
              }
            }}
            style={{backgroundColor: '#fff', borderRadius: 8 }}>
            <Text
              style={{
                color: config.primary_color,
                fontFamily: 'OpenSans-Bold',
                fontSize: 12,
                paddingHorizontal: 20,
                paddingVertical: 10,
              }}>
              Continue
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      
    );
  }
}

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#481380',
    paddingVertical: '10%',
  },
  txt1: {
    fontFamily: 'OpenSans-Semibold',
    fontSize: 26,
    textAlign: 'right',
    color: '#FFF',
    marginBottom: 10,
    // marginRight:"5%"
    textAlign: 'center',
  },
  txt2: {
    fontSize: 20,
    color: '#FFF',
    fontFamily: 'Roboto-Medium',
    marginVertical: 20,
    textAlign: 'left',
  },
  txt3: {
    fontSize: 18,
    color: '#FFF',
    fontFamily: 'Roboto-Regular',
    marginBottom: 20,
    textAlign: 'left',
  },
  playStyles: {
    flex: 0.09,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: '10%',
  },
});

//make this component available to the app
export default connect(state => ({
  recentContacts: {...state.recentContacts},
  ...state.auth}), {
})(HowVouchWorks);
