import React from 'react'
import {StyleSheet,View,TouchableOpacity,Text} from 'react-native'
import {AntDesign} from '@expo/vector-icons';
import config from '../config';

const Header = props => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingTop: 20,
      }}>
      <TouchableOpacity
        activeOpacity={0.6}
        onPress={() => props.navigation.goBack()}>
        <AntDesign name='left' style={{
        color: props?.color?props.color:'#473BB3',
        fontSize: 22,
        paddingRight: 15,
      }} />
      </TouchableOpacity>
      <Text
        numberOfLines={1}
        style={{
          flex: 1,
          fontSize: 26,
          fontFamily: 'OpenSans-Semibold',
          color: props?.color? props.color: config.primary_color,
        }}>
        {props.title}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
    backButton: {
        color: '#473BB3',
        fontSize: 22,
        paddingRight: 15,
      },
})

export default Header;