import Axios from 'axios';
import config from '../config';
import Interceptor from './Interceptor';

class MiscService {
  activityFeed = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/activity/feed`,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  vouchUsers = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/vouch/users`,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  getPaymentPreview = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payment/preview`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  getCategories = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/categories`,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };
  createVirtualUser = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/create/virtualUser`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };

  getHelperKey = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/vhk`
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };

  getCreds = async () => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/vrcreds/web`
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };

  getCashfreeSignature = async (params) => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/cf/signature/get`,
        params
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };

  getCashfreeSignatureforGuestPayment = async (params) => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payment/guest/cf/signature/get`,
        params
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  };

  getCashfreeOrderTokenforGuestPayment = async (params) => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/payment/guest/cf/token/get`,
        params
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  getCheckoutPreview = async () =>{
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/checkout/preview`,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  saveUpiInfo = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/save/upi`,
        params,
      );
      return result.data.data;
    } catch (error) {
      // console.log(error);
      throw error.response.data;
    }
  }
  
  getWebLatestVersion = async () =>{
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.get(
        `${config.node_api_endpoint}/version/min`,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }
  requestRecommendationsService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/recommendations/request`,
        params,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  fetchRecommendationsService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/recommendations/fetch`,
        params,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  submitRecommendationsService = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/recommendations/submit`,
        params,
      );
      return result.data.data;
    } catch (error) {
      throw error.response.data;
    }
  }

  genrerateApiKey = async params => {
    try {
      let axiosObj = await Interceptor.getObject();
      const result = await axiosObj.post(
        `${config.node_api_endpoint}/oauth/generate/token`,
        params,
      );
      return result.data;
    } catch (error) {
      console.log(error);
      throw error.response.data;
    }
  }

  instantiate_vouch_UPI_collect = async params => {
    try {
      let axiosObj = Axios.create()
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
        url:`${config.node_api_endpoint}/payment/guest/upi/collect/initiate`,
      };
      const result = await axiosObj(options);
      return result?.data?.data;
    } catch (error) {
      throw error.response.data;
    }
  }


  check_upi_collection_status = async params => {
    try {
      let axiosObj = Axios.create()
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
        url:`${config.node_api_endpoint}/payment/guest/upi/status`,
        timeout:8000,
        timeoutErrorMessage:"error"
      };
      const result = await axiosObj(options);
      return result?.data?.data;
    } catch (error) {
      return error?.response?.data;
    }
  }

}

export default new MiscService();
