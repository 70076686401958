import React, {Component} from 'react';
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  StyleSheet,
  Text,
  ScrollView,
} from 'react-native';
import AIcon from 'react-native-vector-icons/AntDesign';
import config from '../../config';
import PrimaryButton from '../../components/PrimaryButton';
import InputText from '../../components/InputText';
import LinkBankAcountOverlay from '../../components/mybank/LinkBankAcountOverlay';
import {Formik} from 'formik';
import * as yup from 'yup';
import {linkAccount} from '../../state-management/actions/accounts.action';
import {connect} from 'react-redux';
import {Textarea} from 'native-base';
import { Modal } from 'react-native-paper';
;
import AwesomeAlert from 'react-native-awesome-alerts';
import {Svg, Image} from 'react-native-svg';
import SVG2 from '../../assets/images/paydetails2.svg';


class LinkBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acNumber: '',
      ifsc: '',
      accountHolderName: '',
      address: '',
      openOverlay: false,
      alertVisible: false,
    };
  }
  handleAcNumberInChange = acNumber => {
    this.setState({acNumber});
  };
  handleIfscChange = ifsc => {
    this.setState({ifsc});
  };
  handleAccountHolderNameChange = accountHolderName => {
    this.setState({accountHolderName});
  };
  handleSubmit = values => {
    if (
      values.acNumber.trim() != '' &&
      values.ifsc.trim() != '' &&
      values.accountHolderName.trim() != ''
    ) {
      this.setState({openOverlay: true});
    }
  };
  handleAddressChange = address => {
    this.setState({address});
  };
  componentDidUpdate(prevProps) {
    if (this.props.error != prevProps.error) {
      if (this.props.error != '') {
        // alert(this.props.error)
        this.setState({alertVisible: true});
      }
    }
    if (this.props.linkedSuccess) {
      this.props.navigation.navigate('MyBank');
    }
  }
  renderHeader = () => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 20,
          paddingTop: 20,
        }}>
        <TouchableOpacity
          activeOpacity={0.6}
          onPress={() => {
            this.props.navigation.goBack();
          }}>
          <AIcon name="left" style={styles.backButton} />
        </TouchableOpacity>
        <Text
          numberOfLines={1}
          style={{
            flex: 1,
            fontSize: 26,
            fontFamily: 'OpenSans-Semibold',
            color: config.primary_color,
          }}>
          Update Bank A/C
        </Text>
      </View>
    );
  };
  render() {
    const {acNumber, ifsc, accountHolderName, address} = this.state;
    const validationSchema = yup.object().shape({
      acNumber: yup
        .string()
        .required()
        .label('acNumber'),
      ifsc: yup
        .string()
        .required()
        .label('ifsc'),
      accountHolderName: yup
        .string()
        .required()
        .label('accountHolderName'),
      address: yup
        .string()
        .trim()
        .label('address')
        .required(),
    });
    return (
      <Formik
        initialValues={{
          acNumber: '',
          ifsc: '',
          accountHolderName: '',
          address: '',
        }}
        onSubmit={values => {
          this.handleSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView style={{flex: 1, backgroundColor: '#FFF'}}>
            <ScrollView showsVerticalScrollIndicator={false}>
              {this.renderHeader()}
              <View style={{flex: 1, marginHorizontal: '10%', paddingTop: 25}}>
              <Text
                style={{
                  fontSize: 18,
                  color: '#707070',
                  fontFamily: 'OpenSans-Regular',
                  marginBottom:25,
                  paddingHorizontal:8
                }}>
                Update your bank details to receive money in your account.
              </Text>
              <InputText
                  name="acNumber"
                  label="A/c Number"
                  placeholder="Enter your bank account number"
                  onChangeText={handleChange('acNumber')}
                  onBlur={handleBlur('acNumber')}
                  error={touched.acNumber && errors.acNumber}
                  value={values.acNumber}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.acNumber && errors.acNumber && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Invalid Account Number :(
                    </Text>
                  )}
                  {touched.acNumber && !errors.acNumber && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#09C98F',
                      }}>
                      {/* Nice Account Number :) */}
                    </Text>
                  )}
                </View>
                <InputText
                  name="ifsc"
                  label="IFSC Code"
                  placeholder="Enter the IFSC code"
                  onChangeText={handleChange('ifsc')}
                  onBlur={handleBlur('ifsc')}
                  error={touched.ifsc && errors.ifsc}
                  value={values.ifsc}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.ifsc && errors.ifsc && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Invalid ifcs
                    </Text>
                  )}
                </View>
                <InputText
                  name="accountHolderName"
                  label="Name"
                  placeholder="Enter Account Holder's Name"
                  onChangeText={handleChange('accountHolderName')}
                  onBlur={handleBlur('accountHolderName')}
                  error={touched.accountHolderName && errors.accountHolderName}
                  value={values.accountHolderName}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.accountHolderName && errors.accountHolderName && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      please Enter a valid Name.
                    </Text>
                  )}
                </View>

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Billing Address
                </Text>
                <Textarea
                  rowSpan={4}
                  bordered
                  name="address"
                  placeholder="Billing Address"
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  onChangeText={handleChange('address')}
                  onBlur={handleBlur('address')}
                  error={touched.address && errors.address}
                  value={values.address}
                  style={{fontSize: 14, padding: 15}}
                />
                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.address && errors.address && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      Please specify valid billing address
                    </Text>
                  )}
                </View>
                <PrimaryButton
                  content={'Proceed'}
                  clicked={handleSubmit}
                  loading={this.props.isLoading}
                />
              </View>
            </ScrollView>
            <AwesomeAlert
        show={this.state.alertVisible}
        title=""
        message=""
        messageStyle={{ textAlign: 'center' }}
        customView={(
          <View
                    style={{
                      alignItems: 'center',
                      // paddingVertical: 10,
                      lineHeight: 21,
                      paddingHorizontal: 30,
                    }}>
                        <SVG2 />
              <Text
                style={{
                  fontFamily: 'Roboto-Regular',
                  marginTop: 20,
                  fontSize: 16,
                  color: '#707070',
                }}>
                {this.props?.error}
              </Text>
            </View>
        )}
        showCancelButton={false}
        showConfirmButton={true}
        confirmText="Ok"
        confirmButtonColor={config.primary_color}
        onConfirmPressed={() =>{
          this.setState({alertVisible: false});
        }}
      />
      <Modal
                visible={this.state.openOverlay}
                contentContainerStyle={{backgroundColor: 'white', padding: 20,flex:1}}
                onDismiss={() => {
                  this.setState({openOverlay: false});
                }}>
                <LinkBankAcountOverlay
                  values={values}
                  onClose={data => {
                    this.setState({openOverlay: false});
                    if (data == 'success') {
                      this.props.linkAccount(
                        'bank',
                        values.acNumber,
                        values.ifsc,
                        values.address,
                        null,
                        values.accountHolderName,
                      );
                    }
                  }}
                />
              </Modal>
          </SafeAreaView>
        )}
      </Formik>
    );
  }
}
const styles = StyleSheet.create({
  backButton: {
    color: '#473BB3',
    fontSize: 22,
    paddingRight: 15,
  },
});

export default connect(
  state => ({
    ...state.accounts,
  }),
  {
    linkAccount,
  },
)(LinkBankAccount);
