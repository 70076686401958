import { takeLatest, put, call, select } from "redux-saga/effects";
import {
  EDIT_USER_PROFILE,
  EDIT_USER_PROFILE_SUCCESS,
  EDIT_USER_PROFILE_ERROR,
  ADD_GIG_FOR_SALE,
  ADD_GIG_FOR_SALE_ERROR,
  ADD_GIG_FOR_SALE_SUCCESS,
  LOAD_INITIALL_DATA,
  LOAD_INITIALL_DATA_SUCCESS,
  LOAD_INITIALL_DATA_ERROR,
  ADD_NEW_PORTFOLIO,
  ADD_NEW_PORTFOLIO_SUCCESS,
  ADD_NEW_PORTFOLIO_ERROR,
  UPDATE_USER_PROFILE_DETAILS,
  EDIT_PORTFOLIO,
  EDIT_PORTFOLIO_SUCCESS,
  EDIT_PORTFOLIO_ERROR,
  UPDATE_USER_PROFILE_PORTFOLIO,
  UPDATE_USER_PROFILE_FOR_SALE,
  EDIT_FOR_SALE,
  EDIT_FOR_SALE_ERROR,
  EDIT_FOR_SALE_SUCCESS,
  DELETE_PORTFOLIO,
  DELETE_FOR_SALE,
  DELETE_PORTFOLIO_SUCCESS,
  DELETE_PORTFOLIO_ERROR,
  DELETE_FOR_SALE_SUCCESS,
  DELETE_FOR_SALE_ERROR,
} from "../constants/constants";
import profileService from "../../services/profile.service";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const b64toBlob = (base64, type = "application/octet-stream") =>
  fetch(`${base64}`).then(res => res.blob());

const execEditProfile = async actionData => {
  try {
    var formData = new FormData();
    var result;
    if (actionData.imgData) {
      var image = {
        uri: actionData.imgData.data,
        type: actionData.imgData.type,
        name: actionData.imgData.name,
        size: actionData.imgData.size,
      };
      const b = await b64toBlob(image.uri);
      formData.append("profile_photo", b, actionData.imgData.name);
      formData.append("key", actionData.key);
      result = await profileService.editProfile(formData);
    } else {
      formData.append("key", actionData.key);
      formData.append("value", JSON.stringify(actionData.value));
      result = await profileService.editProfile(formData);
    }
    return result;
  } catch (error) {
    // console.log(error);
    throw { message: "Something went wrong" };
  }
};

function* editProfile(action) {
  try {
    const res = yield call(execEditProfile, action);
    const getItems = state => state.editProfile;
    const currentState = yield select(getItems);
    yield put({
      type: UPDATE_USER_PROFILE_DETAILS,
      response: {
        userProfile: res,
      },
    });
    yield put({
      type: EDIT_USER_PROFILE_SUCCESS,
      response: {
        status: "success",
      },
    });
  } catch (error) {
    yield put({
      type: EDIT_USER_PROFILE_ERROR,
      response: { message: error.message },
    });
  }
}

const execAddForSale = async actionData => {
  try {
    const {
      data,
      milestones,
      category,
      milestone_mode,
      classification,
      images,
    } = actionData;
    const { amount, gigName, requirements, description } = data;

    var currentUserId = await firebase.auth().currentUser?.uid;
    var transaction_details = {};
    (transaction_details.request_from = null),
      (transaction_details.mode = "selling");
    transaction_details.to_user = currentUserId ? currentUserId : null;
    transaction_details.gig_name = gigName;
    transaction_details.description = description;
    transaction_details.requirements = requirements;
    transaction_details.isVirtual = milestone_mode
      ? milestones.length
        ? false
        : true
      : true;
    transaction_details.milestones = milestone_mode ? milestones : [];
    transaction_details.category = category;
    transaction_details.order_status = "yet_to_confirm";
    transaction_details.total_amount = amount;
    transaction_details.classification = classification;
    var formData = new FormData();
    images.forEach(async (item, i) => {
      var image = {
        uri: item.data,
        type: item.type,
        name: item.name,
        size: item.size,
      };
      const b = await b64toBlob(image.uri);
      formData.append("for_sale", b);
    });
    formData.append("transaction_details", JSON.stringify(transaction_details));
    var result = await profileService.addForSaleGig(formData);
    return result;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* addForSale(action) {
  try {
    const res = yield call(execAddForSale, action);
    yield put({
      type: UPDATE_USER_PROFILE_FOR_SALE,
      response: res,
    });
    yield put({
      type: ADD_GIG_FOR_SALE_SUCCESS,
      response: res,
    });
  } catch (error) {
    // console.log(error);
    yield put({
      type: ADD_GIG_FOR_SALE_ERROR,
      response: { message: error.message },
    });
  }
}

const execLoadInitiallProfileData = async actionData => {
  try {
    var result = await profileService.loadProfileNewData();
    return result;
  } catch (error) {
    //console.log(error);
    throw { message: "Something went wrong" };
  }
};

function* loadInitiallProfileData(action) {
  try {
    const res = yield call(execLoadInitiallProfileData, action);
    yield put({ type: LOAD_INITIALL_DATA_SUCCESS, response: res });
  } catch (error) {
    //console.log(error);
    yield put({ type: LOAD_INITIALL_DATA_ERROR, response: { message: error } });
  }
}

const execAddPortfolio = async actionData => {
  try {
    const { data, images } = actionData;
    var formData = new FormData();
    images.forEach(async (item, i) => {
      var image = {
        uri: item.data,
        type: item.type,
        name: item.name,
        size: item.size,
      };
      const b = await b64toBlob(image.uri);
      formData.append("portfolio", b);
    });
    formData.append("portfolio_details", JSON.stringify(data));
    var result = await profileService.addNewPortfolio(formData);
    return result;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* addPortfolio(action) {
  try {
    const res = yield call(execAddPortfolio, action);
    yield put({
      type: UPDATE_USER_PROFILE_PORTFOLIO,
      response: res,
    });
    yield put({
      type: ADD_NEW_PORTFOLIO_SUCCESS,
      response: res,
    });
  } catch (error) {
    // console.log(error);
    yield put({
      type: ADD_NEW_PORTFOLIO_ERROR,
      response: { message: error.message },
    });
  }
}

const execEditPortfolio = async actionData => {
  try {
    const { data, images, removed_images } = actionData;
    data.removed_images = removed_images;
    var formData = new FormData();
    images.forEach(async (item, i) => {
      var image = {
        uri: item.data,
        type: item.type,
        name: item.name,
        size: item.size,
      };
      const b = await b64toBlob(image.uri);
      formData.append("portfolio", b);
    });
    formData.append("portfolio_details", JSON.stringify(data));
    var result = await profileService.editPortfolioService(formData);
    return result;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* editPortfolio(action) {
  try {
    const res = yield call(execEditPortfolio, action);
    yield put({
      type: EDIT_PORTFOLIO_SUCCESS,
      response: res,
    });
    yield put({
      type: UPDATE_USER_PROFILE_PORTFOLIO,
      response: res,
    });
  } catch (error) {
    // console.log(error);
    yield put({
      type: EDIT_PORTFOLIO_ERROR,
      response: { message: error.message },
    });
  }
}

const execEditForSale = async actionData => {
  try {
    const {
      data,
      milestones,
      category,
      milestone_mode,
      classification,
      images,
      removed_images,
    } = actionData;
    const { amount, gigName, requirements, description, id } = data;

    var currentUserId = await firebase.auth().currentUser?.uid;
    var transaction_details = {};
    (transaction_details.request_from = null),
      (transaction_details.mode = "selling");
    transaction_details.to_user = currentUserId ? currentUserId : null;
    transaction_details.gig_name = gigName;
    transaction_details.description = description;
    transaction_details.requirements = requirements;
    transaction_details.isVirtual = milestone_mode
      ? milestones.length
        ? false
        : true
      : true;
    transaction_details.milestones = milestone_mode ? milestones : [];
    transaction_details.category = category;
    transaction_details.order_status = "yet_to_confirm";
    transaction_details.total_amount = amount;
    transaction_details.classification = classification;
    transaction_details.removed_images = removed_images;
    transaction_details.id = id;
    var formData = new FormData();
    images.forEach(async (item, i) => {
      var image = {
        uri: item.data,
        type: item.type,
        name: item.name,
        size: item.size,
      };
      const b = await b64toBlob(image.uri);
      formData.append("for_sale", b);
    });
    formData.append("transaction_details", JSON.stringify(transaction_details));
    var result = await profileService.editForSaleService(formData);
    return result;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* editForSale(action) {
  try {
    const res = yield call(execEditForSale, action);
    yield put({
      type: UPDATE_USER_PROFILE_FOR_SALE,
      response: res,
    });
    yield put({
      type: EDIT_FOR_SALE_SUCCESS,
      response: res,
    });
  } catch (error) {
    // console.log(error);
    yield put({
      type: EDIT_FOR_SALE_ERROR,
      response: { message: error.message },
    });
  }
}

const execDeletePortfolio = async actionData => {
  try {
    var result = await profileService.deletePortFolioService(actionData);
    return result;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* deletePortfolio(action) {
  try {
    const res = yield call(execDeletePortfolio, action);
    yield put({
      type: DELETE_PORTFOLIO_SUCCESS,
      response: res,
    });
    yield put({
      type: UPDATE_USER_PROFILE_PORTFOLIO,
      response: res,
    });
  } catch (error) {
    yield put({
      type: DELETE_PORTFOLIO_ERROR,
      response: { message: error.message },
    });
  }
}

const execDeleteForSale = async actionData => {
  try {
    var result = await profileService.deleteGigForSaleService(actionData);
    return result;
  } catch (error) {
    // console.log(error);
    throw error;
  }
};

function* deleteForSale(action) {
  try {
    const res = yield call(execDeleteForSale, action);
    yield put({
      type: DELETE_FOR_SALE_SUCCESS,
      response: res,
    });
    yield put({
      type: UPDATE_USER_PROFILE_FOR_SALE,
      response: res,
    });
  } catch (error) {
    yield put({
      type: DELETE_FOR_SALE_ERROR,
      response: { message: error.message },
    });
  }
}

function* watchEditProfile() {
  yield takeLatest(EDIT_USER_PROFILE, editProfile);
  yield takeLatest(ADD_GIG_FOR_SALE, addForSale);
  yield takeLatest(ADD_NEW_PORTFOLIO, addPortfolio);
  yield takeLatest(LOAD_INITIALL_DATA, loadInitiallProfileData);
  yield takeLatest(EDIT_PORTFOLIO, editPortfolio);
  yield takeLatest(EDIT_FOR_SALE, editForSale);
  yield takeLatest(DELETE_PORTFOLIO, deletePortfolio);
  yield takeLatest(DELETE_FOR_SALE, deleteForSale);
}

export default watchEditProfile;
