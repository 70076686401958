import {takeLatest, call, put, select, delay} from 'redux-saga/effects';
import {
  INVITE_USER,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
} from '../constants/constants';
import miscService from '../../services/misc.service';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const execInviteUser = async actionData => {
  try {
    const currentUser = firebase.auth().currentUser.uid;
    const phno = actionData.phno.trim();
    const email = actionData.email;

    var params = {
      firstname: phno,
      lastname: '',
      mobile_number: phno,
      email: email,
    };
    const createUser = await miscService.createVirtualUser(params);

    return true;
  } catch (error) {
    // console.log(error)
    throw {message: 'Something went wrong'};
  }
};

function* inviteUserFun(action) {
  try {
    const res = yield call(execInviteUser, action);
    yield put({
      type: INVITE_USER_SUCCESS,
    });
  } catch (error) {
    yield put({type: INVITE_USER_ERROR, response: {message: error.message}});
  }
}

function* watchInviteUser() {
  yield takeLatest(INVITE_USER, inviteUserFun);
}

export default watchInviteUser;
