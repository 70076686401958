import {PHONE_SIGNIN, PHONE_ERROR, PHONE_SUCCESS, NAVIGATE_TO_SIGNUP} from '../constants/constants'

const initialState = {
  isLoading: false,
  error: '',
  success: false,
  confirm: null,
  proceedWithOtp: false,
  mobile_or_username: '',
  mobile: '',
}

const reducer = function signInReducer (state = initialState, action) {

  switch (action.type) {
    case PHONE_SIGNIN: {
      return {
        ...state,
        isLoading: true,
        proceedWithOtp: false,
        confirm: null,
        mobile_or_username: action.data,
        error: '',
      }
    }
    case PHONE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        proceedWithOtp: true,
        confirm: action.response.confirm,
        mobile: action.response.mobile,
      }
    }
    case PHONE_ERROR: {
      return {
        ...state,
        isLoading: false,
        proceedWithOtp: false,
        error: action.response.message,
        mobile_or_username: '',
        mobile: '',
      }
    }
    case NAVIGATE_TO_SIGNUP: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
