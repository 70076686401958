import {
  INIT_TOPIC_MESSAGE_STATE,
  SEND_TOPIC_MESSAGE,
  SUBSCRIBE_TOPIC_MESSAGES,
  ON_TOPIC_MESSAGE_RECEIVED,
  TOPIC_PAGE_STATE, FETCH_TOPIC_CHAT_MESSAGES, UNSUBSCRIBE_SOCKET_CHANNEL_TOPIC_MESSAGE
} from '../constants/constants'

export function initTopicMessageState (data) {
  return {
    type: INIT_TOPIC_MESSAGE_STATE,
    data: data,
  }
}

export function subscribeToTopicMessages () {
  return {
    type: SUBSCRIBE_TOPIC_MESSAGES,
  }
}

export function topicMessageReceived (data) {
  return {
    type: ON_TOPIC_MESSAGE_RECEIVED,
    data: data,
  }
}

export function sendTopicMessage (message) {
  return {
    type: SEND_TOPIC_MESSAGE,
    message: message,
  }
}
export function setTopicPageState (bool) {
  return {
    type: TOPIC_PAGE_STATE,
    bool: bool,
  }
}

export function getTopicChatMessages(page){
  return{
    type:FETCH_TOPIC_CHAT_MESSAGES,
    page:page
  }
}
export function unsubscribeTopicSocketChannel(){
  return {
    type:UNSUBSCRIBE_SOCKET_CHANNEL_TOPIC_MESSAGE
  }
}