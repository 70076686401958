import {
  LOAD_PAYMENTS,
  LOAD_PAYMENT_ERROR,
  LOAD_PAYMENT_SUCCESS,
} from '../constants/constants'

const initialState = {
  payments: [],
  clickedUser: [],
  isLoading: true,
  error: '',
}

const reducer = function paymentsTabReducer (state = initialState, action) {
  switch (action.type) {
    case LOAD_PAYMENTS: {
      return {
        ...state,
        isLoading: true,
        error: '',
      }
    }
    case LOAD_PAYMENT_SUCCESS: {
      return {
        ...state,
        payments: action.response.paymentDetails,
        clickedUser: action.response.clickedUser,
        isLoading: false,
      }
    }
    case LOAD_PAYMENT_ERROR: {
      return {
        ...state,
        error: action.response.message,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
