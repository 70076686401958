//import liraries
import React, { Component, useEffect, useState } from 'react'
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native'
import config from '../../config'
import PrimaryButton from '../../components/PrimaryButton'
import FIcon from 'react-native-vector-icons/SimpleLineIcons'
import { connect } from 'react-redux'
import { CommonActions } from '@react-navigation/native'
import {
  initState,
  makePayment,
  resetState,
} from '../../state-management/actions/makepayment.action'
import { setFloatingData, resetFloatingData } from '../../state-management/actions/floating.action'
import { switchAuth } from '../../state-management/actions/auth.action'
import AIcon from 'react-native-vector-icons/AntDesign';
import apiService from '../../services/api.service'

const MakePayment = props => {
  const [loaders, set_loaders] = useState({
    is_making_payment: false
  })
  useEffect(() => {
    props.resetFloatingData()
    const { transaction_details } = props.route.params
    props.initState(transaction_details)
    return () => { }
  }, [])

  useEffect(() => {
    if (props.success) {
      props.resetState()
      props.navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: 'Home' },
            {
              name: 'PaymentResult',
              params: {
                mode: props.transaction_details.mode,
                uid: props.transaction_details.to_user,
              },
            },
          ],
        }),
      )
    }
  })

  const b64toBlob = (base64, type = "application/octet-stream") => fetch(`${base64}`).then((res) => res.blob());

  const handle_buyer_create_order = async (isPayingFull) => {
    try {
      set_loaders(curr_loaders => ({ ...curr_loaders, is_making_payment: true }));
      const transaction_details = props.transaction_details;
      const Attachments = transaction_details.Attachments;
      delete transaction_details.Attachments;
      var formData = new FormData();
      Attachments.forEach(async (item, i) => {
        const b = await b64toBlob(item.uri);
        formData.append("Attachments", b, item.name);
      });
      const order_data = {
        transaction_details: { ...transaction_details, isPayingFull },
        type: 'Gig_Order',
        amount: isPayingFull ? props?.transaction_details?.total_amount : props?.milestones[0].milestoneAmount
      };
      formData.append("order_data", JSON.stringify(order_data));
      const { data } = await apiService({
        method: 'post',
        endpoint: '/app/order/sync',
        params: formData
      });
      // window.alert(data)
      window.location.href = data;
    } catch (error) {
      set_loaders(curr_loaders => ({ ...curr_loaders, is_making_payment: false }));
      window.alert(error);
    }
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ flexDirection: 'row', alignItems: 'center', padding: 20 }}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={() => props.navigation.goBack()}>
            <AIcon name='left' style={styles.backButton} />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: 26,
              fontFamily: 'Roboto-Medium',
              color: config.primary_color,
            }}>
            Make Payment
          </Text>
        </View>
        <View style={styles.inputContainer}>
          <FIcon
            name='lock'
            style={{
              fontSize: 70,
              color: config.primary_color,
              alignSelf: 'center',
            }}
          />
          {/* <Lock width={70} height={70} style={{alignSelf: 'center'}}/> */}
          <Text
            style={{
              fontFamily: 'Roboto-Regular',
              fontSize: 18,
              color: '#707070',
              lineHeight: 30,
              marginVertical: '5%',
            }}>
            Your funds are safe and secure with us. We will release the money only after you approve.</Text>
          <Text
            style={{
              fontFamily: 'Roboto-Bold',
              fontSize: 18,
              color: config.primary_color,
              marginTop: 20,
              paddingLeft: 10,
            }}>
            Full Amount : Rs {props?.transaction_details?.total_amount}
          </Text>
          <PrimaryButton
            content={'Pay full amount'}
            loading={props.isLoading || loaders.is_making_payment}
            clicked={() => {
              window.alert("Sorry for the inconvenience! We're working behind the scenes to make things better.We'll be back shortly. Thank you for your patience!")
              // if(props.auth.isLoginLater){
              //     props.setFloatingData({
              //       transaction_details:{...props.transaction_details,isPayingFull:true},
              //       type: 'Gig_Order',
              //       mode:'Make_Payment',
              //       amount:props?.transaction_details?.total_amount
              //     })
              //     props.switchAuth()
              //   }else{
              //     handle_buyer_create_order(true);
              //   // props.navigation.navigate('PaymentCheckout', {
              //   //   data: {
              //   //     transaction_details:{...props.transaction_details,isPayingFull:true},
              //   //     type: 'Gig_Order',
              //   //     amount:props?.transaction_details?.total_amount
              //   //   },
              //   // })
              // }
            }}
          />
          {props?.transaction_details?.isVirtual == false ? (
            <View>
              <View style={{ marginVertical: '10%' }}>
                <Text style={{ backgroundColor: '#FFF', fontFamily: 'Roboto-Bold', fontSize: 18, alignSelf: 'center', paddingHorizontal: 15, color: config.primary_color, }}>
                  OR
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'Roboto-Bold',
                  fontSize: 18,
                  color: config.primary_color,
                  paddingLeft: 10,
                }}>
                1st Milestone : Rs {props?.milestones[0]?.milestoneAmount}
              </Text>
              <PrimaryButton
                content={'Fund 1st Milestone'}
                loading={props.isLoading || loaders.is_making_payment}
                clicked={() => {
                  window.alert("Sorry for the inconvenience! We're working behind the scenes to make things better.We'll be back shortly. Thank you for your patience!")
                  // if(props.auth.isLoginLater){
                  //   props.setFloatingData({
                  //     transaction_details:{...props.transaction_details,isPayingFull:false},
                  //     type: 'Gig_Order',
                  //     mode:'Make_Payment',
                  //     amount:props?.milestones[0]?.milestoneAmount
                  //   })
                  //   props.switchAuth()
                  // }else{
                  // handle_buyer_create_order(false);
                  //   // props.navigation.navigate('PaymentCheckout', {
                  //   //   data: {
                  //   //     transaction_details:{...props.transaction_details,isPayingFull:false},
                  //   //     type: 'Gig_Order',
                  //   //     amount:props?.milestones[0]?.milestoneAmount
                  //   //   },
                  //   // })
                  // }
                }}
              />
            </View>
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

// define your styles
const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    width: 16,
    height: 30,
    marginRight: 30,
    marginTop: 5,
    fontSize: 25
  },
  inputContainer: {
    padding: '8%',
  },
  icon: { fontSize: 20, marginBottom: 5, color: config.primary_color },
})

export default connect(state => ({ auth: { ...state.auth }, floating: { ...state.floating }, ...state.makepayment }), {
  initState,
  makePayment,
  resetState,
  switchAuth,
  setFloatingData,
  resetFloatingData
})(MakePayment)