import React, {Component} from 'react'
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native'
import config from '../config'
import {Icon, Textarea} from 'native-base'
import InputText from './InputText'
import PrimaryButton from './PrimaryButton'
import {Formik} from 'formik'
import * as yup from 'yup'
import AIcon from 'react-native-vector-icons/AntDesign'


export default class RedoOverlay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modifications: '',
    }
  }

  componentDidMount () {
    // console.log(this.props)
  }

  handleDetails = modifications => {
    this.setState({modifications})
  }
  handleSubmit = values => {
    if (values.modifications.trim() != '') {
      this.props.onMilestoneClose(values.modifications)
    } else {
      alert('Please fill all the fields')
    }
  }

  render () {
    var {modifications} = this.state
    const validationSchema = yup.object().shape({
      modifications: yup.string()
      .label('modifications')
      .required()
      .max(1000, 'Must have at most 1000 characters'),
    })
    return (
      <Formik
        initialValues={{
          modifications: modifications,
        }}
        onSubmit={values => {
          this.handleSubmit(values)
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({
          handleChange,
          values,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isSubmitting,
        }) => (
          <SafeAreaView
            style={{
              flex: 1,
              backgroundColor: '#707070',
              justifyContent: 'flex-end',
            }}>
            <View
              style={{
                backgroundColor: '#FFF',
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 20,
                }}>
                <TouchableOpacity
                  activeOpacity={0.6}
                  onPress={() => this.props.onGoBack('close')}>
                  <AIcon name='left' style={styles.backButton} />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 22,
                    fontFamily: 'Roboto-Medium',
                    color: config.primary_color,
                  }}>
                    {this.props?.classification=='product'?
                  'Request for Replacement':'Request Redo'
                    }
                    </Text>
              </View>
              <Text
                style={{
                  fontSize: 14,
                  fontFamily: 'OpenSans-Regular',
                  color: '#464646',
                  marginBottom: 20,
                  paddingHorizontal:'8%'
                }}>
                  {this.props.message}
              </Text>
              <View style={styles.inputContainer}>
                {/* **********************************  Modifications Input *********************************** */}

                <Text
                  style={{
                    fontFamily: 'Roboto-Regular',
                    fontSize: 16,
                    color: '#464646',
                    paddingLeft: 5,
                  }}>
                  Details
                </Text>

                <Textarea
                  name='modifications'
                  rowSpan={4}
                  bordered
                  placeholder={this.props?.classification=='product'?'Enter the details for milestone replacement':'Enter the details for milestone redo'}
                  borderRadius={10}
                  placeholderTextColor={'#AAAAAA'}
                  style={{fontSize: 14, padding: 15}}
                  onChangeText={handleChange('modifications')}
                  onBlur={handleBlur('modifications')}
                  error={touched.modifications && errors.modifications}
                  value={values.modifications}
                />

                <View style={{height: 20, width: '95%', paddingTop: 3}}>
                  {touched.modifications && errors.modifications && (
                    <Text
                      style={{
                        fontSize: 12,
                        textAlign: 'right',
                        fontFamily: 'Roboto-Regular',
                        color: '#F90000',
                      }}>
                      {errors.modifications}
                    </Text>
                  )}
                </View>

                <PrimaryButton content={'Submit'} clicked={handleSubmit} />
              </View>
            </View>
          </SafeAreaView>
        )}
      </Formik>
    )
  }
}

const styles = StyleSheet.create({
  backButton: {
    color: config.primary_color,
    // height: 30,
    marginRight: 15,
    fontSize:22
  },
  icon: {fontSize: 20, marginBottom: 5, color: config.primary_color},
  inputContainer: {
    paddingHorizontal: '8%',
    paddingBottom: '10%',
  },
})
