import {takeLatest, put, call, select, take, delay} from 'redux-saga/effects';
import {eventChannel} from 'redux-saga';
import {
  INIT_MESSAGE_STATE,
  INIT_MESSAGE_STATE_SUCCESS,
  INIT_MESSAGE_STATE_ERROR,
  SEND_MESSAGE,
  SEND_MESSAGE_ERROR,
  SEND_MESSAGE_SUCCESS,
  SUBSCRIBE_MESSAGES,
  MESSAGE_PAGE_STATE,
  MESSAGE_PAGE_STATE_ERROR,
  MESSAGE_PAGE_STATE_SUCCESS,
  FETCH_CHAT_MESSAGES_SUCCESS,
  FETCH_CHAT_MESSAGES,
  FETCH_ACTIVITY_ERROR,
  FETCH_CHAT_MESSAGES_ERROR,
  UNSUBSCRIBE_SOCKET_CHANNEL_MESSAGE,
} from '../constants/constants';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import moment from 'moment';
import {
  subscribeToMessages,
  messageReceived,
  getChatMessages,
} from '../actions/messages.action';
import SocketIOClient from 'socket.io-client';
import config from '../../config';
import chatService from '../../services/chat.service';

var socket = null;
var updateChannel = null;
const execInitMessageState = async action => {
  try {
    const {data} = action;
    const {to_user} = data;

    var currentUser = firebase.auth().currentUser.uid;

    var result = await chatService.initChat({
      to_user: to_user,
    });
    const token = await firebase.auth().currentUser.getIdToken();
    return {
      current_user_id: currentUser,
      to_user_id: to_user,
      thread_id: result.thread_id,
      chatId: result.chatId,
      token: token,
    };
  } catch (error) {
    // console.log(error);
    throw {message: 'Something went wrong'};
  }
};

function* initMessageState(action) {
  try {
    const res = yield call(execInitMessageState, action);
    yield put({
      type: INIT_MESSAGE_STATE_SUCCESS,
      response: {...res},
    });
    yield put(getChatMessages(1));
    yield put(subscribeToMessages());
  } catch (error) {
    yield put({
      type: INIT_MESSAGE_STATE_ERROR,
      response: {message: error.message},
    });
  }
}

const execSendMessage = async (action, state) => {
  try {
    const {current_user_id, to_user_id, thread_id, chatId} = state;
    const {message} = action;
    var messageObject = {
      message_id: new Date().getTime(),
      message: message.message,
      date: moment(new Date().toISOString()).format('DD-MMM-YY'),
      time: moment(new Date().toISOString()).format('hh:mm a'),
      sentBy: current_user_id,
      status: 'sent',
      isAttachment: message.image ? true : false,
      attachments: message.image,
    };
    if (socket && socket.connected) {
      socket.emit('message', {
        message: messageObject,
        chatDetails: {threadId: thread_id, sentTo: to_user_id, chatId: chatId},
      });
      return messageObject;
    }
    throw {message: 'Message delivery failed'};
  } catch (error) {
    // console.log(error);
    throw {message: 'Message delivery failed'};
  }
};

function* sendMessage(action) {
  try {
    const getItems = state => state.messages;
    const currentState = yield select(getItems);
    const res = yield call(execSendMessage, action, currentState);
    yield put({type: SEND_MESSAGE_SUCCESS, response: res});
  } catch (error) {
    // console.log(error)
    yield put({type: SEND_MESSAGE_ERROR, response: {message: error.message}});
  }
}

function chatEventChannel(state) {
  const listener = eventChannel(emit => {
    socket = SocketIOClient(config.chat_server, {
      transports: ['websocket'],
      secure: true,
      path: '/chat/socket.io',
      query: {
        room: state.thread_id,
        chatId: state.chatId,
        uid: state.current_user_id,
        token: state.token,
      },
    });
    socket.on('connect_error', err => {
      // console.log('Chat connection error', err.message);
    });
    socket.on('message', function(message) {
      emit(message);
    });

    return () => {
      socket.disconnect();
    };
  });
  return listener;
}

function* subscribe() {
  try {
    const getItems = state => state.messages;
    const currentState = yield select(getItems);
    updateChannel = chatEventChannel(currentState);
    while (true) {
      const item = yield take(updateChannel);
      yield put(messageReceived(item));
    }
  } catch (error) {
    // console.log(error);
  }
}

const updateMessagePageState = async (action, state) => {
  try {
    const thread_id = state.thread_id;
    var result = await chatService.updateChatPageState({
      thread_id: thread_id,
    });
    return;
  } catch (error) {
    throw {message: 'Something went wrong'};
  }
};

function* setMessageState(action) {
  try {
    const getItems = state => state.messages;
    const currentState = yield select(getItems);
    const res = yield call(updateMessagePageState, action, currentState);
  } catch (error) {
    // console.log(error)
    yield put({
      type: MESSAGE_PAGE_STATE_ERROR,
      response: {message: error.message},
    });
  }
}

const execFetchChatMessages = async (action, state) => {
  try {
    const thread_id = state.thread_id;
    const chat_id = state.chatId;
    const page = action.page;
    const result = await chatService.fetchMessages({
      thread_id: thread_id,
      chatId: chat_id,
      page: page,
    });
    return result;
  } catch (error) {
    throw {message: 'Something went wrong'};
  }
};

function* fetchChatMessages(action) {
  try {
    const getItems = state => state.messages;
    const currentState = yield select(getItems);
    const res = yield call(execFetchChatMessages, action, currentState);
    yield put({type: FETCH_CHAT_MESSAGES_SUCCESS, response: res});
  } catch (error) {
    // console.log(error);
    yield put({
      type: FETCH_CHAT_MESSAGES_ERROR,
      response: {message: error.message},
    });
  }
}

function* watchMessages() {
  yield takeLatest(INIT_MESSAGE_STATE, initMessageState);
  yield takeLatest(SEND_MESSAGE, sendMessage);
  yield takeLatest(SUBSCRIBE_MESSAGES, subscribe);
  yield takeLatest(MESSAGE_PAGE_STATE, setMessageState);
  yield takeLatest(FETCH_CHAT_MESSAGES, fetchChatMessages);
  yield takeLatest(UNSUBSCRIBE_SOCKET_CHANNEL_MESSAGE, function*() {
    if (updateChannel) {
      updateChannel.close();
    }
  });
}

export default watchMessages;
