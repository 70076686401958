import React, { useEffect,useState } from 'react';
import {View, Text, Modal, TouchableOpacity,} from 'react-native'
import config from '../../config';

const BankTransferOption = (props) => {
    return (
        <View style={{backgroundColor:'#F3F3F3',borderRadius:20,paddingHorizontal:20,paddingVertical:20}}>
            {/* <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#707070',marginBottom:20}}>For payments above ₹ 20,000</Text> */}
            <Text style={{fontSize: 14,fontFamily: 'Roboto-Regular',color: '#707070',marginBottom:20}}>Temporarily unavailable</Text>
            <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:8}}>
            <Text style={{fontSize: 18,fontFamily: 'OpenSans-Regular',color: '#000'}}>Bank Transfer</Text>
            {props.amount>=20000?
            <TouchableOpacity
            onPress={()=>{props.createAccount()}}
            style={{backgroundColor:config.primary_color,borderRadius:100,justifyContent:'center',paddingHorizontal:23,paddingVertical:4}}>
                <Text style={{fontSize: 16,fontFamily: 'Roboto-Regular',color: '#FFF'}}>Pay</Text>
            </TouchableOpacity>
            :
            <View
            style={{backgroundColor:'#707070',borderRadius:100,justifyContent:'center',paddingHorizontal:23,paddingVertical:4}}>
                <Text style={{fontSize: 16,fontFamily: 'Roboto-Regular',color: '#FFF'}}>Pay</Text>
            </View>
            }
            </View>
            <Text style={{fontSize: 12,fontFamily: 'Roboto-Regular',color: '#707070',paddingBottom:15}}>IMPS | NEFT | RTGS</Text>
            <Text style={{fontSize: 10,fontFamily: 'Roboto-Regular',color: '#707070',paddingBottom:25}}>(no payment gateway charges)</Text>
        </View>        
    );
}

export default BankTransferOption;