import {
  AUTH_CHECK,
  LOGOUT,
  LOGIN_LATER,
  SWITCH_TO_AUTH
} from '../constants/constants'

export function checkAuth () {
  return {
    type: AUTH_CHECK,
  }
}


export function logout () {
  return {
    type: LOGOUT,
  }
}

export function loginLater () {
  return {
    type: LOGIN_LATER,
  }
}

export function switchAuth () {
  return{
    type: SWITCH_TO_AUTH
  }
}