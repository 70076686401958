import {
  VERIFY_OTP_ERROR,
  VERIFY_INIT,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  RE_VERIFY_OTP,
  RE_VERIFY_OTP_SUCCESS,
  RE_VERIFY_OTP_ERROR,
} from '../constants/constants'

const initialState = {
  isLoading: false,
  error: '',
  success: '',
  confirm: null,
  mobile: null,
  page: '',
  signupData: {},
}

const reducer = function otpReducer (state = initialState, action) {

  switch (action.type) {
    case VERIFY_INIT: {
      return {
        ...state,
        isLoading: false,
        confirm: action.confirm,
        mobile: action.mobile,
        page: action.page,
        signupData: action.signupData,
      }
    }
    case VERIFY_OTP: {
      return {
        ...state,
        isLoading: true,
        error: '',
        success: '',
      }
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: '',
        success: '',
      }
    }
    case VERIFY_OTP_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
      }
    }
    case RE_VERIFY_OTP: {
      return {
        ...state,
        isLoading: true,
        error: '',
        success: '',
      }
    }
    case RE_VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        confirm: action.response.confirm,
        success: 'Otp has been resent to your mobile successfully',
      }
    }
    case RE_VERIFY_OTP_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.response.message,
        success: '',
      }
    }
    default:
      return state
  }
}

export default reducer
